import { takeLatest, call, put, all } from 'redux-saga/effects';
import { auth, getCurrentUser } from '../../firebase/firebase.utils';
import Swal from 'sweetalert2';
import _ from 'lodash';

import {
  loginWholesaleUserSuccess,
  loginWholesaleUserFailure,
  logoutWholesaleUserSuccess,
  logoutWholesaleUserFailure,
  checkWholesaleUserSession,
  fetchPaymentMethodsForCustomerIdSuccess,
  fetchPaymentMethodsForCustomerIdFailure,
  sendPasswordResetEmailSuccess,
  sendPasswordResetEmailFailure,
  detachPaymentMethodSuccess,
  detachPaymentMethodFailure,
  unsubscribeCustomerSuccess,
  unsubscribeCustomerFailure,
  fetchWholesaleUserDocSuccess,
  fetchWholesaleUserDocFailure,
} from './user.actions';

import {
  fetchShopDataStart,
  fetchShopDataSuccess,
  resetShopDataAndCollectionsOrderingAndItemsOrdering,
} from '../shop/shop.actions';

import { resetCartStateAfterLogoutOrLogin } from '../cart/cart.actions';

import UserActionTypes from './user.types';
import { userApi as api, shopApi } from '../../services/';
import { adjustShopDataToWholesalePrices } from './user.utils';
import { ORDERTYPES_TO_SINGULAR_TYPES, PICKUP } from '../../global.constants';

// ------------------------------------------------------
//  WORkER SAGAS
// ------------------------------------------------------
export function* loginWholesaleUser({ payload: { loginDetails, config } }) {
  try {
    const { orderTypesWholesale } = config;
    const { email, password } = loginDetails;
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    const wholesaleUserDetails = yield call(api.wholesaleUserDetails, user);

    if (_.size(wholesaleUserDetails) === 1 && wholesaleUserDetails.key) {
      // if the whoods RN user tries to login to wholesale they will
      // be allowed do in firebase, but there will not be a corresponding
      // wholesaleCustomers doc present (it can't be added b/c firebase already
      // has a user acc for admin email address).
      // so we get a wholesaleUserDetails obj back containing only the key (uuid)
      //
      // workaround: check if there's only 1 field in obj, and that it's the key,
      // then abort login saga
      throw new Error('whoods app user has tried to login. Aborting...');
    }

    // update the shop data to reflect the pricing tiers associated with wholesale cust
    // 1. get shop data (the long way)
    let shopData = {};
    shopData['collections'] = yield call(
      shopApi.fetchCollection,
      'collections'
    );
    shopData['items'] = yield call(shopApi.fetchCollection, 'items');
    shopData['customizations'] = yield call(
      shopApi.fetchCollection,
      'customizations'
    );
    shopData['options'] = yield call(shopApi.fetchCollection, 'options');

    const collectionsOrder = yield call(
      shopApi.fetchCollection,
      'collectionsOrder'
    );
    const itemsOrder = yield call(shopApi.fetchCollection, 'itemsOrder');

    const updatedShopData = adjustShopDataToWholesalePrices(
      shopData,
      wholesaleUserDetails
    );

    // values of any field in ORDERTYPES_TO_SINGULAR_TYPES is always an array of at least size 1
    const defaultOrderType = ORDERTYPES_TO_SINGULAR_TYPES[orderTypesWholesale]
      ? ORDERTYPES_TO_SINGULAR_TYPES[orderTypesWholesale][0]
      : PICKUP;
    yield put(resetCartStateAfterLogoutOrLogin(defaultOrderType));

    yield put(
      fetchShopDataSuccess(updatedShopData, collectionsOrder, itemsOrder)
    );
    yield put(loginWholesaleUserSuccess(wholesaleUserDetails));
  } catch (error) {
    console.log(error);
    yield put(loginWholesaleUserFailure(`Login failure, try again.`));
  }
}

export function* logoutWholesaleUser({ payload: { config, navigate } }) {
  try {
    const { orderTypesRetail } = config;
    yield auth.signOut();
    yield put(logoutWholesaleUserSuccess());
    yield put(resetShopDataAndCollectionsOrderingAndItemsOrdering());

    // values of any field in ORDERTYPES_TO_SINGULAR_TYPES is always an array of at least size 1
    const defaultOrderType = ORDERTYPES_TO_SINGULAR_TYPES[orderTypesRetail]
      ? ORDERTYPES_TO_SINGULAR_TYPES[orderTypesRetail][0]
      : PICKUP;
    yield put(resetCartStateAfterLogoutOrLogin(defaultOrderType));

    // call fetch shop data with wholesaleUserDetails === null
    // => this signifies that the user is logged out ie. the default, logged-out
    // state of wholesaleUserDetails is null
    yield put(fetchShopDataStart(null, true));
    yield put(checkWholesaleUserSession());
    yield call(navigate, '/');
  } catch (error) {
    console.log(error);
    yield put(
      logoutWholesaleUserFailure('ERROR: logout wholesale user failure')
    );
  }
}

export function* isWholesaleUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();

    if (!userAuth) return;

    const wholesaleUserDetails = yield call(api.wholesaleUserDetails, userAuth);
    yield put(loginWholesaleUserSuccess(wholesaleUserDetails));
  } catch (error) {
    console.log(error);
    yield put(loginWholesaleUserFailure(`Login failure, try again.`));
  }
}

export function* fetchPaymentMethodsForCustomerId({
  payload: { customerId, shopDetails },
}) {
  try {
    const paymentMethods = yield call(
      api.fetchPaymentMethodsForCustomerId,
      customerId,
      shopDetails
    );
    yield put(fetchPaymentMethodsForCustomerIdSuccess(paymentMethods));
  } catch (error) {
    console.log(error);
    yield put(
      fetchPaymentMethodsForCustomerIdFailure(
        'Failure to fetch payment methods for customer id'
      )
    );
  }
}

export function* sendPasswordResetEmail({ payload: { email } }) {
  try {
    yield auth.sendPasswordResetEmail(email);
    yield auth.signOut();
    yield put(sendPasswordResetEmailSuccess());
    // need to send the context ie 'this' value
    // to Swal.fire metho
    yield call(
      { context: Swal, fn: Swal.fire },
      'Success: sent password reset email.'
    );
  } catch (error) {
    console.log(error);
    yield put(sendPasswordResetEmailFailure('Failed'));
  }
}

export function* detachPaymentMethod({
  payload: { paymentMethodId, shopDetails },
}) {
  try {
    yield call(api.detachPaymentMethod, paymentMethodId, shopDetails);
    yield put(detachPaymentMethodSuccess(paymentMethodId));
  } catch (error) {
    console.log(error);
    yield put(detachPaymentMethodFailure('Failed to detach payment method'));
  }
}

export function* unsubscribeCustomer({
  payload: { customerDetails, shopDetails },
}) {
  try {
    yield call(api.unsubscribeCustomer, customerDetails, shopDetails);
    yield put(unsubscribeCustomerSuccess('Successfully Unsubscribed'));
  } catch (error) {
    console.log(error);
    yield put(unsubscribeCustomerFailure('Failed to unsubscribe user'));
  }
}

export function* fetchWholesaleUserDoc({ payload: { wholesaleUserDetails } }) {
  try {
    const { key } = wholesaleUserDetails;
    const doc = yield call(api.fetchWholesaleUserDoc, key);
    yield put(fetchWholesaleUserDocSuccess(doc));
  } catch (error) {
    console.log(error);
    yield put(
      fetchWholesaleUserDocFailure(
        'Failed to fetch wholesale user doc for docKey'
      )
    );
  }
}

// ------------------------------------------------------
//  LISTENER SAGAS
// ------------------------------------------------------
export function* loginWholesaleUserStart() {
  yield takeLatest(
    UserActionTypes.LOGIN_WHOLESALE_USER_START,
    loginWholesaleUser
  );
}

export function* logoutWholesaleUserStart() {
  yield takeLatest(
    UserActionTypes.LOGOUT_WHOLESALE_USER_START,
    logoutWholesaleUser
  );
}

export function* checkWholesaleUserSessionStart() {
  yield takeLatest(
    UserActionTypes.CHECK_WHOLESALE_USER_SESSION,
    isWholesaleUserAuthenticated
  );
}

export function* fetchPaymentMethodsForCustomerIdStart() {
  yield takeLatest(
    UserActionTypes.FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_START,
    fetchPaymentMethodsForCustomerId
  );
}

export function* sendPasswordResetEmailStart() {
  yield takeLatest(
    UserActionTypes.SEND_PASSWORD_RESET_EMAIL_START,
    sendPasswordResetEmail
  );
}

export function* detachPaymentMethodStart() {
  yield takeLatest(
    UserActionTypes.DETACH_PAYMENT_METHOD_START,
    detachPaymentMethod
  );
}

export function* unsubscribeCustomerStart() {
  yield takeLatest(
    UserActionTypes.UNSUBSCRIBE_CUSTOMER_START,
    unsubscribeCustomer
  );
}

export function* fetchWholesaleUserDocStart() {
  yield takeLatest(
    UserActionTypes.FETCH_WHOLESALE_USER_DOC_START,
    fetchWholesaleUserDoc
  );
}

export function* userSagas() {
  yield all([
    call(loginWholesaleUserStart),
    call(logoutWholesaleUserStart),
    call(checkWholesaleUserSessionStart),
    call(fetchPaymentMethodsForCustomerIdStart),
    call(sendPasswordResetEmailStart),
    call(detachPaymentMethodStart),
    call(unsubscribeCustomerStart),
    call(fetchWholesaleUserDocStart),
  ]);
}
