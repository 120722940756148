import { firestore } from '../firebase/firebase.utils';

export const fetchCollection = async (collectionName) => {
  let collection = {};
  try {
    const ref = firestore.collection(collectionName);
    const snapshot = await ref.get();

    if (snapshot.size !== 1) {
      // should only ever have 1 document in config collection
      throw new Error(
        'Error: should only have 1 document in config collection'
      );
    }

    snapshot.forEach((doc) => {
      const docData = doc.data();
      const key = doc.id;
      collection = {
        key,
        ...docData,
      };
    });
    return collection;
  } catch (error) {
    console.log(error);
    throw new Error('Error: should only have 1 document in config collection');
  }
};
