import styled from 'styled-components';

import { black, mobileSplitPoint } from '../../style-constants';

export const Container = styled.div`
  margin-bottom: 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding: 10px;
  }
`;
Container.displayName = 'Container';

export const OperatingHoursContainer = styled.div``;
OperatingHoursContainer.displayName = 'OperatingHoursContainer';

export const Title = styled.div`
  border-bottom: 1px solid ${black};
  margin-bottom: 7px;
  padding-bottom: 3px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin-bottom: 5px;
  }
`;
Title.displayName = 'Title';
