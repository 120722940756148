import React from 'react';

import {
  OrderMethodContainer,
  TopContainer,
  HeaderContainer,
  Title,
  ButtonContainer,
  PickupButton,
  DeliveryButton,
  DineInButton,
  DeliveryDistanceContainer,
  DeliveryDistance,
} from './order-method.styles';

import {
  DELIVERY_CHECK_TYPE_POSTCODES,
  DELIVERY,
  PICKUP,
  DINE_IN,
  PICKUP_DELIVERY,
  PICKUP_DINE_IN,
  DELIVERY_DINE_IN,
  PICKUP_DELIVERY_DINE_IN,
} from '../../global.constants';

export const OrderMethod = ({
  orderType,
  handleOrderTypeClick,
  shopAddressSearchResultsRadius,
  config,
  wholesaleUserDetails,
}) => {
  const {
    deliveryToAddressCheckType,
    displayMaxDeliveryDistance,
    orderTypesRetail,
    orderTypesWholesale,
  } = config;

  let _orderTypes = wholesaleUserDetails
    ? orderTypesWholesale
    : orderTypesRetail;

  const createButtons = () => {
    return (
      <ButtonContainer>
        {(_orderTypes === PICKUP ||
          _orderTypes === PICKUP_DELIVERY ||
          _orderTypes === PICKUP_DINE_IN ||
          _orderTypes === PICKUP_DELIVERY_DINE_IN) && (
          <PickupButton
            onClick={() => handleOrderTypeClick(PICKUP)}
            isSelected={orderType === PICKUP}
          >
            Pickup
          </PickupButton>
        )}
        {(_orderTypes === DELIVERY ||
          _orderTypes === PICKUP_DELIVERY ||
          _orderTypes === DELIVERY_DINE_IN ||
          _orderTypes === PICKUP_DELIVERY_DINE_IN) && (
          <DeliveryButton
            onClick={() => handleOrderTypeClick(DELIVERY)}
            isSelected={orderType === DELIVERY}
          >
            Delivery
          </DeliveryButton>
        )}

        {(_orderTypes === DINE_IN ||
          _orderTypes === PICKUP_DINE_IN ||
          _orderTypes === DELIVERY_DINE_IN ||
          _orderTypes === PICKUP_DELIVERY_DINE_IN) && (
          <DineInButton
            onClick={() => handleOrderTypeClick(DINE_IN)}
            isSelected={orderType === DINE_IN}
          >
            Dine In
          </DineInButton>
        )}
      </ButtonContainer>
    );
  };

  return (
    <OrderMethodContainer>
      <TopContainer>
        <HeaderContainer>
          {orderType && <Title>{`Order - ${orderType.toUpperCase()}`}</Title>}
        </HeaderContainer>
      </TopContainer>
      {createButtons()}
      {(_orderTypes === DELIVERY ||
        _orderTypes === PICKUP_DELIVERY ||
        _orderTypes === DELIVERY_DINE_IN ||
        _orderTypes === PICKUP_DELIVERY_DINE_IN) &&
        deliveryToAddressCheckType !== DELIVERY_CHECK_TYPE_POSTCODES &&
        displayMaxDeliveryDistance &&
        !!shopAddressSearchResultsRadius && (
          <DeliveryDistanceContainer>
            <DeliveryDistance>{`Max delivery distance is ${(
              shopAddressSearchResultsRadius / 1000.0
            ).toFixed(0)}km`}</DeliveryDistance>
          </DeliveryDistanceContainer>
        )}
    </OrderMethodContainer>
  );
};

export default OrderMethod;
