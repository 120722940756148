import ShopActionTypes from './shop.types';

export const fetchShopDataStart = (
  wholesaleUserDetails,
  showLoadingState = true
) => ({
  type: ShopActionTypes.FETCH_SHOP_DATA_START,
  payload: {
    wholesaleUserDetails,
    showLoadingState,
  },
});

export const fetchShopDataSuccess = (
  shopData,
  collectionsOrder,
  itemsOrder
) => ({
  type: ShopActionTypes.FETCH_SHOP_DATA_SUCCESS,
  payload: {
    shopData,
    collectionsOrder,
    itemsOrder,
  },
});

export const fetchShopDataFailure = (errorMessage) => ({
  type: ShopActionTypes.FETCH_SHOP_DATA_FAILURE,
  payload: {
    errorMessage,
  },
});

export const fetchOperatingHoursStart = () => ({
  type: ShopActionTypes.FETCH_OPERATING_HOURS_START,
});

export const fetchOperatingHoursSuccess = (operatingHours) => ({
  type: ShopActionTypes.FETCH_OPERATING_HOURS_SUCCESS,
  payload: {
    operatingHours,
  },
});

export const fetchOperatingHoursFailure = (errorMessage) => ({
  type: ShopActionTypes.FETCH_OPERATING_HOURS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const fetchShopDetailsStart = () => ({
  type: ShopActionTypes.FETCH_SHOP_DETAILS_START,
});

export const fetchShopDetailsSuccess = (shopDetails) => ({
  type: ShopActionTypes.FETCH_SHOP_DETAILS_SUCCESS,
  payload: {
    shopDetails,
  },
});

export const fetchShopDetailsFailure = (errorMessage) => ({
  type: ShopActionTypes.FETCH_SHOP_DETAILS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const addItemToModal = (item, collection) => ({
  type: ShopActionTypes.ADD_ITEM_TO_MODAL,
  payload: {
    item,
    collection,
  },
});

export const setShowItemModal = (show) => ({
  type: ShopActionTypes.SET_SHOW_ITEM_MODAL,
  payload: show,
});

export const incrementQuantityBy = (quantity) => ({
  type: ShopActionTypes.INCREMENT_QUANTITY_BY,
  payload: {
    quantity,
  },
});

export const setSelectedPrice = (selectedPrice) => ({
  type: ShopActionTypes.SET_SELECTED_PRICE,
  payload: {
    selectedPrice,
  },
});

export const setUserInstructions = (userInstructions) => ({
  type: ShopActionTypes.SET_USER_INSTRUCTIONS,
  payload: {
    userInstructions,
  },
});

export const resetItemModal = () => ({
  type: ShopActionTypes.RESET_ITEM_MODAL,
});

export const addCustomizationOption = (customization) => ({
  type: ShopActionTypes.ADD_CUSTOMIZATION_OPTION,
  payload: customization,
});

export const removeCustomizationOption = (customization) => ({
  type: ShopActionTypes.REMOVE_CUSTOMIZATION_OPTION,
  payload: customization,
});

export const addBackAllTheUserCustomizationsForCheckoutItem = (
  userCustomizations
) => ({
  type: ShopActionTypes.ADD_BACK_ALL_THE_USER_CUSTOMIZATIONS_FOR_CHECKOUT_ITEM,
  payload: userCustomizations,
});

export const setIsUpdatingItem = (isUpdating) => ({
  type: ShopActionTypes.SET_IS_UPDATING_ITEM,
  payload: isUpdating,
});

export const resetShopDataAndCollectionsOrderingAndItemsOrdering = () => ({
  type: ShopActionTypes.RESET_SHOP_DATA_AND_COLLECTIONS_ORDERING_AND_ITEMS_ORDERING,
});

export const setSearchValue = (searchValue) => ({
  type: ShopActionTypes.SET_SEARCH_VALUE,
  payload: {
    searchValue,
  },
});

export const fetchOverrideDaysStart = () => ({
  type: ShopActionTypes.FETCH_OVERRIDE_DAYS_START,
});

export const fetchOverrideDaysSuccess = (overrideDays) => ({
  type: ShopActionTypes.FETCH_OVERRIDE_DAYS_SUCCESS,
  payload: {
    overrideDays,
  },
});

export const fetchOverrideDaysFailure = (errorMessage) => ({
  type: ShopActionTypes.FETCH_OVERRIDE_DAYS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setIsHeartedStart = (isHearted, item) => ({
  type: ShopActionTypes.SET_IS_HEARTED_START,
  payload: {
    isHearted,
    item,
  },
});

export const setIsHeartedSuccess = (isHearted) => ({
  type: ShopActionTypes.SET_IS_HEARTED_SUCCESS,
  payload: {
    isHearted,
  },
});

export const setIsHeartedFailure = (errorMessage) => ({
  type: ShopActionTypes.SET_IS_HEARTED_FAILURE,
  payload: {
    errorMessage,
  },
});

export const fetchDeliveryZonesStart = () => ({
  type: ShopActionTypes.FETCH_DELIVERY_ZONES_START,
});

export const fetchDeliveryZonesSuccess = (deliveryZones) => ({
  type: ShopActionTypes.FETCH_DELIVERY_ZONES_SUCCESS,
  payload: {
    deliveryZones,
  },
});

export const fetchDeliveryZonesFailure = (errorMessage) => ({
  type: ShopActionTypes.FETCH_DELIVERY_ZONES_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setShowDeliveryZonesModal = (show) => ({
  type: ShopActionTypes.SET_SHOW_DELIVERY_ZONES_MODAL,
  payload: {
    show,
  },
});

export const fetchOrdersThrottlingForDayStart = (day) => ({
  type: ShopActionTypes.FETCH_ORDERS_THROTTLING_FOR_DAY_START,
  payload: {
    day,
  },
});

export const fetchOrdersThrottlingForDaySuccess = (throttleDayData) => ({
  type: ShopActionTypes.FETCH_ORDERS_THROTTLING_FOR_DAY_SUCCESS,
  payload: {
    throttleDayData,
  },
});

export const fetchOrdersThrottlingForDayFailure = (errorMessage) => ({
  type: ShopActionTypes.FETCH_ORDERS_THROTTLING_FOR_DAY_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setShowDynamicDeliveryFeeInfoModal = (show) => ({
  type: ShopActionTypes.SET_SHOW_DYNAMIC_DELIVERY_FEE_INFO_MODAL,
  payload: {
    show,
  },
});

export const setShowShopInfoModal = (show) => ({
  type: ShopActionTypes.SET_SHOW_SHOP_INFO_MODAL,
  payload: {
    show,
  },
});
