import { createSelector } from 'reselect';

import { calcCartTotal, calcCartSubtotal } from '../../redux/cart/cart.utils';

const selectCart = (state) => state.cart;

export const selectCartItems = createSelector(
  [selectCart],
  (cart) => cart.cartItems
);

export const selectCartHidden = createSelector(
  [selectCart],
  (cart) => cart.hidden
);

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  (cartItems) =>
    cartItems.reduce((accumulator, item) => {
      return accumulator + item.quantity;
    }, 0)
);

export const selectCartTotal = createSelector([selectCartItems], (cartItems) =>
  calcCartTotal(cartItems)
);

export const selectOrderIsPaid = createSelector([(state) => state], (state) => {
  return state.cart.orderIsPaid;
});

export const selectIsLoading = createSelector(
  [selectCart],
  (cart) => cart.isLoading
);

export const selectIsLoadingPromo = createSelector(
  [selectCart],
  (cart) => cart.isLoadingPromo
);

export const selectOrderType = createSelector(
  [selectCart],
  (cart) => cart.orderType
);

export const selectPurchasedCart = createSelector(
  [selectCart],
  (cart) => cart.purchasedCart
);

export const selectFastDisable = createSelector(
  [selectCart],
  (cart) => cart.fastDisable
);

export const selectPaymentErrorMessage = createSelector(
  [selectCart],
  (cart) => cart.paymentErrorMessage
);

export const selectDeliveryFee = createSelector(
  [selectCart],
  (cart) => cart.deliveryFee
);

export const selectDeliveryFeePostcodes = createSelector(
  [selectCart],
  (cart) => cart.deliveryFeePostcodes
);

export const selectPromotionFee = createSelector(
  [selectCart],
  (cart) => cart.promotionFee
);

export const selectBookingFee = createSelector(
  [selectCart],
  (cart) => cart.bookingFee
);

export const selectPromotion = createSelector(
  [selectCart],
  (cart) => cart.promotion
);

export const selectDeliveryFeeDynamic = createSelector(
  [selectCart],
  (cart) => cart.deliveryFeeDynamic
);

export const selectSavedOrders = createSelector(
  [selectCart],
  (cart) => cart.savedOrders
);

export const selectShouldSaveOrder = createSelector(
  [selectCart],
  (cart) => cart.shouldSaveOrder
);

export const selectPreviouslyUsedPromotions = createSelector(
  [selectCart],
  (cart) => cart.previouslyUsedPromotions
);
