import { createSelector } from 'reselect';
import _ from 'lodash';

const selectShop = (state) => state.shop;

export const selectShopData = createSelector(
  [selectShop],
  (shop) => shop.shopData
);

export const selectCollectionsOrder = createSelector(
  [selectShop],
  (shop) => shop.collectionsOrder
);

export const selectItemForModal = createSelector(
  [selectShop],
  (shop) => shop.itemModal.item
);

export const selectCollectionForItemModal = createSelector(
  [selectShop],
  (shop) => shop.itemModal.collection
);

export const selectShowItemModal = createSelector(
  [selectShop],
  (shop) => shop.showItemModal
);

export const selectItemModalUserCustomizations = createSelector(
  [selectShop],
  (shop) => shop.itemModal.userCustomizations
);

export const selectIsUpdatingItem = createSelector(
  [selectShop],
  (shop) => shop.itemModal.isUpdating
);

export const selectOperatingHours = createSelector(
  [selectShop],
  (shop) => shop.operatingHours
);

export const selectItemsOrder = createSelector(
  [selectShop],
  (shop) => shop.itemsOrder
);

export const selectShopDetails = createSelector(
  [selectShop],
  (shop) => shop.shopDetails
);

export const selectSelectedPrice = createSelector(
  [selectShop],
  (shop) => shop.itemModal.selectedPrice
);

export const selectUserInstructions = createSelector(
  [selectShop],
  (shop) => shop.itemModal.userInstructions
);

export const selectIsFetching = createSelector(
  [selectShop],
  (shop) => shop.isFetching
);

export const selectSearchValue = createSelector(
  [selectShop],
  (shop) => shop.searchValue
);

export const selectOverrideDays = createSelector(
  [selectShop],
  (shop) => shop.overrideDays
);

export const selectDeliveryZones = createSelector(
  [selectShop],
  (shop) => shop.deliveryZones
);

export const selectShowDeliveryZonesModal = createSelector(
  [selectShop],
  (shop) => shop.showDeliveryZonesModal
);

export const selectOrdersThrottling = createSelector(
  [selectShop],
  (shop) => shop.ordersThrottling
);

export const selectIsFetchingThrottlingData = createSelector(
  [selectShop],
  (shop) => shop.isFetchingThrottlingData
);

export const selectShowDynamicDeliveryFeeInfoModal = createSelector(
  [selectShop],
  (shop) => shop.showDynamicDeliveryFeeInfoModal
);

export const selectShowShopInfoModal = createSelector(
  [selectShop],
  (shop) => shop.showShopInfoModal
);
