import _ from 'lodash';

export const adjustShopDataToWholesalePrices = (
  _shopData,
  wholesaleUserDetails
) => {
  const shopData = _.cloneDeep(_shopData);
  const { collectionKeyToTier } = wholesaleUserDetails;
  const translation = _.reduce(
    shopData.items,
    (accum, item) => {
      const { key, salesOutlets } = item;
      // dont make any adjustments for items that dont have salesOutlets field
      if (!salesOutlets) return accum;
      // dont make any adjustments for items that dont appear in wholesale offering
      if (salesOutlets === 'retailOnly') return accum;
      // find the collection the item belongs to
      const collectionKeyForItem = _.reduce(
        shopData.collections,
        (accum, collection) => {
          const { key: collectionKey, items } = collection;
          const possibleMatch = _.find(items, (_, itemKey) => itemKey === key);
          if (possibleMatch) {
            accum = collectionKey;
            return accum;
          }
          return accum;
        },
        ''
      );
      if (collectionKeyForItem) {
        accum[key] = collectionKeyForItem;
      }
      return accum;
    },
    {}
  );

  const updatedItems = _.reduce(
    translation,
    (accum, collectionKey, itemKey) => {
      const item = shopData.items[itemKey];
      const { prices } = item;
      const updatedPrices = _.reduce(
        prices,
        (innerAccum, priceObj) => {
          const { size, tierPricing } = priceObj;
          if (!collectionKeyToTier) {
            // if for some reason collectionKeyToTier is not present in wholesaleUserDetails
            // just return the same pricing - no updating
            innerAccum[size] = priceObj;
            return innerAccum;
          }
          const tier = collectionKeyToTier[collectionKey];
          const newPrice = tierPricing[tier];
          innerAccum[size] = {
            ...priceObj,
            price: newPrice,
          };
          return innerAccum;
        },
        {}
      );
      accum[itemKey] = {
        ...item,
        prices: updatedPrices,
      };
      return accum;
    },
    {}
  );

  const mergedItems = {
    ...shopData.items,
    ...updatedItems,
  };

  shopData.items = mergedItems;

  return shopData;
};
