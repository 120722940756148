import styled, { css } from 'styled-components';

import {
  darkerGray,
  black,
  white,
  red,
  redLight,
  lightGray,
  veryLightGray,
} from '../../style-constants';

const shrinkLabel = css`
  color: ${black};
  top: -18px;
  font-size: 12px;
`;

export const GroupContainer = styled.div`
  /* border: 2px solid ${({ error }) => (error ? red : 'none')}; */
  position: relative;
  margin: 50px 0px 0px 0px;
    input[type='password'] {
    letter-spacing: 0.3em;
  }

  @media screen and (max-width: 800) {
  }
`;
GroupContainer.displayName = 'GroupContainer';

export const FormInputContainer = styled.input`
  background-color: ${veryLightGray};
  border-radius: 3px;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid ${black};
  margin: 20px 0px 0px 0px;

  &:focus {
    outline: none;
  }

  &:focus ~ label {
    ${shrinkLabel}
  }
`;
FormInputContainer.displayName = 'FormInputContainer';

export const FormInputLabel = styled.label`
  color: ${black};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: -20px;
  /* top: 10px; */
  transition: 300ms ease all;

  &.shrink {
    ${shrinkLabel}
  }
`;
FormInputLabel.displayName = 'FormInputLabel';

export const LittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
LittleContainer.displayName = 'LittleContainer';

export const Required = styled.div`
  color: ${red};
  margin-left: 10px;
`;
Required.displayName = 'Required';

export const Label = styled.div`
  padding-bottom: 3px;
`;
Label.displayName = 'Label';
