import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTransition, animated } from 'react-spring';

import { toggleCartHidden, setCartHidden } from '../../redux/cart/cart.actions';
import {
  selectCartItemsCount,
  selectCartHidden,
} from '../../redux/cart/cart.selectors';
import { setIsWholesaleDropdownHidden } from '../../redux/user/user.actions';

import {
  CartContainer,
  ShoppingIcon,
  ItemCountContainer,
  ItemCount,
} from './cart-icon.styles';

export const CartIcon = ({
  setCartHidden,
  itemCount,
  isCartHidden,
  setIsWholesaleDropdownHidden,
}) => {
  const handleCartDropDownClick = () => {
    setCartHidden(!isCartHidden);
    setIsWholesaleDropdownHidden(true);
  };

  const transitions = useTransition(itemCount, null, {
    from: { transform: 'translate3d(0,-50px,0)' },
    enter: { transform: 'translate3d(0,0px,0)' },
    leave: { transform: 'translate3d(0,-50px,0)' },
  });

  return (
    <CartContainer onClick={handleCartDropDownClick}>
      <ShoppingIcon transform={'scale(1.1)'} />
      {transitions.map(({ item, key, props }) => (
        <animated.div key={key} style={props}>
          <ItemCountContainer itemCount={itemCount}>
            <ItemCount>{itemCount}</ItemCount>
          </ItemCountContainer>
        </animated.div>
      ))}
    </CartContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  itemCount: selectCartItemsCount,
  isCartHidden: selectCartHidden,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCartHidden: () => dispatch(toggleCartHidden()),
  setCartHidden: (isHidden) => dispatch(setCartHidden(isHidden)),
  setIsWholesaleDropdownHidden: (isHidden) =>
    dispatch(setIsWholesaleDropdownHidden(isHidden)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);
