import styled from 'styled-components';

import TemplateButton from '../template-button/template-button.component';

import {
  orange,
  mobileSplitPoint,
  black,
  veryLightGray,
  darkerGray,
  lightTextGray,
  red,
  white,
} from '../../style-constants';

export const CheckoutCartDesktopContainer = styled.div`
  background-color: ${veryLightGray};
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 20px 0px 40px 10px;
  overflow-y: hidden;
  width: 61%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: none;
  }
`;
CheckoutCartDesktopContainer.displayName = 'CheckoutCartDesktopContainer';

export const InnerContainer = styled.div`
  height: 90%;
  padding: 20px;
`;
InnerContainer.displayName = 'InnerContainer';

export const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0px 30px 0px;
`;
TitleContainer.displayName = 'TitleContainer';

export const InnerTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
InnerTitleContainer.displayName = 'InnerTitleContainer';

export const OrderTypeContainer = styled.div`
  align-items: center;
  background-color: ${black};
  border: 2px solid ${black};
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 25px;
  position: absolute;
  right: 25px;
  top: 4px;
`;
OrderTypeContainer.displayName = 'OrderTypeContainer';

export const OrderTypeText = styled.div`
  color: ${white};
  font-weight: 700;
  font-size: 20px;
  padding: 5px 10px;
`;
OrderTypeText.displayName = 'OrderTypeText';

export const CheckoutItemsContainer = styled.div``;
CheckoutItemsContainer.displayName = 'CheckoutItemsContainer';

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
Title.displayName = 'Title';

export const CartItemsContainer = styled.div`
  margin: 10px 0px;
`;

CartItemsContainer.displayName = 'CartItemsContainer';

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
TotalContainer.displayName = 'TotalContainer';

export const SubmitButton = styled(TemplateButton)``;
SubmitButton.displayName = 'SubmitButton';

export const FeesContainer = styled.div``;
FeesContainer.displayName = 'FeesContainer';

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
LeftSideContainer.displayName = 'LeftSideContainer';

export const FeeInfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
`;
FeeInfoContainer.displayName = 'FeeInfoContainer';

export const ExtraFeesContainer = styled.div`
  padding: 10px;
`;
ExtraFeesContainer.displayName = 'ExtraFeesContainer';

export const FeeTitle = styled.div`
  font-size: 13px;
  font-weight: 900;
`;
FeeTitle.displayName = 'FeeTitle';

export const FeeTotal = styled.div`
  font-size: 13px;
  font-weight: 900;
`;
FeeTotal.displayName = 'FeeTotal';

export const PromoTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
  min-height: 20px;
`;
PromoTotalContainer.displayName = 'PromoTotalContainer';

export const PromoTitleContainer = styled.div`
  background-color: ${orange};
  border-radius: 15px;
  padding: 1px 5px;
`;
PromoTitleContainer.displayName = 'PromoTitleContainer';

export const PromoTitle = styled.div`
  font-size: 14px;
`;
PromoTitle.displayName = 'PromoTitle';

export const FinalTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 10px 10px 10px;
  border-bottom: 2px solid ${black};
`;
FinalTotalContainer.displayName = 'FinalTotalContainer';

export const TotalsContainer = styled.div`
  align-items: stretch;
  border-top: 2px solid ${black};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 10px 10px 5px 10px;
`;
TotalsContainer.displayName = 'TotalsContainer';

export const ValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
ValueRowContainer.displayName = 'ValueRowContainer';

export const SubvalueTitle = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
`;
SubvalueTitle.displayName = 'SubvalueTitle';

export const SubvalueAmount = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
`;
SubvalueAmount.displayName = 'SubvalueAmount';

export const TotalValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 100%;
`;
TotalValueRowContainer.displayName = 'TotalValueRowContainer';

export const TotalTitle = styled.div`
  font-weight: 900;
  font-size: 13px;
`;
TotalTitle.displayName = 'TotalTitle';

export const TotalAmount = styled.div`
  font-weight: 900;
  font-size: 13px;
`;
TotalAmount.displayName = 'TotalAmount';

export const FinalTotalTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
`;
FinalTotalTitle.displayName = 'FinalTotalTitle';

export const FinalTotalAmount = styled.div`
  font-weight: 700;
  font-size: 22px;
`;
FinalTotalAmount.displayName = 'FinalTotalAmount';

export const EmptyCartMsg = styled.div`
  padding: 30px;
  text-align: center;
`;
EmptyCartMsg.displayName = 'EmptyCartMsg';
