import styled from 'styled-components';

import TemplateButton from '../template-button/template-button.component';

import {
  black,
  lightGray,
  green,
  whiteOff,
  darkerGray,
} from '../../style-constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 15px 0px 50px 0px;
  width: 100%;
`;
Container.displayName = 'Container';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
  margin-bottom: 20px;
  width: 100%;
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  font-weight: 800;
  font-size: 20px;
`;
Title.displayName = 'Title';

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
ButtonContainer.displayName = 'ButtonContainer';

export const OrderButton = styled(TemplateButton)`
  background-color: ${({ isSelected }) => (isSelected ? green : lightGray)};
  border: 1px solid ${({ isSelected }) => (isSelected ? green : darkerGray)};
  color: ${({ isSelected }) => (isSelected ? whiteOff : black)};
  margin-left: 5px;
`;
OrderButton.displayName = 'OrderButton';
