import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RiAccountPinCircleLine } from 'react-icons/ri';

import { Container } from './wholesale-customer-profile-icon.styles';
import { setIsWholesaleDropdownHidden } from '../../redux/user/user.actions';
import { selectIsWholesaleUserProfileHidden } from '../../redux/user/user.selectors';
import { setCartHidden } from '../../redux/cart/cart.actions';

export const WholesaleCustomerProfileIcon = ({
  setIsWholesaleDropdownHidden,
  isWholesaleUserProfileHidden,
  setCartHidden,
}) => {
  const handleProfileDropdownClick = () => {
    setIsWholesaleDropdownHidden(!isWholesaleUserProfileHidden);
    setCartHidden(true);
  };

  return (
    <Container onClick={handleProfileDropdownClick}>
      <RiAccountPinCircleLine size={40} />
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  isWholesaleUserProfileHidden: selectIsWholesaleUserProfileHidden,
});

const mapDispatchToProps = (dispatch) => ({
  setIsWholesaleDropdownHidden: (isHidden) =>
    dispatch(setIsWholesaleDropdownHidden(isHidden)),
  setCartHidden: (isHidden) => dispatch(setCartHidden(isHidden)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WholesaleCustomerProfileIcon);
