import styled from 'styled-components';

import {
  lightGray,
  white,
  greenTransparent,
  mobileSplitPoint,
} from '../../style-constants';

export const RowsContainer = styled.div`
  background-color: ${white};
`;
RowsContainer.displayName = 'RowsContainer';

export const Row = styled.div`
  align-items: center;
  background-color: ${white};
  border-bottom: 1px solid ${lightGray};
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: 50px;
  }
`;
Row.displayName = 'Row';

export const RowActive = styled.div`
  align-items: center;
  background-color: ${greenTransparent};
  border-bottom: 1px solid ${lightGray};
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: 60px;
  }
`;
RowActive.displayName = 'RowActive';

export const AddressContainer = styled.div``;
AddressContainer.displayName = 'AddressContainer';
