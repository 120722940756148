/*global google*/
// the above line tells eslint that google is a global name
// and is available at runtime
// https://github.com/hibiken/react-places-autocomplete/issues/150
// https://github.com/hibiken/react-places-autocomplete/issues/57#issuecomment-335043874

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

import PaymentDetails from './payment-details.component';

import { selectConfig } from '../../redux/config/config.selectors';

import {
  addUserDetails,
  fetchPaymentMethodsForCustomerIdStart,
  detachPaymentMethodStart,
  setKeepUserCredentialsForCheckout,
} from '../../redux/user/user.actions';
import {
  selectStripeCustomerId,
  selectPaymentMethods,
  selectWholesaleUserDetails,
  selectKeepUserCredentialsForCheckout,
} from '../../redux/user/user.selectors';
import {
  DEFAULT_USER_CREDENTIALS_INITIAL_STATE,
  ERROR_OR_TOUCHED_INITIAL_STATE,
} from '../../redux/user/user.reducer';

import {
  setFastDisable,
  resetPaymentErrorMessage,
  setIsLoading,
  wholesaleAddToAccountStart,
  paymentNewCustomerStart,
  paymentCurrentCustomerStart,
  setDeliveryFee,
  setDeliveryFeePostcodes,
} from '../../redux/cart/cart.actions';
import {
  selectCartItems,
  selectCartTotal,
  selectOrderType,
  selectPromotion,
  selectIsLoading,
  selectFastDisable,
  selectPaymentErrorMessage,
  selectDeliveryFee,
  selectDeliveryFeePostcodes,
  selectPromotionFee,
  selectBookingFee,
} from '../../redux/cart/cart.selectors';
import {
  asyncCheckCartForUnavailableItems,
  calcDynamicFee,
} from '../../utils/cart-utils';
import {
  calculateItemTotalSingleQuantity,
  calcBookingFee,
  calcHasFreeDelivery,
} from '../../redux/cart/cart.utils';

import {
  selectOperatingHours,
  selectOverrideDays,
  selectShopDetails,
  selectDeliveryZones,
  selectIsFetchingThrottlingData,
  selectOrdersThrottling,
} from '../../redux/shop/shop.selectors';
import {
  fetchOperatingHoursStart,
  fetchOverrideDaysStart,
  fetchOrdersThrottlingForDayStart,
} from '../../redux/shop/shop.actions';
import {
  createOrderTimesForSelection,
  checkForAnOverrideDay,
  isOverrideDayOpen,
  dayFromWhenOrderWantedDate,
  checkMomentsAreSameDateExactly,
  checkStillOpenOverrideDay,
  checkStillOpenOperatingHours,
  createItemTitle,
  isDateValidForOrderCutoffFeature,
} from '../../utils/shop-utils';
import { geocodeAddress } from '../../pages/checkout/checkout.component';

import { shopApi as api } from '../../services';

import {
  ADD_TO_ACCOUNT,
  CLIENT_APP_VERSION,
  DELIVERY,
  DELIVERY_CHECK_TYPE_RADIUS,
  DELIVERY_CHECK_TYPE_POSTCODES,
  DELIVERY_FEE_MODE_DYNAMIC,
  DINE_IN,
  FUTURE,
  GST_NONE,
  ISO_DATESTRING_FORMAT,
  MAX_LENGTH_USER_INSTRUCTIONS,
  OPERATING_HOURS_FIRESTORE_COLLECTION,
  OVERRIDE_DAYS_FIRESTORE_COLLECTION,
  PAYMENT_TERMS_7_DAYS,
  TODAY,
  WANTED_AT_DATE,
  WANTED_AT_NONE,
  WANTED_AT_TIME,
  WANTED_AT_TIME_DATE,
  WHOLESALE_BILL_TO_ACCOUNT,
  WHOLESALE_PAID_CREDIT_CARD,
  DINE_IN_BATCHING_TYPE__GROUP,
  DINE_IN_BATCHING_TYPE__BY_ITSELF,
  PLATFORM__ONLINE,
  CHANNEL_WHOLESALE,
  CHANNEL_RETAIL,
} from '../../global.constants';

export const PaymentDetailsContainer = ({
  cartItems,
  cartTotal,
  operatingHours,
  overrideDays,
  orderType,
  promotion,
  addUserDetails,
  fetchOperatingHoursStart,
  fetchOverrideDaysStart,
  isLoading,
  shopDetails,
  fastDisable,
  setFastDisable,
  paymentErrorMessage,
  resetPaymentErrorMessage,
  deliveryFee,
  promotionFee,
  bookingFee,
  config,
  wholesaleUserDetails,
  setIsLoading,
  fetchPaymentMethodsForCustomerIdStart,
  customerId,
  paymentNewCustomerStart,
  paymentCurrentCustomerStart,
  paymentMethods,
  detachPaymentMethodStart,
  wholesaleAddToAccountStart,
  deliveryZones,
  setDeliveryFeePostcodes,
  fetchOrdersThrottlingForDayStart,
  isFetchingThrottlingData,
  ordersThrottling,
  reduxUserCredentials,
  setReduxUserCredentials,
  keepUserCredentialsForCheckout,
  setKeepUserCredentialsForCheckout,
}) => {
  const navigate = useNavigate();

  const {
    allowWholesaleOrderingMethods,
    bookingFeePercentage,
    cannotPlaceOrderForToday,
    deliveryFeeCalculationType,
    deliveryFeeDynamicModeCostFunction,
    deliveryToAddressCheckType,
    dineInCanSendEta,
    disableDeliveryDistanceCheck,
    enableConsentToLeaveDeliveryAtFrontDoorCheck,
    enableWholesale,
    isOrdersThrottlingEnabled,
    orderCutoffTime,
    orderCutoffTimeEnabled,
    orderCutoffTimePeriodIncrement,
    wantedAtTypes,
    wantedAtTypeNoneAllowPreOrders,
    whenOrderWantedTimesIncrementMinutes,
  } = config;

  const {
    deliveryFeeDynamicTypeMinimum,
    deliveryFeeDynamicTypeMaximum,
    deliveryMinimum,
    deliveryFreeThreshold,
    shopAddressSearchResultsRadius,
  } = shopDetails;

  // shopAddressSearchResultsRadius is in metres, want kms
  // because distance from google maps is in kms
  const MAX_BOUND = shopAddressSearchResultsRadius / 1000.0;

  const [userErrors, setUserErrors] = useState(ERROR_OR_TOUCHED_INITIAL_STATE);
  const [touched, setTouched] = useState(ERROR_OR_TOUCHED_INITIAL_STATE);
  const [whenOrderWantedTimes, setWhenOrderWantedTimes] = useState([]);

  // its default val in the order payload.
  const [hasSubscribed, setHasSubscribed] = useState(false);

  // require age consent affirmation if any items in cart require age consent.
  // eg. alcohol
  const [requireConsent, setRequireConsent] = useState(false);
  const [hasConsented, setHasConsented] = useState(false);

  // consent for leaving delivery at front door feature
  const [hasConsentedToLeaveAtDoor, setHasConsentedToLeaveAtDoor] =
    useState(false);

  const [paymentMethodsState, setPaymentMethodsState] = useState({
    showCardElement: false,
    selectedPaymentMethodId: null,
    shouldSaveCardDetails: true,
  });

  const [wholesalePaymentMethod, setWholesalePaymentMethod] =
    useState(ADD_TO_ACCOUNT);

  // dineInBatchType can be === 'group' | 'by-itself'
  //
  // it allows customer to add their dine-in order to a grouped/batched order,
  // or shortcut it the batching entirely (ie. be just like a regular pickup/delivery order)
  //
  // grouped/batched dine-in orders incur at least a 2min latency until the 'kitchen'/
  // BOH app receives the order. this is a result of the batching scheduler func only running every 2 mins.
  //
  // the scheduler func looks for orders to batchup in a different collection
  // than the one the BOH app waits for orders on.
  //
  // sortcutting it by selecting 'by-itself' means the order does not go through
  // the batching scheduler path.
  const [dineInBatchType, setDineInBatchType] = useState(
    DINE_IN_BATCHING_TYPE__GROUP
  );

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (_.isEmpty(shopDetails)) return;

    const asyncCheck = async () => {
      const resp = await asyncCheckCartForUnavailableItems(
        cartItems,
        shopDetails
      );
      if (resp && resp.haveUnavailableItems && !Swal.isVisible()) {
        Swal.fire(resp.msg);
      }
    };
    asyncCheck();

    // also calculate if there are any items in cart that requiresAgeConsent === true
    const ageConsentItems = _.filter(
      cartItems,
      (cartItem) => cartItem.requiresAgeConsent === true
    );

    setRequireConsent(_.size(ageConsentItems) > 0);
  }, [cartItems, shopDetails]);

  useEffect(() => {
    if (enableWholesale && wholesaleUserDetails) {
      // pre-populate some fields in the form because there are
      // details for user stored in wholesaleUserDetails

      // we also need to set the touched to true for userName & email
      // fields below in useEffect below
      const { customerName, email, address, phone } = wholesaleUserDetails;
      const INITIAL_STATE = {
        ...DEFAULT_USER_CREDENTIALS_INITIAL_STATE,
        userName: customerName,
        email,
        address: address ? address : '',
        phone: phone ? phone : '',
      };
      setReduxUserCredentials(INITIAL_STATE);
    }
  }, []);

  useEffect(() => {
    // force touched for particular setups (ie bypass touched checks for these cases)
    const syntheticTouchedUpdates = _.reduce(
      touched,
      (accum, isTouched, fieldName) => {
        if (fieldName === 'notes') {
          // notes is optional for all setups
          accum[fieldName] = true;
          return accum;
        }
        if (orderType !== DELIVERY && fieldName === 'address') {
          accum[fieldName] = true;
          return accum;
        }
        if (orderType !== DINE_IN && fieldName === 'tableNumber') {
          accum[fieldName] = true;
          return accum;
        }
        if (orderType === DINE_IN) {
          if (
            fieldName === 'email' ||
            fieldName === 'phone' ||
            fieldName === 'whenOrderWantedDate' ||
            fieldName === 'whenOrderWanted'
          ) {
            accum[fieldName] = true;
            return accum;
          }
        }
        if (
          enableWholesale &&
          wholesaleUserDetails &&
          (fieldName === 'userName' ||
            fieldName === 'email' ||
            fieldName === 'address' ||
            fieldName === 'phone')
        ) {
          accum[fieldName] = true;
          return accum;
        }
        if (
          wantedAtTypes === WANTED_AT_TIME &&
          fieldName === 'whenOrderWantedDate'
        ) {
          accum[fieldName] = true;
          return accum;
        }
        if (
          wantedAtTypes === WANTED_AT_DATE &&
          fieldName === 'whenOrderWanted'
        ) {
          accum[fieldName] = true;
          return accum;
        }
        if (
          wantedAtTypes === WANTED_AT_NONE &&
          (fieldName === 'whenOrderWanted' ||
            fieldName === 'whenOrderWantedDate')
        ) {
          accum[fieldName] = true;
          return accum;
        }

        return accum;
      },
      {}
    );

    const updatedTouchedUsingRedux = _.reduce(
      reduxUserCredentials,
      (accum, val, fieldName) => {
        if (!val) {
          accum[fieldName] = false;
        } else {
          accum[fieldName] = true;
        }

        return accum;
      },
      {}
    );

    // object spreads have to be in this order.
    // the redux updates are for what user *CAN* see,
    // but the synthetic updates are what they cannot see
    // but are needing to be updated behind the scenes to
    // allow all input errors to be properly eliminated
    setTouched({
      ...updatedTouchedUsingRedux,
      ...syntheticTouchedUpdates,
    });

    setUserErrors({
      ...userErrors,
      ..._.reduce(
        syntheticTouchedUpdates,
        (accum, val, fieldName) => {
          accum[fieldName] = !val;
          return accum;
        },
        {}
      ),
    });
  }, [reduxUserCredentials]);

  useEffect(() => {
    if (_.isEmpty(operatingHours)) {
      // if not loaded already fetch operating hours.
      // may also have overrideDays present but since
      // these are optional so it may be empty
      //   => that's we dont check of emptyness above
      fetchOperatingHoursStart();
      fetchOverrideDaysStart();
    }

    // in case order failed and 'spinning wheel of death' is still present
    // this means the submit button will be disabled, preventing user
    // from submitting their order
    if (fastDisable) {
      setFastDisable(false);
    }

    if (isLoading) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!customerId || _.isEmpty(shopDetails)) return;

    fetchPaymentMethodsForCustomerIdStart(customerId, shopDetails);
  }, [customerId, shopDetails]);

  useEffect(() => {
    if (!_.size(paymentMethods)) {
      // TODO: should we also reset customerId to null
      // if there are no paymentMethods?
      // ...UX flow is: customer has deleted all their saved card, or reset
      // client browser state
      // need to remove the paymentMethodId from
      // selectedPaymentMethodId (if present)
      setPaymentMethodsState({
        ...paymentMethodsState,
        selectedPaymentMethodId: null,
      });
    } else {
      // default to selecting the first payment methods available
      setPaymentMethodsState({
        ...paymentMethodsState,
        selectedPaymentMethodId: paymentMethods[0].id,
      });
    }
  }, [paymentMethods]);

  useEffect(() => {
    // this effect constructs the available order times for whatever
    // is set for whenOrderWantedDate field
    // it will get triggered when operatingHours changes and that
    // can occur when background fetching occurs when user
    // navigates away from this app, to another tab etc,
    // then comes back to it. so we want to make sure
    // that the state is returned to how they left it
    // despite the potentially updated operatingHours object.
    // this is why we look at reduxUserCredentials.whenOrderWantedDate
    // for calcing availableHours

    // default to today
    let mStartDate = moment();

    if (reduxUserCredentials.whenOrderWantedDate) {
      mStartDate = moment(
        reduxUserCredentials.whenOrderWantedDate,
        ISO_DATESTRING_FORMAT
      );
    }

    const availableHours = createOrderTimesForSelection(
      operatingHours,
      overrideDays,
      orderType,
      mStartDate,
      whenOrderWantedTimesIncrementMinutes
    );

    setWhenOrderWantedTimes(availableHours);
  }, [operatingHours]);

  useEffect(() => {
    if (!reduxUserCredentials.whenOrderWantedDate) return;

    // triggered everytime user selects a date in datetimepicker
    const { whenOrderWantedDate } = reduxUserCredentials;

    if (isOrdersThrottlingEnabled) {
      // if ordersThottling feature is enabled then
      // fetch the ordersThrottling document for the selected day
      const day = dayFromWhenOrderWantedDate(whenOrderWantedDate);
      fetchOrdersThrottlingForDayStart(day);
    }

    // based on the value of startDate we need to calculate the available
    // hours by looking up operating hours for that day / orderType (pickup or delivery)
    const mStartDate = moment(whenOrderWantedDate, ISO_DATESTRING_FORMAT);

    const newAvailableHours = createOrderTimesForSelection(
      operatingHours,
      overrideDays,
      orderType,
      mStartDate,
      whenOrderWantedTimesIncrementMinutes
    );

    setWhenOrderWantedTimes(newAvailableHours);

    // reset the selected times incase user has already selected a time,
    // then selects a different day having different operating times,
    // making the previous selection invalid/stale
    setReduxUserCredentials({
      ...reduxUserCredentials,
      whenOrderWanted: '',
    });
  }, [reduxUserCredentials.whenOrderWantedDate]);

  useEffect(() => {
    // this effect listens to the paymentErrorMessage field
    // in cart reducer. the field only gets set to a string
    // when ORDER_PAYMENT_FAILURE action gets fired, else
    // it's set to undefined
    if (paymentErrorMessage) {
      Swal.fire(paymentErrorMessage);
      resetPaymentErrorMessage();
    }
  }, [paymentErrorMessage]);

  useEffect(() => {
    // calculate the *wholesale* delivery fee for postCodes feature upon component load
    // because we already have wholesale user's delivery address present in state
    if (_.isEmpty(config) || !wholesaleUserDetails || _.isEmpty(deliveryZones))
      return;

    const { deliveryToAddressCheckType, disableDeliveryDistanceCheck } = config;

    const { address } = wholesaleUserDetails;

    if (
      address &&
      !disableDeliveryDistanceCheck &&
      deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES
    ) {
      checkDeliveryFeeForPostcodeForAddress(address);
    }
  }, [config, wholesaleUserDetails, deliveryZones]);

  const checkDeliveryFeeForPostcodeForAddress = async (address) => {
    const { postCodeComponent } = await geocodeAddress(address, shopDetails);
    // const { postCodeComponent } = await geocodeAddress(address);

    if (!postCodeComponent) {
      toast.error(
        'Sorry we could not find a postcode for your supplied delivery address. Please type in a more detailed address.',
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    const { long_name: postCodeForOrder } = postCodeComponent;

    const matchedZoneMaybe = _.find(deliveryZones, (zone) => {
      const { postCodes } = zone;
      const matchedPostCodeObjMaybe = _.find(postCodes, (postCodeObj) => {
        const { postCode } = postCodeObj;
        return postCode === postCodeForOrder;
      });
      return matchedPostCodeObjMaybe;
    });

    if (matchedZoneMaybe) {
      const { title, price } = matchedZoneMaybe;
      toast.info(
        `Our delivery fee to postcode ${postCodeForOrder} is $${price}`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // set the delivery fee in state
      setDeliveryFeePostcodes(price);
    } else {
      toast.error(
        `Sorry we dont deliver to your post code ${postCodeForOrder}`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setReduxUserCredentials({
      ...reduxUserCredentials,
      [name]: value,
    });

    if (name === 'tableNumber') {
      // handle input-specific validation
      // for userName it is a non-whitespace value
      if (value.trim()) {
        setUserErrors({
          ...userErrors,
          [name]: false,
        });
      }
      return;
    }

    if (name === 'userName') {
      // handle input-specific validation
      // for userName it is a non-whitespace value
      if (value.trim()) {
        setUserErrors({
          ...userErrors,
          [name]: false,
        });
      }
      return;
    }

    if (name === 'email') {
      // handle input-specific validation
      // for email it is valid email string.
      // regex found from StackOverflow answer:
      // https://stackoverflow.com/a/1373724
      const re =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (re.test(value.trim().toLowerCase())) {
        setUserErrors({
          ...userErrors,
          [name]: false,
        });
      }
      return;
    }

    if (name === 'phone') {
      // handle input-specific validation
      // for phone it is a non-whitespace value
      if (parsePhoneNumberFromString(value, 'AU')) {
        setUserErrors({
          ...userErrors,
          [name]: false,
        });
      }
      return;
    }
  };

  const handleBlur = (name) => {
    setTouched({
      ...touched,
      [name]: true,
    });

    if (name === 'tableNumber') {
      if (!reduxUserCredentials[name].trim()) {
        setUserErrors({
          ...userErrors,
          [name]: true,
        });
      }
      return;
    }

    if (name === 'userName') {
      if (!reduxUserCredentials[name].trim()) {
        setUserErrors({
          ...userErrors,
          [name]: true,
        });
      }
      return;
    }

    if (name === 'email') {
      const re =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      const validEmail = re.test(
        reduxUserCredentials[name].trim().toLowerCase()
      );
      if (!validEmail) {
        setUserErrors({
          ...userErrors,
          [name]: true,
        });
      }
      return;
    }

    if (name === 'address') {
      // address input only present for delivery orderType
      if (!reduxUserCredentials[name].trim()) {
        setUserErrors({
          ...userErrors,
          [name]: true,
        });
      }

      return;
    }

    if (name === 'phone') {
      if (!parsePhoneNumberFromString(reduxUserCredentials[name], 'AU')) {
        setUserErrors({
          ...userErrors,
          [name]: true,
        });
      }
      return;
    }
  };

  const handleSelectTime = (time) => {
    setReduxUserCredentials({
      ...reduxUserCredentials,
      whenOrderWanted: time,
    });
    setUserErrors({
      ...userErrors,
      whenOrderWanted: false,
    });
    setTouched({
      ...touched,
      whenOrderWanted: true,
    });
  };
  const handleSetWantedDate = (date) => {
    // now storing date object as an ISOString...
    // will be in format 'YYYY-MM-DDTHH:mm:ss Z'
    setReduxUserCredentials({
      ...reduxUserCredentials,
      whenOrderWantedDate: date.toISOString(),
    });
    setUserErrors({
      ...userErrors,
      whenOrderWantedDate: false,
    });
    setTouched({
      ...touched,
      // if app config is such that user can select a time they want order,
      // then set touched for this field to be false.
      // else, the field is hidden in UI and we should artificially make it touched,
      // hence set it to true
      whenOrderWanted:
        wantedAtTypes === WANTED_AT_TIME ||
        wantedAtTypes === WANTED_AT_TIME_DATE
          ? false
          : true, // * need to also reset this field to not touched *
      whenOrderWantedDate: true,
    });
  };

  const handleAddressChange = (address) => {
    // nb. address input only shows up for delivery orders.
    setReduxUserCredentials({
      ...reduxUserCredentials,
      address,
    });

    // handle input-specific validation
    // for address it is a non-whitespace value
    if (address.trim()) {
      setUserErrors({
        ...userErrors,
        address: false,
      });
    }
  };

  const handleNotesChange = (event) => {
    const { value } = event.target;
    if (value.length > MAX_LENGTH_USER_INSTRUCTIONS) return;
    setReduxUserCredentials({
      ...reduxUserCredentials,
      notes: value,
    });
  };

  const handleSubmit = async (event) => {
    try {
      let calculatedDeliveryFee = 0.0;
      // block native form submission
      event.preventDefault();

      // ----------------------------------------------
      // CHECK CART STATE
      // ----------------------------------------------
      if (cartItems.length === 0) {
        Swal.fire(`Cart is empty`);
        setFastDisable(false);
        return;
      }

      if (cartTotal < 0.0) {
        Swal.fire('Cart total is not greater than zero');
        setFastDisable(false);
        return;
      }

      if (orderType === DELIVERY && cartTotal < deliveryMinimum) {
        Swal.fire(
          `Cart total is less than $${deliveryMinimum} delivery minimum`
        );
        setFastDisable(false);
        return;
      }

      // ---------------------------------------------------------------------
      // ERROR CHECKING / FORM VALIDATION (not including Stripe elems)
      // ---------------------------------------------------------------------
      // check if any fields have not yet been touched
      if (_.some(touched, (isTouched) => !isTouched)) {
        setUserErrors({
          ...userErrors,
          ..._.reduce(
            touched,
            (accum, val, fieldName) => {
              accum[fieldName] = !val;
              return accum;
            },
            {}
          ),
        });
        Swal.fire('Please fill out all required (*) fields');
        setFastDisable(false);
        return;
      }

      // check if any remaining userErrors
      if (_.some(userErrors, (val) => val)) {
        Swal.fire('Please fill out all required (*) fields...');
        setFastDisable(false);
        return;
      }

      // also, if there is at least one item in the cart that
      // has requiresAgeConsent === true, check that the user has
      // selected the 'proper-age consent' switch to true
      // ie. they affirmatively say they are of legal age to buy
      // those items in cart requiring consent
      if (requireConsent && !hasConsented) {
        Swal.fire(
          "Please confirm that you're over 18 years (alcohol restrictions)."
        );
        setFastDisable(false);
        return;
      }

      // consent for leaving delivery at front door feature
      if (enableConsentToLeaveDeliveryAtFrontDoorCheck) {
        if (!hasConsentedToLeaveAtDoor) {
          Swal.fire(
            'Please acknowledge consent for us to leave delivery at the front door.'
          );
          setFastDisable(false);
          return;
        }
      }

      // ---------------------------------------------------------------------
      // FOR DELIVERIES: CHECK:
      // a) IF USER HAS FILLED OUT MORE THAT JUST STREET NUMBER AND STREET NAME
      // b) IF DISTANCE TO DELIVERY > MAX DELIVERY DIST
      // c) OR FIND IF WE DELIVERY TO POSTCODE
      // ---------------------------------------------------------------------
      if (orderType === DELIVERY) {
        const {
          areDeliveryDetailsOk,
          msg: deliveryDetailsMsg,
          calculatedDeliveryFee: _calculatedDeliveryFee,
        } = await checkDeliveryDetails();

        if (!areDeliveryDetailsOk) {
          Swal.fire(deliveryDetailsMsg);
          setFastDisable(false);
          return;
        }

        // set the *local to func* calculated delivery fee
        calculatedDeliveryFee = _calculatedDeliveryFee;
      }

      // ---------------------------------------------------------------------
      // CHECK AGAIN FOR UNAVAILABLE ITEMS
      // ---------------------------------------------------------------------
      const resp = await asyncCheckCartForUnavailableItems(
        cartItems,
        shopDetails
      );
      if (resp && resp.haveUnavailableItems) {
        Swal.fire(resp.msg);
        setFastDisable(false);
        return;
      }

      // ---------------------------------------------------------------------
      // IS SHOP OPEN FOR GIVEN ORDER orderType?
      // ---------------------------------------------------------------------
      const { isStillOpen, msg: openMsg } = await checkIfStillOpen();
      if (!isStillOpen) {
        Swal.fire(openMsg);
        setFastDisable(false);
        return;
      }

      // ---------------------------------------------------------------------
      // STRIPE
      // ---------------------------------------------------------------------
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. make sure to disable
        // form submission until Stripe.js has loaded
        Swal.fire('Payment Provider failure. Please try again.');
        setFastDisable(false);
        return;
      }

      // ---------------------------------------------------------------------
      // GO!
      // ---------------------------------------------------------------------
      constructPayloadAndSend(calculatedDeliveryFee);
    } catch (error) {
      Swal.fire('Please try again.');
      setFastDisable(false);
      console.log(error);
    }
  };

  // ****************************************************************
  // NOTES: on using calculatedDeliveryFee instead of redux
  // state deliveryFee/deliveryFeePostcodes vals
  // ****************************************************************
  // we dont use the redux deliveryFee/deliveryFeePostcodes vals when submitting
  // due to an edge case occurs where the redux state is *NOT* updated in time
  // for when order is submitted.
  //
  // edge case: use fills out all field except for delivery address,
  // then goes back to fill out address, then hits 'Place order' button
  // directly without first tapping out of address input field...
  // ...the address input onBlur func is called first, then the submit handler,
  // but the redux state does not update before submit handler is running,
  // so we are left with old redux state.
  //
  // to guarentee 'fresh' delivery vals, we recalculated everything again
  // including the bookingFee aka surcharge fee. protionFee only depends
  // on cartItems so is not affects by the delivery fee shenanigans here.
  //
  // like always, a better pattern and refactoring is needed.
  const constructPayloadAndSend = async (calculatedDeliveryFee) => {
    try {
      // booking fee depends on delivery fee so recalculate it also
      const calculatedBookingFee = calcBookingFee(
        cartTotal,
        orderType === DELIVERY ? calculatedDeliveryFee : 0.0,
        promotionFee,
        orderType,
        bookingFeePercentage
      );

      //
      // only need *one* stripe card-like element ref to get all cc details
      //
      // during checkout we are using separate stripe elements for
      // number, expiry, and cvc.
      //
      // stripe is clever enough to find all ites elements mounted
      // on page, so we only need to pass around one element to get all cc details
      //
      // arbitrarily chosed here to be the card number element
      const cardNumberElement = elements.getElement(CardNumberElement);

      // need:
      // 1. customer details,
      // 2. order details
      const orderDetails = cartItems.map((cartItem) => {
        const {
          key,
          quantity,
          title,
          kitchenTitle,
          priceDetails,
          userCustomizations,
          userInstructions,
          collectionKey,
          collectionTitle,
          gstSetting,
          itemCode,
          itemCategories,
        } = cartItem;

        const { itemAvailableInManySizes, size } = priceDetails;
        const singleItemTotal = calculateItemTotalSingleQuantity(
          priceDetails,
          userCustomizations,
          cartItem
        );

        const _title = createItemTitle(itemAvailableInManySizes, title, size);

        const _kitchenTitle = createItemTitle(
          itemAvailableInManySizes,
          kitchenTitle,
          size
        );

        return {
          key,
          quantity,
          singleItemTotal,
          title: _title,
          kitchenTitle: _kitchenTitle,
          priceDetails,
          userCustomizations,
          userInstructions,
          collectionKey,
          collectionTitle,
          gstSetting: gstSetting ? gstSetting : GST_NONE,
          itemCode,
          itemCategories: itemCategories ? itemCategories : [],
        };
      });

      // make phone number adhere to the format that Twilio accepts (E164 format)
      // [+][country code][phone number including area code]
      let formattedPhoneNumber;
      if (!!reduxUserCredentials.phone) {
        formattedPhoneNumber = parsePhoneNumberFromString(
          reduxUserCredentials.phone,
          'AU'
        ).number;
      } else if (orderType === DINE_IN && !dineInCanSendEta) {
        // we dont ask for phone number when order is DINE_IN
        // and shop doesn't want to send SMS ETAs for this type
        // of order
        formattedPhoneNumber = '';
      } else {
        Swal.fire('Please fill out phone number');
        setFastDisable(false);
        return;
      }

      const extraCharges = {
        deliveryFee: calculatedDeliveryFee,
        promotionFee: promotionFee === undefined ? 0.0 : promotionFee,
        bookingFee: bookingFee === undefined ? 0.0 : calculatedBookingFee,
        promotion: promotionFee === undefined ? {} : promotion,
      };

      let actionPayload = {
        cartTotal,
        extraCharges,
        items: orderDetails,
        user: {
          ...reduxUserCredentials,
          email: reduxUserCredentials.email.trim(), // remove whitespace which sometimes causes api email validation errors
          orderType,
          phone: formattedPhoneNumber,
          hasSubscribed,
          clientVersion: CLIENT_APP_VERSION,
          channel: wholesaleUserDetails ? CHANNEL_WHOLESALE : CHANNEL_RETAIL,
          platform: PLATFORM__ONLINE,
          wholesalePaymentMethod: '', // leave blank for retail, see below for wholesale
          wholesalePaymentTerms: '', // leave blank for retails, see below for wholesale
          wholesaleUserKey: '', // leave blank for retail, see below for wholesale
          dineInBatchType: orderType === DINE_IN ? dineInBatchType : '', //leave blank for pickup, delivery orderTypes
        },
      };

      if (wholesaleUserDetails) {
        const { key: wholesaleUserKey, paymentTerms } = wholesaleUserDetails;
        let _wholesalePaymentMethod = WHOLESALE_PAID_CREDIT_CARD;
        let _wholesalePaymentTerms = PAYMENT_TERMS_7_DAYS;

        if (allowWholesaleOrderingMethods) {
          if (wholesalePaymentMethod === ADD_TO_ACCOUNT) {
            _wholesalePaymentMethod = WHOLESALE_BILL_TO_ACCOUNT;
          }

          if (paymentTerms) {
            _wholesalePaymentTerms = paymentTerms;
          }
        }

        actionPayload = {
          ...actionPayload,
          user: {
            ...actionPayload.user,
            wholesalePaymentMethod: _wholesalePaymentMethod,
            wholesalePaymentTerms: _wholesalePaymentTerms,
            wholesaleUserKey, // add an extra field for id-ing wholesale user later on
          },
        };
      }

      // add the user details to redux for use during payment process
      addUserDetails({
        ...reduxUserCredentials,
        orderType,
        hasSubscribed,
      });

      if (
        wholesaleUserDetails &&
        allowWholesaleOrderingMethods &&
        wholesalePaymentMethod === ADD_TO_ACCOUNT
      ) {
        // different route for wholesale and add order to account
        wholesaleAddToAccountStart(
          actionPayload,
          cartItems,
          navigate,
          shopDetails
        );
      } else {
        // all else logical permutations of retail/wholesale state goes
        // thru the following workflows
        if (!customerId) {
          paymentNewCustomerStart(
            actionPayload,
            cartItems,
            reduxUserCredentials,
            cardNumberElement,
            stripe,
            paymentMethodsState.shouldSaveCardDetails,
            config,
            navigate,
            shopDetails
          );
        } else {
          paymentCurrentCustomerStart(
            actionPayload,
            cartItems,
            reduxUserCredentials,
            cardNumberElement,
            stripe,
            customerId,
            paymentMethodsState,
            config,
            navigate,
            shopDetails
          );
        }
      }
    } catch (error) {
      setFastDisable(false);
      console.log(error);
    }
  };

  const checkDeliveryDetails = async () => {
    let response = {
      areDeliveryDetailsOk: true,
      msg: '',
      calculatedDeliveryFee: 0.0,
    };
    const { address } = reduxUserCredentials;

    // a) on mobile devices especially: some users when typing into autocomplete address
    //    will hit enter on their keyboard when seeing (presumably) their address as the
    //    top suggestion in popup-dropdown list. currently this doesn NOT select the top
    //    suggestion. instead it will 'shortcut' out of address autocomplete input element,
    //    leaving an incomplete address like '3 smith st'.
    //    as a simple check just split string on spaces and check if length <= 3
    if (_.size(address.split(' ')) <= 3) {
      response.areDeliveryDetailsOk = false;
      response.msg =
        `Please make sure a full street address is used.\n\n` +
        `Currently it is ${address}.\n\n` +
        `Our delivery drivers need more info for the address`;
      return response;
    }

    if (!disableDeliveryDistanceCheck) {
      const { distance, postCodeComponent } = await geocodeAddress(
        address,
        shopDetails
      );

      if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS) {
        // b) address is complete. now go onto check if delivery distance is within
        //    delivery radius
        if (
          _.isNumber(distance) &&
          _.isNumber(MAX_BOUND) &&
          distance > MAX_BOUND
        ) {
          response.areDeliveryDetailsOk = false;
          response.msg = `Delivery distance for your address is ${distance.toFixed(
            2
          )} km. Sorry, we don't deliver beyond ${MAX_BOUND.toFixed(2)} km`;
          return response;
        } else {
          // NOTE:
          // for dynamic delivery fee mode we should recalc the delivery fee.
          // that is because user may skip from writing delivery address and
          // directly select pay. in this case, no onBlur is called for the
          // address field and so the delivery fee will NOT be calculated,
          // so we do it here to ensure that it always is.
          if (deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC) {
            let dynamicFee = calcDynamicFee(
              distance,
              deliveryFeeDynamicModeCostFunction,
              deliveryFeeDynamicTypeMinimum,
              deliveryFeeDynamicTypeMaximum,
              shopAddressSearchResultsRadius
            );

            // need to also check for free delivery again
            dynamicFee = calcHasFreeDelivery(
              cartTotal,
              dynamicFee,
              deliveryFreeThreshold
            )
              ? 0.0
              : dynamicFee;

            response.calculatedDeliveryFee = dynamicFee;
          } else {
            // STATIC delivery fee mode
            // deliveryFee here refers to deliveryFee in redux state and is
            // the radius deliveryFee val.
            // the postcode delivery fee has its own state in redux and is
            // called deliveryFeePostcodes
            response.calculatedDeliveryFee = deliveryFee;
          }
        }
      } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
        if (!postCodeComponent) {
          response.areDeliveryDetailsOk = false;
          response.msg = `Sorry we failed to find postal code for your address. Try again or use a more detailed address.`;
          return response;
        }

        const { long_name: postCodeForOrder } = postCodeComponent;

        const matchedZoneMaybe = _.find(deliveryZones, (zone) => {
          const { postCodes } = zone;
          const matchedPostCodeObjMaybe = _.find(postCodes, (postCodeObj) => {
            const { postCode } = postCodeObj;
            return postCode === postCodeForOrder;
          });
          return matchedPostCodeObjMaybe;
        });

        if (matchedZoneMaybe) {
          const { title, price } = matchedZoneMaybe;

          response.calculatedDeliveryFee = price;
        } else {
          console.log(`FAILURE: could not find delivery zone for address`);
          response.areDeliveryDetailsOk = false;
          response.msg = `Sorry we dont deliver to post code ${postCodeForOrder}`;
          return response;
        }
      } else {
        // no-op. misconfigured delivery check type
        // fall through and let last return execute
      }
    }

    // otherwise ok
    return response;
  };

  const checkIfStillOpen = async () => {
    // default is open
    let response = {
      isStillOpen: true,
      msg: '',
    };
    let mDateToCheck;

    if (orderType === DINE_IN) {
      // dine in can only for today
      mDateToCheck = moment();
    } else if (
      wantedAtTypes === WANTED_AT_NONE ||
      wantedAtTypes === WANTED_AT_TIME
    ) {
      // these wantedAtTypes can only ever order for today
      mDateToCheck = moment();
    } else if (
      wantedAtTypes === WANTED_AT_TIME_DATE ||
      wantedAtTypes === WANTED_AT_DATE
    ) {
      // these wantedAtTypes can order for today, or the future
      const { whenOrderWantedDate } = reduxUserCredentials;
      mDateToCheck = moment(whenOrderWantedDate, ISO_DATESTRING_FORMAT);
    } else {
      // default to today
      mDateToCheck = moment();
    }

    // sanity check: is date to check in the past?
    // since we are now storing userCredentials in redux there
    // is the possibility that user leaves checkout filled out,
    // comes back a few days later and then tries to purchase for
    // a day in the past.
    if (mDateToCheck.isBefore(moment(), 'day')) {
      response.isStillOpen = false;
      response.msg = `Cannot order for a date in the past. Please change date wanted.`;
      return response;
    }

    // handle possibility when cannotPlaceOrderForToday === true and user
    // has filled out order date, waited a day, and checked out for order wanted today
    if (
      cannotPlaceOrderForToday &&
      checkMomentsAreSameDateExactly(mDateToCheck, moment())
    ) {
      response.isStillOpen = false;
      response.msg = `Sorry we need at least one day in advance for preparing orders. Please chose a day in the future.`;
      return response;
    }

    // is order cutoff feature applicable
    if (
      !isDateValidForOrderCutoffFeature(
        mDateToCheck,
        orderCutoffTime,
        orderCutoffTimeEnabled,
        orderCutoffTimePeriodIncrement
      )
    ) {
      response.isStillOpen = false;
      response.msg = `Sorry, we need an advance for preparing orders. Please choose at least ${
        orderCutoffTimePeriodIncrement + 1
      } day(s) in the future.`;
      return response;
    }

    // check override days first
    const freshOverrideDays = await api.fetchCollection(
      OVERRIDE_DAYS_FIRESTORE_COLLECTION
    );
    const overrideDay = checkForAnOverrideDay(freshOverrideDays, mDateToCheck);
    if (overrideDay) {
      const isStillOpenOverrideDay = checkStillOpenOverrideDay(
        mDateToCheck,
        overrideDay,
        orderType,
        wantedAtTypes,
        wantedAtTypeNoneAllowPreOrders
      );

      if (isStillOpenOverrideDay) return response;

      response.isStillOpen = false;
      response.msg = `Override Day. Sorry shop is closed.`;
      return response;
    } else {
      // have no overrides for date. check operating hours
      const freshOperatingHours = await api.fetchCollection(
        OPERATING_HOURS_FIRESTORE_COLLECTION
      );
      const isStillOpenOperatingHours = checkStillOpenOperatingHours(
        mDateToCheck,
        freshOperatingHours,
        orderType,
        wantedAtTypes,
        wantedAtTypeNoneAllowPreOrders
      );

      if (isStillOpenOperatingHours) return response;

      response.isStillOpen = false;
      response.msg = `Sorry shop is closed for ${orderType}`;
      return response;
    }
  };

  const handleSubscription = (checked) => {
    setHasSubscribed(checked);
  };

  const handleConsentToLeaveAtDoor = (checked) => {
    setHasConsentedToLeaveAtDoor(checked);
  };

  const calcMinDate = () => {
    // minDate prop for datepicker component is called first.
    // even before picker is displayed.
    // filterDate prop is called once picker is displayed
    // so calcMinDate is called before handleFilterDate func
    // -> for this fun, just block off any times in the past
    const todayDate = new Date();
    return todayDate;
  };

  const handleFilterDate = (date) => {
    // date is a Date obj.
    // this only gets dates starting from whatever minDate is calculated to be
    // so if minDate is set to today, then handleFilterDate will get all days
    // starting from today and into the future.

    // *****************************************************************
    // *** IF YOU NEED TO MUTATE mNow or mDate CREATE A NEW MOMENTJS ***
    //  (dont mutate mNow or mDate)
    // *****************************************************************
    const mNow = moment();
    const mDate = moment(date);

    // deal with 'past' first...let everything thru.
    // the calcMinDate() above will block off any 'past' days anyway
    // we then deal with 'present' and 'future' next.
    if (mNow.isAfter(mDate)) return true;

    // because we have already checked for 'past' dates, if the following is false then it means 'future' date
    const nowEqualsDate = checkMomentsAreSameDateExactly(mNow, mDate);

    // override days take top priority. they override
    // - cannotPlaceOrderForToday feature toggle
    // - orderCutoffTime feature toggle
    // - operatingHours
    const overrideDay = checkForAnOverrideDay(overrideDays, mDate);
    if (overrideDay) {
      const todayOrFuture = nowEqualsDate ? TODAY : FUTURE;
      const isOpen = isOverrideDayOpen(
        overrideDay,
        todayOrFuture,
        wantedAtTypes,
        wantedAtTypeNoneAllowPreOrders
      );
      return isOpen;
    }

    if (cannotPlaceOrderForToday && nowEqualsDate) {
      // closed for today
      return false;
    }

    if (
      !isDateValidForOrderCutoffFeature(
        mDate,
        orderCutoffTime,
        orderCutoffTimeEnabled,
        orderCutoffTimePeriodIncrement
      )
    ) {
      return false;
    }

    // finally can use standard operating hours
    const relevantHours = _.find(
      operatingHours,
      (doc) => doc.title === orderType
    );

    if (relevantHours) {
      const _day = mDate.format('ddd');
      const { isClosed } = relevantHours.hours[_day];
      return !isClosed;
    } else {
      return false;
    }
  };

  const isPlaceOrderButtonDisabled = () => {
    if (
      wantedAtTypes === WANTED_AT_TIME ||
      wantedAtTypes === WANTED_AT_TIME_DATE
    ) {
      if (_.isEmpty(whenOrderWantedTimes)) return true;

      return false;
    }

    return fastDisable || isLoading || !stripe;
  };

  const toggleUseDifferentCard = () => {
    const { showCardElement } = paymentMethodsState;
    // if currently showing cc input and user presses toggle button
    // then they want to NOT enter new cc details ie. go back to
    // their paymentMethod ids.
    // else, they want to add a new cc so signify that by returning null
    const _selectedPaymentMethodId = showCardElement
      ? paymentMethods[0].id
      : null;

    setPaymentMethodsState({
      ...paymentMethodsState,
      showCardElement: !showCardElement,
      selectedPaymentMethodId: _selectedPaymentMethodId,
    });
  };

  const handleSelectCardForPaymentMethodClick = (paymentMethodId) => {
    setPaymentMethodsState({
      ...paymentMethodsState,
      showCardElement: false,
      selectedPaymentMethodId: paymentMethodId,
    });
  };

  const handleShouldSaveCardDetailsCheck = (checked) => {
    setPaymentMethodsState({
      ...paymentMethodsState,
      shouldSaveCardDetails: checked,
    });
  };

  const handleDetachPaymentMethod = (paymentMethodId) => {
    // detaching a payment method will trigger an effect above
    // which is 'listening' to paymentMethods state
    detachPaymentMethodStart(paymentMethodId, shopDetails);
  };

  const handleKeepUserCredentialsForCheckout = (checked) => {
    setKeepUserCredentialsForCheckout(checked);
  };

  // reduxUserCredentials are initially undefined before
  // redux correctly populates it with objec
  return (
    <PaymentDetails
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleAddressChange={handleAddressChange}
      whenOrderWantedTimes={whenOrderWantedTimes}
      orderType={orderType}
      reduxUserCredentials={reduxUserCredentials}
      userErrors={userErrors}
      isLoading={isLoading}
      hasSubscribed={hasSubscribed}
      handleSubscription={handleSubscription}
      handleSelectTime={handleSelectTime}
      handleSetWantedDate={handleSetWantedDate}
      fastDisable={fastDisable}
      setFastDisable={setFastDisable}
      requireConsent={requireConsent}
      hasConsented={hasConsented}
      setHasConsented={setHasConsented}
      handleNotesChange={handleNotesChange}
      hasConsentedToLeaveAtDoor={hasConsentedToLeaveAtDoor}
      handleConsentToLeaveAtDoor={handleConsentToLeaveAtDoor}
      config={config}
      wholesaleUserDetails={wholesaleUserDetails}
      calcMinDate={calcMinDate}
      handleFilterDate={handleFilterDate}
      isPlaceOrderButtonDisabled={isPlaceOrderButtonDisabled}
      operatingHours={operatingHours}
      overrideDays={overrideDays}
      paymentMethods={paymentMethods}
      toggleUseDifferentCard={toggleUseDifferentCard}
      paymentMethodsState={paymentMethodsState}
      handleSelectCardForPaymentMethodClick={
        handleSelectCardForPaymentMethodClick
      }
      handleShouldSaveCardDetailsCheck={handleShouldSaveCardDetailsCheck}
      handleDetachPaymentMethod={handleDetachPaymentMethod}
      wholesalePaymentMethod={wholesalePaymentMethod}
      setWholesalePaymentMethod={setWholesalePaymentMethod}
      isOrdersThrottlingEnabled={isOrdersThrottlingEnabled}
      isFetchingThrottlingData={isFetchingThrottlingData}
      ordersThrottling={ordersThrottling}
      dineInBatchType={dineInBatchType}
      setDineInBatchType={setDineInBatchType}
      keepUserCredentialsForCheckout={keepUserCredentialsForCheckout}
      handleKeepUserCredentialsForCheckout={
        handleKeepUserCredentialsForCheckout
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  cartTotal: selectCartTotal,
  operatingHours: selectOperatingHours,
  overrideDays: selectOverrideDays,
  orderType: selectOrderType,
  promotion: selectPromotion,
  isLoading: selectIsLoading,
  shopDetails: selectShopDetails,
  fastDisable: selectFastDisable,
  paymentErrorMessage: selectPaymentErrorMessage,
  deliveryFee: selectDeliveryFee,
  promotionFee: selectPromotionFee,
  bookingFee: selectBookingFee,
  config: selectConfig,
  wholesaleUserDetails: selectWholesaleUserDetails,
  customerId: selectStripeCustomerId,
  paymentMethods: selectPaymentMethods,
  deliveryZones: selectDeliveryZones,
  deliveryFeePostcodes: selectDeliveryFeePostcodes,
  isFetchingThrottlingData: selectIsFetchingThrottlingData,
  ordersThrottling: selectOrdersThrottling,
  keepUserCredentialsForCheckout: selectKeepUserCredentialsForCheckout,
});

const mapDispatchToProps = (dispatch) => ({
  addUserDetails: (user) => dispatch(addUserDetails(user)),
  fetchOperatingHoursStart: () => dispatch(fetchOperatingHoursStart()),
  fetchOverrideDaysStart: () => dispatch(fetchOverrideDaysStart()),
  setFastDisable: (isDisabled) => dispatch(setFastDisable(isDisabled)),
  resetPaymentErrorMessage: () => dispatch(resetPaymentErrorMessage()),
  setIsLoading: (isLoading) => dispatch(setIsLoading(isLoading)),
  fetchPaymentMethodsForCustomerIdStart: (customerId, shopDetails) =>
    dispatch(fetchPaymentMethodsForCustomerIdStart(customerId, shopDetails)),
  wholesaleAddToAccountStart: (
    actionPayload,
    cartItems,
    navigate,
    shopDetails
  ) =>
    dispatch(
      wholesaleAddToAccountStart(
        actionPayload,
        cartItems,
        navigate,
        shopDetails
      )
    ),
  paymentNewCustomerStart: (
    actionPayload,
    cartItems,
    userCredentials,
    cardNumberElement,
    stripe,
    shouldSaveCardDetails,
    config,
    navigate,
    shopDetails
  ) =>
    dispatch(
      paymentNewCustomerStart(
        actionPayload,
        cartItems,
        userCredentials,
        cardNumberElement,
        stripe,
        shouldSaveCardDetails,
        config,
        navigate,
        shopDetails
      )
    ),
  paymentCurrentCustomerStart: (
    actionPayload,
    cartItems,
    userCredentials,
    cardNumberElement,
    stripe,
    customerId,
    paymentMethodsState,
    config,
    navigate,
    shopDetails
  ) =>
    dispatch(
      paymentCurrentCustomerStart(
        actionPayload,
        cartItems,
        userCredentials,
        cardNumberElement,
        stripe,
        customerId,
        paymentMethodsState,
        config,
        navigate,
        shopDetails
      )
    ),
  detachPaymentMethodStart: (id, shopDetails) =>
    dispatch(detachPaymentMethodStart(id, shopDetails)),
  setDeliveryFee: (deliveryFee) => dispatch(setDeliveryFee(deliveryFee)),
  setDeliveryFeePostcodes: (deliveryFeePostcodes) =>
    dispatch(setDeliveryFeePostcodes(deliveryFeePostcodes)),
  fetchOrdersThrottlingForDayStart: (day) =>
    dispatch(fetchOrdersThrottlingForDayStart(day)),
  setKeepUserCredentialsForCheckout: (checked) =>
    dispatch(setKeepUserCredentialsForCheckout(checked)),
});

/*
// the order matters here. need WithSpinner HOC to get props from connect
// so it goes after connect
const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);
*/

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailsContainer);
