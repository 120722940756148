import CartActionTypes from './cart.types';

export const toggleCartHidden = () => ({
  type: CartActionTypes.TOGGLE_CART_HIDDEN,
});

export const setCartHidden = (isHidden) => ({
  type: CartActionTypes.SET_CART_HIDDEN,
  payload: isHidden,
});

export const addToCart = (itemToAdd) => ({
  type: CartActionTypes.ADD_TO_CART,
  payload: {
    itemToAdd,
  },
});

export const removeFromCart = (item) => ({
  type: CartActionTypes.REMOVE_FROM_CART,
  payload: item,
});

export const clearItemFromCart = (item) => ({
  type: CartActionTypes.CLEAR_ITEM_FROM_CART,
  payload: item,
});

export const updateCartItem = (cartItems, prevItem, updatedItem) => ({
  type: CartActionTypes.UPDATE_CART_ITEM,
  payload: {
    cartItems,
    prevItem,
    updatedItem,
  },
});

export const setNewOrder = () => ({
  type: CartActionTypes.SET_NEW_ORDER,
});

// export const setPurchasedCart = (actionPayload, purchasedItems) => ({
//   type: CartActionTypes.SET_PURCHASED_CART,
//   payload: {
//     actionPayload,
//     purchasedItems,
//   },
// });

export const setOrderType = (orderType) => ({
  type: CartActionTypes.SET_ORDER_TYPE,
  payload: {
    orderType,
  },
});

export const verifyPromoCodeStart = (promoCode, cartTotal, shopDetails) => ({
  type: CartActionTypes.VERIFY_PROMO_CODE_START,
  payload: {
    promoCode,
    cartTotal,
    shopDetails,
  },
});

export const verifyPromoCodeSuccess = (promotion) => ({
  type: CartActionTypes.VERIFY_PROMO_CODE_SUCCESS,
  payload: {
    promotion,
  },
});

export const verifyPromoCodeFailure = (errorMessage) => ({
  type: CartActionTypes.VERIFY_PROMO_CODE_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setPromotion = (promotion) => ({
  type: CartActionTypes.SET_PROMOTION,
  payload: {
    promotion,
  },
});

export const setFastDisable = (isDisabled) => ({
  type: CartActionTypes.SET_FAST_DISABLE,
  payload: {
    isDisabled,
  },
});

export const resetPaymentErrorMessage = () => ({
  type: CartActionTypes.RESET_PAYMENT_ERROR_MESSAGE,
});

export const setBookingFee = (bookingFee) => ({
  type: CartActionTypes.SET_BOOKING_FEE,
  payload: {
    bookingFee,
  },
});

export const setPromotionFee = (promotionFee) => ({
  type: CartActionTypes.SET_PROMOTION_FEE,
  payload: {
    promotionFee,
  },
});

export const setDeliveryFee = (deliveryFee) => ({
  type: CartActionTypes.SET_DELIVERY_FEE,
  payload: {
    deliveryFee,
  },
});

export const setDeliveryFeePostcodes = (deliveryFeePostcodes) => ({
  type: CartActionTypes.SET_DELIVERY_FEE_POSTCODES,
  payload: {
    deliveryFeePostcodes,
  },
});

export const setDeliveryFeeDynamic = (deliveryFeeDynamic) => ({
  type: CartActionTypes.SET_DELIVERY_FEE_DYNAMIC,
  payload: {
    deliveryFeeDynamic,
  },
});

export const resetCartStateAfterLogoutOrLogin = (defaultOrderType) => ({
  type: CartActionTypes.RESET_CART_STATE_AFTER_LOGOUT_OR_LOGIN,
  payload: {
    defaultOrderType,
  },
});

export const setIsLoading = (isLoading) => ({
  type: CartActionTypes.SET_IS_LOADING,
  payload: {
    isLoading,
  },
});

export const wholesaleAddToAccountStart = (
  actionPayload,
  cartItems,
  navigate,
  shopDetails
) => ({
  type: CartActionTypes.WHOLESALE_ADD_TO_ACCOUNT_START,
  payload: {
    actionPayload,
    cartItems,
    navigate,
    shopDetails,
  },
});

export const wholesaleAddToAccountSuccess = (actionPayload, cartItems) => ({
  type: CartActionTypes.WHOLESALE_ADD_TO_ACCOUNT_SUCCESS,
  payload: {
    actionPayload,
    cartItems,
  },
});

export const wholesaleAddToAccountFailure = (response) => ({
  type: CartActionTypes.WHOLESALE_ADD_TO_ACCOUNT_FAILURE,
  payload: {
    response,
  },
});

export const paymentNewCustomerStart = (
  actionPayload,
  cartItems,
  userCredentials,
  cardNumberElement,
  stripe,
  shouldSaveCardDetails,
  config,
  navigate,
  shopDetails
) => ({
  type: CartActionTypes.PAYMENT_NEW_CUSTOMER_START,
  payload: {
    actionPayload,
    cartItems,
    userCredentials,
    cardNumberElement,
    stripe,
    shouldSaveCardDetails,
    config,
    navigate,
    shopDetails,
  },
});

export const paymentNewCustomerSuccess = (actionPayload, cartItems) => ({
  type: CartActionTypes.PAYMENT_NEW_CUSTOMER_SUCCESS,
  payload: {
    actionPayload,
    cartItems,
  },
});

export const paymentNewCustomerFailure = (response) => ({
  type: CartActionTypes.PAYMENT_NEW_CUSTOMER_FAILURE,
  payload: {
    response,
  },
});

export const paymentCurrentCustomerStart = (
  actionPayload,
  cartItems,
  userCredentials,
  cardNumberElement,
  stripe,
  customerId,
  paymentMethodState,
  config,
  navigate,
  shopDetails
) => ({
  type: CartActionTypes.PAYMENT_CURRENT_CUSTOMER_START,
  payload: {
    actionPayload,
    cartItems,
    userCredentials,
    cardNumberElement,
    stripe,
    customerId,
    paymentMethodState,
    config,
    navigate,
    shopDetails,
  },
});

export const paymentCurrentCustomerSuccess = (actionPayload, cartItems) => ({
  type: CartActionTypes.PAYMENT_CURRENT_CUSTOMER_SUCCESS,
  payload: {
    actionPayload,
    cartItems,
  },
});

export const paymentCurrentCustomerFailure = (response) => ({
  type: CartActionTypes.PAYMENT_CURRENT_CUSTOMER_FAILURE,
  payload: {
    response,
  },
});

export const resetCartAllCartFees = () => ({
  type: CartActionTypes.RESET_ALL_CART_FEES,
});

export const addOrderToSavedOrders = (order) => ({
  type: CartActionTypes.ADD_ORDER_TO_SAVED_ORDERS,
  payload: {
    order,
  },
});

export const addSavedOrderToCart = (order) => ({
  type: CartActionTypes.ADD_SAVED_ORDER_TO_CART,
  payload: {
    order,
  },
});

export const removeSavedOrder = (order) => ({
  type: CartActionTypes.REMOVE_SAVED_ORDER,
  payload: {
    order,
  },
});

export const setShouldSaveOrder = (shouldSaveOrder) => ({
  type: CartActionTypes.SET_SHOULD_SAVE_ORDER,
  payload: {
    shouldSaveOrder,
  },
});

export const removeAllCartItems = () => ({
  type: CartActionTypes.REMOVE_ALL_CART_ITEMS,
  payload: {},
});

export const addPromotionToPreviouslyUsedPromotions = (promotion) => ({
  type: CartActionTypes.ADD_PROMOTION_TO_PREVIOUSLY_USED_PROMOTIONS,
  payload: {
    promotion,
  },
});

export const removePromotionFromPreviouslyUsedPromotions = (promotion) => ({
  type: CartActionTypes.REMOVE_PROMOTION_FROM_PREVIOUSLY_USED_PROMOTIONS,
  payload: {
    promotion,
  },
});
