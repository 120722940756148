import ConfigActionTypes from './config.types';

export const INITIAL_CONFIG_STATE = {
  config: {},
  errorMessage: '',
};

export const configReducer = (state = INITIAL_CONFIG_STATE, action) => {
  switch (action.type) {
    case ConfigActionTypes.FETCH_CONFIG_SUCCESS:
      return {
        config: action.payload.config,
        errorMessage: '',
      };
    case ConfigActionTypes.FETCH_CONFIG_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default configReducer;
