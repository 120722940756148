import React from 'react';
import _ from 'lodash';

import OperatingHoursDay from '../operating-hours-day/operating-hours-day.component';

import {
  Container,
  Title,
  OperatingHoursContainer,
} from './operating-hours.styles';

import { DAY_ORDER } from '../../global.constants';

export const OperatingHours = ({ operatingHoursDetails, overrideDays }) => {
  const { hours, title } = operatingHoursDetails;

  return (
    <Container>
      <Title>{title.toUpperCase()}</Title>
      <OperatingHoursContainer>
        {_.map(DAY_ORDER, (day, idx) => (
          <OperatingHoursDay
            key={idx}
            idx={idx}
            day={day}
            hours={hours[day]}
            overrideDays={overrideDays}
          />
        ))}
      </OperatingHoursContainer>
    </Container>
  );
};

export default OperatingHours;
