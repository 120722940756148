import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Swal from 'sweetalert2';

import ItemMobile from './item-mobile.component';

import {
  resetItemModal,
  incrementQuantityBy,
  setSelectedPrice,
  setUserInstructions,
} from '../../redux/shop/shop.actions';
import {
  selectItemForModal,
  selectCollectionForItemModal,
  selectItemModalUserCustomizations,
  selectIsUpdatingItem,
  selectShopData,
  selectSelectedPrice,
  selectUserInstructions,
  selectShopDetails,
} from '../../redux/shop/shop.selectors';
import {
  areAllRequiredCustomizationsSelected,
  computeDisplayPrice,
} from '../../utils/shop-utils';
import {
  addToCart,
  updateCartItem,
  setCartHidden,
} from '../../redux/cart/cart.actions';
import { selectCartItems } from '../../redux/cart/cart.selectors';
import {
  calculateItemTotal,
  findCollectionForItem,
  calcGstBlurb,
  computeModalButtonText,
  sortCustomizationsUsingOrder,
} from '../../redux/cart/cart.utils';
import { selectConfig } from '../../redux/config/config.selectors';
import {
  MAX_LENGTH_USER_INSTRUCTIONS,
  ADD,
  SUBTRACT,
  GST_NONE,
} from '../../global.constants';

export const ItemMobileContainer = ({
  item,
  addToCart,
  incrementQuantityBy,
  itemModalUserCustomizations,
  resetItemModal,
  cartItems,
  isUpdatingItem,
  updateCartItem,
  shopData,
  setCartHidden,
  selectedPrice,
  setSelectedPrice,
  userInstructions,
  setUserInstructions,
  config,
  shopDetails,
  collection,
}) => {
  const navigate = useNavigate();
  const {
    customizations,
    customizationsOrder,
    description,
    gstSetting,
    itemCode,
    itemCategories,
    key,
    kitchenTitle,
    prices,
    quantity,
    requiresAgeConsent,
    title,
  } = item;

  const { sortItemPricesByPriceOrSize } = config;

  const [sortedPrices, setSortedPrices] = useState([]);

  useEffect(() => {
    // scroll to top of the page on load
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSortedPrices(_.orderBy(prices, [sortItemPricesByPriceOrSize], ['asc']));

    if (!item.title) {
      // WORKAROUND
      // user refreshed page OR someone has shared link to this item online:
      //
      // user has refreshed the page which means item has not been
      // passed in as a prop from collection item component.
      // user has refreshed the page which means item has not been
      // passed in as a prop from collection item component.
      // instead, we have the default state of shop reducer which has
      // item.title as undefined:
      // ...
      // ...item: {price: 1} <= no 'title' field, hence undefined
      // ...
      // just go back to home page and load shop data
      navigate('/');
    }
  }, [item]);

  useEffect(() => {
    if (_.size(sortedPrices) === 1) {
      setSelectedPrice(sortedPrices[0]);
    }
  }, [sortedPrices]);

  useEffect(() => {
    if (isUpdatingItem) {
      setUserInstructions(item.userInstructions);
      setSelectedPrice(item.priceDetails);
    }
  }, [isUpdatingItem]);

  const handleClose = () => {
    setCartHidden(true);
    setTimeout(() => {
      // wait a little before resetting modal state.
      // => ensures navigation back has finished
      resetItemModal();
    }, 500);
    navigate(-1);
  };

  const calculateItemPrice = () => {
    return calculateItemTotal(
      selectedPrice,
      quantity,
      itemModalUserCustomizations,
      item
    );
  };

  const _computeButtonText = () => {
    return computeModalButtonText(
      item,
      selectedPrice,
      itemModalUserCustomizations,
      isUpdatingItem
    );
  };

  const _calcGstBlurb = () => {
    return calcGstBlurb(item, selectedPrice, itemModalUserCustomizations);
  };

  const handleSubmission = () => {
    if (_.size(sortedPrices) !== 1 && _.isEmpty(selectedPrice)) {
      // user needs to specify item size for item having
      // multiple size options
      Swal.fire({
        icon: 'error',
        title: 'Choose a size',
      });
      return;
    }

    //check if all required customizations have been selected
    if (
      !areAllRequiredCustomizationsSelected(
        customizations,
        customizationsOrder,
        itemModalUserCustomizations,
        shopData
      )
    ) {
      // user needs to specify options for all required customizations
      Swal.fire({
        icon: 'error',
        title: 'More info needed',
        text: 'Please fill out all required fields',
      });
      return;
    }

    let _itemCode = '';
    if (itemCode !== undefined || itemCode !== null) {
      _itemCode = itemCode;
    }

    let proposedItem = {
      key,
      title,
      kitchenTitle,
      description,
      requiresAgeConsent,
      priceDetails: {
        ...selectedPrice,
        itemAvailableInManySizes: _.size(sortedPrices) > 1,
      },
      prices, // keep prices options for when updating item
      quantity,
      imagePath: item.imagePath,
      imageUrl: item.imageUrl,
      // handle new feature of ordered customizations display.
      // need to copy over customization keys if item doesnt
      // have customizationsOrder present.
      userCustomizations: customizationsOrder
        ? sortCustomizationsUsingOrder(
            itemModalUserCustomizations,
            customizationsOrder
          )
        : itemModalUserCustomizations,
      userInstructions,
      customizations,
      // handle new feature of ordered customizations display.
      // need to copy over customization keys if item doesnt
      // have customizationsOrder present.
      // this will ensure item will display customizations
      // when user potentially revists it to update their item
      // customization selections
      customizationsOrder: customizationsOrder
        ? customizationsOrder
        : _.map(customizations, (val, custKey) => custKey),
      gstSetting: gstSetting ? gstSetting : GST_NONE,
      itemCode: _itemCode,
      itemCategories: itemCategories ? itemCategories : [],
    };

    // add collection key & title to proposed item.
    const matchedCollection = findCollectionForItem(shopData, key);

    if (matchedCollection) {
      const { title: collectionTitle, key: collectionKey } = matchedCollection;
      proposedItem = {
        ...proposedItem,
        collectionKey,
        collectionTitle,
      };
    } else {
      proposedItem = {
        ...proposedItem,
        collectionKey: '',
        collectionTitle: '',
      };
    }

    if (!isUpdatingItem) {
      addToCart(proposedItem);
    } else {
      // we are updating the item which is already in the cart
      updateCartItem(cartItems, item, proposedItem);
    }

    setTimeout(() => {
      // redux store resets item modal before
      // navigation takes us back.
      // => get a flashing price of 'NaN' when
      //    this happens, so delay it
      // note: this does not happen for desktop
      // item modal because there we're only using
      // redux actions, both to reset the modal and
      // hide it.
      resetItemModal();
    }, 500);
    navigate(-1);
  };

  const handleItemChangeClick = (operation) => {
    if (operation === ADD) {
      incrementQuantityBy(1);
    }
    if (operation === SUBTRACT) {
      incrementQuantityBy(-1);
    }
  };

  const handleUserInstructionsChange = (event) => {
    if (event.target.value.length > MAX_LENGTH_USER_INSTRUCTIONS) {
      return;
    }
    setUserInstructions(event.target.value);
  };

  return (
    <ItemMobile
      item={item}
      sortedPrices={sortedPrices}
      selectedPrice={selectedPrice}
      setSelectedPrice={setSelectedPrice}
      handleClose={handleClose}
      handleItemChangeClick={handleItemChangeClick}
      handleSubmission={handleSubmission}
      shopData={shopData}
      userInstructions={userInstructions}
      handleUserInstructionsChange={handleUserInstructionsChange}
      config={config}
      shopDetails={shopDetails}
      computeDisplayPrice={computeDisplayPrice}
      computeButtonText={_computeButtonText}
      calcGstBlurb={_calcGstBlurb}
      calculateItemPrice={calculateItemPrice}
      isUpdatingItem={isUpdatingItem}
      collection={collection}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  item: selectItemForModal,
  collection: selectCollectionForItemModal,
  itemModalUserCustomizations: selectItemModalUserCustomizations,
  cartItems: selectCartItems,
  isUpdatingItem: selectIsUpdatingItem,
  shopData: selectShopData,
  selectedPrice: selectSelectedPrice,
  userInstructions: selectUserInstructions,
  config: selectConfig,
  shopDetails: selectShopDetails,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
  incrementQuantityBy: (quantity) => dispatch(incrementQuantityBy(quantity)),
  resetItemModal: () => dispatch(resetItemModal()),
  updateCartItem: (cartItems, prevItem, updatedItem) =>
    dispatch(updateCartItem(cartItems, prevItem, updatedItem)),
  setCartHidden: (isHidden) => dispatch(setCartHidden(isHidden)),
  setSelectedPrice: (selectedPrice) =>
    dispatch(setSelectedPrice(selectedPrice)),
  setUserInstructions: (userInstructions) =>
    dispatch(setUserInstructions(userInstructions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemMobileContainer);
