import axios from 'axios';

import {
  API_VERSION_V1,
  VERIFY_PROMO_CODE_SERVER_URL,
} from '../global.constants';

export const verifyPromoCode = async (promoCode, cartTotal, shopDetails) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/${VERIFY_PROMO_CODE_SERVER_URL}`;

  const { data } = await axios({
    url,
    method: 'post',
    data: {
      promoCode,
      cartTotal,
    },
  });
  return { ...data };
};

export const paymentNewCustomer = async (
  actionPayload,
  shouldSaveCardDetails,
  shopDetails
) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/paymentNewCustomer`;
  const {
    data: {
      clientSecret,
      customerId,
      shouldChargeCard,
      paymentIntentId,
      error,
    },
  } = await axios({
    url,
    method: 'post',
    data: {
      ...actionPayload,
      shouldSaveCardDetails,
    },
  });

  let errorMsg = 'API (0)';
  if (`${error}`.startsWith('ValidationError'))
    errorMsg = `${error}. ${errorMsg}`;
  if (error) throw new Error(errorMsg);

  return { clientSecret, customerId, shouldChargeCard, paymentIntentId, error };
};

export const paymentCurrentCustomer = async (
  actionPayload,
  customerId,
  paymentMethodId,
  shopDetails
) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/paymentCurrentCustomer`;
  const {
    data: { clientSecret, paymentIntentId, error },
  } = await axios({
    url,
    method: 'post',
    data: {
      ...actionPayload,
      customerId,
      paymentMethodId,
    },
  });

  let errorMsg = 'API (1)';
  if (`${error}`.startsWith('ValidationError'))
    errorMsg = `${error}. ${errorMsg}`;
  if (error) throw new Error(errorMsg);

  return { clientSecret, paymentIntentId };
};

export const paymentCurrentCustomerNewCard = async (
  actionPayload,
  customerId,
  shouldSaveCardDetails,
  shopDetails
) => {
  const { backendServerUrl } = shopDetails;

  const url = `${backendServerUrl}/api/${API_VERSION_V1}/paymentCurrentCustomerNewCard`;
  const {
    data: { clientSecret, paymentIntentId, error },
  } = await axios({
    url,
    method: 'post',
    data: {
      ...actionPayload,
      customerId,
      shouldSaveCardDetails,
    },
  });

  let errorMsg = 'API (2)';
  if (`${error}`.startsWith('ValidationError'))
    errorMsg = `${error}. ${errorMsg}`;
  if (error) throw new Error(errorMsg);

  return { clientSecret, paymentIntentId };
};

export const paymentBackOfHouse = async (
  actionPayload,
  customerId,
  paymentIntentId,
  isOrdersThrottlingEnabled,
  wantedAtTypes,
  shopDetails
) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/paymentBackOfHouse`;
  const {
    data: { error },
  } = await axios({
    url,
    method: 'post',
    data: {
      ...actionPayload,
      customerId: customerId ? customerId : '',
      paymentIntentId,
      isOrdersThrottlingEnabled: isOrdersThrottlingEnabled
        ? isOrdersThrottlingEnabled
        : false,
      wantedAtTypes: wantedAtTypes ? wantedAtTypes : '',
    },
  });

  return { error };
};

export const wholesaleAddToAccount = async (
  actionPayload,
  idToken,
  shopDetails
) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/wholesaleAddToAccount`;
  const {
    data: { error },
  } = await axios({
    url,
    method: 'post',
    data: {
      ...actionPayload,
      idToken,
    },
  });

  return { error };
};
