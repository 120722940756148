import styled, { keyframes } from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

import {
  black,
  lightGray,
  darkerGray,
  white,
  red,
  mobileSplitPoint,
  orange,
  veryLightGray,
} from '../../style-constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const PromoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  min-height: 175px;
  padding: 30px 25px 15px 25px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: 30px;
    width: 100%;
  }
`;
PromoContainer.displayName = 'PromoContainer';

export const PromoTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
`;
PromoTitleContainer.displayName = 'PromoTitleContainer';

export const PromoTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
PromoTitle.displayName = 'PromoTitle';

export const PromoBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
PromoBody.displayName = 'PromoBody';

export const PromoInput = styled.input`
  border: 1px solid ${darkerGray};
  border-radius: 3px;
  font-size: 15px;
  height: 37px;
  padding-left: 3px;
  width: 45%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 45%;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${darkerGray};
    padding-left: 3px;
  }
  &:focus {
    outline: none;
  }
`;
PromoInput.displayName = 'PromoInput';

export const PromoActionContainer = styled.div`
  align-items: center;
  background-color: ${lightGray};
  border: 2px solid ${darkerGray};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 37px;
  justify-content: center;
  padding: 10px 20px;

  -webkit-tap-highlight-color: transparent;
`;
PromoActionContainer.displayName = 'PromoActionContainer';

export const PromoAction = styled.div`
  font-weight: 700;
`;
PromoAction.displayName = 'PromoAction';

export const SpinnerContainer = styled.div`
  margin: 0px 5px;
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export const CloseButtonAction = styled.div`
  align-items: center;
  background-color: ${({ isValid }) => (isValid ? orange : red)};
  border: none;
  border-radius: 30px;
  color: ${black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  margin-right: 5px;
  padding: 7px;

  &:focus {
    outline: none;
  }
`;
CloseButtonAction.displayName = 'CloseButtonAction';

export const ButtonImg = styled.img`
  height: 10px;
  width: 10px;
`;
ButtonImg.displayName = 'ButtonImg';

export const PreviouslyUsedPromotionsContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0px;
  width: 100%;
`;
PreviouslyUsedPromotionsContainer.displayName =
  'PreviouslyUsedPromotionsContainer';

export const OuterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-right: 5px;
  /* min-width: 100px; */
`;
OuterContainer.displayName = 'OuterContainer';

export const PreviouslyUsedPromotionContainer = styled.button`
  align-items: center;
  background-color: ${white};
  border: unset;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 15px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${veryLightGray};
  }
`;
PreviouslyUsedPromotionContainer.displayName =
  'PreviouslyUsedPromotionContainer';

export const PreviouslyUsedPromotionText = styled.div`
  font-size: 14px;
`;
PreviouslyUsedPromotionText.displayName = 'PreviouslyUsedPromotionText';

export const RemoveButton = styled.button`
  align-items: center;
  background-color: ${white};
  border: unset;
  border-radius: 30px;
  box-shadow: none;
  color: ${black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'Cabin';
  font-size: 100%;
  font-weight: 700;
  justify-content: center;
  margin-left: 5px;
  padding: 0.4em;
  /* width: 100px; */

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
RemoveButton.displayName = 'RemoveButton';

export const ButtonText = styled.div`
  color: ${black};
  font-size: 14px;
  padding-left: 10px;
`;
ButtonText.displayName = 'ButtonText';

export const SubTitle = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;
`;
SubTitle.displayName = 'SubTitle';
