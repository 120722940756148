import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { motion } from 'framer-motion';

import { FiPlus, FiMinus, FiMoreHorizontal, FiHeart } from 'react-icons/fi';
import { FaHeart } from 'react-icons/fa';

import {
  addItemToModal,
  setShowItemModal,
} from './../../redux/shop/shop.actions';
import { selectShopDetails } from '../../redux/shop/shop.selectors';

import {
  setCartHidden,
  addToCart,
  removeFromCart,
} from '../../redux/cart/cart.actions';
import { selectCartItems } from '../../redux/cart/cart.selectors';
import { findCollectionForItem } from '../../redux/cart/cart.utils';

import { toggleItemIsHearted } from '../../redux/hearted-items/hearted-items.actions';
import { selectHeartedItems } from '../../redux/hearted-items/hearted-items.selectors';

import { red } from '../../style-constants';

import {
  // ONE
  ContainerOne,
  ImageContainerOne,
  WordsContainerOne,
  DescriptionOne,
  InnerContentOne,
  KeeperOne,
  QuantityContainerOne,
  // TWO
  ContainerTwo,
  ImageContainerTwo,
  WordsContainerTwo,
  DescriptionTwo,
  InnerContentTwo,
  KeeperTwo,
  QuantityContainerTwo,
  // THREE
  ContainerThree,
  FooterContainerThree,
  KeeperThree,
  QuantityContainerThree,
  // FOUR,
  ContainerFour,
  ContentContainerFour,
  FooterContainerFour,
  KeeperFour,
  QuantityContainerFour,
  // Common
  ItemImage,
  Title,
  Description,
  Price,
  ShortCutQuantity,
  ActionButtonsContainer,
  ActionButton,
  SoldOutContainer,
  SoldOutText,
  PartyContainer,
  PartyLeft,
  PartyRight,
} from './collection-item.styles';

import {
  windowInnerWidthSplit,
  veryLightGray,
  redBackgroundLight,
  blackTransparentDark,
  black,
} from '../../style-constants';

import {
  ADD,
  SUBTRACT,
  GST_NONE,
  AVAILABILITY_STATUS__SOLD_OUT,
  ITEM_IMAGE_BORDER_STYLE,
} from '../../global.constants';

const BASE_IMAGE_FORMATTING = 'auto=compress&fit=crop&h=110&w=110&q=90';
// const BASE_IMAGE_FORMATTING =
//   'auto=compress&fit=crop&mask=ellipse&h=110&w=110&q=90';
const JUMP = 20;

export const CollectionItem = ({
  item,
  collection,
  shopData,
  setShowItemModal,
  addItemToModal,
  setCartHidden,
  shopDetails,
  addToCart,
  removeFromCart,
  cartItems,
  config,
  toggleItemIsHearted,
  heartedItems,
  isHearted,
  availabilityStatus,
}) => {
  const navigate = useNavigate();
  const {
    customizations,
    customizationsOrder,
    description,
    gstSetting,
    imagePath,
    itemCode,
    itemCategories,
    key,
    kitchenTitle,
    prices,
    requiresAgeConsent,
    title,
  } = item;
  const { imageProviderApi } = shopDetails;
  const { enableHeartedItems, itemImageBorderStyle } = config;

  const [showQuickActions, setShowQuickActions] = useState(true);
  const [shortCutQuantity, setShortCutQuantity] = useState(null);
  const [_isHearted, _setIsHearted] = useState(false);

  useEffect(() => {
    // if an item has customizations, look through each one and
    // check if any have isRequired === true.
    // if there's at least one, then we don't want to show
    // the 'quick actions' buttons on the collection item.
    // ...user *must* select the required information before
    // being able to add item to cart
    let haveAnyRequiredCustsOldFeature = false;
    let haveAnyRequiredCustsNewFeature = false;
    let haveMultiplePrices = false;

    // for new customizationsOrder display feature info:
    // need to check both:
    // - customizations
    // - customizationsOrder
    // for customizations since older item versions wont have customizationsOrder field.
    // so check both fields and we logically OR both results later in
    // the variable 'haveAnyRequiredCusts'
    if (!_.isEmpty(customizations)) {
      haveAnyRequiredCustsOldFeature = _.reduce(
        item.customizations,
        (accum, val, custKey) => {
          if (
            shopData.customizations[custKey] &&
            shopData.customizations[custKey].isRequired
          ) {
            accum = true;
          }
          return accum;
        },
        false
      );
    }

    // check if new feature is present first
    if (customizationsOrder && !_.isEmpty(customizationsOrder)) {
      haveAnyRequiredCustsNewFeature = _.reduce(
        customizationsOrder,
        (accum, custKey) => {
          if (
            shopData.customizations[custKey] &&
            shopData.customizations[custKey].isRequired
          ) {
            accum = true;
          }
          return accum;
        },
        false
      );
    }

    const haveAnyRequiredCusts =
      haveAnyRequiredCustsOldFeature || haveAnyRequiredCustsNewFeature;

    if (!_.isEmpty(prices) && _.size(prices) > 1) {
      haveMultiplePrices = true;
    }

    if (haveAnyRequiredCusts || haveMultiplePrices) {
      setShowQuickActions(false);
    }
  }, [customizations, prices]);

  useEffect(() => {
    // item may be in cart at many different places in cartItems array.
    // that's because an item is considered unique based *not only on its key*,
    // but its customizations & userInstructions.
    // we need to find all the instances of the item in the cart, then
    // add up all the quantities for the item
    const itemInCartArray = _.filter(
      cartItems,
      (cartItem) => cartItem.key === key
    );

    if (_.isEmpty(itemInCartArray)) {
      setShortCutQuantity(null);
      return;
    }

    setShortCutQuantity(
      _.reduce(itemInCartArray, (accum, item) => accum + item.quantity, 0)
    );
  }, [cartItems]);

  useEffect(() => {
    // see if the item is present in the heartedItems state
    if (!item || !item.key) return;

    const isAlreadyHearted = _.find(
      heartedItems,
      (heartedItem) => heartedItem.key === item.key
    );
    _setIsHearted(!!isAlreadyHearted);
  }, [item, heartedItems]);

  const handleClick = () => {
    addItemToModal(item, collection);
    setCartHidden(true);

    if (window.innerWidth > windowInnerWidthSplit) {
      // render desktop version: item modal
      setShowItemModal(true);
    } else {
      // render mobile version: new route & component
      navigate(`/item/${item.key}`);
    }
  };

  const computePrice = () => {
    const pricesSorted = _.orderBy(prices, ['price'], ['asc']);
    const pricesLength = _.size(pricesSorted);
    let _price = pricesSorted[0].price;

    if (!_price) return null;

    let displayPrice = pricesLength === 1 ? `$${_price}` : `From $${_price}`;

    return displayPrice;
  };

  const handleShortCutClick = (actionName) => {
    // also we should not allow to use the 'short-cut add/remove from cart' feature
    // if the item is a multisize type - user still needs to click thru
    // to item details screen to select mandatory size requirements
    //
    // the problem of whether the item has any customizations which
    // are marked, asRequired === true, is already handled in the useEffect hook above.
    // i.e. handleShortCutClick handler will not ever be called for this case
    // because the short-cut click buttons dont display for items with required
    // customizations.
    if (_.size(prices) > 1) {
      alert(
        'Quick add to cart feature not possible for multisized items. Please click through to item details.'
      );
      return;
    }

    let _itemCode = '';
    if (itemCode !== undefined || itemCode !== null) {
      _itemCode = itemCode;
    }

    // now we can safely add the item to the cart
    let proposedItem = {
      key,
      title,
      kitchenTitle,
      description,
      requiresAgeConsent,
      priceDetails: {
        price: _.values(prices)[0].price,
        size: _.values(prices)[0].size,
        itemAvailableInManySizes: false,
      },
      // keep prices options for when updating item
      prices,
      quantity: 1,
      imagePath: item.imagePath,
      imageUrl: item.imageUrl,
      // by definition of quick action button, user wont be looking at any
      // customizations for the item, so this must be empty
      userCustomizations: [],
      userInstructions: '',
      // keep item's customizations in case user wants to edit item later from cart.
      // i think this is also an empty []
      customizations,
      // ditto, i think this is also an empty [] if present.
      // potentially unecessary logic here
      customizationsOrder: customizationsOrder
        ? customizationsOrder
        : _.map(customizations, (val, custKey) => custKey),
      gstSetting: gstSetting ? gstSetting : GST_NONE,
      itemCode: _itemCode,
      itemCategories: itemCategories ? itemCategories : [],
    };

    // add collection key & title to proposed item.
    const matchedCollection = findCollectionForItem(shopData, key);

    if (matchedCollection) {
      const { title: collectionTitle, key: collectionKey } = matchedCollection;
      proposedItem = {
        ...proposedItem,
        collectionKey,
        collectionTitle,
      };
    } else {
      proposedItem = {
        ...proposedItem,
        collectionKey: '',
        collectionTitle: '',
      };
    }

    if (actionName === ADD) {
      addToCart(proposedItem);
    } else {
      removeFromCart(proposedItem);
    }
  };

  const handleHeartClick = () => {
    toggleItemIsHearted(item);
  };

  const collectionItemWithImage = () => {
    let _render = null;
    const titleStandardised = _.truncate(title, { length: 80 });
    const descriptionStandardised = _.truncate(description, { length: 80 });

    if (showQuickActions) {
      _render = (
        <ContainerOne onClick={handleClick} isHearted={isHearted}>
          <motion.div
            style={{ zIndex: 8 }}
            animate={{
              y: ['-6px', `${-1 * (JUMP + shortCutQuantity)}px`, '-6px'],
            }}
            transition={{
              duration: 0.3,
            }}
            initial={false}
          >
            {shortCutQuantity &&
              (shortCutQuantity >= 5 ? (
                <PartyContainer>
                  <PartyLeft>🎉</PartyLeft>
                  <QuantityContainerOne>
                    <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                  </QuantityContainerOne>
                  <PartyRight>🎉</PartyRight>
                </PartyContainer>
              ) : (
                <QuantityContainerOne>
                  <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                </QuantityContainerOne>
              ))}
          </motion.div>
          <ImageContainerOne>
            {isHearted ? (
              <ItemImage
                src={`${imageProviderApi}/${imagePath}?${BASE_IMAGE_FORMATTING}&maskbg=${redBackgroundLight.substring(
                  1
                )}`}
                itemImageBorderStyle={itemImageBorderStyle}
              />
            ) : (
              <ItemImage
                src={`${imageProviderApi}/${imagePath}?${BASE_IMAGE_FORMATTING}&maskbg=${veryLightGray.substring(
                  1
                )}`}
                itemImageBorderStyle={itemImageBorderStyle}
              />
            )}
            <WordsContainerOne>
              <Title>{titleStandardised}</Title>
              <DescriptionOne>{descriptionStandardised}</DescriptionOne>
            </WordsContainerOne>
          </ImageContainerOne>
          <InnerContentOne>
            <KeeperOne>
              <Price>{computePrice()}</Price>
            </KeeperOne>
            {availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT ? (
              <ActionButtonsContainer>
                <SoldOutContainer>
                  <SoldOutText>Sold Out</SoldOutText>
                </SoldOutContainer>
              </ActionButtonsContainer>
            ) : (
              <ActionButtonsContainer>
                {enableHeartedItems && (
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHeartClick();
                    }}
                  >
                    {_isHearted ? (
                      <FaHeart style={{ color: red }} />
                    ) : (
                      <FiHeart />
                    )}
                  </ActionButton>
                )}
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShortCutClick(SUBTRACT);
                  }}
                >
                  <FiMinus />
                </ActionButton>
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShortCutClick(ADD);
                  }}
                >
                  <FiPlus />
                </ActionButton>
              </ActionButtonsContainer>
            )}
          </InnerContentOne>
        </ContainerOne>
      );
    } else {
      _render = (
        <ContainerTwo onClick={handleClick} isHearted={isHearted}>
          <motion.div
            style={{ zIndex: 8 }}
            animate={{
              y: ['-6px', `${-1 * (JUMP + shortCutQuantity)}px`, '-6px'],
            }}
            transition={{
              duration: 0.3,
            }}
            initial={shortCutQuantity}
          >
            {shortCutQuantity &&
              (shortCutQuantity >= 5 ? (
                <PartyContainer>
                  <PartyLeft>🎉</PartyLeft>
                  <QuantityContainerTwo>
                    <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                  </QuantityContainerTwo>
                  <PartyRight>🎉</PartyRight>
                </PartyContainer>
              ) : (
                <QuantityContainerTwo>
                  <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                </QuantityContainerTwo>
              ))}
          </motion.div>
          <ImageContainerTwo>
            {isHearted ? (
              <ItemImage
                src={`${imageProviderApi}/${imagePath}?${BASE_IMAGE_FORMATTING}&maskbg=${redBackgroundLight.substring(
                  1
                )}`}
                itemImageBorderStyle={itemImageBorderStyle}
              />
            ) : (
              <ItemImage
                src={`${imageProviderApi}/${imagePath}?${BASE_IMAGE_FORMATTING}&maskbg=${veryLightGray.substring(
                  1
                )}`}
                itemImageBorderStyle={itemImageBorderStyle}
              />
            )}
            <WordsContainerTwo>
              <Title>{titleStandardised}</Title>
              <DescriptionTwo>{descriptionStandardised}</DescriptionTwo>
            </WordsContainerTwo>
          </ImageContainerTwo>
          <InnerContentTwo>
            <KeeperTwo>
              <Price>{computePrice()}</Price>
            </KeeperTwo>

            {availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT ? (
              <ActionButtonsContainer>
                <SoldOutContainer>
                  <SoldOutText>Sold Out</SoldOutText>
                </SoldOutContainer>
              </ActionButtonsContainer>
            ) : (
              <ActionButtonsContainer>
                {enableHeartedItems && (
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHeartClick();
                    }}
                  >
                    {_isHearted ? (
                      <FaHeart style={{ color: red }} />
                    ) : (
                      <FiHeart />
                    )}
                  </ActionButton>
                )}
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick();
                  }}
                >
                  <FiMoreHorizontal />
                </ActionButton>
              </ActionButtonsContainer>
            )}
          </InnerContentTwo>
        </ContainerTwo>
      );
    }

    return _render;
  };

  const collectionItemNoImage = () => {
    let _render = null;
    const titleStandardised = title;
    const descriptionStandardised = description;

    if (showQuickActions) {
      _render = (
        <ContainerThree onClick={handleClick} isHearted={isHearted}>
          <motion.div
            style={{ zIndex: 8 }}
            animate={{
              y: ['-10px', `${-1 * (JUMP + shortCutQuantity)}px`, '-10px'],
              x: ['5px', '0px', '0px'],
            }}
            transition={{
              duration: 0.3,
            }}
            initial={shortCutQuantity}
          >
            {shortCutQuantity &&
              (shortCutQuantity >= 5 ? (
                <PartyContainer>
                  <PartyLeft>🎉</PartyLeft>
                  <QuantityContainerThree>
                    <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                  </QuantityContainerThree>
                  <PartyRight>🎉</PartyRight>
                </PartyContainer>
              ) : (
                <QuantityContainerThree>
                  <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                </QuantityContainerThree>
              ))}
          </motion.div>
          <Title>{titleStandardised}</Title>
          <Description>{descriptionStandardised}</Description>
          <FooterContainerThree>
            <Price>{computePrice()}</Price>
            <KeeperThree>
              {availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT ? (
                <ActionButtonsContainer>
                  <SoldOutContainer>
                    <SoldOutText>Sold Out</SoldOutText>
                  </SoldOutContainer>
                </ActionButtonsContainer>
              ) : (
                <ActionButtonsContainer>
                  {enableHeartedItems && (
                    <ActionButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleHeartClick();
                      }}
                    >
                      {_isHearted ? (
                        <FaHeart style={{ color: red }} />
                      ) : (
                        <FiHeart />
                      )}
                    </ActionButton>
                  )}
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShortCutClick(SUBTRACT);
                    }}
                  >
                    <FiMinus />
                  </ActionButton>
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShortCutClick(ADD);
                    }}
                  >
                    <FiPlus />
                  </ActionButton>
                </ActionButtonsContainer>
              )}
            </KeeperThree>
          </FooterContainerThree>
        </ContainerThree>
      );
    } else {
      _render = (
        <ContainerFour onClick={handleClick} isHearted={isHearted}>
          <motion.div
            style={{ zIndex: 8 }}
            animate={{
              y: ['-10px', `${-1 * (JUMP + shortCutQuantity)}px`, '-10px'],
              x: ['5px', '0px', '0px'],
            }}
            transition={{
              duration: 0.3,
            }}
            initial={shortCutQuantity}
          >
            {shortCutQuantity &&
              (shortCutQuantity >= 5 ? (
                <PartyContainer>
                  <PartyLeft>🎉</PartyLeft>
                  <QuantityContainerFour>
                    <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                  </QuantityContainerFour>
                  <PartyRight>🎉</PartyRight>
                </PartyContainer>
              ) : (
                <QuantityContainerFour>
                  <ShortCutQuantity>{`${shortCutQuantity}`}</ShortCutQuantity>
                </QuantityContainerFour>
              ))}
          </motion.div>
          <Title>{titleStandardised}</Title>
          <Description>{descriptionStandardised}</Description>
          <ContentContainerFour>
            <FooterContainerFour>
              <Price>{computePrice()}</Price>
              <KeeperFour>
                {availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT ? (
                  <ActionButtonsContainer>
                    <SoldOutContainer>
                      <SoldOutText>Sold Out</SoldOutText>
                    </SoldOutContainer>
                  </ActionButtonsContainer>
                ) : (
                  <ActionButtonsContainer>
                    {enableHeartedItems && (
                      <ActionButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHeartClick();
                        }}
                      >
                        {_isHearted ? (
                          <FaHeart style={{ color: red }} />
                        ) : (
                          <FiHeart />
                        )}
                      </ActionButton>
                    )}
                    <ActionButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick();
                      }}
                    >
                      <FiMoreHorizontal />
                    </ActionButton>
                  </ActionButtonsContainer>
                )}
              </KeeperFour>
            </FooterContainerFour>
          </ContentContainerFour>
        </ContainerFour>
      );
    }

    return _render;
  };

  const determineRender = () => {
    let _render = null;
    if (!!imagePath) {
      _render = collectionItemWithImage();
    } else {
      _render = collectionItemNoImage();
    }

    return _render;
  };

  // conditionally determine what to render
  // based on item details
  return determineRender();
};

const mapStateToProps = createStructuredSelector({
  shopDetails: selectShopDetails,
  cartItems: selectCartItems,
  heartedItems: selectHeartedItems,
});

const mapDispatchToProps = (dispatch) => ({
  addItemToModal: (item, collection) =>
    dispatch(addItemToModal(item, collection)),
  setShowItemModal: (show) => dispatch(setShowItemModal(show)),
  setCartHidden: (visible) => dispatch(setCartHidden(visible)),
  addToCart: (item) => dispatch(addToCart(item)),
  removeFromCart: (item) => dispatch(removeFromCart(item)),
  toggleItemIsHearted: (item) => dispatch(toggleItemIsHearted(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionItem);
