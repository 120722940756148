import React, { useState } from 'react';
import _ from 'lodash';
import BeatLoader from 'react-spinners/BeatLoader';

import { HEARTED_ITEMS_COLLECTION_REF } from '../../global.constants';

import {
  OuterContainer,
  CurrentCollectionContainer,
  TitleContainer,
  InnerContainer,
  LoaderInnerContainer,
  EmptyContainer,
  EmptyText,
  Title,
  DownIcon,
  UpIcon,
  Container,
  ButtonContainer,
} from './nav-bar-collections-mobile.styles';

import { black, lightGray } from '../../style-constants';

const MAX_MOBILE_TITLE_LENGTH = 25;

export const NavBarCollectionsMobile = ({
  collections,
  handleClick,
  collectionsOrder,
  config,
  selectedCollection,
}) => {
  const { enableHeartedItems } = config;

  const [isVisible, setIsVisible] = useState(false);

  const _handleClick = (cKey, { isSavedCollectionClicked }) => {
    handleClick(cKey, { isSavedCollectionClicked });
    setIsVisible(false);
  };

  const renderDropdown = () => {
    if (_.isEmpty(collectionsOrder)) {
      return (
        <Container>
          <EmptyContainer>
            <EmptyText>No items available</EmptyText>
          </EmptyContainer>
        </Container>
      );
    } else {
      return (
        <Container>
          {enableHeartedItems && (
            <ButtonContainer
              key={HEARTED_ITEMS_COLLECTION_REF}
              type="button"
              onClick={() =>
                _handleClick(HEARTED_ITEMS_COLLECTION_REF, {
                  isSavedCollectionClicked: true,
                })
              }
            >
              Saved
            </ButtonContainer>
          )}
          {!_.isEmpty(collectionsOrder) &&
            _.map(collectionsOrder, (cKey, idx) => {
              const c = collections[cKey];
              return (
                <ButtonContainer
                  key={cKey}
                  type="button"
                  onClick={() =>
                    _handleClick(cKey, { isSavedCollectionClicked: false })
                  }
                  idx={idx}
                  length={_.size(collections)}
                >
                  <Title>{c.title}</Title>
                </ButtonContainer>
              );
            })}
        </Container>
      );
    }
  };

  return (
    <OuterContainer>
      <CurrentCollectionContainer onClick={() => setIsVisible(!isVisible)}>
        <TitleContainer>
          {_.isEmpty(collections) || _.isEmpty(collectionsOrder) ? (
            <LoaderInnerContainer>
              <BeatLoader color={black} size={13} loading={true} />
            </LoaderInnerContainer>
          ) : (
            <InnerContainer>
              {_.isEmpty(collections) || !selectedCollection ? (
                <Title>Select...</Title>
              ) : selectedCollection === HEARTED_ITEMS_COLLECTION_REF ? (
                <Title>Saved</Title>
              ) : (
                <Title>
                  {_.truncate(collections[selectedCollection].title, {
                    length: MAX_MOBILE_TITLE_LENGTH,
                  })}
                </Title>
              )}
              {isVisible ? <UpIcon /> : <DownIcon />}
            </InnerContainer>
          )}
        </TitleContainer>
      </CurrentCollectionContainer>
      {isVisible && renderDropdown()}
    </OuterContainer>
  );
};

export default NavBarCollectionsMobile;
