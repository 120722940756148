import styled from 'styled-components';

import {
  mobileSplitPoint,
  darkGray,
  veryLightGray,
  black,
  white,
  red,
  lightGray,
  green,
  whiteOff,
  whiteTransparent,
} from '../../style-constants';

export const OuterContainer = styled.div`
  border-bottom: 2px solid ${darkGray};
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  width: 100%;
  z-index: 10;
  margin: 20px 0px 40px 0px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: none;
    margin: unset;
    padding-right: unset;
  }
`;
OuterContainer.displayName = 'OuterContainer';

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;
InnerContainer.displayName = 'InnerContainer';

export const Container = styled.div`
  align-items: center;
  background-color: ${veryLightGray};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  height: 57px;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: auto;
  padding: 0px 25px;
  scrollbar-width: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    justify-content: flex-start;
    padding-right: unset;
    border-radius: 0px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
Container.displayName = 'Container';

export const ButtonContainer = styled.div`
  align-items: stretch;
  background-color: ${({ isSelected }) => (isSelected ? black : white)};
  border: 2px solid black;
  border-radius: 20px;

  color: ${black};
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Cabin';
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 5px;
  outline: 0;
  padding: 5px 15px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 15px;
    padding: unset;
    min-width: 100px;
  }

  &:active {
    background-color: ${darkGray};
    outline: 0;
  }
`;
ButtonContainer.displayName = 'ButtonContainer';

export const ButtonText = styled.div`
  color: ${({ isSelected }) => (isSelected ? white : black)};
  height: 100%;
  text-align: center;
  width: 100%;
  white-space: nowrap;
`;
ButtonText.displayName = 'ButtonText';

export const ScrollLeftIconContainer = styled.div`
  align-items: center;
  background-color: ${whiteTransparent};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 57px;
  justify-content: center;
  position: absolute;
  left: 0px;
  width: 75px;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255) 20%,
    rgba(255, 255, 255, 0) 100%
  );
`;
ScrollLeftIconContainer.displayName = 'ScrollLeftIconContainer';

export const ScrollRightIconContainer = styled.div`
  align-items: center;
  background-color: ${whiteTransparent};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 57px;
  justify-content: center;
  position: absolute;
  right: 0px;
  width: 75px;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.22) 0%,
    rgba(255, 255, 255) 20%,
    rgba(255, 255, 255, 1) 100%
  );
`;
ScrollRightIconContainer.displayName = 'ScrollRightIconContainer';

export const IconInnerContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  position: absolute;
  width: 50px;
`;
IconInnerContainer.displayName = 'IconInnerContainer';
