import React from 'react';

import {
  Container,
  DropdownContainer,
  TheNotch,
  ContentContainer,
  DetailsContainer,
  ProfileTitle,
  LogoutButtonContainer,
  LogoutButtonText,
} from './wholesale-customer-profile-dropdown.styles';

export const WholesaleCustomerProfileDropdown = ({
  isWholesaleUserProfileHidden,
  handleLogout,
  hideProfileDropdown,
}) => {
  return (
    <Container onClick={hideProfileDropdown}>
      <DropdownContainer isHidden={isWholesaleUserProfileHidden}>
        <TheNotch />
        <ContentContainer>
          <DetailsContainer>
            <ProfileTitle>Wholesale Profile</ProfileTitle>
          </DetailsContainer>
          <LogoutButtonContainer onClick={handleLogout}>
            <LogoutButtonText>Log out</LogoutButtonText>
          </LogoutButtonContainer>
        </ContentContainer>
      </DropdownContainer>
    </Container>
  );
};

export default WholesaleCustomerProfileDropdown;
