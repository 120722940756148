const CartActionTypes = {
  TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
  SET_CART_HIDDEN: 'SET_CART_HIDDEN',

  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',

  CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
  UPDATE_CART_ITEM: 'UPDATE_CART_ITEM',

  SET_NEW_ORDER: 'SET_NEW_ORDER',

  SET_PURCHASED_CART: 'SET_PURCHASED_CART',

  SET_ORDER_TYPE: 'SET_ORDER_TYPE',

  VERIFY_PROMO_CODE_START: 'VERIFY_PROMO_CODE_START',
  VERIFY_PROMO_CODE_SUCCESS: 'VERIFY_PROMO_CODE_SUCCESS',
  VERIFY_PROMO_CODE_FAILURE: 'VERIFY_PROMO_CODE_FAILURE',

  SET_PROMOTION: 'SET_PROMOTION',

  SET_FAST_DISABLE: 'SET_FAST_DISABLE',

  RESET_PAYMENT_ERROR_MESSAGE: 'RESET_PAYMENT_ERROR_MESSAGE',

  SET_BOOKING_FEE: 'SET_BOOKING_FEE',
  SET_PROMOTION_FEE: 'SET_PROMOTION_FEE',
  SET_DELIVERY_FEE: 'SET_DELIVERY_FEE',
  SET_DELIVERY_FEE_POSTCODES: 'SET_DELIVERY_FEE_POSTCODES',
  SET_DELIVERY_FEE_DYNAMIC: 'SET_DELIVERY_FEE_DYNAMIC',

  RESET_CART_STATE_AFTER_LOGOUT_OR_LOGIN:
    'RESET_CART_STATE_AFTER_LOGOUT_OR_LOGIN',

  SET_IS_LOADING: 'SET_IS_LOADING',

  WHOLESALE_ADD_TO_ACCOUNT_START: 'WHOLESALE_ADD_TO_ACCOUNT_START',
  WHOLESALE_ADD_TO_ACCOUNT_SUCCESS: 'WHOLESALE_ADD_TO_ACCOUNT_SUCCESS',
  WHOLESALE_ADD_TO_ACCOUNT_FAILURE: 'WHOLESALE_ADD_TO_ACCOUNT_FAILURE',

  PAYMENT_NEW_CUSTOMER_START: 'PAYMENT_NEW_CUSTOMER_START',
  PAYMENT_NEW_CUSTOMER_SUCCESS: 'PAYMENT_NEW_CUSTOMER_SUCCESS',
  PAYMENT_NEW_CUSTOMER_FAILURE: 'PAYMENT_NEW_CUSTOMER_FAILURE',

  PAYMENT_CURRENT_CUSTOMER_START: 'PAYMENT_CURRENT_CUSTOMER_START',
  PAYMENT_CURRENT_CUSTOMER_SUCCESS: 'PAYMENT_CURRENT_CUSTOMER_SUCCESS',
  PAYMENT_CURRENT_CUSTOMER_FAILURE: 'PAYMENT_CURRENT_CUSTOMER_FAILURE',

  RESET_ALL_CART_FEES: 'RESET_ALL_CART_FEES',

  ADD_ORDER_TO_SAVED_ORDERS: 'ADD_ORDER_TO_SAVED_ORDERS',
  ADD_SAVED_ORDER_TO_CART: 'ADD_SAVED_ORDER_TO_CART',
  REMOVE_SAVED_ORDER: 'REMOVE_SAVED_ORDER',
  SET_SHOULD_SAVE_ORDER: 'SET_SHOULD_SAVE_ORDER',
  REMOVE_ALL_CART_ITEMS: 'REMOVE_ALL_CART_ITEMS',

  ADD_PROMOTION_TO_PREVIOUSLY_USED_PROMOTIONS:
    'ADD_PROMOTION_TO_PREVIOUSLY_USED_PROMOTIONS',
  REMOVE_PROMOTION_FROM_PREVIOUSLY_USED_PROMOTIONS:
    'REMOVE_PROMOTION_FROM_PREVIOUSLY_USED_PROMOTIONS',
};

export default CartActionTypes;
