import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import OperatingHours from '../operating-hours/operating-hours.component';

import { selectConfig } from '../../redux/config/config.selectors';
import {
  selectOperatingHours,
  selectOverrideDays,
} from '../../redux/shop/shop.selectors';
import { selectWholesaleUserDetails } from '../../redux/user/user.selectors';
import { ORDERTYPES_TO_SINGULAR_TYPES } from '../../global.constants';

import {
  OperatingHoursContainer,
  TitleContainer,
  Title,
  HoursContainer,
} from './operating-hours-overview.styles';

export const OperatingHoursOverview = ({
  operatingHours,
  config,
  overrideDays,
  wholesaleUserDetails,
}) => {
  const { orderTypesRetail, orderTypesWholesale } = config;

  let _orderTypes = wholesaleUserDetails
    ? orderTypesWholesale
    : orderTypesRetail;

  return (
    <OperatingHoursContainer>
      <TitleContainer>
        <Title>Operating Hours</Title>
      </TitleContainer>
      <HoursContainer>
        {_orderTypes &&
          ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes] &&
          !_.isEmpty(operatingHours) &&
          _.map(ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes], (orderType, idx) => {
            const matchedDetails = _.find(
              operatingHours,
              (oDetails) => oDetails.title === orderType
            );
            if (!matchedDetails) return;
            return (
              <OperatingHours
                key={idx}
                operatingHoursDetails={matchedDetails}
                overrideDays={overrideDays}
              />
            );
          })}
      </HoursContainer>
    </OperatingHoursContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  operatingHours: selectOperatingHours,
  config: selectConfig,
  overrideDays: selectOverrideDays,
  wholesaleUserDetails: selectWholesaleUserDetails,
});

export default connect(mapStateToProps, null)(OperatingHoursOverview);
