import React from 'react';
import _ from 'lodash';

import { FiEdit, FiTrash2 } from 'react-icons/fi';

import CartItemCustomizationGroup from '../cart-item-customization-group/cart-item-customization-group.component';

import { calcItemTitle } from '../../utils/cart-utils';

import { black } from '../../style-constants';

import {
  Container,
  HeaderContainer,
  Quantity,
  Title,
  CustomizationsContainer,
  TitleContainer,
  Total,
  CartItemClear,
  CartItemEdit,
  SubText,
  ActionsContainer,
  UserInstructionsContainer,
  UserInstructionsTitle,
  UserInstructions,
} from './cart-item.styles';

export const CartItem = ({
  item,
  itemTotal,
  groupedCustomizations,
  orderIsPaid,
  handleEditClick,
  handleClearItemFromCart,
  config,
}) => {
  const { displayPricingOnline } = config;
  const { quantity, userInstructions } = item;

  // dont show edit button for promotions, which have a total < 0.0
  return (
    <Container>
      <HeaderContainer>
        <Quantity>{`${quantity} x`}</Quantity>
        <TitleContainer>
          <Title>{calcItemTitle(item)}</Title>
        </TitleContainer>
        {displayPricingOnline && <Total>{itemTotal.toFixed(2)}</Total>}
      </HeaderContainer>
      <CustomizationsContainer>
        {Object.keys(groupedCustomizations).map((key) => {
          // 'key' is actually the 'displayTitle' of a customization
          return (
            <CartItemCustomizationGroup
              key={key}
              title={key}
              options={groupedCustomizations[key]}
            />
          );
        })}
      </CustomizationsContainer>
      {userInstructions && (
        <UserInstructionsContainer>
          <UserInstructionsTitle>Notes:</UserInstructionsTitle>
          <UserInstructions>{userInstructions}</UserInstructions>
        </UserInstructionsContainer>
      )}
      <ActionsContainer>
        {itemTotal < 0.0 ? (
          <div />
        ) : (
          <CartItemEdit orderIsPaid={orderIsPaid} onClick={handleEditClick}>
            <FiEdit />
            <SubText>Edit</SubText>
          </CartItemEdit>
        )}
        <CartItemClear
          orderIsPaid={orderIsPaid}
          onClick={handleClearItemFromCart}
        >
          <FiTrash2 style={{ color: black }} />
          <SubText>Remove</SubText>
        </CartItemClear>
      </ActionsContainer>
    </Container>
  );
};

export default CartItem;
