import _ from 'lodash';
import axios from 'axios';

import {
  API_VERSION_V1,
  DELIVERY_FEE_DYNAMIC_COST_FUNC_VALLEY,
  DELIVERY_FEE_DYNAMIC_COST_FUNC_LOGISTIC,
  DELIVERY_FEE_DYNAMIC_COST_FUNC_LINEAR,
  DELIVERY_FEE_DYNAMIC_COST_FUNC_STEPS,
  GST_EXCLUDED,
  GST_INCLUDED,
  AVAILABILITY_STATUS_FOR_ITEMS_IN_CART,
} from '../global.constants';

export const makeStringForCustomization = (custName, custArray) => {
  let joined = `${custName}: ${_.reduce(
    custArray,
    (accum, c) => {
      return `${accum + c.title}, `;
    },
    ''
  )}`;
  joined = joined.substring(0, joined.length - 2);
  return joined;
};

export const standardiseText = (text, MAX_LEN) => {
  let buildStr = '';

  if (text.length < MAX_LEN) {
    return text;
  }

  for (let word of text.split(' ')) {
    const temp = `${buildStr} ${word}`;
    if (temp.length > MAX_LEN) {
      buildStr = `${temp} ...`;
      break;
    } else {
      buildStr = temp;
    }
  }

  return buildStr;
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  // https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula/27943#27943
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const asyncCheckCartForUnavailableItems = async (
  cartItems,
  shopDetails
) => {
  if (!cartItems || _.isEmpty(cartItems) || _.isEmpty(shopDetails)) return;

  return await checkForUnavailableItems(cartItems, shopDetails);
};

const checkForUnavailableItems = async (cartItems, shopDetails) => {
  let response = {
    haveUnavailableItems: false,
    msg: '',
  };

  try {
    const { backendServerUrl } = shopDetails;
    const url = `${backendServerUrl}/api/${API_VERSION_V1}/${AVAILABILITY_STATUS_FOR_ITEMS_IN_CART}`;
    const {
      data: { unavailableItems, error: unavailableItemsError },
    } = await axios({
      url,
      method: 'post',
      data: {
        cartItems,
      },
    });

    if (unavailableItemsError) {
      throw new Error(`${JSON.stringify({ unavailableItemsError })}`);
    }

    if (!_.isEmpty(unavailableItems)) {
      // we have sold out items in the cart and user
      // tried to purchase them.
      const unavailableItemTitles = _.map(
        unavailableItems,
        (item) => `${item.title},`
      ).join('\n');
      const msg =
        `Some items are no longer available and need to be removed from your cart:\n\n` +
        `${unavailableItemTitles}\n`;

      response.haveUnavailableItems = true;
      response.msg = msg;
    }

    return response;
  } catch (error) {
    console.log(
      `ERROR(checkForUnavailableItems func): trouble. in catch. ${error}`
    );
    // let proceed if have random error from server
    return response;
  }
};

export const calcItemTitle = (item) => {
  if (_.isEmpty(item)) return '';

  // build the title string step-by-step
  const { gstSetting, title, priceDetails } = item;
  const { itemAvailableInManySizes, size } = priceDetails;

  let _title = title;

  if (itemAvailableInManySizes) {
    _title = `${title} (${_.capitalize(size)})`;
  }

  if (
    gstSetting &&
    (gstSetting === GST_INCLUDED || gstSetting === GST_EXCLUDED)
  ) {
    // signify item price has GST applied by appending '*'
    _title = `${_title} *`;
  }

  return _title;
};

export const calcItemTitleNoStar = (item) => {
  if (_.isEmpty(item)) return '';

  // build the title string step-by-step
  const { gstSetting, title, priceDetails } = item;
  const { itemAvailableInManySizes, size } = priceDetails;

  let _title = title;

  if (itemAvailableInManySizes) {
    _title = `${title} (${_.capitalize(size)})`;
  }
  return _title;
};

// -----------------------------------------
//       DYNAMIC DELIVERY FEE FUNCS
// -----------------------------------------

export const calcDynamicFee = (
  distanceKms,
  costFunc,
  deliveryMin,
  deliveryMax,
  maxDistanceMetres
) => {
  let price = 0.0;
  const distanceMetres = distanceKms * 1000.0;
  const maxDistanceKms = maxDistanceMetres / 1000.0;

  // short cuts
  if (deliveryMin === deliveryMax) {
    price = deliveryMin;
  } else if (costFunc === DELIVERY_FEE_DYNAMIC_COST_FUNC_VALLEY) {
    price = calcValleyCost(
      distanceMetres,
      deliveryMin,
      deliveryMax,
      maxDistanceMetres
    );
  } else if (costFunc === DELIVERY_FEE_DYNAMIC_COST_FUNC_LINEAR) {
    price = calcLinearCost(
      distanceMetres,
      deliveryMin,
      deliveryMax,
      maxDistanceMetres
    );
  } else if (costFunc === DELIVERY_FEE_DYNAMIC_COST_FUNC_LOGISTIC) {
    // *** NOTE ***
    // we ARE using kms here, not metres!!
    // read calcLogarithmicCost func for details
    // as to why
    price = calcLogisticCost(
      distanceKms,
      deliveryMin,
      deliveryMax,
      maxDistanceKms
    );
  } else if (costFunc === DELIVERY_FEE_DYNAMIC_COST_FUNC_STEPS) {
    price = calcStepsCost(
      distanceMetres,
      deliveryMin,
      deliveryMax,
      maxDistanceMetres
    );
  } else {
    price = deliveryMin;
  }

  return _.round(price, 2);
};

const calcValleyCost = (
  distanceMetres,
  deliveryMin,
  deliveryMax,
  maxDistanceMetres
) => {
  if (distanceMetres < 0.1 * maxDistanceMetres)
    return deliveryMin + 0.5 * (deliveryMax - deliveryMin);

  if (distanceMetres > 0.75 * maxDistanceMetres) return deliveryMax;

  return deliveryMin;
};

const calcLogisticCost = (
  distanceKms,
  deliveryMin,
  deliveryMax,
  maxDistanceKms
) => {
  // ** NOTE **
  // we are using kms instead of metres.
  // because having a large x range causes
  // y to basically be max price for all but
  // a few metres distance at the start.

  // using generalized logistic function
  // y(x) = A + (K - A)/ [(C + Q*exp(-B*x))^(1/v)]

  // set C = Q = v = 1.
  // set B = 0.35 (growth rate). bigger B => faster growth. found emperically
  // solve for A and K using max delivery distance and fee max/mins
  const C = 1;
  const Q = 1;
  const v = 1.0;

  const B = 0.35; // can reduce to make growth rate slower
  const A =
    (deliveryMax - 2 * deliveryMin) * (1 + Math.exp(-1 * maxDistanceKms * B));
  const K =
    2 * deliveryMin -
    (deliveryMax - 2 * deliveryMin) * (1 + Math.exp(-1 * maxDistanceKms * B));

  const expPart = Q * Math.exp(-1 * B * distanceKms);

  const y = A + (K - A) / (C + expPart) ** (1 / v);

  // console.log({ A, B, K, distanceKms, expPart, y });

  return y;
};

const calcLinearCost = (
  distanceMetres,
  deliveryMin,
  deliveryMax,
  maxDistanceMetres
) => {
  // gradient has units $/metre
  const m = (deliveryMax - deliveryMin) / maxDistanceMetres;
  const c = deliveryMin;

  // equation for linear func is: y = mx + c
  return m * distanceMetres + c;
};

const calcStepsCost = (
  distanceMetres,
  deliveryMin,
  deliveryMax,
  maxDistanceMetres
) => {
  if (distanceMetres < 0.5 * maxDistanceMetres) return deliveryMin;

  if (
    distanceMetres >= 0.5 * maxDistanceMetres &&
    distanceMetres < 0.75 * maxDistanceMetres
  ) {
    return deliveryMin + 0.5 * (deliveryMax - deliveryMin);
  }

  return deliveryMax;
};

export const delay = (ms) =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      resolve();
    }, ms)
  );
