import React from 'react';

import {
  Container,
  TitleContainer,
  Title,
  ButtonContainer,
  OrderButton,
  SubText,
  SubTextExtra,
} from './dine-in-batch-type.styles';

import {
  DINE_IN_BATCHING_TYPE__GROUP,
  DINE_IN_BATCHING_TYPE__BY_ITSELF,
} from '../../global.constants';

export const DineInBatchType = ({ dineInBatchType, setDineInBatchType }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>Select Dine-in Details</Title>
      </TitleContainer>
      <SubText>Is your order part of a Group order, or just By Itself?</SubText>
      <ButtonContainer>
        <OrderButton
          isSelected={dineInBatchType === DINE_IN_BATCHING_TYPE__GROUP}
          onClick={(e) => {
            setDineInBatchType(DINE_IN_BATCHING_TYPE__GROUP);
          }}
          type="button"
          disabled={false}
        >
          Group
        </OrderButton>
        <OrderButton
          isSelected={dineInBatchType === DINE_IN_BATCHING_TYPE__BY_ITSELF}
          onClick={(e) => {
            setDineInBatchType(DINE_IN_BATCHING_TYPE__BY_ITSELF);
          }}
          type="button"
          disabled={false}
        >
          By Itself
        </OrderButton>
      </ButtonContainer>
      <SubTextExtra>
        If multiple people on your table choose 'Group' and place their orders
        within 2mins, all the orders will be made together on 1 docket.
      </SubTextExtra>
    </Container>
  );
};

export default DineInBatchType;
