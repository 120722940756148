import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Modal from 'react-modal';
import _ from 'lodash';
import Swal from 'sweetalert2';

import ItemModal from './item-modal.component';

import {
  setShowItemModal,
  resetItemModal,
  incrementQuantityBy,
  setSelectedPrice,
  setUserInstructions,
} from './../../redux/shop/shop.actions';
import {
  areAllRequiredCustomizationsSelected,
  computeDisplayPrice,
} from '../../utils/shop-utils';
import {
  selectShowItemModal,
  selectCollectionForItemModal,
  selectItemForModal,
  selectItemModalUserCustomizations,
  selectIsUpdatingItem,
  selectShopData,
  selectSelectedPrice,
  selectUserInstructions,
  selectShopDetails,
} from '../../redux/shop/shop.selectors';

import { addToCart, updateCartItem } from '../../redux/cart/cart.actions';
import { selectCartItems } from '../../redux/cart/cart.selectors';
import {
  calculateItemTotal,
  findCollectionForItem,
  calcGstBlurb,
  computeModalButtonText,
  sortCustomizationsUsingOrder,
} from '../../redux/cart/cart.utils';

import { selectConfig } from '../../redux/config/config.selectors';

import {
  MAX_LENGTH_USER_INSTRUCTIONS,
  ADD,
  SUBTRACT,
  GST_NONE,
} from '../../global.constants';

import { useScrollLock } from '../../hooks/use-scroll-lock';

export const ItemModalContainer = ({
  item,
  collection,
  isUpdatingItem,
  showItemModal,
  setShowItemModal,
  incrementQuantityBy,
  addToCart,
  itemModalUserCustomizations,
  resetItemModal,
  cartItems,
  updateCartItem,
  shopData,
  selectedPrice,
  setSelectedPrice,
  userInstructions,
  setUserInstructions,
  config,
  shopDetails,
}) => {
  const {
    customizations,
    customizationsOrder,
    description,
    gstSetting,
    itemCode,
    itemCategories,
    key,
    kitchenTitle,
    prices,
    quantity,
    requiresAgeConsent,
    title,
  } = item;
  const { sortItemPricesByPriceOrSize } = config;

  const [sortedPrices, setSortedPrices] = useState([]);

  useScrollLock(showItemModal);

  useEffect(() => {
    if (showItemModal) {
      setSortedPrices(
        _.orderBy(prices, [sortItemPricesByPriceOrSize], ['asc'])
      );
    }
    // if item only has one size option, then set selectedPrice
    // on component load. use 'prices' object instead of sortedPlaces
    // because setting state is async so sortedPlaces could still be empty
    // inside below if statement
    if (showItemModal && _.size(prices) === 1) {
      setSelectedPrice(
        _.orderBy(prices, [sortItemPricesByPriceOrSize], ['asc'])[0]
      );
    }
  }, [showItemModal]);

  useEffect(() => {
    if (isUpdatingItem) {
      setUserInstructions(item.userInstructions);
      setSelectedPrice(item.priceDetails);
    }
  }, [isUpdatingItem]);

  if (process.env.NODE_ENV === 'test') {
    // https://github.com/reactjs/react-modal/issues/632
    Modal.setAppElement(document.createElement('div'));
  } else {
    Modal.setAppElement('#root');
  }

  const handleCloseOfModal = () => {
    setShowItemModal(false);
    setTimeout(() => {
      // wait a little before resetting modal state.
      // => ensures navigation back has finished
      resetItemModal();
    }, 500);
  };

  const calculateItemPrice = () => {
    return calculateItemTotal(
      selectedPrice,
      quantity,
      itemModalUserCustomizations,
      item
    );
  };

  const _computeButtonText = () => {
    return computeModalButtonText(
      item,
      selectedPrice,
      itemModalUserCustomizations,
      isUpdatingItem
    );
  };

  const _calcGstBlurb = () => {
    return calcGstBlurb(item, selectedPrice, itemModalUserCustomizations);
  };

  const handleSubmission = () => {
    if (_.size(sortedPrices) !== 1 && _.isEmpty(selectedPrice)) {
      // user needs to specify item size for item having
      // multiple size options
      Swal.fire({
        icon: 'error',
        title: 'Choose a size',
      });
      return;
    }

    //check if all required customizations have been selected
    if (
      !areAllRequiredCustomizationsSelected(
        customizations,
        customizationsOrder,
        itemModalUserCustomizations,
        shopData
      )
    ) {
      // user needs to specify options for all required customizations
      Swal.fire({
        icon: 'error',
        title: 'More info needed',
        text: 'Please fill out all required fields',
      });
      return;
    }

    let _itemCode = '';
    if (itemCode !== undefined || itemCode !== null) {
      _itemCode = itemCode;
    }

    let proposedItem = {
      key,
      title,
      kitchenTitle,
      description,
      requiresAgeConsent,
      priceDetails: {
        ...selectedPrice,
        itemAvailableInManySizes: _.size(sortedPrices) > 1,
      },
      prices, // keep prices options for when updating item
      quantity,
      imagePath: item.imagePath,
      imageUrl: item.imageUrl,
      // handle new feature of ordered customizations display.
      // need to copy over customization keys if item doesnt
      // have customizationsOrder present.
      userCustomizations: customizationsOrder
        ? sortCustomizationsUsingOrder(
            itemModalUserCustomizations,
            customizationsOrder
          )
        : itemModalUserCustomizations,
      userInstructions,
      customizations,
      // handle new feature of ordered customizations display.
      // need to copy over customization keys if item doesnt
      // have customizationsOrder present.
      // this will ensure item will display customizations
      // when user potentially revists it to update their item
      // customization selections
      customizationsOrder: customizationsOrder
        ? customizationsOrder
        : _.map(customizations, (val, custKey) => custKey),
      total: selectedPrice.price * quantity,
      gstSetting: gstSetting ? gstSetting : GST_NONE,
      itemCode: _itemCode,
      itemCategories: itemCategories ? itemCategories : [],
    };

    // add collection key & title to proposed item.
    const matchedCollection = findCollectionForItem(shopData, key);

    if (matchedCollection) {
      const { title: collectionTitle, key: collectionKey } = matchedCollection;
      proposedItem = {
        ...proposedItem,
        collectionKey,
        collectionTitle,
      };
    } else {
      proposedItem = {
        ...proposedItem,
        collectionKey: '',
        collectionTitle: '',
      };
    }

    if (!isUpdatingItem) {
      addToCart(proposedItem);
    } else {
      // we are updating the item which is already in the cart
      updateCartItem(cartItems, item, proposedItem);
    }
    setTimeout(() => {
      // wait a little before resetting the state
      // otherwise price will flicker/change
      // between clicking the button and hiding the modal
      resetItemModal();
    }, 500);
    setShowItemModal(false);
  };

  const handleItemChangeClick = (operation) => {
    if (operation === ADD) {
      incrementQuantityBy(1);
    }
    if (operation === SUBTRACT) {
      incrementQuantityBy(-1);
    }
  };

  const handleUserInstructionsChange = (event) => {
    if (event.target.value.length > MAX_LENGTH_USER_INSTRUCTIONS) {
      return;
    }
    setUserInstructions(event.target.value);
  };

  return (
    <ItemModal
      item={item}
      sortedPrices={sortedPrices}
      selectedPrice={selectedPrice}
      setSelectedPrice={setSelectedPrice}
      showItemModal={showItemModal}
      handleCloseOfModal={handleCloseOfModal}
      handleItemChangeClick={handleItemChangeClick}
      handleSubmission={handleSubmission}
      shopData={shopData}
      userInstructions={userInstructions}
      handleUserInstructionsChange={handleUserInstructionsChange}
      config={config}
      computeDisplayPrice={computeDisplayPrice}
      computeButtonText={_computeButtonText}
      shopDetails={shopDetails}
      calcGstBlurb={_calcGstBlurb}
      calculateItemPrice={calculateItemPrice}
      isUpdatingItem={isUpdatingItem}
      collection={collection}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  item: selectItemForModal,
  collection: selectCollectionForItemModal,
  isUpdatingItem: selectIsUpdatingItem,
  showItemModal: selectShowItemModal,
  itemModalUserCustomizations: selectItemModalUserCustomizations,
  cartItems: selectCartItems,
  shopData: selectShopData,
  selectedPrice: selectSelectedPrice,
  userInstructions: selectUserInstructions,
  config: selectConfig,
  shopDetails: selectShopDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setShowItemModal: (show) => dispatch(setShowItemModal(show)),
  addToCart: (item) => dispatch(addToCart(item)),
  incrementQuantityBy: (quantity) => dispatch(incrementQuantityBy(quantity)),
  resetItemModal: () => dispatch(resetItemModal()),
  updateCartItem: (cartItems, prevItem, updatedItem) =>
    dispatch(updateCartItem(cartItems, prevItem, updatedItem)),
  setSelectedPrice: (selectedPrice) =>
    dispatch(setSelectedPrice(selectedPrice)),
  setUserInstructions: (userInstructions) =>
    dispatch(setUserInstructions(userInstructions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemModalContainer);
