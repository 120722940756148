import styled from 'styled-components';

import {
  white,
  red,
  veryLightGray,
  mobileSplitPoint,
  collectionSplitPoint,
  redTextDark,
  redBackgroundLight,
  black,
} from '../../style-constants';

import {
  CIRCULAR_BORDER,
  ROUNDED_BORDER,
  SQUARE_BORDER,
} from '../../global.constants';

const IMAGE_HEIGHT_DESKTOP = '110px';
const IMAGE_WIDTH_DESKTOP = '110px';
const IMAGE_HEIGHT_MOBILE = '110px';
const IMAGE_WIDTH_MOBILE = '110px';
const QUANTITY_SIZE = '25px';
const QUANTITY_Z_INDEX = 8;
const ITEM_WIDTH_DESKTOP = '49%';
const ITEM_BORDER_RADIUS_DESKTOP = '20px';
const ITEM_BORDER_RADIUS_MOBILE = '10px';

// ONE
export const ContainerOne = styled.div`
  align-items: stretch;
  background-color: ${({ isHearted }) => {
    return isHearted ? redBackgroundLight : veryLightGray;
  }};
  border-radius: ${ITEM_BORDER_RADIUS_DESKTOP};
  /* border: ${({ isHearted }) =>
    isHearted ? `2px solid ${red}` : 'unset'}; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: ${({ isHearted }) => (isHearted ? redTextDark : black)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  padding: 10px;
  position: relative;
  width: ${ITEM_WIDTH_DESKTOP};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
    margin-right: unset;
    margin-bottom: 5px;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: ${ITEM_BORDER_RADIUS_MOBILE};
    margin-right: unset;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 100px;
    padding: 10px;
    width: 95%;
  }
`;
ContainerOne.displayName = 'ContainerOne';

export const ImageContainerOne = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 10px;
`;
ImageContainerOne.displayName = 'ImageContainerOne';

export const WordsContainerOne = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
`;
WordsContainerOne.displayName = 'WordsContainerOne';

export const DescriptionOne = styled.div`
  font-size: 14px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
DescriptionOne.displayName = 'DescriptionOne';

export const InnerContentOne = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
`;
InnerContentOne.displayName = 'InnerContentOne';

export const KeeperOne = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
KeeperOne.displayName = 'KeeperOne';

export const QuantityContainerOne = styled.div`
  align-items: center;
  background-color: ${red};
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${QUANTITY_SIZE};
  width: ${QUANTITY_SIZE};
  position: absolute;
  top: -9px;
  left: -12px;
  z-index: ${QUANTITY_Z_INDEX};

  @media screen and (max-width: ${mobileSplitPoint}) {
    top: -5px;
    left: -0px;
  }
`;
QuantityContainerOne.displayName = 'QuantityContainerOne';

// TWO
export const ContainerTwo = styled.div`
  align-items: stretch;
  background-color: ${({ isHearted }) => {
    return isHearted ? redBackgroundLight : veryLightGray;
  }};
  /* border: ${({ isHearted }) =>
    isHearted ? `2px solid ${red}` : 'unset'}; */
  border-radius: ${ITEM_BORDER_RADIUS_DESKTOP};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: ${({ isHearted }) => (isHearted ? redTextDark : black)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  padding: 10px;
  position: relative;
  width: ${ITEM_WIDTH_DESKTOP};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
    margin-right: unset;
    margin-bottom: 5px;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: ${ITEM_BORDER_RADIUS_MOBILE};
    margin-right: unset;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 100px;
    width: 95%;
  }
`;
ContainerTwo.displayName = 'ContainerTwo';

export const ImageContainerTwo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 10px;
`;
ImageContainerTwo.displayName = 'ImageContainerTwo';

export const WordsContainerTwo = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
`;
WordsContainerTwo.displayName = 'WordsContainerTwo';

export const DescriptionTwo = styled.div`
  font-size: 14px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
DescriptionTwo.displayName = 'DescriptionTwo';

export const InnerContentTwo = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
`;
InnerContentTwo.displayName = 'InnerContentTwo';

export const KeeperTwo = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
KeeperTwo.displayName = 'KeeperTwo';

export const QuantityContainerTwo = styled.div`
  align-items: center;
  background-color: ${red};
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${QUANTITY_SIZE};
  width: ${QUANTITY_SIZE};
  position: absolute;
  top: -9px;
  left: -12px;
  z-index: ${QUANTITY_Z_INDEX};

  @media screen and (max-width: ${mobileSplitPoint}) {
    top: -5px;
    left: -0px;
    right: unset;
  }
`;
QuantityContainerTwo.displayName = 'QuantityContainerTwo';

// THREE

export const ContainerThree = styled.div`
  align-items: stretch;
  background-color: ${({ isHearted }) => {
    return isHearted ? redBackgroundLight : veryLightGray;
  }};
  /* border: ${({ isHearted }) =>
    isHearted ? `2px solid ${red}` : 'unset'}; */
  border-radius: ${ITEM_BORDER_RADIUS_DESKTOP};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: ${({ isHearted }) => (isHearted ? redTextDark : black)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
  padding: 10px;
  position: relative;
  width: ${ITEM_WIDTH_DESKTOP};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
    margin-right: unset;
    margin-bottom: 5px;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: ${ITEM_BORDER_RADIUS_MOBILE};
    margin-bottom: 10px;
    margin-right: unset;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 100px;
    width: 95%;
  }
`;
ContainerThree.displayName = 'ContainerThree';

export const FooterContainerThree = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: unset;
    padding-top: 15px;
  }
`;
FooterContainerThree.displayName = 'FooterContainerThree';

export const KeeperThree = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
KeeperThree.displayName = 'KeeperThree';

export const QuantityContainerThree = styled.div`
  align-items: center;
  background-color: ${red};
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${QUANTITY_SIZE};
  width: ${QUANTITY_SIZE};
  position: absolute;
  top: -9px;
  left: -12px;
  z-index: ${QUANTITY_Z_INDEX};

  @media screen and (max-width: ${mobileSplitPoint}) {
    top: -15px;
    left: -0px;
    right: unset;
  }
`;
QuantityContainerThree.displayName = 'QuantityContainerThree';

// FOUR
export const ContainerFour = styled.div`
  align-items: stretch;
  background-color: ${({ isHearted }) => {
    return isHearted ? redBackgroundLight : veryLightGray;
  }};
  /* border: ${({ isHearted }) =>
    isHearted ? `2px solid ${red}` : 'unset'}; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: ${ITEM_BORDER_RADIUS_DESKTOP};
  color: ${({ isHearted }) => (isHearted ? redTextDark : black)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
  padding: 10px;
  position: relative;
  width: ${ITEM_WIDTH_DESKTOP};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
    margin-right: unset;
    margin-bottom: 5px;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: ${ITEM_BORDER_RADIUS_MOBILE};
    margin-bottom: 10px;
    margin-right: unset;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 100px;
    width: 95%;
  }
`;
ContainerFour.displayName = 'ContainerFour';

export const ContentContainerFour = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
ContentContainerFour.displayName = 'ContentContainerFour';

export const FooterContainerFour = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: unset;
  }
`;
FooterContainerFour.displayName = 'FooterContainerFour';

export const KeeperFour = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
KeeperFour.displayName = 'KeeperFour';

export const QuantityContainerFour = styled.div`
  align-items: center;
  background-color: ${red};
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${QUANTITY_SIZE};
  width: ${QUANTITY_SIZE};
  position: absolute;
  top: -9px;
  left: -12px;
  z-index: ${QUANTITY_Z_INDEX};

  @media screen and (max-width: ${mobileSplitPoint}) {
    top: -15px;
    left: -0px;
    right: unset;
  }
`;
QuantityContainerFour.displayName = 'QuantityContainerFour';

// common

export const ItemImage = styled.img`
  height: ${IMAGE_HEIGHT_DESKTOP};
  width: ${IMAGE_WIDTH_DESKTOP};
  border-radius: ${({ itemImageBorderStyle }) => {
    if (itemImageBorderStyle === ROUNDED_BORDER) return '10px';
    if (itemImageBorderStyle === CIRCULAR_BORDER) return '80px';
    if (itemImageBorderStyle === SQUARE_BORDER) return '0px';

    // default to square
    return '0px';
  }};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: ${IMAGE_HEIGHT_MOBILE};
    width: ${IMAGE_WIDTH_MOBILE};
  }
`;
ItemImage.displayName = 'ItemImage';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 10px;
  }
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 13px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 14px;
  }
`;
Description.displayName = 'Description';

export const Price = styled.div`
  font-size: 16px;
  font-weight: 600;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
Price.displayName = 'Price';

export const ShortCutQuantity = styled.div`
  color: ${white};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
ShortCutQuantity.displayName = 'ShortCutQuantity';

export const ActionButtonsContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
ActionButtonsContainer.displayName = 'ActionButtonsContainer';

export const ActionButton = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  height: 38px;
  justify-content: center;
  margin-left: 10px;
  width: 38px;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
ActionButton.displayName = 'ActionButton';

export const SoldOutContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 15px;
`;
SoldOutContainer.displayName = 'SoldOutContainer';

export const SoldOutText = styled.div`
  color: ${red};
  font-size: 14px;
  font-weight: 700;
`;
SoldOutText.displayName = 'SoldOutText';

export const PartyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
PartyContainer.displayName = 'PartyContainer';

export const PartyLeft = styled.div`
  position: absolute;
  top: -24px;
  left: -22px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    top: -24px;
    left: -10px;
  }
`;
PartyLeft.displayName = 'PartyLeft';

export const PartyRight = styled.div`
  position: absolute;
  top: -24px;
  left: 10px;
  @media screen and (max-width: ${mobileSplitPoint}) {
    top: -24px;
    left: 23px;
  }
`;
PartyRight.displayName = 'PartyRight';
