import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { MdSearch, MdClear } from 'react-icons/md';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import CartDesktop from '../../components/cart-desktop/cart-desktop.component';
import OrderMethod from '../../components/order-method/order-method.container';
import CollectionsOverview from '../../components/collections-overview/collections-overview.component';
import NavBarCollections from '../../components/nav-bar-collections/nav-bar-collections.component';
import NabBarCollectionsMobile from '../../components/nav-bar-collections-mobile/nav-bar-collections-mobile.component';
import ShopOpenStatusBar from '../../components/shop-open-status-bar/shop-open-status-bar.container';
import HeartedItemsCollection from '../../components/hearted-items-collection/hearted-items-collection.component';
import GotoCheckoutButton from '../../components/goto-checkout-button/goto-checkout-button.component';
import SavedOrders from '../../components/saved-orders/saved-orders.component';

import { selectConfig } from '../../redux/config/config.selectors';
import {
  selectShopDetails,
  selectOperatingHours,
  selectShopData,
  selectCollectionsOrder,
  selectSearchValue,
  selectOverrideDays,
} from '../../redux/shop/shop.selectors';
import { makeBlurb, calcCollectionAvailability } from '../../utils/shop-utils';
import { selectWholesaleUserDetails } from '../../redux/user/user.selectors';
import { fetchWholesaleUserDocStart } from '../../redux/user/user.actions';
import {
  setSearchValue,
  setShowDeliveryZonesModal,
  setShowShopInfoModal,
} from '../../redux/shop/shop.actions';

import { getCollectionsOrderForPlatformOrDefault } from '../../utils/shop-utils';

import { setAllHeartedItems } from '../../redux/hearted-items/hearted-items.actions';
import { selectHeartedItems } from '../../redux/hearted-items/hearted-items.selectors';

import { selectSavedOrders } from '../../redux/cart/cart.selectors';

import {
  ContentContainer,
  InnerContainer,
  ShopPageContainer,
  ImageContainer,
  BusinessName,
  OrderingInformation,
  OrderingInformationText,
  BackgroundImage,
  BackgroundImageDesktop,
  BackgroundImageDesktopContainer,
  DesktopContainer,
  MobileContainer,
  MainFeed,
  SearchContainer,
  SearchContainerInner,
  SearchIconContainer,
  SearchInput,
} from './shop.styles';

import {
  HERO_IMAGE_FILENAME,
  HEARTED_ITEMS_COLLECTION_REF,
  AVAILABILITY_STATUS__INVISIBLE,
  PLATFORM__ONLINE,
} from '../../global.constants';

export const ShopPage = ({
  shopDetails,
  operatingHours,
  shopData,
  collectionsOrder,
  config,
  wholesaleUserDetails,
  setSearchValue,
  searchValue,
  overrideDays,
  heartedItems,
  setAllHeartedItems,
  setShowDeliveryZonesModal,
  fetchWholesaleUserDocStart,
  setShowShopInfoModal,
  savedOrders,
}) => {
  const _collectionsOrder = getCollectionsOrderForPlatformOrDefault(
    PLATFORM__ONLINE,
    collectionsOrder
  );
  const { showOpenStatusIcons, enableHeartedItems, saveOrderToSavedOrders } =
    config;
  const { imageProviderApi } = shopDetails;
  const FIT_METHOD = 'scale';

  const [selectedCollection, setSelectedCollection] = useState(null);

  // make refs for ALL collections, event if they are not visible
  // or empty (depending on wholesale logged in state)
  // and first add the hearted item collection should the feature
  // be enabled
  let collectionRefs = {
    [HEARTED_ITEMS_COLLECTION_REF]: createRef(),
  };
  collectionRefs = _.reduce(
    shopData.collections,
    (accum, coll) => {
      accum[coll.key] = createRef();
      return accum;
    },
    collectionRefs
  );

  useEffect(() => {
    // update wholesale user details if logged in and refreshing page.
    // after user logs into wholesale this call is kinda redundant
    // because their details are also obtained during the login process.
    // but we should try to keep details up-to-date for users who stay logged in
    // for a long period of time:
    // the backend app may update wholesale details and if we dont refetch details
    // we may end up with divergent data.
    // at the expense of an extra API call, which returns a small amount of data, it
    // at least seems worth it for now (JUN 2021)
    if (!wholesaleUserDetails) return;
    fetchWholesaleUserDocStart(wholesaleUserDetails);
  }, []);

  const handleClick = (collectionKey, { isSavedCollectionClicked }) => {
    // need to treat clicking 'Saved' collection differently to the rest
    // of the shop collections
    const element = isSavedCollectionClicked
      ? collectionRefs[HEARTED_ITEMS_COLLECTION_REF].current
      : collectionRefs[collectionKey].current;
    // make some space
    const yOffset = -225;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: y,
    });
    setSelectedCollection(collectionKey);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value.toLowerCase());
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const handleDeliveryPostcodesFeeInfoClick = () => {
    setShowDeliveryZonesModal(true);
  };

  const handleShopInfoClick = () => {
    setShowShopInfoModal(true);
  };

  return (
    <ShopPageContainer>
      <ImageContainer>
        <BusinessName>
          {showOpenStatusIcons &&
            !_.isEmpty(operatingHours) &&
            !_.isEmpty(config) && (
              <ShopOpenStatusBar
                operatingHours={operatingHours}
                displayType={'desktop'}
                config={config}
                overrideDays={overrideDays}
                wholesaleUserDetails={wholesaleUserDetails}
                shopDetails={shopDetails}
                handleShopInfoClick={handleShopInfoClick}
                handleDeliveryPostcodesFeeInfoClick={
                  handleDeliveryPostcodesFeeInfoClick
                }
              />
            )}
        </BusinessName>
        {!!imageProviderApi && (
          <BackgroundImageDesktopContainer>
            <BackgroundImageDesktop
              src={`${imageProviderApi}/${HERO_IMAGE_FILENAME}?auto=compress&fit=${FIT_METHOD}`}
            ></BackgroundImageDesktop>
          </BackgroundImageDesktopContainer>
        )}
      </ImageContainer>
      <ContentContainer>
        <DesktopContainer>
          {!!makeBlurb(shopDetails) && (
            <OrderingInformation>
              <OrderingInformationText>
                {makeBlurb(shopDetails)}
              </OrderingInformationText>
            </OrderingInformation>
          )}
        </DesktopContainer>
        <NavBarCollections
          collections={shopData.collections}
          handleClick={handleClick}
          collectionsOrder={_.filter(_collectionsOrder.order, (collKey) => {
            const collection = shopData.collections[collKey];
            if (!collection) {
              // in case collection has been deleted, but still
              // have collection key in collectionsOrder
              return false;
            }
            const availabilityStatus = calcCollectionAvailability(
              collection,
              shopData,
              wholesaleUserDetails
            );
            return availabilityStatus !== AVAILABILITY_STATUS__INVISIBLE;
          })}
          config={config}
          selectedCollection={selectedCollection}
        />
        <InnerContainer>
          <MobileContainer>
            <NabBarCollectionsMobile
              collections={shopData.collections}
              handleClick={handleClick}
              collectionsOrder={_.filter(_collectionsOrder.order, (collKey) => {
                const collection = shopData.collections[collKey];
                if (!collection) {
                  // in case collection has been deleted, but still
                  // have collection key in collectionsOrder
                  return false;
                }
                const availabilityStatus = calcCollectionAvailability(
                  collection,
                  shopData,
                  wholesaleUserDetails
                );
                return availabilityStatus !== AVAILABILITY_STATUS__INVISIBLE;
              })}
              config={config}
              selectedCollection={selectedCollection}
            />
            {!imageProviderApi ? (
              <SkeletonTheme>
                <Skeleton height={250} />
              </SkeletonTheme>
            ) : (
              <BackgroundImage
                src={`${imageProviderApi}/${HERO_IMAGE_FILENAME}?auto=compress&fit=${FIT_METHOD}`}
              ></BackgroundImage>
            )}
            {showOpenStatusIcons &&
              !_.isEmpty(operatingHours) &&
              !_.isEmpty(config) && (
                <ShopOpenStatusBar
                  operatingHours={operatingHours}
                  displayType={'mobile'}
                  config={config}
                  overrideDays={overrideDays}
                  wholesaleUserDetails={wholesaleUserDetails}
                  shopDetails={shopDetails}
                  handleShopInfoClick={handleShopInfoClick}
                  handleDeliveryPostcodesFeeInfoClick={
                    handleDeliveryPostcodesFeeInfoClick
                  }
                />
              )}
            {!!makeBlurb(shopDetails) && (
              <OrderingInformation>
                <OrderingInformationText>
                  {makeBlurb(shopDetails)}
                </OrderingInformationText>
              </OrderingInformation>
            )}
          </MobileContainer>
          <MobileContainer>
            <OrderMethod />
            <GotoCheckoutButton />
          </MobileContainer>
          <MainFeed>
            {saveOrderToSavedOrders && <SavedOrders />}
            <SearchContainer>
              <SearchContainerInner>
                <SearchIconContainer>
                  <MdSearch color={'black'} size="2rem" />
                </SearchIconContainer>
                <SearchInput
                  name="search"
                  type="text"
                  value={searchValue}
                  label="* Search"
                  onChange={handleSearchChange}
                  error={false}
                  onBlur={() => console.log('search input blur')}
                />
                <SearchIconContainer onClick={handleClearSearch}>
                  {searchValue && <MdClear color={'black'} size="2rem" />}
                </SearchIconContainer>
              </SearchContainerInner>
            </SearchContainer>
            {enableHeartedItems && (
              <HeartedItemsCollection
                collectionRefs={collectionRefs}
                heartedItems={heartedItems}
                shopData={shopData}
                config={config}
                wholesaleUserDetails={wholesaleUserDetails}
                setAllHeartedItems={setAllHeartedItems}
              />
            )}
            <CollectionsOverview
              collectionRefs={collectionRefs}
              wholesaleUserDetails={wholesaleUserDetails}
              searchValue={searchValue}
              config={config}
            />
          </MainFeed>
          <CartDesktop />
        </InnerContainer>
      </ContentContainer>
    </ShopPageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  shopDetails: selectShopDetails,
  operatingHours: selectOperatingHours,
  shopData: selectShopData,
  collectionsOrder: selectCollectionsOrder,
  config: selectConfig,
  wholesaleUserDetails: selectWholesaleUserDetails,
  searchValue: selectSearchValue,
  overrideDays: selectOverrideDays,
  heartedItems: selectHeartedItems,
  savedOrders: selectSavedOrders,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchValue: (searchValue) => dispatch(setSearchValue(searchValue)),
  setAllHeartedItems: (heartedItems) =>
    dispatch(setAllHeartedItems(heartedItems)),
  setShowDeliveryZonesModal: (show) =>
    dispatch(setShowDeliveryZonesModal(show)),
  fetchWholesaleUserDocStart: (wholesaleUserDetails) =>
    dispatch(fetchWholesaleUserDocStart(wholesaleUserDetails)),
  setShowShopInfoModal: (show) => dispatch(setShowShopInfoModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
