import _ from 'lodash';
import moment from 'moment';

import {
  DINE_IN,
  ORDERTYPES_TO_SINGULAR_TYPES,
  WANTED_AT_TIME_DATE,
  WANTED_AT_DATE,
  WANTED_AT_NONE,
  WANTED_AT_TIME,
  OVERRIDE_OPEN_TYPE,
  OVERRIDE_CLOSED_TYPE,
  TODAY,
  FUTURE,
  RETAIL_ONLY,
  RETAIL_AND_WHOLESALE,
  WHOLESALE_ONLY,
  INCREMENT_MINUTES_DEFAULT,
  ITEM_SOLD_AT_PLATFORM__ONLINE,
  AVAILABILITY_STATUS__AVAILABLE,
  AVAILABILITY_STATUS__SOLD_OUT,
  AVAILABILITY_STATUS__INVISIBLE,
} from '../global.constants';

export const getItemsOrderForCollection = (itemsOrder, collectionKey) => {
  let itemsOrdering = [];
  const itemOrder = _.find(
    itemsOrder,
    (anItemOrder) => anItemOrder.collectionKey === collectionKey
  );
  if (itemOrder) {
    itemsOrdering = itemOrder.order;
  }
  return itemsOrdering;
};

export const createOrderTimesForSelection = (
  operatingHours,
  overrideDays,
  orderType,
  selectedDay,
  incrementMins
) => {
  const incrementMinsSafe = incrementMins
    ? incrementMins
    : INCREMENT_MINUTES_DEFAULT;
  const now = moment();

  // check if today checks out for day && week && year
  // -> used for calculating avail times whilst taking into
  // account current time (if selected day is today)
  const isToday = checkMomentsAreSameDateExactly(selectedDay, now);

  let availHrs = [];
  let _openTime = '';
  let _closeTime = '';

  // prioritise override day feature before using operating hours data
  const overrideDay = checkForAnOverrideDay(overrideDays, selectedDay);
  if (overrideDay) {
    const { type, openTypeOpenTime, openTypeCloseTime } = overrideDay;

    if (type === OVERRIDE_CLOSED_TYPE) {
      // we are closed
      return [];
    }

    _openTime = moment(openTypeOpenTime, 'ddd MMM DD YYYY HH:mm:ss ZZ')
      .format('HH:mm')
      .toString();

    _closeTime = moment(openTypeCloseTime, 'ddd MMM DD YYYY HH:mm:ss ZZ')
      .format('HH:mm')
      .toString();
  } else {
    const relevantHours = _.find(
      operatingHours,
      (doc) => doc.title === orderType
    );

    if (relevantHours) {
      const times = relevantHours.hours[selectedDay.format('ddd')];

      if (times.isClosed) {
        // shop is closed.
        return [];
      }

      _openTime = times.open;
      _closeTime = times.close;
    }
  }
  // have open type
  availHrs = createTimesBetweenOpenAndClose(
    _openTime,
    _closeTime,
    incrementMinsSafe,
    isToday
  );

  return availHrs;
};

export const createTimesBetweenOpenAndClose = (open, close, inc, isToday) => {
  if (!open || !close) return [];
  let runner = moment(open, 'HH:mm');
  let closeTime = moment(close, 'HH:mm');

  // get the correct starting point based on inc
  const remainderRunner = inc - (runner.minutes() % inc);
  runner.add(remainderRunner, 'minutes');
  runner.subtract(inc, 'minutes'); // we want the start endpoint

  // get the correct closing point based on inc
  const remainderClose = closeTime.minutes() % inc;
  closeTime.subtract(remainderClose, 'minutes');
  closeTime.add(inc, 'minutes'); // we want the end endpoint

  return generateTimes(runner, closeTime, inc, isToday);
};

export const generateTimes = (runner, closeTime, inc, isToday) => {
  let list = [];

  if (isToday) return timesForToday(runner, closeTime, inc);

  // we have a day in the future ie. not today
  //
  // now increment through the interval between runner and closeTime
  while (runner < closeTime) {
    // add the available option time
    list = [...list, runner.format('HH:mm').toString()];
    // increment the current time used to calculate available options
    runner = runner.add(inc, 'minutes');
  }
  return list;
};

export const timesForToday = (runner, closeTime, inc) => {
  let list = [];
  const now = moment();

  while (runner < closeTime) {
    if (runner < now) {
      // don't show times that are in the past
      runner = runner.add(inc, 'minutes');
      continue;
    }
    // add the available option time
    list = [...list, runner.format('HH:mm').toString()];
    // increment the current time used to calculate available options
    runner = runner.add(inc, 'minutes');
  }
  return list;
};

export const areAllRequiredCustomizationsSelected = (
  customizations,
  customizationsOrder,
  itemModalUserCustomizations,
  shopData
) => {
  // only want unique customization keys for selected customizations.
  // if a customization is multiSelect and user selects 2 customizations,
  // then itemModalUserCustomizations will have 2 options with the same customization key.
  // all we care about is if all the required customizations have at least one
  // selected option, each.
  const groupedSelected = _.groupBy(
    itemModalUserCustomizations,
    (cust) => cust.customizationKey
  );
  const numRequiredCustomizationsSelected = _.sum(
    _.map(groupedSelected, (val, key) =>
      shopData.customizations[key].isRequired ? 1 : 0
    )
  );

  // check if all required customizations have been selected.
  // (DONT assume that customizations and customizationsOrder are always 'in sync')
  // check new feature first, the customizationsOrder array.
  if (customizationsOrder) {
    const numRequiredCustomizationsNewFeature = _.sum(
      _.map(customizationsOrder, (custKey) =>
        shopData.customizations[custKey].isRequired ? 1 : 0
      )
    );
    return (
      numRequiredCustomizationsNewFeature === numRequiredCustomizationsSelected
    );
  } else {
    // if not present then fallback to using older customizations obj
    const numRequiredCustomizationsOldFeature = _.sum(
      _.map(customizations, (val, custKey) =>
        shopData.customizations[custKey].isRequired ? 1 : 0
      )
    );
    return (
      numRequiredCustomizationsOldFeature === numRequiredCustomizationsSelected
    );
  }
};

export const isTodayOpenForOrderType = (
  orderType,
  operatingHours,
  overrideDays
) => {
  const now = moment();

  // check first if there is an override day. if so, it takes priority over
  // any operating hours set for the day
  const overrideDay = checkForAnOverrideDay(overrideDays, now);
  if (overrideDay) {
    // just want to show if closed, or if open then if now is between today's open and close times
    const { type, openTypeOpenTime, openTypeCloseTime } = overrideDay;
    if (type === OVERRIDE_CLOSED_TYPE) return false;

    // else we have an 'open' type for override day. check bounds
    const mOpen = moment(openTypeOpenTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');
    const mClose = moment(openTypeCloseTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');

    return now.isAfter(mOpen) && now.isSameOrBefore(mClose);
  }

  // check operating hours
  return isTodayOpenUsingOperatingHours(operatingHours, orderType, {
    boundsToCheck: 'startEnd',
  });
};

export const isTodayOpenUsingOperatingHours = (
  operatingHours,
  orderType,
  { boundsToCheck }
) => {
  const now = moment();
  const nowDay = now.format('ddd');

  const hoursForType = _.find(operatingHours, (doc) => doc.title === orderType);
  if (!hoursForType) return false;

  const { open, close, isClosed } = hoursForType.hours[nowDay];
  const openMoment = moment(open, 'HH:mm');
  const closeMoment = moment(close, 'HH:mm');
  const isDayClosed = isClosed;
  const timeIsAfterOrEqualToOpen = now.isSameOrAfter(openMoment);
  const timeIsBeforeOrEqualToClose = now.isSameOrBefore(closeMoment);

  if (boundsToCheck === 'startEnd') {
    return (
      !isDayClosed && timeIsAfterOrEqualToOpen && timeIsBeforeOrEqualToClose
    );
  } else if (boundsToCheck === 'end') {
    return !isDayClosed && timeIsBeforeOrEqualToClose;
  } else {
    // default to closed for weird boundsToCheck val
    return false;
  }
};

export const checkForAnOverrideDay = (overrideDays, mDate) => {
  // mDate is a momentjs obj.
  // if there are multiple override entries for the same day, we assume only
  // the first found one (doesnt make sense to have multiple overrides for one day)
  return _.find(overrideDays, (details) => {
    const { date: overrideDate } = details;
    const mOverrideDay = moment(overrideDate, 'ddd MMM DD YYYY HH:mm:ss ZZ');

    return checkMomentsAreSameDateExactly(mDate, mOverrideDay);
  });
};

export const isOverrideDayOpen = (
  overrideDay,
  todayOrFuture, // TODAY | FUTURE
  wantedAtTypes,
  wantedAtTypeNoneAllowPreOrders
) => {
  const now = moment();
  const { type, openTypeOpenTime, openTypeCloseTime } = overrideDay;
  let isOpen = false;

  if (type === OVERRIDE_OPEN_TYPE) {
    // check if now is between open and close times for override day
    const mOpen = moment(openTypeOpenTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');
    const mClose = moment(openTypeCloseTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');

    if (wantedAtTypes === WANTED_AT_NONE) {
      // allow preorder. only need to check close endpoint
      isOpen = now.isBefore(mClose);

      if (wantedAtTypeNoneAllowPreOrders) {
        // allow preorder. only check close endpoint
        isOpen = now.isBefore(mClose);
      } else {
        isOpen = !(now.isBefore(mOpen) || now.isAfter(mClose));
      }
    }

    if (
      wantedAtTypes === WANTED_AT_TIME_DATE ||
      wantedAtTypes === WANTED_AT_DATE ||
      wantedAtTypes === WANTED_AT_TIME
    ) {
      // only check close endpoint
      isOpen = todayOrFuture === TODAY ? now.isBefore(mClose) : true;
    }
  }

  // else close day off:
  // - a 'close' (all day) override day is present
  // - a misconfigured wantedAtTypes config
  return isOpen;
};

export const arePreOrdersClosedTodayForNoTimesFeature = (
  operatingHours,
  config,
  overrideDays,
  wholesaleUserDetails
) => {
  try {
    // only use PICKUP and DELIVERY for considering pre-orders open.
    // DINE_IN is a separate which could require a table booking/be in actual venue
    // TODO: geofencing for DINE_IN??
    const { orderTypesRetail, orderTypesWholesale } = config;

    let _orderTypes = wholesaleUserDetails
      ? orderTypesWholesale
      : orderTypesRetail;

    if (_.isEmpty(operatingHours) || !_orderTypes) return true;

    const orderTypesArray = ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes]
      ? ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes]
      : [];

    if (_.size(orderTypesArray) === 0) return true;

    // if only have DINE_IN then default to pre-orders closed
    if (_.size(orderTypesArray) === 1 && orderTypesArray[0] === DINE_IN)
      return true;

    // operatingHours will contain hours for every possible orderType,
    // (PICKUP, DELIVERY, and DINE_IN)...but we only want hours for
    // what the shop 'orderTypes' config var has, so *must* use orderTypesArray
    // when determining whether to filter out/or not, a given operating hours set
    const _operatingHours = _.filter(operatingHours, (hoursDoc) => {
      const { title } = hoursDoc;
      if (orderTypesArray.includes(DINE_IN) && title === DINE_IN) return false;

      return orderTypesArray.includes(title);
    });

    const now = moment();
    const mTodayDay = now.format('ddd');

    // prioritise override days checking
    const overrideDay = checkForAnOverrideDay(overrideDays, now);
    if (overrideDay) {
      return isPreOrderOverrideDayClosed(overrideDay, now);
    }

    const areAllClosed = _.every(_operatingHours, (hoursDoc) => {
      return hoursDoc.hours[mTodayDay].isClosed;
    });

    // now check operating hours...
    // cant have preorders on a day which is completely closed
    if (areAllClosed) return true;

    // just have a single operating hours set to consider
    if (_.size(_operatingHours) === 1) {
      const _hrs = _operatingHours[0];
      const { open } = _hrs.hours[mTodayDay];
      const mOpening = moment(open, 'HH:mm');
      const areWeClosed = moment().isSameOrAfter(mOpening);
      return areWeClosed;
    }

    // else need to check both PICKUP and DELIVERY hours
    // either both PICKUP and DELIVERY are open, or one is open.
    // ASSUMPTION: take preorders 'closed' endpoint to be the soonest
    // opening time that has isClosed === false
    const hrsNotHavingIsClosedTrue = _.filter(
      _operatingHours,
      (hrsDoc) => !hrsDoc.hours[mTodayDay].isClosed
    );

    // find the soonest opening hrs
    const orderedHrs = _.sortBy(hrsNotHavingIsClosedTrue, (hrsDoc) => {
      const _timestamp = moment(hrsDoc.hours[mTodayDay].open, 'HH:mm').unix();
      return _timestamp;
    });

    let arePreordersClosed = true;

    if (_.size(orderedHrs) > 0) {
      const mSoonestOpeningTime = moment(
        orderedHrs[0].hours[mTodayDay].open,
        'HH:mm'
      );
      arePreordersClosed = moment().isSameOrAfter(mSoonestOpeningTime);
    }

    return arePreordersClosed;
  } catch (error) {
    console.log(error);
    return true;
  }
};

export const isPreOrderOverrideDayClosed = (overrideDay, now) => {
  const { type, openTypeOpenTime } = overrideDay;

  if (type === OVERRIDE_OPEN_TYPE) {
    // check if now is between open and close times for override day
    const mOpen = moment(openTypeOpenTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');

    if (now.isBefore(mOpen)) {
      // preorders open
      return false;
    }

    // now is after open time
    return true;
  }

  // else we have a closed all day
  return true;
};

export const haveAnyApplicableOverrideDaysForPreOrder = (overrideDays) => {
  if (_.isEmpty(overrideDays)) return false;

  const now = moment();

  const haveAtLeastOneOpen = _.reduce(
    overrideDays,
    (accum, day) => {
      const { openTypeOpenTime, type } = day;

      if (type === 'closed' || !openTypeOpenTime) return accum;

      const mOpen = moment(openTypeOpenTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');

      if (mOpen.isSameOrBefore(now)) return accum;

      // override day is an 'open' type and is for a future time & date
      // so we should have preorders open
      accum = true;

      return accum;
    },
    false
  );

  return haveAtLeastOneOpen;
};

export const checkMomentsAreSameDateExactly = (m1, m2) => {
  // check that moments are same day + week + year

  if (!m1 | !m2) return false;
  if (!moment.isMoment(m1) || !moment.isMoment(m2)) return;

  // From momentjs docs (https://momentjs.com/docs/#/query/is-same/):
  // `Passing in day will check day, month, and year`
  return m1.isSame(m2, 'day');
};

export const makeBlurb = (shopDetails) => {
  if (!shopDetails) return '';

  const { orderingInfoBlurb1 } = shopDetails;

  return orderingInfoBlurb1 ? orderingInfoBlurb1 : '';
};

export const checkStillOpenOverrideDay = (
  mDateToCheck,
  overrideDay,
  orderType,
  wantedAtTypes,
  wantedAtTypeNoneAllowPreOrders
) => {
  // mDateToCheck is either today or a future date because calling function
  // has already checked if mDateToCheck is a past date

  const mNow = moment();

  if (!overrideDay) return true;

  // prioritise checking for an override day before checking operating hours
  let isOpen = true;

  if (orderType === DINE_IN) {
    // dine in can only order for *today*
    isOpen = isOverrideDayOpen(
      overrideDay,
      TODAY,
      WANTED_AT_NONE,
      wantedAtTypeNoneAllowPreOrders
    );
  } else if (
    wantedAtTypes === WANTED_AT_NONE ||
    wantedAtTypes === WANTED_AT_TIME
  ) {
    // can only order for *today*
    isOpen = isOverrideDayOpen(
      overrideDay,
      TODAY,
      WANTED_AT_NONE,
      wantedAtTypeNoneAllowPreOrders
    );
  } else if (
    wantedAtTypes === WANTED_AT_TIME_DATE ||
    wantedAtTypes === WANTED_AT_DATE
  ) {
    const todayOrFuture = checkMomentsAreSameDateExactly(mDateToCheck, mNow)
      ? TODAY
      : FUTURE;
    isOpen = isOverrideDayOpen(
      overrideDay,
      todayOrFuture,
      wantedAtTypes,
      wantedAtTypeNoneAllowPreOrders
    );
  } else {
    // default to only for today
    isOpen = isOverrideDayOpen(
      overrideDay,
      TODAY,
      WANTED_AT_NONE,
      wantedAtTypeNoneAllowPreOrders
    );
  }

  return isOpen;
};

export const checkStillOpenOperatingHours = (
  mDateToCheck,
  operatingHours,
  orderType,
  wantedAtTypes,
  wantedAtTypeNoneAllowPreOrders
) => {
  const relevantHours = _.find(
    operatingHours,
    (doc) => doc.title === orderType
  );

  if (!relevantHours) return false;

  const mNow = moment();
  const mDateToCheckDay = mDateToCheck.format('ddd');
  const { close, isClosed, open } = relevantHours.hours[mDateToCheckDay];
  const mOpen = moment(open, 'HH:mm');
  const mClose = moment(close, 'HH:mm');

  let isOpen = false;

  if (orderType === DINE_IN) {
    // have to be in shop to dine in.
    const _isClosed = isClosed || mNow.isBefore(mOpen) || mNow.isAfter(mClose);
    isOpen = !_isClosed;
  } else if (
    wantedAtTypes === WANTED_AT_NONE ||
    wantedAtTypes === WANTED_AT_TIME
  ) {
    if (wantedAtTypeNoneAllowPreOrders) {
      isOpen = !isClosed && mNow.isBefore(mClose);
    } else {
      isOpen = !(isClosed || mNow.isBefore(mOpen) || mNow.isAfter(mClose));
    }
  } else if (
    wantedAtTypes === WANTED_AT_TIME_DATE ||
    wantedAtTypes === WANTED_AT_DATE
  ) {
    if (checkMomentsAreSameDateExactly(mDateToCheck, mNow)) {
      isOpen = !isClosed && mNow.isBefore(mClose);
    } else {
      // future date. allow preorder if isClosed is false
      isOpen = !isClosed;
    }
  } else {
    // default to closed.
    // since isOpen === false by default this is a noop
  }

  return isOpen;
};

// currently used for
// - determining whether to show collection row in navbars (desktop and mobile) in shop component
// - show collection or not in collections-overview component
export const calcCollectionAvailability = (
  collection,
  shopData,
  wholesaleUserDetails
) => {
  if (
    !collection ||
    _.isEmpty(collection) ||
    _.isEmpty(shopData) ||
    _.isEmpty(shopData.items)
  )
    return AVAILABILITY_STATUS__SOLD_OUT;

  if (isCollectionIsAvailable(collection) === AVAILABILITY_STATUS__INVISIBLE)
    return AVAILABILITY_STATUS__INVISIBLE;

  let collectionItems = _.reduce(
    collection.items,
    (accum, _, key) => {
      // make sure item actually exists before returning it.
      // sometimes item key will be in collection.items but not
      // in items collection!
      if (!shopData.items[key]) return accum;

      return [...accum, shopData.items[key]];
    },
    []
  );

  collectionItems = _.filter(collectionItems, (item) => {
    const { availabilityStatus, isVisible } = item;
    if (availabilityStatus) {
      return (
        availabilityStatus[ITEM_SOLD_AT_PLATFORM__ONLINE] ===
        AVAILABILITY_STATUS__AVAILABLE
      );
    }
    return isVisible;
  });

  // - handle sales outles
  let filteredCollectionItems = [];
  // determine if the collection is empty, taking into account if user
  // is logged in as wholesale, or not
  if (wholesaleUserDetails) {
    // only look at wholesale items
    filteredCollectionItems = _.filter(collectionItems, (item) => {
      // if feature not present then just keep item around
      if (!item.salesOutlets) return AVAILABILITY_STATUS__AVAILABLE;
      return (
        item.salesOutlets === WHOLESALE_ONLY ||
        item.salesOutlets === RETAIL_AND_WHOLESALE
      );
    });
  } else {
    // retail
    filteredCollectionItems = _.filter(collectionItems, (item) => {
      // if feature not present then just keep item around
      if (!item.salesOutlets) return AVAILABILITY_STATUS__AVAILABLE;
      return (
        item.salesOutlets === RETAIL_ONLY ||
        item.salesOutlets === RETAIL_AND_WHOLESALE
      );
    });
  }
  if (_.isEmpty(filteredCollectionItems)) return AVAILABILITY_STATUS__INVISIBLE;

  // - handle purchase times (TODO)/visible times
  // check if collection has visibleTimes field applied
  if (collection.visibleTimes) {
    const { start, end } = collection.visibleTimes;
    if (start && end) {
      const mStart = moment(start, 'HH:mm');
      const mEnd = moment(end, 'HH:mm');
      const mNow = moment();
      if (mNow.isBefore(mStart) || mNow.isAfter(mEnd)) {
        return AVAILABILITY_STATUS__INVISIBLE;
      }
    }
  }
  return AVAILABILITY_STATUS__AVAILABLE;
};

const isCollectionIsAvailable = (collection) => {
  // try new availabilityStatus feature
  if (collection?.availabilityStatus?.[ITEM_SOLD_AT_PLATFORM__ONLINE]) {
    return collection.availabilityStatus[ITEM_SOLD_AT_PLATFORM__ONLINE];
  }

  // fallback: use older isVisible feature
  return collection.isVisible
    ? AVAILABILITY_STATUS__AVAILABLE
    : AVAILABILITY_STATUS__INVISIBLE;
};

// - calc whether to show item in collection component
// - prevent adding item when looking at item modals (item-modal and item-mobile)
export const calcItemAvailabilityStatus = (item, collection) => {
  if (!item || !collection) return AVAILABILITY_STATUS__INVISIBLE;

  // collection status takes priority over item status
  if (
    collection?.availabilityStatus?.[ITEM_SOLD_AT_PLATFORM__ONLINE] &&
    collection.availabilityStatus[ITEM_SOLD_AT_PLATFORM__ONLINE] !==
      AVAILABILITY_STATUS__AVAILABLE
  ) {
    return collection.availabilityStatus[ITEM_SOLD_AT_PLATFORM__ONLINE];
  }

  // collection is avail, now check the item status
  return _checkItemAvailabilityStatus(item);
};

const _checkItemAvailabilityStatus = (item) => {
  if (item?.availabilityStatus?.[ITEM_SOLD_AT_PLATFORM__ONLINE]) {
    return item.availabilityStatus[ITEM_SOLD_AT_PLATFORM__ONLINE];
  }

  return item.isVisible
    ? AVAILABILITY_STATUS__AVAILABLE
    : AVAILABILITY_STATUS__INVISIBLE;
};

export const formatDatetime = (dt) =>
  moment(dt, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('HH:mm').toString();

export const computeDisplayPrice = (sortedPrices) => {
  const pricesLength = _.size(sortedPrices);

  if (pricesLength === 0) return null;

  const _price = sortedPrices[0].price;

  if (!_price) return null;

  let displayPrice = pricesLength === 1 ? `$${_price}` : `From $${_price}`;

  return displayPrice;
};

export const dayFromWhenOrderWantedDate = (dt) => {
  const mDate = moment(dt, 'YYYY-MM-DDTHH:mm:ss.sssZ');
  const day = mDate.format('ddd');
  return day;
};

export const createItemTitle = (hasMultiSizes, title, size) => {
  // title can either be 'title' or 'kitchenTitle'
  //
  if (!title) return `EMPTY TITLE`;

  // include the size info in title for item, if it has > 1 size options
  // otherwise, just use title
  return hasMultiSizes ? `${title} (${_.capitalize(size)})` : title;
};

export const isDateValidForOrderCutoffFeature = (
  mDateToCheck, // a momentjs obj
  orderCutoffTime,
  orderCutoffTimeEnabled,
  orderCutoffTimePeriodIncrement
) => {
  try {
    // feature not turned on
    if (!orderCutoffTimeEnabled) return true;

    const mNow = moment();
    const mCutoffTime = moment(orderCutoffTime, 'HH:mm:ss');
    const mLastCuttoffDate = moment().add(
      orderCutoffTimePeriodIncrement,
      'days'
    );
    const nowEqualsDate = checkMomentsAreSameDateExactly(mNow, mDateToCheck);

    let isValid = true;

    if (
      mNow.isAfter(mCutoffTime) &&
      (nowEqualsDate || mDateToCheck.isSameOrBefore(mLastCuttoffDate, 'days'))
    ) {
      // 1. today's time exceeds the order cutoff time for ordering today, or
      // 2. cutoff still applies up to, and including,
      //    orderCutoffTimePeriodIncrement num of days from today
      isValid = false;
    }

    return isValid;
  } catch (error) {
    console.log(error);
    // allow a pass if feature breaks
    return true;
  }
};
export const getCollectionsOrderForPlatformOrDefault = (
  platform,
  collectionsOrder
) => {
  if (!collectionsOrder || _.isEmpty(collectionsOrder)) return { order: [] };
  // if (!collectionsOrder || _.isEmpty(collectionsOrder)) return {};

  let matchedOrderDefault = {};
  // default to showing online platform collection ordering
  if (_.size(_.values(collectionsOrder))) {
    const onlineCollectionsOrder = _.find(
      collectionsOrder,
      (aCollectionOrder) => aCollectionOrder.platform === platform
    );

    if (onlineCollectionsOrder) {
      matchedOrderDefault = onlineCollectionsOrder;
    } else {
      // collectionsOrder did not have platform key or no online platform collectionOrder
      // set arbitrarily to first collectionOrder (we know there is at least one)
      matchedOrderDefault = _.values(collectionsOrder)[0];
    }
  }
  return matchedOrderDefault;
};
