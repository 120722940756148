import React from 'react';
import _ from 'lodash';

import CustomizationButton from '../customization-button/customization-button.component';

import {
  Container,
  TitleContainer,
  InfoContainer,
  Title,
  IsRequired,
  MaxSelect,
  RequiredText,
  PickOneText,
  ButtonsContainer,
} from './customization.styles';

export const Customization = ({
  displayTitle,
  isRequired,
  isMultiSelect,
  maxSelect,
  customizationKey,
  sortedOptions,
  handleClick,
  selectedOptions,
}) => {
  const isSelected = (optionTitle) =>
    !!_.find(
      selectedOptions,
      (selectedOption) =>
        selectedOption.customizationKey === customizationKey &&
        selectedOption.title === optionTitle
    );

  return (
    !_.isEmpty(sortedOptions) && (
      <Container>
        <TitleContainer>
          <Title>{displayTitle}</Title>
          <InfoContainer>
            {isMultiSelect ? (
              <MaxSelect>Max select: {maxSelect}</MaxSelect>
            ) : (
              <PickOneText>Pick one</PickOneText>
            )}
            <IsRequired>
              {isRequired && <RequiredText>(Required)</RequiredText>}
            </IsRequired>
          </InfoContainer>
        </TitleContainer>

        <ButtonsContainer>
          {_.map(sortedOptions, ({ optionKey, ...option }) => (
            <CustomizationButton
              key={optionKey}
              option={option}
              handleClick={handleClick}
              isSelected={isSelected(option.title)}
            />
          ))}
        </ButtonsContainer>
      </Container>
    )
  );
};

export default Customization;
