import ConfigActionTypes from './config.types';

export const fetchConfigStart = () => ({
  type: ConfigActionTypes.FETCH_CONFIG_START,
});

export const fetchConfigSuccess = (config) => ({
  type: ConfigActionTypes.FETCH_CONFIG_SUCCESS,
  payload: {
    config,
  },
});

export const fetchConfigFailure = (errorMessage) => ({
  type: ConfigActionTypes.FETCH_CONFIG_FAILURE,
  payload: {
    errorMessage,
  },
});
