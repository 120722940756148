import styled from 'styled-components';

import {
  white,
  mobileSplitPoint,
  darkRed,
  redBackgroundLight,
} from '../../style-constants';

export const Container = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;
Container.displayName = 'Container';

export const TitleContainer = styled.div`
  background-color: ${redBackgroundLight};
  border-radius: 30px;
  padding: 15px 30px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding: 5px 30px;
  }
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  color: ${darkRed};
  font-size: 30px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 26px;
  }
`;
Title.displayName = 'Title';
