import React from 'react';

import { Container, TitleContainer, Title } from './not-found.styles';

export const NotFound = () => {
  return (
    <Container>
      <TitleContainer>
        <Title>Page Not Found</Title>
      </TitleContainer>
    </Container>
  );
};

export default NotFound;
