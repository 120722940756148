import styled from 'styled-components';

import { white, mobileSplitPoint } from '../../style-constants';

export const OperatingHoursContainer = styled.div`
  background-color: ${white};
  border-radius: 3px;
  padding: 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    flex-grow: 2;
    padding: 10px;
    width: 100%;
  }
`;
OperatingHoursContainer.displayName = 'OperatingHoursContainer';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  padding: 15px 0px;
`;
Title.displayName = 'Title';

export const HoursContainer = styled.div`
  @media screen and (max-width: ${mobileSplitPoint}) {
    margin-right: 11px;
  }
`;
HoursContainer.displayName = 'HoursContainer';
