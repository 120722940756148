import styled from 'styled-components';

import { mobileSplitPoint } from '../../style-constants';

export const OuterContainer = styled.div`
  height: 90%;
  margin-bottom: 30px;
  overflow: hidden;
  width: 80%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding-top: 0px;
    margin-bottom: 0px;
    width: unset;
  }
`;
OuterContainer.displayName = 'OuterContainer';

export const InnerContainer = styled.div`
  box-sizing: content-box;
  height: 90%;
  overflow-y: scroll;
  padding-right: 40px;
  width: 100%;
`;
InnerContainer.displayName = 'InnerContainer';

export const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* overflow-y: scroll; */
  width: 70%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
  }
`;
Container.displayName = 'Container';
