import styled from 'styled-components';
import { MdLens, MdPanoramaFishEye, MdClear } from 'react-icons/md';

import {
  darkerGray,
  darkGray,
  white,
  green,
  darkGreen,
  mobileSplitPoint,
  greenVeryLight,
} from '../../style-constants';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? greenVeryLight : white};
  border: ${({ isSelected }) =>
    isSelected ? `2px solid ${green}` : `1px solid ${darkGray}`};
  border-radius: 3px;
  color: ${({ isSelected }) => (isSelected ? darkGreen : darkerGray)};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  height: 50px;
  justify-content: flex-start;
  margin-bottom: 3px;
  padding: 5px 10px;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
`;
Container.displayName = 'Container';

export const RowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
RowContainer.displayName = 'RowContainer';

export const SelectContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
`;
SelectContainer.displayName = 'SelectContainer';

export const CheckMarkContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 35px;
  justify-content: center;
  width: 5%;
`;
CheckMarkContainer.displayName = 'CheckMarkContainer';

export const CheckedIcon = styled(MdLens)``;
CheckedIcon.displayName = 'CheckedIcon';

export const UncheckedIcon = styled(MdPanoramaFishEye)``;
UncheckedIcon.displayName = 'UncheckedIcon';

export const BrandContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 50%;
  }
`;
BrandContainer.displayName = 'BrandContainer';

export const BrandImage = styled.img`
  height: 35px;
  width: 35px;
`;
BrandImage.displayName = 'BrandImage';

export const BrandImageMastercard = styled.img`
  height: 35px;
  width: 43px;
`;
BrandImageMastercard.displayName = 'BrandImageMastercard';

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30%;
`;
ImageContainer.displayName = 'ImageContainer';

export const BrandImageAmex = styled.img`
  height: 30px;
  width: 30px;
`;
BrandImageAmex.displayName = 'BrandImageAmex';

export const BrandText = styled.div`
  font-weight: 700;
  padding-left: 10px;
  width: 70%;
`;
BrandText.displayName = 'BrandText';

export const CardDetailsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 35px;
  justify-content: flex-start;
  padding-right: 5px;
  width: 30%;
`;
CardDetailsContainer.displayName = 'CardDetailsContainer';

export const CardDetail = styled.div``;
CardDetail.displayName = 'CardDetail';

export const CardDetailLast4 = styled.div`
  padding-left: 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding-left: 10px;
  }
`;
CardDetailLast4.displayName = 'CardDetailLast4';

export const DeleteContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15%;
`;
DeleteContainer.displayName = 'DeleteContainer';

export const DeleteIcon = styled(MdClear)``;
DeleteIcon.displayName = 'DeleteIcon';
