import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';

import FlyingImage from '../../assets/unsubscribe_me.jpeg';

import {
  Container,
  ContentContainer,
  InnerContainer,
  Title,
  ImageContainer,
  UnsubscribeImage,
  BlurbContainer,
  BlurbText,
  ButtonOuterContainer,
  SpinnerContainer,
  ButtonContainer,
  ButtonText,
  MsgContainer,
  Msg,
} from './unsubscribe.styles';

import { darkerGray } from '../../style-constants';

export const Unsubscribe = ({
  handleUnsubscribeClick,
  isLoading,
  unsubscribedMsg,
}) => {
  return (
    <Container>
      <ContentContainer>
        <InnerContainer>
          <Title>Unsubscribe</Title>
          <ImageContainer>
            <UnsubscribeImage src={FlyingImage} />
          </ImageContainer>
          <BlurbContainer>
            <BlurbText>
              Remove yourself from any promotional emails or SMS texts from us
            </BlurbText>
          </BlurbContainer>
        </InnerContainer>

        {!!unsubscribedMsg && (
          <MsgContainer>
            <Msg>{unsubscribedMsg}</Msg>
          </MsgContainer>
        )}
        <ButtonOuterContainer>
          <SpinnerContainer>
            <BounceLoader size={30} color={darkerGray} loading={isLoading} />
          </SpinnerContainer>
          <ButtonContainer
            onClick={handleUnsubscribeClick}
            isLoading={isLoading}
            unsubscribedMsg={unsubscribedMsg}
          >
            <ButtonText>Confirm</ButtonText>
          </ButtonContainer>
        </ButtonOuterContainer>
      </ContentContainer>
    </Container>
  );
};

export default Unsubscribe;
