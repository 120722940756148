import styled from 'styled-components';

import { white, darkGray } from '../../style-constants';

export const Row = styled.div`
  align-items: flex-end;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? darkGray : white};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px;
`;
Row.displayName = 'Row';

export const Day = styled.div`
  font-weight: 700;
  width: 20%;
`;
Day.displayName = 'Day';

export const Times = styled.div`
  font-size: 14px;
  margin-left: 15px;
  width: 50%;
`;
Times.displayName = 'Times';
