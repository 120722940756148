import styled from 'styled-components';

import { black, lightTextGray } from '../../style-constants';

export const Container = styled.div`
  align-items: flex-start;
  /* border-bottom: 1px solid ${black}; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
Container.displayName = 'Container';

export const ItemTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
ItemTitleContainer.displayName = 'ItemTitleContainer';

export const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0px;
`;
ItemTitle.displayName = 'ItemTitle';

export const ItemPrice = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;
ItemPrice.displayName = 'ItemPrice';

export const CustomizationsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 25px;
  width: 80%;
`;
CustomizationsContainer.displayName = 'CustomizationsContainer';

export const Item = styled.div`
  color: ${lightTextGray};
  font-size: 14px;
`;
Item.displayName = 'Item';

export const UserInstructionsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${lightTextGray};
  font-size: 14px;
`;
UserInstructionsContainer.displayName = 'UserInstructionsContainer';

export const UserInstructionsTitle = styled.div``;
UserInstructionsTitle.displayName = 'UserInstructionsTitle';

export const UserInstructions = styled.div`
  margin-left: 5px;
`;
UserInstructions.displayName = 'UserInstructions';
