import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';

import WholesaleCustomerProfileDropdown from './wholesale-customer-profile-dropdown.component';

import { selectIsWholesaleUserProfileHidden } from '../../redux/user/user.selectors';

import {
  logoutWholesaleUserStart,
  setIsWholesaleDropdownHidden,
} from '../../redux/user/user.actions';

import { selectConfig } from '../../redux/config/config.selectors';

import { useScrollLock } from '../../hooks/use-scroll-lock';

export const WholesCustomerProfileDropdownContainer = ({
  isWholesaleUserProfileHidden,
  logoutWholesaleUserStart,
  setIsWholesaleDropdownHidden,
  config,
}) => {
  const navigate = useNavigate();
  useScrollLock(!isWholesaleUserProfileHidden);

  const handleLogout = () => {
    logoutWholesaleUserStart(config, navigate);
  };

  const hideProfileDropdown = () => {
    setIsWholesaleDropdownHidden(true);
  };

  return (
    <WholesaleCustomerProfileDropdown
      isWholesaleUserProfileHidden={isWholesaleUserProfileHidden}
      handleLogout={handleLogout}
      hideProfileDropdown={hideProfileDropdown}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  isWholesaleUserProfileHidden: selectIsWholesaleUserProfileHidden,
  config: selectConfig,
});

const mapDispatchToProps = (dispatch) => ({
  logoutWholesaleUserStart: (config, navigate) =>
    dispatch(logoutWholesaleUserStart(config, navigate)),
  setIsWholesaleDropdownHidden: (isHidden) =>
    dispatch(setIsWholesaleDropdownHidden(isHidden)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WholesCustomerProfileDropdownContainer);
