import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const selectUserDetails = createSelector(
  [selectUser],
  (user) => user.userDetails
);

export const selectErrorMessage = createSelector(
  [selectUser],
  (user) => user.errorMessage
);

export const selectWholesaleUserDetails = createSelector(
  [selectUser],
  (user) => user.wholesaleUserDetails
);

export const selectIsWholesaleUserProfileHidden = createSelector(
  [selectUser],
  (user) => user.isWholesaleUserProfileHidden
);

export const selectIsLoading = createSelector(
  [selectUser],
  (user) => user.isLoading
);

export const selectPaymentMethods = createSelector(
  [selectUser],
  (user) => user.paymentProviderDetails.paymentMethods
);

export const selectStripeCustomerId = createSelector(
  [selectUser],
  (user) => user.paymentProviderDetails.customerId
);

export const selectUnsubscribedMsg = createSelector(
  [selectUser],
  (user) => user.unsubscribedMsg
);

export const selectUserCredentials = createSelector(
  [selectUser],
  (user) => user.userCredentials
);

export const selectKeepUserCredentialsForCheckout = createSelector(
  [selectUser],
  (user) => user.keepUserCredentialsForCheckout
);
