import QrCodesActionTypes from './qr-codes.types';

export const setTableNumber = (tableNumber) => ({
  type: QrCodesActionTypes.SET_TABLE_NUMBER,
  payload: {
    tableNumber,
  },
});

export const setOrderType = (orderType) => ({
  type: QrCodesActionTypes.SET_ORDER_TYPE,
  payload: {
    orderType,
  },
});

export const setVoucherId = (voucherId) => ({
  type: QrCodesActionTypes.SET_VOUCHER_ID,
  payload: {
    voucherId,
  },
});
