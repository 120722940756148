import { takeLatest, call, put, all } from 'redux-saga/effects';

import { fetchConfigSuccess, fetchConfigFailure } from './config.actions';

import ConfigActionTypes from './config.types';
import { configApi as api } from '../../services/';

export function* fetchConfig() {
  try {
    const config = yield call(api.fetchCollection, 'config');
    yield put(fetchConfigSuccess(config));
  } catch (error) {
    console.log(error);
    yield put(fetchConfigFailure('ERROR: fetch config failure'));
  }
}

export function* fetchConfigStart() {
  yield takeLatest(ConfigActionTypes.FETCH_CONFIG_START, fetchConfig);
}

export function* configSagas() {
  yield all([call(fetchConfigStart)]);
}
