import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import CartItemMinimal from '../cart-item-minimal/cart-item-minimal.component';

import { selectShopDetails } from '../../redux/shop/shop.selectors';
import { resetUserCredentials } from '../../redux/user/user.actions';
import { selectUserDetails } from '../../redux/user/user.selectors';
import { selectConfig } from '../../redux/config/config.selectors';

import { resetCartAllCartFees } from '../../redux/cart/cart.actions';
import { selectPurchasedCart } from '../../redux/cart/cart.selectors';
import {
  calcFinalTotal,
  calcCartSubtotalAndCartGst,
} from '../../redux/cart/cart.utils';

import { DELIVERY, DINE_IN, WANTED_AT_NONE } from '../../global.constants';

import {
  PaymentSuccessContainer,
  ContentContainer,
  MessageContainer,
  HeaderContainer,
  MessageTitle,
  Message,
  Bold,
  Notes,
  TotalsContainer,
  FeesContainer,
  Row,
  DetailText,
  Detail,
  DetailTextLight,
  DetailLight,
  DetailBoldText,
  DetailBold,
  RowSpaced,
  RowTotal,
  TotalText,
  Total,
  Link,
  BackButtonContainer,
  BackIcon,
  BackText,
} from './payment-success.styles';

export const PaymentSuccess = ({
  purchasedCart,
  userDetails,
  shopDetails,
  config,
  resetUserCredentials,
  resetCartAllCartFees,
}) => {
  const navigate = useNavigate();

  const {
    genericSuccessMessage,
    addBookingFee,
    wantedAtTypes,
    displayPricingOnline,
  } = config;
  const { shopName, shopPhoneNumber } = shopDetails;
  const { userName, whenOrderWanted, whenOrderWantedDate, orderType, notes } =
    userDetails;

  // be protective of accessing fields in purchasedCart as sometimes
  // pressing back button or logging wholesale user out will first
  // reset reducer state for purchasedCart before this component
  // unmounts...resulting in errors thrown accessing non-existent fields
  const _cartItems = purchasedCart.cartItems ?? [];
  const _cartTotal = purchasedCart.actionPayload?.cartTotal ?? 0.0;
  const _deliveryFee =
    purchasedCart.actionPayload?.extraCharges?.deliveryFee ?? 0.0;
  const _promotionFee =
    purchasedCart.actionPayload?.extraCharges?.promotionFee ?? 0.0;
  const _bookingFee =
    purchasedCart.actionPayload?.extraCharges?.bookingFee ?? 0.0;

  const { cartSubtotal, cartGst } = calcCartSubtotalAndCartGst(_cartItems);
  const finalTotal = calcFinalTotal(
    _cartTotal,
    _deliveryFee,
    _promotionFee,
    _bookingFee,
    orderType,
    addBookingFee
  );

  const handleTimeDate = () => {
    if (whenOrderWanted) {
      if (whenOrderWantedDate) {
        const humanDate = moment(whenOrderWantedDate).format('ddd DD MMM');
        return `at ${whenOrderWanted} ${humanDate}`;
      } else {
        return `at ${whenOrderWanted}`;
      }
    } else {
      if (whenOrderWantedDate) {
        const humanDate = moment(whenOrderWantedDate).format('ddd DD MMM');
        return `on ${humanDate}`;
      } else {
        return '';
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // checkout component has many useEffects listening to state of
    // cart fees. so if we reset the fees before the checkout component
    // has unmounted we run the risk of wrongly recalculating the fees.
    // ...so reset the fees in THIS component to make sure all useEffects
    // in checkout component have stopped listening to the fees
    resetCartAllCartFees();
  }, []);

  useEffect(() => {
    if (!config || _.isEmpty(config)) return;
    resetUserCredentials(config);
  }, [config]);

  const successMessage = () => {
    let message;
    if (orderType === DINE_IN) {
      message = (
        <Message>
          A staff member will bring your order to the table shorty.
        </Message>
      );
    } else if (genericSuccessMessage) {
      message = (
        <Message>
          You will receive a confirmation email of your order. If you have any
          issues please contact us.
        </Message>
      );
    } else if (wantedAtTypes === WANTED_AT_NONE) {
      message = (
        <Message>
          You will receive an sms confirmation from us with information on when
          it will be ready. If you have any issues please contact us.
        </Message>
      );
    } else {
      message = (
        <Message>
          You will receive an sms confirmation from us. A confirmation email has
          also been sent. If you have any issues please contact us.
        </Message>
      );
    }

    return message;
  };

  return (
    <PaymentSuccessContainer>
      <ContentContainer>
        <BackButtonContainer onClick={() => navigate('/')}>
          <BackIcon transform={'scale(1.0)'} />
          <BackText>Shop</BackText>
        </BackButtonContainer>
        <MessageContainer>
          <HeaderContainer>
            <MessageTitle>Order Success</MessageTitle>
          </HeaderContainer>
          <br />
          <br />
          <Message>Thanks {userName}!</Message>
          <br />
          <br />
          {wantedAtTypes === WANTED_AT_NONE ? (
            <Message>
              Your order has been submitted for{' '}
              <Bold>{_.capitalize(orderType)}</Bold>.
            </Message>
          ) : (
            <Message>
              Your order has been submitted and requested for:{' '}
              <Bold>{`${_.capitalize(orderType)} ${handleTimeDate()} `}</Bold>
            </Message>
          )}
          <br />
          <br />
          <Message>
            {_cartItems.map((item, idx) => (
              <CartItemMinimal key={idx} item={item} />
            ))}
            {!!notes && <Notes>Notes: {notes}</Notes>}
            {displayPricingOnline && (
              <TotalsContainer>
                <FeesContainer>
                  <Row>
                    <DetailTextLight>Subtotal</DetailTextLight>
                    <DetailLight>{`${cartSubtotal.toFixed(2)}`}</DetailLight>
                  </Row>
                  <Row>
                    <DetailTextLight>GST *</DetailTextLight>
                    <DetailLight>{`${cartGst.toFixed(2)}`}</DetailLight>
                  </Row>
                  <RowSpaced>
                    <DetailBoldText>Cart Total</DetailBoldText>
                    <DetailBold>{_cartTotal.toFixed(2)}</DetailBold>
                  </RowSpaced>
                  {orderType === DELIVERY && (
                    <Row>
                      <DetailBoldText>Delivery</DetailBoldText>
                      <DetailBold>{`${_deliveryFee.toFixed(2)}`}</DetailBold>
                    </Row>
                  )}
                  {_bookingFee && (
                    <Row>
                      <DetailBoldText>Service Fee</DetailBoldText>
                      <DetailBold>{`${_bookingFee.toFixed(2)}`}</DetailBold>
                    </Row>
                  )}
                  {!!_promotionFee && (
                    <Row>
                      <DetailBoldText>Promotion</DetailBoldText>
                      <DetailBold>{`${_promotionFee.toFixed(2)}`}</DetailBold>
                    </Row>
                  )}
                </FeesContainer>
                <RowTotal>
                  <TotalText>Total</TotalText>
                  <Total>
                    {`$${
                      _.isNumber(finalTotal)
                        ? finalTotal.toFixed(2)
                        : finalTotal
                    }`}
                  </Total>
                </RowTotal>
              </TotalsContainer>
            )}
            <br />
            <br />
          </Message>
          {successMessage()}
          <br />
          <br />
          <br />
          <Message>{shopName}</Message>
          <Link>
            <a href={`tel:${shopPhoneNumber}`}>{shopPhoneNumber}</a>
          </Link>
        </MessageContainer>
      </ContentContainer>
    </PaymentSuccessContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  purchasedCart: selectPurchasedCart,
  userDetails: selectUserDetails,
  shopDetails: selectShopDetails,
  config: selectConfig,
});

const mapDispatchToProps = (dispatch) => ({
  resetUserCredentials: (config) => dispatch(resetUserCredentials(config)),
  resetCartAllCartFees: () => dispatch(resetCartAllCartFees()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
