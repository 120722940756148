import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import SyncLoader from 'react-spinners/SyncLoader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Select from 'react-select';

import FormInput from '../form-input/form-input.component';
import PaymentDeliveryAddress from '../payment-delivery-address/payment-delivery-address.component';
import PaymentMethod from '../payment-method/payment-method.component';
import WholesaleOrderingMethods from '../wholesale-ordering-methods/wholesale-ordering-methods.component';
import DineInBatchType from '../dine-in-batch-type/dine-in-batch-type.component';
import { ToastContainer } from 'react-toastify';

import {
  isTodayOpenUsingOperatingHours,
  isOverrideDayOpen,
  checkForAnOverrideDay,
  dayFromWhenOrderWantedDate,
  checkMomentsAreSameDateExactly,
} from '../../utils/shop-utils';

import {
  PaymentDetailsContainer,
  PaymentInformationContainer,
  PaymentInformation,
  PaymentForm,
  CardContainer,
  CardInnerContainer,
  CardRowContainer,
  CardNumberRowContainer,
  CardExpiryRowContainer,
  CardCvcRowContainer,
  CardFieldNumberTitle,
  CardFieldTitle,
  CardElementContainer,
  CardNumberElementContainer,
  CardExpiryElementContainer,
  CardCvcElementContainer,
  CardDetailsContainer,
  CCNumberContainer,
  PaymentMethodsContainer,
  Title,
  TitleContainer,
  SelectTitleContainer,
  SelectTitle,
  Required,
  PlaceOrderButtonContainer,
  PlaceOrderButton,
  PlaceOrderText,
  DateWantedContainer,
  DateContainer,
  DateSelector,
  TimeWantedContainer,
  TimeTitleContainer,
  TimesButtonsContainer,
  TimeActionContainer,
  TimeAction,
  ClosedMsg,
  SpinnerContainer,
  SwitchContainerConsent,
  SwitchContainer,
  SwitchLabel,
  SwitchLabelInfo,
  SwitchReact,
  DeliveryAddressContainer,
  UseDifferentCardContainer,
  UseDifferentCardText,
  SwitchContainerSaveCard,
} from './payment-details.styles';

import {
  DELIVERY,
  DINE_IN,
  WANTED_AT_TIME,
  WANTED_AT_DATE,
  WANTED_AT_TIME_DATE,
  TODAY,
  ADD_TO_ACCOUNT,
  ISO_DATESTRING_FORMAT,
} from '../../global.constants';

import { darkerGray, red } from '../../style-constants';

export const PaymentDetails = ({
  handleSubmit,
  handleChange,
  handleBlur,
  handleAddressChange,
  whenOrderWantedTimes,
  orderType,
  reduxUserCredentials,
  userErrors,
  isLoading,
  hasSubscribed,
  handleSubscription,
  handleSelectTime,
  handleSetWantedDate,
  fastDisable,
  setFastDisable,
  requireConsent,
  hasConsented,
  setHasConsented,
  handleNotesChange,
  hasConsentedToLeaveAtDoor,
  handleConsentToLeaveAtDoor,
  config,
  wholesaleUserDetails,
  calcMinDate,
  handleFilterDate,
  isPlaceOrderButtonDisabled,
  operatingHours,
  overrideDays,
  paymentMethods,
  toggleUseDifferentCard,
  paymentMethodsState,
  handleSelectCardForPaymentMethodClick,
  handleShouldSaveCardDetailsCheck,
  handleDetachPaymentMethod,
  wholesalePaymentMethod,
  setWholesalePaymentMethod,
  isOrdersThrottlingEnabled,
  isFetchingThrottlingData,
  ordersThrottling,
  dineInBatchType,
  setDineInBatchType,
  keepUserCredentialsForCheckout,
  handleKeepUserCredentialsForCheckout,
}) => {
  const {
    tableNumber,
    userName,
    email,
    address,
    phone,
    whenOrderWanted,
    whenOrderWantedDate,
    notes,
  } = reduxUserCredentials;

  const {
    tableNumber: tableNumberError,
    userName: userNameError,
    email: emailError,
    address: addressError,
    phone: phoneError,
    whenOrderWanted: whenOrderWantedError,
    whenOrderWantedDate: whenOrderWantedDateError,
  } = userErrors;

  const {
    allowWholesaleOrderingMethods,
    dineInCanSendEta,
    enableConsentToLeaveDeliveryAtFrontDoorCheck,
    enableSubscriptionSwitch,
    wantedAtTypeNoneAllowPreOrders,
    wantedAtTypes,
    onlineOrderingEnableToggleToKeepUserCredentialsForCheckout,
  } = config;

  const calcIfTimeIsThrottled = (
    time,
    day,
    orderType,
    ordersThrottling,
    fullDateWanted
  ) => {
    try {
      if (_.isEmpty(ordersThrottling))
        throw new Error('ordersThrottling data is empty');

      const dayThrottleData = _.find(
        ordersThrottling,
        (throttleDoc) => throttleDoc.day === day
      );

      if (!dayThrottleData)
        throw new Error('Error: could not find throttle data for day');

      if (
        dayThrottleData.times &&
        dayThrottleData.times[time] &&
        dayThrottleData.times[time][orderType]
      ) {
        const { currentValues, maxValue, isBlockedOff } =
          dayThrottleData.times[time][orderType];

        // first check if customer has deliberately blocked off timeslot
        if (isBlockedOff) return true;
        // currentValues may be empty..
        // or not have the fullDateWanted as a key
        if (_.isEmpty(currentValues) || !currentValues[fullDateWanted]) {
          // not even one order for this timeslot yet
          // check if we can add one, tho
          return maxValue < 1;
        } else {
          return currentValues[fullDateWanted] + 1 > maxValue;
        }
      }

      throw new Error('Error: fallthrough all checks...default to NO throttle');
    } catch (error) {
      // if we have errors then default to NO throttle
      // console.log({ error });
      return false;
    }
  };

  const renderDates = () => {
    const stuff = (
      <DateWantedContainer error={whenOrderWantedDateError}>
        <DateContainer>
          <SelectTitleContainer>
            <SelectTitle>{`* What day for ${_.capitalize(
              orderType
            )}?`}</SelectTitle>
            {whenOrderWantedDateError && <Required>* required</Required>}
          </SelectTitleContainer>
          <DateSelector
            selected={(() => {
              // can only handle Date type for picker
              if (!whenOrderWantedDate) return '';

              const m = moment(whenOrderWantedDate, ISO_DATESTRING_FORMAT);
              const selectedDate = new Date(m.unix() * 1000);
              return selectedDate;
            })()}
            onChange={(date) => handleSetWantedDate(date)}
            minDate={calcMinDate()}
            filterDate={handleFilterDate}
            dateFormat="dd MMM"
            placeholderText="Click to select date"
            disabledKeyboardNavigation
            onFocus={(e) => (e.target.readOnly = true)}
            withPortal={true}
          />
        </DateContainer>
      </DateWantedContainer>
    );

    if (
      wantedAtTypes === WANTED_AT_DATE ||
      wantedAtTypes === WANTED_AT_TIME_DATE
    ) {
      return stuff;
    }

    return null;
  };

  const renderTimes = () => {
    if (wantedAtTypes === WANTED_AT_TIME) {
      const _stuff = (
        <TimeWantedContainer error={whenOrderWantedError}>
          <TimeTitleContainer>
            <SelectTitle>
              {`* What time ${
                whenOrderWantedDate
                  ? `${moment(whenOrderWantedDate).format('ddd DD MMM')}`
                  : ''
              } for ${_.capitalize(orderType)}?`}
            </SelectTitle>
            {whenOrderWantedError && <Required>* required</Required>}
          </TimeTitleContainer>
          <TimesButtonsContainer>
            {_.map(whenOrderWantedTimes, (time) => {
              return (
                <TimeActionContainer
                  key={time}
                  onClick={() => handleSelectTime(time)}
                  isSelected={whenOrderWanted === time}
                >
                  <TimeAction isSelected={whenOrderWanted === time}>
                    {time}
                  </TimeAction>
                </TimeActionContainer>
              );
            })}
            {_.isEmpty(whenOrderWantedTimes) && orderType && (
              <ClosedMsg>{`Closed for ${_.capitalize(orderType)}`}</ClosedMsg>
            )}
          </TimesButtonsContainer>
        </TimeWantedContainer>
      );
      return _stuff;
    }

    if (wantedAtTypes === WANTED_AT_TIME_DATE && !!whenOrderWantedDate) {
      return renderTimesForWantedAtTimeDateConfig();
    }

    if (wantedAtTypes === WANTED_AT_DATE && whenOrderWantedDate) {
      const mWantedDate = moment(whenOrderWantedDate, ISO_DATESTRING_FORMAT);
      const mNow = moment();
      if (checkMomentsAreSameDateExactly(mWantedDate, mNow)) {
        // check override days for override for today
        const overrideDay = checkForAnOverrideDay(overrideDays, mNow);
        if (overrideDay) {
          const isOpen = isOverrideDayOpen(
            overrideDay,
            TODAY,
            WANTED_AT_DATE,
            wantedAtTypeNoneAllowPreOrders
          );
          return isOpen ? null : (
            <TimesButtonsContainer>
              <ClosedMsg>{`Closed for ${_.capitalize(orderType)}`}</ClosedMsg>
            </TimesButtonsContainer>
          );
        } else if (
          !isTodayOpenUsingOperatingHours(operatingHours, orderType, {
            boundsToCheck: 'end',
          })
        ) {
          // we can have preorders/orders until end of operating hours for day
          // => use 'end' for boundsToCheck
          // display closed message
          return (
            <TimesButtonsContainer>
              <ClosedMsg>{`Closed for ${_.capitalize(orderType)}`}</ClosedMsg>
            </TimesButtonsContainer>
          );
        }
      }
      return null;
    }

    return null;
  };

  const renderTimesForWantedAtTimeDateConfig = () => {
    if (!isOrdersThrottlingEnabled) {
      const selectOptions = _.map(whenOrderWantedTimes, (time) => {
        return {
          value: time,
          label: time,
        };
      });

      const customSelectStyles = {
        control: (provided, state) => ({
          ...provided,
          border: `1px solid ${darkerGray}`,
          borderRadius: '5px',
        }),
      };

      return (
        <TimeWantedContainer error={whenOrderWantedError}>
          <TimeTitleContainer>
            <SelectTitle>
              {`* What time ${
                whenOrderWantedDate
                  ? `${moment(whenOrderWantedDate).format('ddd DD MMM')}`
                  : ''
              } for ${_.capitalize(orderType)}?`}
            </SelectTitle>
            {whenOrderWantedError && <Required>* required</Required>}
          </TimeTitleContainer>
          <Select
            styles={customSelectStyles}
            onChange={(option) => handleSelectTime(option.value)}
            value={
              !!whenOrderWanted
                ? { value: whenOrderWanted, label: whenOrderWanted }
                : null
            }
            options={selectOptions}
            isOptionDisabled={(option) => option.isDisabled}
            isSearchable={false}
          />
          {_.isEmpty(whenOrderWantedTimes) && orderType && (
            <ClosedMsg>{`Closed for ${_.capitalize(orderType)}`}</ClosedMsg>
          )}
        </TimeWantedContainer>
      );
    }

    // we have ordersThrottling feature enabled
    if (isFetchingThrottlingData) {
      // show loading state
      return (
        <TimeWantedContainer error={whenOrderWantedError}>
          <TimeTitleContainer>
            <SelectTitle>
              {`* What time ${
                whenOrderWantedDate
                  ? `${moment(whenOrderWantedDate).format('ddd DD MMM')}`
                  : ''
              } for ${_.capitalize(orderType)}?`}
            </SelectTitle>
            {whenOrderWantedError && <Required>* required</Required>}
          </TimeTitleContainer>
          <SkeletonTheme color={'#b1b1b1'}>
            <Skeleton height={45} />
          </SkeletonTheme>
        </TimeWantedContainer>
      );
    }

    const day = dayFromWhenOrderWantedDate(whenOrderWantedDate);
    const mDate = moment(whenOrderWantedDate, ISO_DATESTRING_FORMAT);
    const fullDateWanted = mDate.format('YYYY-MM-DD');

    const selectOptions = _.map(whenOrderWantedTimes, (time) => {
      const isTimeThrottled = calcIfTimeIsThrottled(
        time,
        day,
        orderType,
        ordersThrottling,
        fullDateWanted
      );

      return {
        value: time,
        label: isTimeThrottled
          ? `${time} - Sold out for ${_.capitalize(orderType)}`
          : time,
        isDisabled: isTimeThrottled,
      };
    });

    const customSelectStyles = {
      control: (provided, state) => ({
        ...provided,
        border: `1px solid ${darkerGray}`,
        borderRadius: '5px',
      }),
    };

    return (
      <TimeWantedContainer error={whenOrderWantedError}>
        <TimeTitleContainer>
          <SelectTitle>
            {`* What time ${
              whenOrderWantedDate
                ? `${moment(whenOrderWantedDate).format('ddd DD MMM')}`
                : ''
            } for ${_.capitalize(orderType)}?`}
          </SelectTitle>
          {whenOrderWantedError && <Required>* required</Required>}
        </TimeTitleContainer>
        <Select
          styles={customSelectStyles}
          defaultValue={null}
          onChange={(option) => handleSelectTime(option.value)}
          value={
            !!whenOrderWanted
              ? { value: whenOrderWanted, label: whenOrderWanted }
              : null
          }
          options={selectOptions}
          isOptionDisabled={(option) => option.isDisabled}
          isSearchable={false}
        />
        {_.isEmpty(whenOrderWantedTimes) && orderType && (
          <ClosedMsg>{`Closed for ${_.capitalize(orderType)}`}</ClosedMsg>
        )}
      </TimeWantedContainer>
    );
  };

  const renderCardDetailsMaybe = () => {
    // the card component stuff if applicable below
    const cardComponent = (
      <CardContainer>
        <CardDetailsContainer>
          <Title>Card Details</Title>
        </CardDetailsContainer>
        {_.size(paymentMethods) !== 0 && (
          <PaymentMethodsContainer>
            {_.map(paymentMethods, (paymentMethod, idx) => {
              return (
                <PaymentMethod
                  key={idx}
                  paymentMethod={paymentMethod}
                  paymentMethodsState={paymentMethodsState}
                  handleSelectCardForPaymentMethodClick={
                    handleSelectCardForPaymentMethodClick
                  }
                  handleDetachPaymentMethod={handleDetachPaymentMethod}
                />
              );
            })}
            <UseDifferentCardContainer onClick={toggleUseDifferentCard}>
              {paymentMethodsState.showCardElement ? (
                <UseDifferentCardText>x Cancel</UseDifferentCardText>
              ) : (
                <UseDifferentCardText>+ Other Card</UseDifferentCardText>
              )}
            </UseDifferentCardContainer>
          </PaymentMethodsContainer>
        )}
        {(!_.size(paymentMethods) || paymentMethodsState.showCardElement) && (
          <CardInnerContainer>
            <CardNumberRowContainer>
              <CardFieldNumberTitle>Number</CardFieldNumberTitle>
              <CardNumberElementContainer
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
            </CardNumberRowContainer>
            <CardRowContainer>
              <CardExpiryRowContainer>
                <CardFieldTitle>Expiry</CardFieldTitle>
                <CardExpiryElementContainer
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </CardExpiryRowContainer>
              <CardCvcRowContainer>
                <CardFieldTitle>CVC</CardFieldTitle>
                <CardCvcElementContainer
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </CardCvcRowContainer>
            </CardRowContainer>
          </CardInnerContainer>
        )}
        {!_.size(paymentMethods) && (
          <SwitchContainerSaveCard>
            <SwitchLabel>
              <SwitchLabelInfo>Save card for next time</SwitchLabelInfo>
              <SwitchReact
                checked={paymentMethodsState.shouldSaveCardDetails}
                onChange={(checked) =>
                  handleShouldSaveCardDetailsCheck(checked)
                }
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={35}
                className="react-switch"
                id="material-switch-should-save-payment-method"
              />
            </SwitchLabel>
          </SwitchContainerSaveCard>
        )}
      </CardContainer>
    );

    if (wholesaleUserDetails) {
      if (!allowWholesaleOrderingMethods) {
        // wholesale ordering methods is not displayed.
        // user has to pay now using card component
        return cardComponent;
      } else if (wholesalePaymentMethod === ADD_TO_ACCOUNT) {
        // dont show card component if user can, and has selected,
        // add to account option for checkout
        return null;
      }
    }

    return cardComponent;
  };

  return (
    <PaymentDetailsContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <PaymentInformationContainer>
        <PaymentInformation>
          <TitleContainer>
            <Title>Payment Details</Title>
          </TitleContainer>

          <PaymentForm>
            {requireConsent && (
              <SwitchContainerConsent>
                <SwitchLabel>
                  <SwitchReact
                    checked={hasConsented}
                    onChange={(checked) => setHasConsented(checked)}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={10}
                    width={35}
                    className="react-switch"
                    id="material-switch-consent"
                  />
                  <SwitchLabelInfo>
                    Please confirm that your age is 18 years or older.
                    Requirement for purchasing alcohol.
                  </SwitchLabelInfo>
                </SwitchLabel>
              </SwitchContainerConsent>
            )}

            {orderType === DINE_IN && (
              <FormInput
                name="tableNumber"
                type="text"
                value={tableNumber}
                label="* Table Number"
                handleChange={handleChange}
                error={tableNumberError}
                onBlur={() => handleBlur('tableNumber')}
              />
            )}

            <FormInput
              name="userName"
              type="text"
              value={userName}
              label="* Name"
              handleChange={handleChange}
              error={userNameError}
              onBlur={() => handleBlur('userName')}
            />
            {/* 
                DINE_IN feature: dont require email for DINE_IN orderType
            */}
            {orderType !== DINE_IN && (
              <FormInput
                name="email"
                type="email"
                value={email}
                handleChange={handleChange}
                label="* Email"
                error={emailError}
                onBlur={() => handleBlur('email')}
              />
            )}
            {orderType === DELIVERY && (
              <DeliveryAddressContainer>
                <PaymentDeliveryAddress
                  address={address}
                  addressError={addressError}
                  handleBlur={handleBlur}
                  handleAddressChange={handleAddressChange}
                />
              </DeliveryAddressContainer>
            )}
            {/* 
                DINE_IN feature: dont require phone for DINE_IN and no ETA sending 
                for that orderType
            */}
            {orderType === DINE_IN && !dineInCanSendEta ? null : (
              <FormInput
                name="phone"
                type="number"
                value={phone}
                handleChange={handleChange}
                label="* Mobile number (for ETA msg)"
                error={phoneError}
                onBlur={() => handleBlur('phone')}
              />
            )}

            {orderType !== DINE_IN && renderDates()}

            {orderType !== DINE_IN && renderTimes()}

            {orderType !== DINE_IN && (
              <FormInput
                name="notes"
                type="text"
                value={notes}
                handleChange={handleNotesChange}
                label="Notes"
              />
            )}

            {orderType === DINE_IN && (
              <DineInBatchType
                dineInBatchType={dineInBatchType}
                setDineInBatchType={setDineInBatchType}
              />
            )}

            {wholesaleUserDetails && allowWholesaleOrderingMethods && (
              <WholesaleOrderingMethods
                wholesalePaymentMethod={wholesalePaymentMethod}
                setWholesalePaymentMethod={setWholesalePaymentMethod}
              />
            )}

            {onlineOrderingEnableToggleToKeepUserCredentialsForCheckout && (
              <SwitchContainer>
                <SwitchLabel>
                  <SwitchLabelInfo>
                    Keep my details for next time
                  </SwitchLabelInfo>
                  <SwitchReact
                    checked={keepUserCredentialsForCheckout}
                    onChange={handleKeepUserCredentialsForCheckout}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={10}
                    width={35}
                    className="react-switch"
                    id="material-switch-keep-user-credentials"
                  />
                </SwitchLabel>
              </SwitchContainer>
            )}

            {enableSubscriptionSwitch &&
              orderType !== DINE_IN &&
              !wholesaleUserDetails && (
                <SwitchContainer>
                  <SwitchLabel>
                    <SwitchLabelInfo>Receive promotion emails</SwitchLabelInfo>
                    <SwitchReact
                      checked={hasSubscribed}
                      onChange={handleSubscription}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={35}
                      className="react-switch"
                      id="material-switch-subscription"
                    />
                  </SwitchLabel>
                </SwitchContainer>
              )}

            {enableConsentToLeaveDeliveryAtFrontDoorCheck && (
              <SwitchContainerConsent>
                <SwitchLabel>
                  <SwitchLabelInfo>
                    * Acknowledge permission for us to leave delivery at door
                  </SwitchLabelInfo>
                  <SwitchReact
                    checked={hasConsentedToLeaveAtDoor}
                    onChange={handleConsentToLeaveAtDoor}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={10}
                    width={35}
                    className="react-switch"
                    id="material-switch-enable-leave-at-door"
                  />
                </SwitchLabel>
              </SwitchContainerConsent>
            )}

            {renderCardDetailsMaybe()}
            <PlaceOrderButtonContainer>
              {fastDisable && (
                <SpinnerContainer>
                  <SyncLoader color={red} size={13} loading={fastDisable} />
                </SpinnerContainer>
              )}
              <PlaceOrderButton
                onClick={(e) => {
                  setFastDisable(true);
                  handleSubmit(e);
                }}
                type="button"
                disabled={isPlaceOrderButtonDisabled()}
                isLoading={fastDisable || isLoading}
              >
                <PlaceOrderText>Place Order</PlaceOrderText>
              </PlaceOrderButton>
            </PlaceOrderButtonContainer>
          </PaymentForm>
        </PaymentInformation>
      </PaymentInformationContainer>
    </PaymentDetailsContainer>
  );
};

export default PaymentDetails;
