import React from 'react';

import {
  CartItemCustomizationContainer,
  Title,
  Details,
} from './cart-item-customization-group.styles';

export const CartItemCustomizationGroup = ({ title, options }) => {
  // FYI: 'title' prop is actually 'displayTitle' field of a customization
  const details = options.map((option) => option.title).join(', ');

  return (
    <CartItemCustomizationContainer>
      <Title>{title}: </Title>
      <Details>{details}</Details>
    </CartItemCustomizationContainer>
  );
};

export default CartItemCustomizationGroup;
