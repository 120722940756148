import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Swal from 'sweetalert2';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  selectCartItems,
  selectOrderType,
} from '../../redux/cart/cart.selectors';

import { selectShopDetails } from '../../redux/shop/shop.selectors';

import {
  calcCartTotal,
  calcCartSubtotalAndCartGst,
} from '../../redux/cart/cart.utils';

import { DELIVERY, PAYMENT_URL } from '../../global.constants';
import { white } from '../../style-constants';

import {
  SubmitButtonContainer,
  SubmitButton,
  SubmitButtonText,
} from './goto-checkout-button.styles';

export const GotoCheckoutButton = ({ cartItems, orderType, shopDetails }) => {
  const navigate = useNavigate();
  const { deliveryMinimum } = shopDetails;

  const [totals, setTotals] = useState({
    cartSubtotal: 0.0,
    cartGst: 0.0,
    cartTotal: 0.0,
  });

  useEffect(() => {
    const cartTotal = calcCartTotal(cartItems);
    const { cartSubtotal, cartGst } = calcCartSubtotalAndCartGst(cartItems);

    setTotals({
      cartSubtotal,
      cartGst,
      cartTotal,
    });
  }, [cartItems]);

  const handleClick = () => {
    if (orderType === DELIVERY && totals.cartTotal < deliveryMinimum) {
      Swal.fire(
        `Delivery mininum amount is $${deliveryMinimum}. Please add more items.`
      );
      return;
    }
    navigate(PAYMENT_URL);
  };

  return (
    <SubmitButtonContainer>
      <SubmitButton onClick={handleClick}>
        <SubmitButtonText>Go to Checkout</SubmitButtonText>
        <FaArrowRight style={{ color: white }} />
      </SubmitButton>
    </SubmitButtonContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  orderType: selectOrderType,
  shopDetails: selectShopDetails,
});

export default connect(mapStateToProps, null)(GotoCheckoutButton);
