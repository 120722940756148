const ShopActionTypes = {
  FETCH_SHOP_DATA_START: 'FETCH_SHOP_DATA_START',
  FETCH_SHOP_DATA_SUCCESS: 'FETCH_SHOP_DATA_SUCCESS',
  FETCH_SHOP_DATA_FAILURE: 'FETCH_SHOP_DATA_FAILURE',

  FETCH_OPERATING_HOURS_START: 'FETCH_OPERATING_HOURS_START',
  FETCH_OPERATING_HOURS_SUCCESS: 'FETCH_OPERATING_HOURS_SUCCESS',
  FETCH_OPERATING_HOURS_FAILURE: 'FETCH_OPERATING_HOURS_FAILURE',

  FETCH_SHOP_DETAILS_START: 'FETCH_SHOP_DETAILS_START',
  FETCH_SHOP_DETAILS_SUCCESS: 'FETCH_SHOP_DETAILS_SUCCESS',
  FETCH_SHOP_DETAILS_FAILURE: 'FETCH_SHOP_DETAILS_FAILURE',

  ADD_ITEM_TO_MODAL: 'ADD_ITEM_TO_MODAL',
  SET_SHOW_ITEM_MODAL: 'SET_SHOW_ITEM_MODAL',
  INCREMENT_QUANTITY_BY: 'INCREMENT_QUANTITY_BY',
  SET_SELECTED_PRICE: 'SET_SELECTED_PRICE',
  SET_USER_INSTRUCTIONS: 'SET_USER_INSTRUCTIONS',
  RESET_ITEM_MODAL: 'RESET_ITEM_MODAL',

  ADD_CUSTOMIZATION_OPTION: 'ADD_CUSTOMIZATION_OPTION',
  REMOVE_CUSTOMIZATION_OPTION: 'REMOVE_CUSTOMIZATION_OPTION',
  ADD_BACK_ALL_THE_USER_CUSTOMIZATIONS_FOR_CHECKOUT_ITEM:
    'ADD_BACK_ALL_THE_USER_CUSTOMIZATIONS_FOR_CHECKOUT_ITEM',
  SET_IS_UPDATING_ITEM: 'SET_IS_UPDATING_ITEM',

  RESET_SHOP_DATA_AND_COLLECTIONS_ORDERING_AND_ITEMS_ORDERING:
    'RESET_SHOP_DATA_AND_COLLECTIONS_ORDERING_AND_ITEMS_ORDERING',

  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',

  FETCH_OVERRIDE_DAYS_START: 'FETCH_OVERRIDE_DAYS_START',
  FETCH_OVERRIDE_DAYS_SUCCESS: 'FETCH_OVERRIDE_DAYS_SUCCESS',
  FETCH_OVERRIDE_DAYS_FAILURE: 'FETCH_OVERRIDE_DAYS_FAILURE',

  FETCH_DELIVERY_ZONES_START: 'FETCH_DELIVERY_ZONES_START',
  FETCH_DELIVERY_ZONES_SUCCESS: 'FETCH_DELIVERY_ZONES_SUCCESS',
  FETCH_DELIVERY_ZONES_FAILURE: 'FETCH_DELIVERY_ZONES_FAILURE',

  SET_SHOW_DELIVERY_ZONES_MODAL: 'SET_SHOW_DELIVERY_ZONES_MODAL',

  FETCH_ORDERS_THROTTLING_FOR_DAY_START:
    'FETCH_ORDERS_THROTTLING_FOR_DAY_START',
  FETCH_ORDERS_THROTTLING_FOR_DAY_SUCCESS:
    'FETCH_ORDERS_THROTTLING_FOR_DAY_SUCCESS',
  FETCH_ORDERS_THROTTLING_FOR_DAY_FAILURE:
    'FETCH_ORDERS_THROTTLING_FOR_DAY_FAILURE',

  SET_SHOW_DYNAMIC_DELIVERY_FEE_INFO_MODAL:
    'SET_SHOW_DYNAMIC_DELIVERY_FEE_INFO_MODAL',
  SET_SHOW_SHOP_INFO_MODAL: 'SET_SHOW_SHOP_INFO_MODAL',
};

export default ShopActionTypes;
