import styled from 'styled-components';
import { MdArrowBack } from 'react-icons/md';

import {
  white,
  black,
  mobileSplitPoint,
  lightTextGray,
  greenSuccessNew,
  veryLightGray,
} from '../../style-constants';

export const PaymentSuccessContainer = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 69px;
  min-height: 100vh;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: flex-start;
    margin-top: 70px;
    padding: 15px;
  }
`;
PaymentSuccessContainer.displayName = 'PaymentSuccessContainer';

export const BackButtonContainer = styled.div`
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 70%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
    margin-top: unset;
  }
`;
BackButtonContainer.displayName = 'BackButtonContainer';

export const BackIcon = styled(MdArrowBack)`
  height: 25px;
  width: 25px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    color: ${black};
  }
`;
BackIcon.displayName = 'BackIcon';

export const BackText = styled.div`
  border-bottom: 2px solid ${black};
  color: ${black};
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-bottom: 2px solid ${black};
  }
`;
BackText.displayName = 'BackText';

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  width: 70%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
  }
`;
ContentContainer.displayName = 'ContentContainer';

export const MessageContainer = styled.div`
  background-color: ${veryLightGray};
  border-radius: 30px;
  margin-bottom: 20px;
  padding: 20px;
  width: 70%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin: unset;
    padding-bottom: 30px;
    position: unset;
    top: unset;
    width: 100%;
  }
`;
MessageContainer.displayName = 'MessageContainer';

export const HeaderContainer = styled.div`
  background: rgb(1, 202, 154);
  background: radial-gradient(
    circle,
    rgba(1, 202, 154, 1) 23%,
    rgba(19, 186, 223, 1) 100%
  );
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const MessageTitle = styled.div`
  /* background-color: ${greenSuccessNew}; */
  color: ${white};
  font-size: 20px;
  font-weight: 700;
  padding: 15px 0px;
`;
MessageTitle.displayName = 'MessageTitle';

export const Message = styled.div``;
Message.displayName = 'Message';

export const Bold = styled.span`
  font-weight: 700;
  font-size: 20px;
`;
Bold.displayName = 'Bold';

export const Notes = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0px;
`;
Notes.displayName = 'Notes';

export const TotalsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10px;
  width: 100%;
`;
TotalsContainer.displayName = 'TotalsContainer';

export const FeesContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 15px;
  width: 100%;
`;
FeesContainer.displayName = 'FeesContainer';

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 50%;
  }
`;
Row.displayName = 'Row';

export const DetailText = styled.div`
  font-size: 14px;
  text-align: right;
  width: 50%;
`;
DetailText.displayName = 'DetailText';

export const Detail = styled.div`
  font-size: 14px;
  text-align: right;
  width: 50%;
`;
Detail.displayName = 'Detail';

export const DetailTextLight = styled.div`
  color: ${lightTextGray};
  font-size: 14px;
  text-align: right;
  width: 50%;
`;
DetailTextLight.displayName = 'DetailTextLight';

export const DetailLight = styled.div`
  color: ${lightTextGray};
  font-size: 14px;
  text-align: right;
  width: 50%;
`;
DetailLight.displayName = 'DetailLight';

export const RowSpaced = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 0px 15px 0px;
  width: 50%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 50%;
  }
`;
RowSpaced.displayName = 'RowSpaced';

export const DetailBoldText = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 900;
  text-align: right;
  width: 50%;
`;
DetailBoldText.displayName = 'DetailBoldText';

export const DetailBold = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 900;
  text-align: right;
  width: 50%;
`;
DetailBold.displayName = 'DetailBold';

export const RowTotal = styled.div`
  align-items: center;
  border-bottom: 2px solid ${black};
  border-top: 2px solid ${black};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
  width: 50%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 50%;
  }
`;
RowTotal.displayName = 'RowTotal';

export const TotalContainer = styled.div`
  width: 100%;
`;
TotalContainer.displayName = 'TotalContainer';

export const TotalText = styled.div`
  color: ${black};
  font-size: 20px;
  font-weight: 900;
  padding-top: 10px;
  text-align: right;
  width: 50%;
`;
TotalText.displayName = 'TotalText';

export const Total = styled.div`
  color: ${black};
  font-size: 20px;
  font-weight: 800;
  padding-top: 10px;
  text-align: right;
  width: 50%;
`;
Total.displayName = 'Total';

export const Link = styled.div`
  margin-top: 5px;
`;
Link.displayName = 'Link';
