import styled from 'styled-components';

import { lightTextGray } from '../../style-constants';

export const CartItemCustomizationContainer = styled.div`
  margin: 0px 0px 0px 2px;
`;

export const Title = styled.span`
  color: ${lightTextGray};
  font-size: 13px;
  font-weight: 900;
`;
Title.displayName = 'Title';

export const Details = styled.span`
  color: ${lightTextGray};
  font-size: 13px;
`;
Details.displayName = 'Details';

CartItemCustomizationContainer.displayName = 'CartItemCustomizationContainer';

export const OptionsContainer = styled.div`
  display: flex;
  font-size: 12px;
  flex-direction: column;
`;
OptionsContainer.displayName = 'OptionsContainer';
