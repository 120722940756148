import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AnimateGroup } from 'react-animation';

import CartItem from '../cart-item/cart-item.container';

import {
  selectCartItems,
  selectOrderType,
  selectCartHidden,
} from '../../redux/cart/cart.selectors';

import {
  selectShowItemModal,
  selectShopDetails,
} from '../../redux/shop/shop.selectors';

import {
  setShowItemModal,
  resetItemModal,
} from '../../redux/shop/shop.actions';

import { toggleCartHidden } from '../../redux/cart/cart.actions';

import {
  createUniqueIdentifier,
  calcCartTotal,
  calcCartSubtotalAndCartGst,
} from '../../redux/cart/cart.utils';

import { selectWholesaleUserDetails } from '../../redux/user/user.selectors';

import { selectConfig } from '../../redux/config/config.selectors';

import {
  Container,
  CartDropdownContainer,
  CartItemsContainer,
  EmptyMessageContainer,
  ButtonContainer,
  CartDropdownButton,
  CartHeaderContainer,
  TheNotch,
  SummaryContainer,
  Summary,
  TotalsContainer,
  ValueRowContainer,
  SubvalueTitle,
  SubvalueAmount,
  TotalValueRowContainer,
  TotalTitle,
  TotalAmount,
} from './cart-dropdown.styles';

import { DELIVERY, PAYMENT_URL } from '../../global.constants';

import { useScrollLock } from '../../hooks/use-scroll-lock';

export const CartDropdown = ({
  cartItems,
  orderType,
  toggleCartHidden,
  showItemModal,
  setShowItemModal,
  resetItemModal,
  shopDetails,
  isCartHidden,
  wholesaleUserDetails,
  config,
}) => {
  const navigate = useNavigate();
  const { displayPricingOnline } = config;
  const { deliveryMinimum } = shopDetails;

  const [totals, setTotals] = useState({
    cartSubtotal: 0.0,
    cartGst: 0.0,
    cartTotal: 0.0,
  });

  useScrollLock(!isCartHidden);

  useEffect(() => {
    const cartTotal = calcCartTotal(cartItems);
    const { cartSubtotal, cartGst } = calcCartSubtotalAndCartGst(cartItems);

    setTotals({
      cartSubtotal,
      cartGst,
      cartTotal,
    });
  }, [cartItems]);

  const handleCheckoutClick = (event) => {
    // dont bubble click event up to cart dropdown overlay element.
    event.stopPropagation();

    if (orderType === DELIVERY && totals.cartTotal < deliveryMinimum) {
      Swal.fire(
        `Delivery mininum amount is $${deliveryMinimum}. Please add more items.`
      );
      return;
    }

    toggleCartHidden();

    if (showItemModal) {
      setShowItemModal(false);
    }
    resetItemModal();
    navigate(PAYMENT_URL);
  };

  const displayGoToCheckoutButton = () => {
    if (!cartItems.length) return false;

    return true;
  };

  return (
    <Container onClick={toggleCartHidden}>
      <CartDropdownContainer isCartHidden={isCartHidden}>
        <TheNotch wholesaleUserDetails={wholesaleUserDetails} />
        <CartHeaderContainer>
          <SummaryContainer>
            {orderType && (
              <Summary>Order Summary - {orderType.toUpperCase()}</Summary>
            )}
          </SummaryContainer>
        </CartHeaderContainer>
        <CartItemsContainer isEmpty={!cartItems.length}>
          {cartItems.length ? (
            <AnimateGroup>
              {cartItems.map((cartItem) => (
                <CartItem
                  key={createUniqueIdentifier(cartItem)}
                  item={cartItem}
                />
              ))}
            </AnimateGroup>
          ) : (
            <EmptyMessageContainer>Your cart is empty</EmptyMessageContainer>
          )}
          {!!cartItems.length && displayPricingOnline && (
            <TotalsContainer>
              <ValueRowContainer>
                <SubvalueTitle>Subtotal</SubvalueTitle>
                <SubvalueAmount>
                  {totals.cartSubtotal.toFixed(2)}
                </SubvalueAmount>
              </ValueRowContainer>
              <ValueRowContainer>
                <SubvalueTitle>* GST</SubvalueTitle>
                <SubvalueAmount>{totals.cartGst.toFixed(2)}</SubvalueAmount>
              </ValueRowContainer>
              <TotalValueRowContainer>
                <TotalTitle>Total</TotalTitle>
                <TotalAmount>${totals.cartTotal.toFixed(2)}</TotalAmount>
              </TotalValueRowContainer>
            </TotalsContainer>
          )}
        </CartItemsContainer>

        {displayGoToCheckoutButton() ? (
          <ButtonContainer>
            <CartDropdownButton onClick={handleCheckoutClick}>
              Go to Checkout
            </CartDropdownButton>
          </ButtonContainer>
        ) : null}
      </CartDropdownContainer>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  showItemModal: selectShowItemModal,
  orderType: selectOrderType,
  shopDetails: selectShopDetails,
  isCartHidden: selectCartHidden,
  wholesaleUserDetails: selectWholesaleUserDetails,
  config: selectConfig,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCartHidden: () => dispatch(toggleCartHidden()),
  setShowItemModal: (show) => dispatch(setShowItemModal(show)),
  resetItemModal: () => dispatch(resetItemModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartDropdown);
