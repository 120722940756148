import React from 'react';

import ShopOpenStatusBar from './shop-open-status-bar.component';

export const ShopOpenStatusBarContainer = ({
  operatingHours,
  displayType,
  config,
  overrideDays,
  wholesaleUserDetails,
  shopDetails,
  handleShopInfoClick,
  handleDeliveryPostcodesFeeInfoClick,
}) => {
  return (
    <ShopOpenStatusBar
      operatingHours={operatingHours}
      displayType={displayType}
      config={config}
      overrideDays={overrideDays}
      wholesaleUserDetails={wholesaleUserDetails}
      shopDetails={shopDetails}
      handleShopInfoClick={handleShopInfoClick}
      handleDeliveryPostcodesFeeInfoClick={handleDeliveryPostcodesFeeInfoClick}
    />
  );
};

export default ShopOpenStatusBarContainer;
