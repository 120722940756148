import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { AnimateGroup } from 'react-animation';
import { FaInfoCircle } from 'react-icons/fa';

import CartItem from '../cart-item/cart-item.container';
import Promotion from '../promotion/promotion.container';
import SaveOrderSwitch from '../save-order-switch/save-order-switch.component';

import {
  selectCartItems,
  selectOrderType,
  selectDeliveryFee,
  selectDeliveryFeeDynamic,
  selectDeliveryFeePostcodes,
  selectBookingFee,
  selectPromotionFee,
  selectPromotion,
} from '../../redux/cart/cart.selectors';

import {
  createUniqueIdentifier,
  calcFinalTotal,
  calcCartTotal,
  calcCartSubtotalAndCartGst,
} from '../../redux/cart/cart.utils';

import {
  setShowDeliveryZonesModal,
  setShowDynamicDeliveryFeeInfoModal,
} from '../../redux/shop/shop.actions';

import {
  DELIVERY,
  DELIVERY_FEE_MODE_DYNAMIC,
  DELIVERY_CHECK_TYPE_RADIUS,
  DELIVERY_CHECK_TYPE_POSTCODES,
} from '../../global.constants';

import {
  CheckoutCartDesktopContainer,
  InnerContainer,
  Title,
  TitleContainer,
  InnerTitleContainer,
  OrderTypeContainer,
  OrderTypeText,
  CartItemsContainer,
  HeaderContainer,
  TotalContainer,
  FeesContainer,
  FeeInfoContainer,
  LeftSideContainer,
  ExtraFeesContainer,
  FeeTitle,
  FeeTotal,
  PromoTotalContainer,
  PromoTitleContainer,
  PromoTitle,
  FinalTotalContainer,
  TotalsContainer,
  ValueRowContainer,
  SubvalueTitle,
  SubvalueAmount,
  TotalValueRowContainer,
  TotalAmount,
  TotalTitle,
  FinalTotalAmount,
  FinalTotalTitle,
  EmptyCartMsg,
} from './checkout-cart-desktop.styles';

export const CheckoutCartDesktop = ({
  cartItems,
  orderType,
  deliveryFee,
  deliveryFeePostcodes,
  deliveryFeeDynamic,
  bookingFee,
  promotionFee,
  promotion,
  config,
  setShowDeliveryZonesModal,
  setShowDynamicDeliveryFeeInfoModal,
}) => {
  const {
    addBookingFee,
    deliveryFeeCalculationType,
    deliveryToAddressCheckType,
    displayPricingOnline,
    enablePromotionCodes,
    saveOrderToSavedOrders,
  } = config;

  const cartScrollRef = createRef();
  const [totals, setTotals] = useState({
    cartSubtotal: 0.0,
    cartGst: 0.0,
    cartTotal: 0.0,
  });
  const [finalTotal, setFinalTotal] = useState(undefined);

  useEffect(() => {
    // whenever a cart item gets added to the cart, automatically
    // scroll the cart to the bottom, showing newly added item
    cartScrollRef.current.scrollTo(0, cartScrollRef.current.scrollHeight);
  }, [cartItems]);

  useEffect(() => {
    const cartTotal = calcCartTotal(cartItems);
    const { cartSubtotal, cartGst } = calcCartSubtotalAndCartGst(cartItems);

    setTotals({
      cartSubtotal,
      cartGst,
      cartTotal,
    });
  }, [cartItems]);

  useEffect(() => {
    // calc the final total to be charged
    let _total = 0.0;

    // default is radius and static mode
    let _deliveryFee = deliveryFee;
    if (
      deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS &&
      deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC
    ) {
      _deliveryFee = deliveryFeeDynamic;
    } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
      _deliveryFee = deliveryFeePostcodes;
    }

    _total = calcFinalTotal(
      totals.cartTotal,
      _deliveryFee /* look */,
      promotionFee,
      bookingFee,
      orderType,
      addBookingFee
    );

    setFinalTotal(_total);
  }, [
    totals,
    deliveryFee,
    deliveryFeePostcodes,
    deliveryFeeDynamic,
    promotionFee,
    bookingFee,
  ]);

  const handleDeliveryPostcodesFeeInfoClick = () => {
    setShowDeliveryZonesModal(true);
  };

  const handleDynamicDeliveryFeeInfoClick = () => {
    setShowDynamicDeliveryFeeInfoModal(true);
  };

  const calcDeliveryFeeUI = () => {
    let _inner = '';

    if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
      _inner = (
        <TotalContainer>
          <LeftSideContainer>
            <FeeTitle>Delivery Fee</FeeTitle>
            <FeeInfoContainer onClick={handleDeliveryPostcodesFeeInfoClick}>
              <FaInfoCircle />
            </FeeInfoContainer>
          </LeftSideContainer>
          <FeeTotal>
            {deliveryFeePostcodes ? deliveryFeePostcodes.toFixed(2) : `0.00`}
          </FeeTotal>
        </TotalContainer>
      );
    } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS) {
      if (deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC) {
        _inner = (
          <TotalContainer>
            <LeftSideContainer>
              <FeeTitle>Delivery Fee</FeeTitle>
              <FeeInfoContainer onClick={handleDynamicDeliveryFeeInfoClick}>
                <FaInfoCircle />
              </FeeInfoContainer>
            </LeftSideContainer>
            <FeeTotal>
              {deliveryFeeDynamic === undefined
                ? 'TBC'
                : deliveryFeeDynamic
                ? deliveryFeeDynamic.toFixed(2)
                : (0.0).toFixed(2)}
            </FeeTotal>
          </TotalContainer>
        );
      } else {
        _inner = (
          <TotalContainer>
            <FeeTitle>Delivery Fee</FeeTitle>
            <FeeTotal>{deliveryFee ? deliveryFee.toFixed(2) : 0.0}</FeeTotal>
          </TotalContainer>
        );
      }
    } else {
      // default to radius static mode for delivery pricing
      _inner = (
        <TotalContainer>
          <FeeTitle>Delivery Fee</FeeTitle>
          <FeeTotal>
            {deliveryFeeDynamic ? deliveryFeeDynamic.toFixed(2) : 0.0}
          </FeeTotal>
        </TotalContainer>
      );
    }

    return _inner;
  };

  return (
    <CheckoutCartDesktopContainer>
      <InnerContainer ref={cartScrollRef}>
        <HeaderContainer>
          <TitleContainer>
            {orderType && (
              <InnerTitleContainer>
                <Title>Order Summary</Title>
                <OrderTypeContainer>
                  <OrderTypeText>{_.capitalize(orderType)}</OrderTypeText>
                </OrderTypeContainer>
              </InnerTitleContainer>
            )}
          </TitleContainer>
        </HeaderContainer>
        <CartItemsContainer>
          {cartItems.length ? (
            <AnimateGroup animate="group">
              {cartItems.map((cartItem) => (
                <CartItem
                  key={createUniqueIdentifier(cartItem)}
                  item={cartItem}
                />
              ))}
            </AnimateGroup>
          ) : (
            <EmptyCartMsg>Your cart is empty</EmptyCartMsg>
          )}
        </CartItemsContainer>
        {displayPricingOnline && (
          <FeesContainer>
            <TotalsContainer>
              <ValueRowContainer>
                <SubvalueTitle>Subtotal</SubvalueTitle>
                <SubvalueAmount>
                  {totals.cartSubtotal.toFixed(2)}
                </SubvalueAmount>
              </ValueRowContainer>
              <ValueRowContainer>
                <SubvalueTitle>GST *</SubvalueTitle>
                <SubvalueAmount>{totals.cartGst.toFixed(2)}</SubvalueAmount>
              </ValueRowContainer>
              <TotalValueRowContainer>
                <TotalTitle>Cart Total</TotalTitle>
                <TotalAmount>{totals.cartTotal.toFixed(2)}</TotalAmount>
              </TotalValueRowContainer>
            </TotalsContainer>

            <ExtraFeesContainer>
              {promotionFee !== undefined ? (
                <PromoTotalContainer>
                  <PromoTitleContainer>
                    <PromoTitle>
                      Promotion {promotion.promo && promotion.promo.title}
                    </PromoTitle>
                  </PromoTitleContainer>
                  <FeeTotal>{promotionFee.toFixed(2)}</FeeTotal>
                </PromoTotalContainer>
              ) : (
                <PromoTotalContainer />
              )}
              {orderType === DELIVERY && calcDeliveryFeeUI()}
              {addBookingFee && bookingFee !== undefined && (
                <TotalContainer>
                  <FeeTitle>Service Charge</FeeTitle>
                  <FeeTotal>{bookingFee.toFixed(2)}</FeeTotal>
                </TotalContainer>
              )}
            </ExtraFeesContainer>
            <FinalTotalContainer>
              <FinalTotalTitle>Total</FinalTotalTitle>
              <FinalTotalAmount>
                ${finalTotal === undefined ? 0.0 : finalTotal.toFixed(2)}
              </FinalTotalAmount>
            </FinalTotalContainer>
          </FeesContainer>
        )}
      </InnerContainer>
      {saveOrderToSavedOrders && <SaveOrderSwitch />}
      {enablePromotionCodes && <Promotion />}
    </CheckoutCartDesktopContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  orderType: selectOrderType,
  deliveryFee: selectDeliveryFee,
  deliveryFeeDynamic: selectDeliveryFeeDynamic,
  deliveryFeePostcodes: selectDeliveryFeePostcodes,
  bookingFee: selectBookingFee,
  promotionFee: selectPromotionFee,
  promotion: selectPromotion,
});

const mapDispatchToProps = (dispatch) => ({
  setShowDeliveryZonesModal: (show) =>
    dispatch(setShowDeliveryZonesModal(show)),
  setShowDynamicDeliveryFeeInfoModal: (show) =>
    dispatch(setShowDynamicDeliveryFeeInfoModal(show)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutCartDesktop);
