import React from 'react';
import _ from 'lodash';

import CollectionItem from './../collection-item/collection-item.component';

import { getItemsOrderForCollection } from '../../utils/shop-utils';

import {
  CollectionContainer,
  TitleContainer,
  Title,
  SubTitle,
  ItemsContainer,
} from './collection.styles';

import {
  RETAIL_ONLY,
  WHOLESALE_ONLY,
  RETAIL_AND_WHOLESALE,
  AVAILABILITY_STATUS__AVAILABLE,
  AVAILABILITY_STATUS__SOLD_OUT,
  AVAILABILITY_STATUS__INVISIBLE,
} from '../../global.constants';

import { calcItemAvailabilityStatus } from '../../utils/shop-utils';

export const Collection = ({
  collection,
  itemsOrder,
  collectionKey,
  shopData,
  collectionRefs,
  wholesaleUserDetails,
  searchValue,
  config,
}) => {
  const { title, subTitle } = collection;
  const itemsOrdering = getItemsOrderForCollection(itemsOrder, collectionKey);

  const searchMatchForItem = (item) => {
    if (!item) return;
    // search term needs to be matched in the following possible locations
    // 1. item title
    // 2. multisize price titles
    // 3. customization (if present) option title

    const { title: itemTitle, prices, customizations } = item;

    const inTitle = itemTitle.toLowerCase().includes(searchValue);

    const priceTitleBools = _.map(prices, (priceVal, sizeTitle) => {
      return sizeTitle.toLowerCase().includes(searchValue);
    });
    const inPriceTitle = !!_.find(priceTitleBools, (aBool) => aBool);

    const itemCusts = _.map(
      customizations,
      (_, custKey) => shopData.customizations[custKey]
    );
    const optionTitlesInEachCust = _.map(itemCusts, (aCust) => {
      return _.reduce(
        aCust.options,
        (accum, _, optionKey) => {
          accum += shopData.options[optionKey].title;
          return accum;
        },
        ''
      );
    });

    const grossOptions = _.reduce(
      optionTitlesInEachCust,
      (accum, aBunch) => {
        accum += aBunch.toLowerCase();
        return accum;
      },
      ''
    );

    const inCusts = grossOptions.includes(searchValue);

    return inTitle || inPriceTitle || inCusts;
  };

  const renderItem = (item, idx) => {
    if (!item) return;

    const { salesOutlets } = item;

    const availabilityStatus = calcItemAvailabilityStatus(item, collection);

    if (availabilityStatus === AVAILABILITY_STATUS__INVISIBLE) return null;

    if (
      availabilityStatus === AVAILABILITY_STATUS__AVAILABLE ||
      availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT
    ) {
      if (!salesOutlets) {
        // if no salesOutlets variable is present then default to showing it
        // for both retail and wholesale outlets.
        // shop hasn't setup wholesale feature, or has but this item hasn't been
        // updated in the whoods app yet
        return (
          <CollectionItem
            key={idx}
            item={item}
            collection={collection}
            shopData={shopData}
            config={config}
            isHearted={false}
            availabilityStatus={availabilityStatus}
          />
        );
      }

      // ...salesOutlets field IS present in item doc

      if (!wholesaleUserDetails) {
        if (
          salesOutlets === RETAIL_ONLY ||
          salesOutlets === RETAIL_AND_WHOLESALE
        ) {
          return (
            <CollectionItem
              key={idx}
              item={item}
              collection={collection}
              shopData={shopData}
              config={config}
              isHearted={false}
              availabilityStatus={availabilityStatus}
            />
          );
        } else {
          // need to return here otherwise we will fall thru to next if statement
          // and subsequently display WHOLESALE_ONLY items when not logged in!
          return null;
        }
      }

      // ...wholesaleUserDetails is NOT null...
      if (
        salesOutlets === WHOLESALE_ONLY ||
        salesOutlets === RETAIL_AND_WHOLESALE
      ) {
        return (
          <CollectionItem
            key={idx}
            item={item}
            collection={collection}
            shopData={shopData}
            config={config}
            isHearted={false}
            availabilityStatus={availabilityStatus}
          />
        );
      }
    }
  };

  return (
    <CollectionContainer ref={collectionRefs[collectionKey]}>
      <TitleContainer>
        <Title>{title}</Title>
        {!!subTitle && <SubTitle>{subTitle}</SubTitle>}
      </TitleContainer>
      <ItemsContainer>
        {_.map(itemsOrdering, (itemKey, idx) => {
          const item = shopData.items[itemKey];
          if (searchValue && !searchMatchForItem(item)) {
            return;
          }

          return renderItem(item, idx);
        })}
      </ItemsContainer>
    </CollectionContainer>
  );
};

export default Collection;
