import React from 'react';

import plus from '../../assets/plus.svg';
import minus from '../../assets/minus.svg';
import close from '../../assets/close.svg';
import _ from 'lodash';

import Customizations from '../customizations/customizations.component';
import ItemPrices from '../item-prices/item-prices.container';

import { calcItemAvailabilityStatus } from '../../utils/shop-utils';

import {
  ItemMobileContentsContainer,
  CloseButtonAction,
  HeaderContainer,
  ItemImageContainer,
  ImageContainer,
  ItemImage,
  TitleContainer,
  Title,
  Price,
  Description,
  ChangeItemCountContainer,
  QuantityContainer,
  ButtonContainer,
  OuterSoldOutContainer,
  SoldOutContainer,
  SoldOutText,
  SubmitButton,
  CurvedLeftBorder,
  CurvedRightBorder,
  SubtractButtonAction,
  AddButtonAction,
  ButtonImg,
  Quantity,
  CommitContainer,
  CustomizationsContainer,
  UserInstructionsContainer,
  UserInstructions,
  GstContainer,
  GstText,
} from './item-mobile.styles';

import {
  IMAGE_MOBILE_FORMATTING,
  ADD,
  SUBTRACT,
  GST_EXCLUDED,
  AVAILABILITY_STATUS__SOLD_OUT,
} from '../../global.constants';

export const ItemMobile = ({
  item,
  sortedPrices,
  selectedPrice,
  setSelectedPrice,
  handleClose,
  handleItemChangeClick,
  handleSubmission,
  shopData,
  userInstructions,
  handleUserInstructionsChange,
  shopDetails,
  config,
  computeDisplayPrice,
  computeButtonText,
  calcGstBlurb,
  calculateItemPrice,
  isUpdatingItem,
  collection,
}) => {
  const availabilityStatus = calcItemAvailabilityStatus(item, collection);
  const { imageProviderApi } = shopDetails;
  const { disableItemUserInstructions } = config;
  const {
    customizations,
    customizationsOrder,
    description,
    gstSetting,
    imagePath,
    quantity,
    title,
  } = item;

  return (
    <ItemMobileContentsContainer>
      <ItemImageContainer>
        {!!imagePath && (
          <ImageContainer>
            <ItemImage
              src={`${imageProviderApi}/${imagePath}?${IMAGE_MOBILE_FORMATTING}`}
            />
          </ImageContainer>
        )}
      </ItemImageContainer>
      <HeaderContainer>
        <CloseButtonAction onClick={handleClose}>
          <ButtonImg src={close} />
        </CloseButtonAction>
        <TitleContainer>
          <Price>{computeDisplayPrice(sortedPrices)}</Price>
        </TitleContainer>
      </HeaderContainer>
      <Title haveImage={!!imagePath}>{item && title}</Title>
      <Description>{item && description}</Description>
      {_.size(sortedPrices) <= 1 ? null : (
        <ItemPrices
          sortedPrices={sortedPrices}
          selectedPrice={selectedPrice}
          setSelectedPrice={setSelectedPrice}
        />
      )}
      <CustomizationsContainer>
        <Customizations
          customizations={customizations}
          customizationsOrder={customizationsOrder}
          shopData={shopData}
        />
      </CustomizationsContainer>
      {!disableItemUserInstructions && (
        <UserInstructionsContainer>
          <UserInstructions
            placeholder="Comments/Notes"
            value={userInstructions}
            onChange={handleUserInstructionsChange}
          ></UserInstructions>
        </UserInstructionsContainer>
      )}
      {calculateItemPrice() && gstSetting && gstSetting === GST_EXCLUDED ? (
        <GstContainer>
          <GstText>{calcGstBlurb()}</GstText>
        </GstContainer>
      ) : null}
      <CommitContainer>
        {availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT &&
        !isUpdatingItem ? (
          <ButtonContainer availabilityStatus={availabilityStatus}>
            <OuterSoldOutContainer>
              <SoldOutContainer>
                <SoldOutText>Sold Out</SoldOutText>
              </SoldOutContainer>
            </OuterSoldOutContainer>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <ChangeItemCountContainer>
              <CurvedLeftBorder>
                <SubtractButtonAction
                  onClick={() =>
                    item.quantity === 1 ? null : handleItemChangeClick(SUBTRACT)
                  }
                >
                  <ButtonImg src={minus} />
                </SubtractButtonAction>
              </CurvedLeftBorder>
              <QuantityContainer>
                <Quantity>{quantity}</Quantity>
              </QuantityContainer>
              <CurvedRightBorder>
                <AddButtonAction onClick={() => handleItemChangeClick(ADD)}>
                  <ButtonImg src={plus} />
                </AddButtonAction>
              </CurvedRightBorder>
            </ChangeItemCountContainer>
            <SubmitButton onClick={handleSubmission}>
              {computeButtonText()}
            </SubmitButton>
          </ButtonContainer>
        )}
      </CommitContainer>
    </ItemMobileContentsContainer>
  );
};

export default ItemMobile;
