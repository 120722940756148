import HeartedItemsTypes from './hearted-items.types';

export const toggleItemIsHearted = (item) => ({
  type: HeartedItemsTypes.TOGGLE_ITEM_IS_HEARTED,
  payload: {
    item,
  },
});

export const setAllHeartedItems = (heartedItems) => ({
  type: HeartedItemsTypes.SET_ALL_HEARTED_ITEMS,
  payload: {
    heartedItems,
  },
});
