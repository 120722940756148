import React from 'react';
import _ from 'lodash';

import {
  Container,
  HeaderContainer,
  HeaderTitle,
  InfoContainer,
  Info,
  InfoRequired,
  ContentContainer,
  SizeButton,
} from './item-prices.styles';

export const ItemPrices = ({
  sortedPrices,
  selectedPrice,
  setSelectedPrice,
}) => {
  const calcButtonText = (priceDetails) => {
    const { size, price } = priceDetails;
    if (!price) return _.capitalize(size);
    return `${_.capitalize(size)} $${price}`;
  };

  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>Item Sizes</HeaderTitle>
        <InfoContainer>
          <Info>Pick one</Info>
          <InfoRequired>(Required)</InfoRequired>
        </InfoContainer>
      </HeaderContainer>
      <ContentContainer>
        {_.map(sortedPrices, (priceDetails) => (
          <SizeButton
            key={priceDetails.size}
            onClick={() => {
              setSelectedPrice(priceDetails);
            }}
            isSelected={selectedPrice.size === priceDetails.size}
          >
            {calcButtonText(priceDetails)}
          </SizeButton>
        ))}
      </ContentContainer>
    </Container>
  );
};

export default ItemPrices;
