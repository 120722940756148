import styled from 'styled-components';

import { darkerGray, lightTextGray, black } from '../../style-constants';

export const Container = styled.div`
  border-top: 2px solid ${darkerGray};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
`;
Container.displayName = 'Container';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const Quantity = styled.div`
  padding-right: 5px;
`;
Quantity.displayName = 'Quantity';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 2;
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  font-weight: 900;
`;
Title.displayName = 'Title';

export const CustomizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0px 0px 0px;
`;
CustomizationsContainer.displayName = 'CustomizationsContainer';

export const Total = styled.div`
  font-size: 14px;
  padding-left: 5px;
  white-space: nowrap;
`;
Total.displayName = 'Total';

export const CartItemClear = styled.div`
  align-items: center;
  display: ${({ orderIsPaid }) => (orderIsPaid ? 'none' : 'flex')};
  /* display: ${({ orderIsPaid }) => (orderIsPaid ? 'none' : 'initial')}; */
  flex-direction: row;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
  }
`;
CartItemClear.displayName = 'CartItemClear';

export const CartItemEdit = styled.div`
  align-items: center;
  cursor: ${({ orderIsPaid }) => (orderIsPaid ? 'auto' : 'pointer')};
  display: ${({ orderIsPaid }) => (orderIsPaid ? 'none' : 'flex')};
  /* display: ${({ orderIsPaid }) => (orderIsPaid ? 'none' : 'initial')}; */
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 20px;
`;
CartItemEdit.displayName = 'CartItemEdit';

export const SubText = styled.div`
  color: ${black};
  font-size: 12px;
  padding-left: 5px;
`;
SubText.displayName = 'SubText';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
`;
ActionsContainer.displayName = 'ActionsContainer';

export const UserInstructionsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  margin-bottom: 5px;
  padding-left: 2px;
`;
UserInstructionsContainer.displayName = 'UserInstructionsContainer';

export const UserInstructionsTitle = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
  font-weight: 900;
`;
UserInstructionsTitle.displayName = 'UserInstructionsTitle';

export const UserInstructions = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
  margin-left: 3px;
`;
UserInstructions.displayName = 'UserInstructions';
