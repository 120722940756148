import { combineReducers } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cartReducer from './cart/cart.reducer';
import shopReducer from './shop/shop.reducer';
import userReducer from './user/user.reducer';
import configReducer from './config/config.reducer';
import heartedItemsReducer from './hearted-items/hearted-items.reducer';
import qrCodesReducer from './qr-codes/qr-codes.reducer';

import { DEFAULT_USER_CREDENTIALS_INITIAL_STATE } from './user/user.reducer';

const migration = {
  0: (previousVersionState) => {
    return {
      ...previousVersionState,
      user: {
        ...previousVersionState.user,
        paymentProviderDetails: {
          customerId: null,
          paymentMethods: [],
        },
      },
    };
  },
  1: (previousVersionState) => {
    return {
      ...previousVersionState,
      cart: {
        ...previousVersionState.cart,
        deliveryFeePostCodes: undefined,
      },
    };
  },
  2: (previousVersionState) => {
    return {
      ...previousVersionState,
      user: {
        ...previousVersionState.user,
        userCredentials: DEFAULT_USER_CREDENTIALS_INITIAL_STATE,
      },
    };
  },
  3: (previousVersionState) => {
    return {
      ...previousVersionState,
      cart: {
        ...previousVersionState.cart,
        deliveryFeeDynamic: undefined,
      },
    };
  },
  4: (previousVersionState) => {
    return {
      ...previousVersionState,
      cart: {
        ...previousVersionState.cart,
        savedOrders: [],
        shouldSaveOrder: false,
        previouslyUsedPromotions: [],
      },
      user: {
        ...previousVersionState.user,
        keepUserCredentialsForCheckout: false,
      },
    };
  },
};

// we dont persist shop data or config: if network fails during page load
// then user will be stuck with incomplete shop data/config
// ...an inoperable app
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'user', 'heartedItems'],
  version: 4,
  migrate: createMigrate(migration, { debug: false }),
};

const rootReducer = combineReducers({
  cart: cartReducer,
  shop: shopReducer,
  user: userReducer,
  config: configReducer,
  heartedItems: heartedItemsReducer,
  qrCodes: qrCodesReducer,
});

export default persistReducer(persistConfig, rootReducer);
