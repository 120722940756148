import React from 'react';

import ItemPrices from './item-prices.component';

export const ItemPricesContainer = ({
  sortedPrices,
  selectedPrice,
  setSelectedPrice,
}) => {
  return (
    <ItemPrices
      sortedPrices={sortedPrices}
      selectedPrice={selectedPrice}
      setSelectedPrice={setSelectedPrice}
    />
  );
};

export default ItemPricesContainer;
