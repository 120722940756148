import styled from 'styled-components';

import { lightGray, lightBlue, darkBlue, red } from '../../style-constants';

export const Container = styled.div`
  align-items: stretch;
  background-color: ${lightGray};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem;
  padding: 0.5rem;
`;
Container.displayName = 'Container';

export const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const HeaderTitle = styled.h3`
  margin: 5px;
`;
HeaderTitle.displayName = 'HeaderTitle';

export const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  margin: 5px;
`;
InfoContainer.displayName = 'InfoContainer';

export const Info = styled.div``;
Info.displayName = 'Info';

export const InfoRequired = styled.div`
  color: ${red};
  margin-left: 5px;
`;
InfoRequired.displayName = 'InfoRequired';

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
ContentContainer.displayName = 'ContentContainer';

export const SizeButton = styled.button`
  background-color: ${({ isSelected }) => (isSelected ? lightBlue : 'white')};
  border: 1px solid ${({ isSelected }) => (isSelected ? lightBlue : lightGray)};
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  font-family: 'Cabin';
  margin: 2px;
  padding: 10px;

  -webkit-tap-highlight-color: transparent;

  &:hover {
    /* background-color: ${({ isSelected }) =>
      isSelected ? darkBlue : lightGray};
    border: 1px solid ${lightGray};
    transition: background-color 400ms cubic-bezier(0.4, 0, 0.4, 1);
    transition: border 400ms cubic-bezier(0.4, 0, 0.4, 1); */
  }

  &:active {
    /* background-color: ${({ isSelected }) =>
      isSelected ? lightGray : darkBlue};
    transition: background-color 100ms cubic-bezier(0.4, 0, 0.4, 1); */
  }

  &:focus {
    outline: 0;
  }
`;
SizeButton.displayName = 'SizeButton';
