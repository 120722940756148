import styled from 'styled-components';

import TemplateButton from '../template-button/template-button.component';

import {
  veryLightGray,
  mobileSplitPoint,
  black,
  lightGray,
  orange,
  red,
  white,
} from '../../style-constants';

export const OuterContainer = styled.div`
  width: 40%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: none;
  }
`;
OuterContainer.displayName = 'OuterContainer';

export const CartDesktopContainer = styled.div`
  background-color: ${lightGray};
  box-shadow: rgba(0, 0, 0, 0.54) 0px 3px 8px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: none;
    margin-top: unset;
  }
`;
CartDesktopContainer.displayName = 'CartDesktopContainer';

export const InnerContainer = styled.div`
  /*
  overflow: auto;
  max-height: 28vh; */
  padding-bottom: 15px;
  border-bottom: 2px solid ${black};
`;
InnerContainer.displayName = 'InnerContainer';

export const Container = styled.div`
  background-color: ${lightGray};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  width: 100%;
`;
Container.displayName = 'Container';

export const CartItemsContainer = styled.div`
  margin: 8px 0px;
`;
CartItemsContainer.displayName = 'CartItemsContainer';

export const TotalsContainer = styled.div`
  align-items: stretch;
  background-color: ${lightGray};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 20px;
`;
TotalsContainer.displayName = 'TotalsContainer';

export const ValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
ValueRowContainer.displayName = 'ValueRowContainer';

export const SubvalueTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
`;
SubvalueTitle.displayName = 'SubvalueTitle';

export const SubvalueAmount = styled.div`
  font-weight: 500;
  font-size: 14px;
`;
SubvalueAmount.displayName = 'SubvalueAmount';

export const TotalValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;
`;
TotalValueRowContainer.displayName = 'TotalValueRowContainer';

export const TotalTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
`;
TotalTitle.displayName = 'TotalTitle';

export const TotalAmount = styled.div`
  font-weight: 700;
  font-size: 20px;
`;
TotalAmount.displayName = 'TotalAmount';

export const SubmitButton = styled(TemplateButton)`
  align-items: center;
  background-color: ${red};
  border: 1px solid red;
  border-radius-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-bottom: 5px; */
`;
SubmitButton.displayName = 'SubmitButton';

export const SubmitButtonText = styled.div`
  color: ${white};
  font-size: 18px;
  font-weight: 600;
  padding-right: 10px;
`;
SubmitButtonText.displayName = 'SubmitButtonText';

export const EmptyCartMsg = styled.div`
  padding: 30px;
  text-align: center;
`;
EmptyCartMsg.displayName = 'EmptyCartMsg';
