import { firestore } from '../firebase/firebase.utils';

export const fetchCollection = async (collectionName) => {
  let collection = {};
  try {
    const ref = firestore.collection(collectionName);
    const snapshot = await ref.get();
    snapshot.forEach((doc) => {
      const docData = doc.data();
      const key = doc.id;
      collection[key] = {
        ...docData,
        key,
      };
    });
    return collection;
  } catch (error) {
    console.log(error);
    return collection;
  }
};

export const setIsHearted = async (isHearted, item) => {
  const { key } = item;
  const ref = firestore.collection('items').doc(key);
  await ref.update({
    isHearted,
  });
};

export const fetchOrdersThrottlingDataForDay = async (day) => {
  // day is like 'Mon' | 'Tue' | 'Wed' | etc etc
  const ref = firestore.collection('ordersThrottling');
  const query = ref.where('day', '==', day);
  const querySnapshot = await query.get();
  let data = {};
  querySnapshot.forEach((doc) => {
    data = {
      ...data,
      [doc.id]: {
        key: doc.id,
        ...doc.data(),
      },
    };
  });
  return data;
};
