import styled from 'styled-components';

import { white, mobileSplitPoint } from '../../style-constants';

export const ContactContainer = styled.div`
  background-color: ${white};
  padding: 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    flex-grow: 2;
    padding: 10px;
    width: 100%;
  }
`;
ContactContainer.displayName = 'ContactContainer';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  padding: 15px 0px;
`;
Title.displayName = 'Title';

export const Row = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5px;
`;
Row.displayName = 'Row';

export const Link = styled.a`
  margin-left: 10px;
`;
Link.displayName = 'Link';

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
AddressContainer.displayName = 'AddressContainer';

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
IconContainer.displayName = 'IconContainer';
