import styled from 'styled-components';

import {
  white,
  darkerGray,
  collectionSplitPoint,
  mobileSplitPoint,
  red,
  redTextDark,
  redBackgroundLight,
  redBackgroundVeryLightTransparent,
} from '../../style-constants';

export const CollectionContainer = styled.div`
  border-bottom: 4px solid ${redBackgroundLight};
  border-top: 4px solid ${redBackgroundLight};
  background-color: ${redBackgroundVeryLightTransparent};
  margin-bottom: 15px;
  padding: 15px 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
CollectionContainer.displayName = 'CollectionContainer';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    margin-bottom: 20px;
  }
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  /* border-bottom: 1px solid ${darkerGray}; */
  color: ${redTextDark};
  font-size: 26px;
  margin-bottom: 5px;
  padding-right: 2px;
  padding-left: 1px;
  text-align: center;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 22px;
    padding-left: 5px;
  }
`;
Title.displayName = 'Title';

export const SubTitle = styled.div`
  color: ${redTextDark};
  padding-bottom: 10px;
  padding-left: 2px;
  text-align: center;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding-left: 5px;
  }
`;
SubTitle.displayName = 'SubTitle';

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: ${collectionSplitPoint}) {
    align-items: center;
    flex-direction: column;
    flex-wrap: unset;
    justify-content: flex-start;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    flex-direction: column;
    flex-wrap: unset;
    justify-content: flex-start;
  }
`;
ItemsContainer.displayName = 'ItemsContainer';
