import styled from 'styled-components';

import {
  mobileSplitPoint,
  darkerGray,
  lightTextGray,
  black,
} from '../../style-constants';

export const CheckoutItemContainer = styled.div`
  width: 100%;
  display: flex;
  /* min-height: 100px; */
  border-bottom: 2px solid ${darkerGray};
  padding: 5px 0;
  font-size: 20px;
  align-items: center;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 17px;
  }
`;
CheckoutItemContainer.displayName = 'CheckoutItemContainer';

export const ImageContainer = styled.div`
  width: 23%;
  padding-right: 15px;

  img {
    width: 100%;
    height: 100%;
  }
`;
ImageContainer.displayName = 'ImageContainer';

export const TotalText = styled.span`
  width: 23%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 22%;
  }
`;
TotalText.displayName = 'TotalText';

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 46%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 44%;
  }
`;
ItemContainer.displayName = 'ItemContainer';

export const Title = styled.h4`
  margin: 2px;
`;
Title.displayName = 'Title';

export const ItemCustomizationsContainer = styled.div`
  color: ${lightTextGray};
`;
ItemCustomizationsContainer.displayName = 'ItemCustomizationsContainer';

export const QuantityContainer = styled(TotalText)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  div {
    cursor: pointer;
  }

  span {
    margin: 0 10px;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 22%;
    margin-left: 5px;
  }
`;
QuantityContainer.displayName = 'QuantityContainer';

export const ClearButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* width: 46%; */
`;
ClearButtonContainer.displayName = 'ClearButtonContainer';

export const ClearButton = styled.div`
  padding-left: 12px;
  cursor: pointer;
`;
ClearButton.displayName = 'ClearButton';

export const OptionTypeContainer = styled.div`
  font-size: 14px;
`;

export const OptionsContainer = styled.div`
  font-size: 10px;
`;
OptionsContainer.displayName = 'OptionsContainer';

export const CheckoutItemEdit = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  margin-top: 8px;
  margin-left: 2px;
  padding: 5px 0px;
`;
CheckoutItemEdit.displayName = 'CheckoutItemEdit';

export const SubText = styled.div`
  color: ${black};
  font-size: 12px;
  padding-left: 5px;
`;
SubText.displayName = 'SubText';

export const RemoveFromCart = styled.div``;
RemoveFromCart.displayName = 'RemoveFromCart';

export const AddToCart = styled.div``;
AddToCart.displayName = 'AddToCart';

export const UserInstructionsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
`;
UserInstructionsContainer.displayName = 'UserInstructionsContainer';

export const UserInstructionsTitle = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
  margin-left: 2px;
  font-weight: 900;
`;
UserInstructionsTitle.displayName = 'UserInstructionsTitle';

export const UserInstructions = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
  margin-left: 3px;
`;
UserInstructions.displayName = 'UserInstructions';
