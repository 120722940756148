import UserActionTypes from './user.types';

export const addUserDetails = (userDetails) => ({
  type: UserActionTypes.ADD_USER_DETAILS,
  payload: {
    userDetails,
  },
});

export const loginWholesaleUserStart = (loginDetails, config) => ({
  type: UserActionTypes.LOGIN_WHOLESALE_USER_START,
  payload: {
    loginDetails,
    config,
  },
});

export const loginWholesaleUserSuccess = (wholesaleUserDetails) => ({
  type: UserActionTypes.LOGIN_WHOLESALE_USER_SUCCESS,
  payload: {
    wholesaleUserDetails,
  },
});

export const loginWholesaleUserFailure = (errorMessage) => ({
  type: UserActionTypes.LOGIN_WHOLESALE_USER_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setIsWholesaleDropdownHidden = (isHidden) => ({
  type: UserActionTypes.SET_WHOLESALE_DROPDOWN_IS_HIDDEN,
  payload: {
    isHidden,
  },
});

export const logoutWholesaleUserStart = (config, navigate) => ({
  type: UserActionTypes.LOGOUT_WHOLESALE_USER_START,
  payload: {
    config,
    navigate,
  },
});

export const logoutWholesaleUserSuccess = () => ({
  type: UserActionTypes.LOGOUT_WHOLESALE_USER_SUCCESS,
});

export const logoutWholesaleUserFailure = (errorMessage) => ({
  type: UserActionTypes.LOGOUT_WHOLESALE_USER_FAILURE,
  payload: {
    errorMessage,
  },
});

export const checkWholesaleUserSession = () => ({
  type: UserActionTypes.CHECK_WHOLESALE_USER_SESSION,
});

export const updateWholesaleUserDetails = (someDetails) => ({
  type: UserActionTypes.UPDATE_WHOLESALE_USER_DETAILS,
  payload: {
    someDetails,
  },
});

export const fetchPaymentMethodsForCustomerIdStart = (
  customerId,
  shopDetails
) => ({
  type: UserActionTypes.FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_START,
  payload: {
    customerId,
    shopDetails,
  },
});

export const fetchPaymentMethodsForCustomerIdSuccess = (paymentMethods) => ({
  type: UserActionTypes.FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_SUCCESS,
  payload: {
    paymentMethods,
  },
});

export const fetchPaymentMethodsForCustomerIdFailure = (errorMessage) => ({
  type: UserActionTypes.FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setStripeCustomerId = (customerId) => ({
  type: UserActionTypes.SET_STRIPE_CUSTOMER_ID,
  payload: {
    customerId,
  },
});

export const sendPasswordResetEmailStart = (email) => ({
  type: UserActionTypes.SEND_PASSWORD_RESET_EMAIL_START,
  payload: {
    email,
  },
});

export const sendPasswordResetEmailSuccess = () => ({
  type: UserActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
});

export const sendPasswordResetEmailFailure = (errorMessage) => ({
  type: UserActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE,
  payload: {
    errorMessage,
  },
});

export const resetErrorAndLoadingState = () => ({
  type: UserActionTypes.RESET_ERROR_AND_LOADING_STATE,
});

export const detachPaymentMethodStart = (paymentMethodId, shopDetails) => ({
  type: UserActionTypes.DETACH_PAYMENT_METHOD_START,
  payload: {
    paymentMethodId,
    shopDetails,
  },
});

export const detachPaymentMethodSuccess = (paymentMethodId) => ({
  type: UserActionTypes.DETACH_PAYMENT_METHOD_SUCCESS,
  payload: {
    paymentMethodId,
  },
});

export const detachPaymentMethodFailure = (errorMessage) => ({
  type: UserActionTypes.DETACH_PAYMENT_METHOD_FAILURE,
  payload: {
    errorMessage,
  },
});

export const unsubscribeCustomerStart = (customerDetails, shopDetails) => ({
  type: UserActionTypes.UNSUBSCRIBE_CUSTOMER_START,
  payload: {
    customerDetails,
    shopDetails,
  },
});

export const unsubscribeCustomerSuccess = (msg) => ({
  type: UserActionTypes.UNSUBSCRIBE_CUSTOMER_SUCCESS,
  payload: {
    msg,
  },
});

export const unsubscribeCustomerFailure = (errorMessage) => ({
  type: UserActionTypes.UNSUBSCRIBE_CUSTOMER_FAILURE,
  payload: {
    errorMessage,
  },
});

export const resetUnsubscribeMsg = () => ({
  type: UserActionTypes.RESET_UNSUBSCRIBE_MSG,
});

export const fetchWholesaleUserDocStart = (wholesaleUserDetails) => ({
  type: UserActionTypes.FETCH_WHOLESALE_USER_DOC_START,
  payload: {
    wholesaleUserDetails,
  },
});

export const fetchWholesaleUserDocSuccess = (doc) => ({
  type: UserActionTypes.FETCH_WHOLESALE_USER_DOC_SUCCESS,
  payload: {
    doc,
  },
});

export const fetchWholesaleUserDocFailure = (errorMessage) => ({
  type: UserActionTypes.FETCH_WHOLESALE_USER_DOC_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setUserCredentials = (userCredentials) => ({
  type: UserActionTypes.SET_USER_CREDENTIALS,
  payload: {
    userCredentials,
  },
});

export const resetUserCredentials = (config) => ({
  type: UserActionTypes.RESET_USER_CREDENTIALS,
  payload: { config },
});

export const setKeepUserCredentialsForCheckout = (shouldKeep) => ({
  type: UserActionTypes.SET_KEEP_USER_CREDENTIALS_FOR_CHECKOUT,
  payload: {
    shouldKeep,
  },
});
