import React from 'react';
import close from '../../assets/close.svg';
import _ from 'lodash';
import BounceLoader from 'react-spinners/BounceLoader';
import { FiTrash2 } from 'react-icons/fi';

import {
  PromoContainer,
  PromoTitleContainer,
  PromoTitle,
  PromoBody,
  PromoInput,
  PromoActionContainer,
  PromoAction,
  SpinnerContainer,
  CloseButtonAction,
  ButtonImg,
  PreviouslyUsedPromotionsContainer,
  OuterContainer,
  PreviouslyUsedPromotionContainer,
  PreviouslyUsedPromotionText,
  RemoveButton,
  SubTitle,
} from './promotion.styles';

import { darkerGray, black } from '../../style-constants';

export const Promotion = ({
  isLoadingPromo,
  promotion,
  promoCode,
  handleClosePromoResult,
  handlePromoCodeChange,
  handlePromoCodeSubmit,
  previouslyUsedPromotions,
  handlePressPreviouslyUsedPromotion,
  handleRemovePromotionClick,
}) => {
  // console.log('IN promotion component');
  // console.log({ promotion });
  return (
    <PromoContainer>
      <PromoTitleContainer>
        <PromoTitle>Promotion</PromoTitle>
      </PromoTitleContainer>
      <PromoBody>
        <PromoInput
          name="promoCode"
          placeholder={'Promo code'}
          type="text"
          value={promoCode}
          onChange={handlePromoCodeChange}
          label="Promo Code"
        />

        {!_.isEmpty(promotion) && (
          <CloseButtonAction
            onClick={handleClosePromoResult}
            isValid={promotion.isPromoValid}
          >
            <ButtonImg src={close} />
          </CloseButtonAction>
        )}
        {isLoadingPromo && (
          <SpinnerContainer>
            <BounceLoader
              color={darkerGray}
              size={20}
              loading={isLoadingPromo}
            />
          </SpinnerContainer>
        )}
        <PromoActionContainer>
          <PromoAction onClick={handlePromoCodeSubmit}>Apply</PromoAction>
        </PromoActionContainer>
      </PromoBody>
      {previouslyUsedPromotions && !_.isEmpty(previouslyUsedPromotions) && (
        <SubTitle>Previously Used Promotions</SubTitle>
      )}
      <PreviouslyUsedPromotionsContainer>
        {previouslyUsedPromotions &&
          _.map(previouslyUsedPromotions, (promotion, idx) => (
            <OuterContainer>
              <PreviouslyUsedPromotionContainer
                key={idx}
                onClick={() => handlePressPreviouslyUsedPromotion(promotion)}
              >
                <PreviouslyUsedPromotionText>
                  {promotion.promoCode}
                </PreviouslyUsedPromotionText>
              </PreviouslyUsedPromotionContainer>
              <RemoveButton
                onClick={() => handleRemovePromotionClick(promotion)}
              >
                <FiTrash2 style={{ color: black }} />
              </RemoveButton>
            </OuterContainer>
          ))}
      </PreviouslyUsedPromotionsContainer>
    </PromoContainer>
  );
};

export default Promotion;
