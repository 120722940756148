import { all, call } from 'redux-saga/effects';

import { shopSagas } from './shop/shop.sagas';
import { cartSagas } from './cart/cart.sagas';
import { configSagas } from './config/config.sagas';
import { userSagas } from './user/user.sagas';
import { qrCodesSagas } from './qr-codes/qr-codes.sagas';

export default function* rootSaga() {
  yield all([
    call(shopSagas),
    call(cartSagas),
    call(configSagas),
    call(userSagas),
    call(qrCodesSagas),
  ]);
}
