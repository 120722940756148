import { createGlobalStyle } from 'styled-components';
import { mobileSplitPoint } from './style-constants';

export const GlobalStyle = createGlobalStyle`
  html {
  }
  body {
    background-color: #fff;
    color: #354b5d;
    font-family: 'Cabin', sans-serif;
    margin: 0px;
    
    @media screen and (max-width: ${mobileSplitPoint}) {
      margin: 0px;
    }
  }

  a {
    text-decoration: none;
    color: black;
  }

  * {
    box-sizing: border-box;
  }
`;
