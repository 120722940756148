import styled from 'styled-components';
import { MdArrowBack } from 'react-icons/md';
import Switch from 'react-switch';

import {
  collectionSplitPoint,
  mobileSplitPoint,
  white,
  lightBlue,
  pink,
  orange,
  darkerGray,
  black,
  veryLightGray,
  lightTextGray,
  red,
  redBackgroundLight,
  redBackgroundVeryLightTransparent,
  redTextDark,
} from '../../style-constants';

export const CheckoutPageContainer = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  padding: 0px 85px 150px 85px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
    margin: unset;
    margin-top: 70px;
    padding: 0px 15px 250px 15px;
  }
`;
CheckoutPageContainer.displayName = 'CheckoutPageContainer';

export const CheckoutContentsContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${veryLightGray};
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 25px;
  }
`;
CheckoutContentsContainer.displayName = 'CheckoutContentsContainer';

export const BackButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 30px;
  margin-top: 87px;
  width: 70%;

  -webkit-tap-highlight-color: transparent;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: unset;
  }
`;
BackButtonContainer.displayName = 'BackButtonContainer';

export const BackContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 3px 5px;
`;
BackContainer.displayName = 'BackContainer';

export const BackIcon = styled(MdArrowBack)`
  width: 25px;
  height: 25px;
`;
BackIcon.displayName = 'BackIcon';

export const BackText = styled.div`
  border-bottom: 2px solid ${black};
  font-size: 20px;
  font-weight: 700;
  margin-left: 5px;
`;
BackText.displayName = 'BackText';

export const CheckoutHeaderContainer = styled.div`
  border-bottom: 2px solid ${darkerGray};
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
`;
CheckoutHeaderContainer.displayName = 'CheckoutHeaderContainer';

export const HeaderBlockContainer = styled.div`
  text-transform: capitalize;
  width: 23%;

  &:last-child {
    width: 8%;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 18%;

    &:first-child {
      width: 41%;
    }

    &:last-child {
      margin-right: 5px;
      text-align: center;
      width: 16%;
    }
  }
`;
HeaderBlockContainer.displayName = 'HeaderBockContainer';

export const PriceHeader = styled.span`
  margin-left: 10px;
`;
PriceHeader.displayName = 'PriceHeader';

export const AllFeesContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
  width: 100%;
`;
AllFeesContainer.displayName = 'AllFeesContainer';

export const RHSContainer = styled.div`
  width: 30%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
  }
`;
RHSContainer.displayName = 'RHSContainer';

export const TotalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  padding-top: 15px;
  width: 30%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
  }
`;
TotalContainer.displayName = 'TotalContainer';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  width: 70%;
  position: relative;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 60px;
  }
`;
Container.displayName = 'Container';

export const PromotionContainer = styled.div`
  display: none;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
PromotionContainer.displayName = 'PromotionContainer';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0px 30px 0px;
`;
TitleContainer.displayName = 'TitleContainer';

export const InnerTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
InnerTitleContainer.displayName = 'InnerTitleContainer';

export const OrderTypeContainer = styled.div`
  align-items: center;
  background-color: ${black};
  border: 2px solid ${black};
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 25px;
  position: absolute;
  top: 115px;
  right: 10%;
`;
OrderTypeContainer.displayName = 'OrderTypeContainer';

export const OrderTypeText = styled.div`
  color: ${white};
  font-weight: 700;
  font-size: 20px;
  padding: 5px 10px;
`;
OrderTypeText.displayName = 'OrderTypeText';

export const CheckoutItemsContainer = styled.div``;
CheckoutItemsContainer.displayName = 'CheckoutItemsContainer';

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
Title.displayName = 'Title';

export const COVIDContainer = styled.div`
  background-color: ${lightBlue};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 30px;
  padding: 15px;
  width: 68%;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
    margin: 15px;
  }
`;
COVIDContainer.displayName = 'COVIDContainer';

export const COVIDHeading = styled.div`
  font-size: 22px;
`;
COVIDHeading.displayName = 'COVIDHeading';

export const COVIDLine = styled.div`
  padding-top: 15px;
`;
COVIDLine.displayName = 'COVIDLine';

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
Row.displayName = 'Row';

export const PromotionRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 30px;
  margin-bottom: 5px;
  width: 100%;
`;
PromotionRow.displayName = 'PromotionRow';

export const PromotionFeeTitle = styled.div`
  background-color: ${orange};
  border-radius: 15px;
  font-size: 12px;
  padding: 3px 5px;
`;
PromotionFeeTitle.displayName = 'PromotionFeeTitle';

export const FeeTitleContainer = styled.div`
  font-size: 13px;
  font-weight: 900;
  padding-left: 5px;
`;
FeeTitleContainer.displayName = 'FeeTitleContainer';

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
LeftSideContainer.displayName = 'LeftSideContainer';

export const FeePrice = styled.div`
  font-size: 13px;
  font-weight: 900;
`;
FeePrice.displayName = 'FeePrice';

export const FeeInfoContainer = styled.div`
  align-items: center;
  color: ${lightTextGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
`;
FeeInfoContainer.displayName = 'FeeInfoContainer';

export const OrderingInfoContainer = styled.div`
  background-color: ${pink};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 15px;
  padding: 15px;
  width: 68%;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 100%;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
    margin: 0px 15px 15px 15px;
  }
`;
OrderingInfoContainer.displayName = 'OrderingInfoContainer';

export const OrderingInfoHeading = styled.div`
  font-size: 22px;
`;
OrderingInfoHeading.displayName = 'OrderingInfoHeading';

export const OrderingInfoLine = styled.div`
  padding-top: 5px;
`;
OrderingInfoLine.displayName = 'OrderingInfoLine';

export const TotalsContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0px 5px 5px;
  width: 100%;
`;
TotalsContainer.displayName = 'TotalsContainer';

export const ValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
ValueRowContainer.displayName = 'ValueRowContainer';

export const SubvalueTitle = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
`;
SubvalueTitle.displayName = 'SubvalueTitle';

export const SubvalueAmount = styled.div`
  color: ${lightTextGray};
  font-size: 13px;
`;
SubvalueAmount.displayName = 'SubvalueAmount';

export const TotalValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 100%;
`;
TotalValueRowContainer.displayName = 'TotalValueRowContainer';

export const TotalTitle = styled.div`
  font-weight: 900;
  font-size: 13px;
`;
TotalTitle.displayName = 'TotalTitle';

export const TotalAmount = styled.div`
  font-weight: 900;
  font-size: 13px;
`;
TotalAmount.displayName = 'TotalAmount';

export const FinalTotalTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  padding-left: 5px;
`;
FinalTotalTitle.displayName = 'FinalTotalTitle';

export const FinalTotalAmount = styled.div`
  font-weight: 700;
  font-size: 22px;
`;
FinalTotalAmount.displayName = 'FinalTotalAmount';

export const MobileOnlyContainer = styled.div`
  display: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
MobileOnlyContainer.displayName = 'MobileOnlyContainer';

export const EmptyCartMsg = styled.div`
  padding: 30px;
  text-align: center;
`;
EmptyCartMsg.displayName = 'EmptyCartMsg';
