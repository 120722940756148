import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import CollectionItem from '../collection-item/collection-item.component';

import {
  CollectionContainer,
  TitleContainer,
  Title,
  SubTitle,
  ItemsContainer,
} from './hearted-items-collection.styles';

import {
  HEARTED_ITEMS_COLLECTION_REF,
  RETAIL_ONLY,
  WHOLESALE_ONLY,
  RETAIL_AND_WHOLESALE,
} from '../../global.constants';

export const HeartedItemsCollection = ({
  collectionRefs,
  heartedItems,
  shopData,
  config,
  wholesaleUserDetails,
  setAllHeartedItems,
}) => {
  const [_items, _setItems] = useState([]);

  const validateHeartedItems = (heartedItems) => {
    /* IMPORTANT
      1. when there is a matched item found in shopData.items,
      we *ALWAYS* add the *shopData.items* item to the reduce accumulator.
      NOT the heartedItem. That's because the shopData may have been
      updated since user added the heartedItem. heartedItems are persisted
      in local storage so there will inevitably be a drift out of sync
      with newest data for shopData.

      2. once we have matched all heartedItems to shopData.items data
      we reset the heartedItems state. this will prune any old items
      no longer available
    */
    const validItems = _.reduce(
      heartedItems,
      (accum, heartedItem) => {
        const matchedItem = shopData.items[heartedItem.key];

        if (!matchedItem) return accum;

        return [...accum, matchedItem];
      },
      []
    );

    setAllHeartedItems(validItems);
  };

  useEffect(() => {
    if (
      _.isEmpty(shopData) ||
      _.isEmpty(shopData.collections) ||
      _.isEmpty(shopData.items) ||
      _.isEmpty(config)
    ) {
      return;
    }

    // calculate what items to show based on various params
    validateHeartedItems(heartedItems);
  }, [shopData, config, wholesaleUserDetails]);

  const renderItem = (heartedItem, idx) => {
    const itemComponent = (
      <CollectionItem
        key={idx}
        item={heartedItem}
        shopData={shopData}
        config={config}
        isHearted={true}
      />
    );

    const { isVisible, salesOutlets } = heartedItem;

    if (!isVisible) return null;
    if (!salesOutlets) return itemComponent;

    if (!wholesaleUserDetails) {
      // retail shopfront
      if (
        salesOutlets === RETAIL_ONLY ||
        salesOutlets === RETAIL_AND_WHOLESALE
      ) {
        // cool show it
        return itemComponent;
      } else {
        return null;
      }
    } else {
      // wholesale shopfront
      if (
        salesOutlets === WHOLESALE_ONLY ||
        salesOutlets === RETAIL_AND_WHOLESALE
      ) {
        // cool show it
        return itemComponent;
      } else {
        return null;
      }
    }
  };
  return (
    !_.isEmpty(shopData.collections) && (
      <CollectionContainer ref={collectionRefs[HEARTED_ITEMS_COLLECTION_REF]}>
        <TitleContainer>
          <Title>Save</Title>
          <SubTitle>
            Save items for later by clicking on an item's Heart icon
          </SubTitle>
        </TitleContainer>
        <ItemsContainer>
          {_.map(heartedItems, (heartedItem, idx) => {
            // todo: search functionality
            return renderItem(heartedItem, idx);
          })}
        </ItemsContainer>
      </CollectionContainer>
    )
  );
};

export default HeartedItemsCollection;
