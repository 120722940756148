import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useMatch } from 'react-router-dom';

import Unsubscribe from './unsubscribe.component';

import {
  unsubscribeCustomerStart,
  resetUnsubscribeMsg,
} from '../../redux/user/user.actions';
import {
  selectIsLoading,
  selectUnsubscribedMsg,
} from '../../redux/user/user.selectors';

import { selectShopDetails } from '../../redux/shop/shop.selectors';

export const UnsubscribeContainer = ({
  unsubscribeCustomerStart,
  isLoading,
  unsubscribedMsg,
  resetUnsubscribeMsg,
  shopDetails,
}) => {
  const [msg, setMsg] = useState('');

  const {
    params: { method, val },
  } = useMatch();

  useEffect(() => {
    resetUnsubscribeMsg();
  }, []);

  useEffect(() => {
    if (!unsubscribedMsg) return;

    setMsg(unsubscribedMsg);
  }, [unsubscribedMsg]);

  const handleUnsubscribeClick = () => {
    const customerDetails = {
      method,
      val,
    };
    unsubscribeCustomerStart(customerDetails, shopDetails);
  };

  return (
    <Unsubscribe
      handleUnsubscribeClick={handleUnsubscribeClick}
      isLoading={isLoading}
      unsubscribedMsg={unsubscribedMsg}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  unsubscribedMsg: selectUnsubscribedMsg,
  shopDetails: selectShopDetails,
});

const mapDispatchToProps = (dispatch) => ({
  unsubscribeCustomerStart: (customerDetails, shopDetails) =>
    dispatch(unsubscribeCustomerStart(customerDetails, shopDetails)),
  resetUnsubscribeMsg: () => dispatch(resetUnsubscribeMsg()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeContainer);
