import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import Collection from '../collection/collection.component';

import {
  selectShopData,
  selectCollectionsOrder,
  selectItemsOrder,
} from '../../redux/shop/shop.selectors';

import {
  calcCollectionAvailability,
  getCollectionsOrderForPlatformOrDefault,
} from '../../utils/shop-utils';

import {
  AVAILABILITY_STATUS__INVISIBLE,
  PLATFORM__ONLINE,
} from '../../global.constants';

import { Container } from './collections-overview.styles';

export const CollectionsOverview = ({
  collectionRefs,
  shopData,
  collectionsOrder,
  itemsOrder,
  wholesaleUserDetails,
  searchValue,
  config,
}) => {
  const _collectionsOrder = getCollectionsOrderForPlatformOrDefault(
    PLATFORM__ONLINE,
    collectionsOrder
  );
  const renderCollection = () => {
    return _.reduce(
      _collectionsOrder.order,
      (accum, collectionKey) => {
        const collection = _.find(
          shopData.collections,
          (coll) => coll.key === collectionKey
        );
        // 1. if for some reason, the collection is no longer present,
        // but still in collectionsOrder, do nothing,
        if (!collection) return accum;
        // 2. check collection availability status
        const availabilityStatus = calcCollectionAvailability(
          collection,
          shopData,
          wholesaleUserDetails
        );
        if (availabilityStatus === AVAILABILITY_STATUS__INVISIBLE) return accum;
        return [
          ...accum,
          <Collection
            key={collectionKey}
            collection={collection}
            itemsOrder={itemsOrder}
            collectionKey={collectionKey}
            shopData={shopData}
            collectionRefs={collectionRefs}
            wholesaleUserDetails={wholesaleUserDetails}
            searchValue={searchValue}
            config={config}
          />,
        ];
      },
      []
    );
  };

  return (
    <Container>
      {shopData &&
        !_.isEmpty(shopData.collections) &&
        !_.isEmpty(shopData.items) &&
        renderCollection()}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  shopData: selectShopData,
  collectionsOrder: selectCollectionsOrder,
  itemsOrder: selectItemsOrder,
});

export default connect(mapStateToProps)(CollectionsOverview);
