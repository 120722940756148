import styled from 'styled-components';

import { mobileSplitPoint } from '../../style-constants'

export const Container = styled.div`
  @media screen and (max-width: ${mobileSplitPoint}) {
    /* margin-bottom: 80px; */
  }
`;
Container.displayName = 'Container';
