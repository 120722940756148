import styled, { keyframes } from 'styled-components';

import {
  lightGray,
  white,
  whiteTransparent,
  green,
  darkerGray,
  darkGray,
  black,
  mobileSplitPoint,
  red,
  veryLightGray,
} from '../../style-constants';

import { AVAILABILITY_STATUS__SOLD_OUT } from '../../global.constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const ItemMobileContentsContainer = styled.div`
  animation: ${fadeIn} 0.1s ease-in;
  background: #fff;
  outline: none;
  position: absolute;

  @media screen and (max-width: ${mobileSplitPoint}) {
    top: 70px;
    width: 100%;
  }
`;
ItemMobileContentsContainer.displayName = 'ItemMobileContentsContainer';

export const ItemImageContainer = styled.div`
  background-color: ${white};
  width: 100%;
`;
ItemImageContainer.displayName = 'ItemImageContainer';

export const ImageContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 400px;
  padding-top: 65px;
  width: 100%;
`;

export const ItemImage = styled.img`
  height: 275px;
  max-width: 350px;
`;
ItemImage.displayName = 'ItemImage';

export const CloseButtonAction = styled.div`
  align-items: center;
  background-color: ${darkGray};
  border: none;
  border-radius: 30px;
  color: ${black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  height: 40px;
  margin-left: 16px;
  padding: 3px;
  width: 40px;

  z-index: 3;
  position: absolute;
  top: 15px;
  left: 0px;

  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
`;
CloseButtonAction.displayName = 'CloseButtonAction';

export const HeaderContainer = styled.div`
  background-color: ${whiteTransparent};
  /* border-bottom: 1px solid ${darkGray}; */
  left: 0px;
  padding: 20px 15px;
  position: fixed;
  top: 65px;
  width: 100%;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  font-size: 20px;
  margin: 15px;
  padding-top: ${({ haveImage }) => (haveImage ? 'unset' : '100px')};
  padding-left: 5px;
`;
Title.displayName = 'Title';

export const Price = styled.div`
  font-size: 20px;
`;
Price.displayName = 'Price';

export const Spacer = styled.div``;
Spacer.displayName = 'Spacer';

export const Description = styled.pre`
  font-family: 'Cabin', sans-serif;
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
  padding: 10px 20px;
`;
Description.displayName = 'Description';

export const CustomizationsContainer = styled.div``;
CustomizationsContainer.displayName = 'CustomizationsContainer';

export const CommitContainer = styled.div`
  background-color: ${whiteTransparent};
  border-top: ${`1px solid ${lightGray}`};
  bottom: 0px;
  height: 100px;
  left: 0px;
  position: fixed;
  width: 100%;
`;
CommitContainer.displayName = 'CommitContainer';

export const ButtonContainer = styled.div`
  align-items: center;
  background-color: ${({ availabilityStatus }) => {
    if (availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT)
      return veryLightGray;

    return whiteTransparent;
  }};
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: center;
  padding: 7px;
  width: 100%;
`;
ButtonContainer.displayName = 'ButtonContainer';

export const OuterSoldOutContainer = styled.div`
  align-items: center;
  /* background-color: ${white}; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
OuterSoldOutContainer.displayName = 'OuterSoldOutContainer';

export const SoldOutContainer = styled.div`
  align-items: center;
  background-color: ${white};
  /* border: 2px solid ${red}; */
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 15px;
`;
SoldOutContainer.displayName = 'SoldOutContainer';

export const SoldOutText = styled.div`
  color: ${red};
  font-size: 18px;
  font-weight: 700;
`;
SoldOutText.displayName = 'SoldOutText';

export const ChangeItemCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 15px;
  width: 45%;
`;
ChangeItemCountContainer.displayName = 'ChangeItemCountContainer';

export const CurvedLeftBorder = styled.div`
  background-color: ${white};
  border-radius: 30px 0px 0px 30px;
`;
CurvedLeftBorder.displayName = 'CurvedLeftBorder';

export const CurvedRightBorder = styled.div`
  background-color: ${white};
  border-radius: 0px 30px 30px 0px;
`;
CurvedRightBorder.displayName = 'CurvedRightBorder';

export const SubtractButtonAction = styled.div`
  align-items: center;
  background-color: ${darkGray};
  border: none;
  border-radius: 30px;
  color: ${black};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  height: 40px;
  width: 40px;

  &:focus {
    outline: 0;
  }
`;
SubtractButtonAction.displayName = 'SubtractButtonAction';

export const QuantityContainer = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 40px;
`;
QuantityContainer.displayName = 'QuantityContainer';

export const Quantity = styled.div`
  color: ${black};
  font-size: 20px;
`;
Quantity.displayName = 'Quantity';

export const SubmitButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? lightGray : red)};
  border: 1px solid ${({ disabled }) => (disabled ? lightGray : red)};
  border-radius: 30px;
  box-shadow: none;
  color: #fff;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  font-family: 'Cabin';
  font-size: 120%;
  font-weight: 700;
  height: 60px;
  letter-spacing: 0.075em;
  padding: 1.1rem;
  text-align: center;
  width: 40%;

  -webkit-tap-highlight-color: transparent;

  &:hover {
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 18px;
    height: 45px;
    padding: 0px;
  }
`;
SubmitButton.displayName = 'SubmitButton';

export const AddButtonAction = styled.div`
  align-items: center;
  background-color: ${darkGray};
  border: none;
  border-radius: 30px;
  color: ${black};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  height: 40px;
  width: 40px;

  &:focus {
    outline: none;
  }
`;
AddButtonAction.displayName = 'AddButtonAction';

export const ButtonImg = styled.img``;

export const UserInstructionsContainer = styled.div`
  margin-bottom: 250px;
  padding: 0px 15px;
  width: 100%;
`;
UserInstructionsContainer.displayName = 'UserInstructionsContainer';

export const UserInstructions = styled.textarea.attrs((props) => ({}))`
  border: 2px solid ${lightGray};
  color: ${darkerGray};
  font-size: 15px;
  height: 100px;
  padding-left: 5px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${darkerGray};
    padding-left: 3px;
  }
`;
UserInstructions.displayName = 'UserInstructions';

export const GstContainer = styled.div`
  align-items: center;
  background-color: ${white}};
  border: 1px solid ${black};
  border-radius: 15px;
  bottom: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 110px;
  padding: 5px 10px;
  position: fixed;
  right: 30px;
`;
GstContainer.displayName = 'GstContainer';

export const GstText = styled.div`
  font-size: 16px;
`;
GstText.displayName = 'GstText';
