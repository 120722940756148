import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import CartItemCustomizationGroup from '../cart-item-customization-group/cart-item-customization-group.component';

import {
  clearItemFromCart,
  addToCart,
  removeFromCart,
  setCartHidden,
} from '../../redux/cart/cart.actions';

import {
  addItemToModal,
  setIsUpdatingItem,
  addBackAllTheUserCustomizationsForCheckoutItem,
} from '../../redux/shop/shop.actions';

import { selectConfig } from '../../redux/config/config.selectors';

import { calculateItemTotal } from '../../redux/cart/cart.utils';
import { calcItemTitle } from '../../utils/cart-utils';

import { black } from '../../style-constants';

import {
  CheckoutItemContainer,
  TotalText,
  QuantityContainer,
  ClearButtonContainer,
  ClearButton,
  CheckoutItemEdit,
  SubText,
  ItemContainer,
  ItemCustomizationsContainer,
  Title,
  RemoveFromCart,
  AddToCart,
  UserInstructionsContainer,
  UserInstructionsTitle,
  UserInstructions,
} from './checkout-item.styles';

// only used for mobile sized devices
export const CheckoutItem = ({
  item,
  addToCart,
  removeFromCart,
  clearItemFromCart,
  setCartHidden,
  addItemToModal,
  setIsUpdatingItem,
  addBackAllTheUserCustomizationsForCheckoutItem,
  config,
}) => {
  const navigate = useNavigate();
  const { quantity, userCustomizations, userInstructions } = item;

  const { displayPricingOnline } = config;

  const groupedCustomizations = _.groupBy(
    userCustomizations,
    (customization) => customization.customizationType
  );

  const handleEditClick = () => {
    addItemToModal(item);
    addBackAllTheUserCustomizationsForCheckoutItem(item.userCustomizations);
    setIsUpdatingItem(true);
    setCartHidden(true); // in case user also has this open
    navigate(`/item/${item.key}`);
  };

  const handleClearItemFromCart = () => {
    clearItemFromCart(item);
  };

  const calculateItemPrice = () => {
    const total = calculateItemTotal(
      item.priceDetails,
      quantity,
      userCustomizations,
      item
    );

    return _.isNumber(total) ? total.toFixed(2) : total;
  };

  return (
    <CheckoutItemContainer>
      <ItemContainer>
        <Title>{calcItemTitle(item)}</Title>
        <ItemCustomizationsContainer>
          {Object.keys(groupedCustomizations).map((key) => (
            <CartItemCustomizationGroup
              key={key}
              title={key}
              options={groupedCustomizations[key]}
            />
          ))}
        </ItemCustomizationsContainer>
        {userInstructions && (
          <UserInstructionsContainer>
            <UserInstructionsTitle>Notes:</UserInstructionsTitle>
            <UserInstructions>{userInstructions}</UserInstructions>
          </UserInstructionsContainer>
        )}
        <CheckoutItemEdit>
          <FiEdit onClick={handleEditClick} />
          <SubText>Edit</SubText>
        </CheckoutItemEdit>
      </ItemContainer>
      <QuantityContainer>
        <RemoveFromCart onClick={() => removeFromCart(item)}>
          &#10094;
        </RemoveFromCart>
        <span>{quantity}</span>
        <AddToCart
          onClick={() => {
            const itemClone = _.cloneDeep(item);
            itemClone.quantity = 1;
            addToCart(itemClone);
          }}
        >
          &#10095;
        </AddToCart>
      </QuantityContainer>
      {displayPricingOnline ? (
        <TotalText>{calculateItemPrice()}</TotalText>
      ) : (
        <TotalText>{''}</TotalText>
      )}
      <ClearButtonContainer>
        <ClearButton onClick={handleClearItemFromCart}>
          <FiTrash2 style={{ color: black }} />
        </ClearButton>
      </ClearButtonContainer>
    </CheckoutItemContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  config: selectConfig,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
  removeFromCart: (item) => dispatch(removeFromCart(item)),
  clearItemFromCart: (item) => dispatch(clearItemFromCart(item)),
  setCartHidden: (isHidden) => dispatch(setCartHidden(isHidden)),
  addItemToModal: (item) => dispatch(addItemToModal(item)),
  setIsUpdatingItem: (isUpdating) => dispatch(setIsUpdatingItem(isUpdating)),
  addBackAllTheUserCustomizationsForCheckoutItem: (userCustomizations) =>
    dispatch(
      addBackAllTheUserCustomizationsForCheckoutItem(userCustomizations)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItem);
