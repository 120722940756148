import React from 'react';
import _ from 'lodash';

import {
  Container,
  ItemTitleContainer,
  ItemTitle,
  ItemPrice,
  CustomizationsContainer,
  Item,
  UserInstructionsContainer,
  UserInstructionsTitle,
  UserInstructions,
} from './cart-item-minimal.styles';

import {
  makeStringForCustomization,
  calcItemTitle,
  calcItemTitleNoStar,
} from '../../utils/cart-utils';

import { calculateItemTotal } from '../../redux/cart/cart.utils';

export const CartItemMinimal = ({ item }) => {
  const { quantity, userCustomizations, userInstructions, priceDetails } = item;

  // 'cust.customizationType' is actually the 'displayTitle' of a customization
  const grouped = _.groupBy(
    userCustomizations,
    (cust) => cust.customizationType
  );

  const calculateItemPrice = () => {
    return calculateItemTotal(
      priceDetails,
      quantity,
      userCustomizations,
      item
    ).toFixed(2);
  };

  return (
    <Container>
      <ItemTitleContainer>
        <ItemTitle>{`${quantity} x ${calcItemTitleNoStar(item)}`}</ItemTitle>
        <ItemPrice>{`${calculateItemPrice()}`}</ItemPrice>
      </ItemTitleContainer>
      <CustomizationsContainer>
        {_.map(grouped, (custsArray, custTitle) => {
          // 'custTitle' is actually the 'displayTitle' of the customization
          const custString = makeStringForCustomization(custTitle, custsArray);
          return <Item key={custString}>{custString}</Item>;
        })}
      </CustomizationsContainer>
      {userInstructions && (
        <UserInstructionsContainer>
          <UserInstructionsTitle>Notes: </UserInstructionsTitle>
          <UserInstructions>{userInstructions}</UserInstructions>
        </UserInstructionsContainer>
      )}
    </Container>
  );
};

export default CartItemMinimal;
