import styled from 'styled-components';

import {
  mobileSplitPoint,
  lightTextGray,
  veryLightGray,
} from '../../style-constants';

export const Container = styled.div`
  margin-top: 70px;
  padding: 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    flex-grow: 2;
    margin-top: unset;
    padding: 10px;
    width: 100%;
  }
`;
Container.displayName = 'Container';

export const InnerContainer = styled.div`
  background-color: ${veryLightGray};
  border-radius: 30px;
  padding-bottom: 15px;
`;
InnerContainer.displayName = 'InnerContainer';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  padding: 15px 0px;
`;
Title.displayName = 'Title';

export const Row = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px;
`;
Row.displayName = 'Row';

export const RowInfo = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px;
  padding-top: 15px;
`;
RowInfo.displayName = 'RowInfo';

export const ValInfo = styled.div`
  color: ${lightTextGray};
  font-style: italic;
  width: 100%;
`;
ValInfo.displayName = 'ValInfo';

export const RowTitle = styled.div`
  font-weight: 800;
  margin-right: 5px;
  padding-bottom: 5px;
  width: 56%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 70%;
  }
`;
RowTitle.displayName = 'RowTitle';

export const Val = styled.div`
  font-weight: 500;
  width: 40%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 28%;
  }
`;
Val.displayName = 'Val';

export const DeliveryFeeTitle = styled.div``;
DeliveryFeeTitle.displayName = 'DeliveryFeeTitle';

export const RightContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 56%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 70%;
  }
`;
RightContainer.displayName = 'RightContainer';
