import styled from 'styled-components';

import { FiShoppingCart } from 'react-icons/fi';

import { red, white, mobileSplitPoint } from '../../style-constants';

export const CartContainer = styled.div`
  cursor: pointer;
  display: none;
  height: 45px;
  position: relative;
  width: 45px;

  -webkit-tap-highlight-color: transparent;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;
CartContainer.displayName = 'CartContainer';

export const ShoppingIcon = styled(FiShoppingCart)`
  width: 50px;
  height: 50px;
`;
ShoppingIcon.displayName = 'ShoppingIcon';

export const ItemCountContainer = styled.span`
  align-items: center;
  background-color: ${red};
  border-radius: 13px;
  bottom: -3px;
  color: ${white};
  display: ${({ itemCount }) => (!itemCount ? 'none' : 'flex')};
  font-size: 15px;
  font-weight: bold;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: -10px;
  width: 24px;
`;
ItemCountContainer.displayName = 'ItemCountContainer';

export const ItemCount = styled.div`
  font-size: 13px;
`;
ItemCount.displayName = 'ItemCount';
