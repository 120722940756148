import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Swal from 'sweetalert2';

import { FiTrash2 } from 'react-icons/fi';

import CartItemMinimal from '../cart-item-minimal/cart-item-minimal.component';

import { selectSavedOrders } from '../../redux/cart/cart.selectors';

import {
  addSavedOrderToCart,
  removeSavedOrder,
  removeAllCartItems,
} from '../../redux/cart/cart.actions';

import { delay } from '../../utils/cart-utils';

import {
  Container,
  HeaderContainer,
  BodyContainer,
  Title,
  Row,
  OrderButton,
  TopContainer,
  RemoveButton,
  // ButtonText,
  FooterContainer,
  AddToCartContainer,
  AddToCartText,
} from './saved-orders.styles';

import { black } from '../../style-constants';

export const SavedOrders = ({
  savedOrders,
  addSavedOrderToCart,
  removeSavedOrder,
  removeAllCartItems,
}) => {
  const handleOrderClick = async (e, order) => {
    e.stopPropagation();
    removeAllCartItems();
    await delay(500);
    addSavedOrderToCart(order);
  };

  const handleRemoveSavedOrderClick = async (e, order) => {
    e.stopPropagation();
    const result = await Swal.fire({
      title: 'Remove this order from your Saved Orders?',
      showCancelButton: true,
      confirmButtonText: 'Remove',
      showCloseButton: true,
    });
    if (result.isConfirmed) {
      removeSavedOrder(order);
    }
  };

  return !savedOrders || _.isEmpty(savedOrders) ? null : (
    <Container>
      <HeaderContainer>
        <Title>Your Saved Orders</Title>
      </HeaderContainer>
      <BodyContainer>
        {_.map(savedOrders, (order, index) => {
          return (
            <OrderButton
              key={index}
              onClick={(e) => handleOrderClick(e, order)}
            >
              <TopContainer>
                <RemoveButton
                  onClick={(e) => handleRemoveSavedOrderClick(e, order)}
                >
                  <FiTrash2 style={{ color: black }} />
                </RemoveButton>
              </TopContainer>
              {_.map(order.items, (item, indexAgain) => {
                return (
                  <Row key={indexAgain}>
                    <CartItemMinimal item={item} />
                  </Row>
                );
              })}
              <FooterContainer></FooterContainer>
            </OrderButton>
          );
        })}
      </BodyContainer>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  savedOrders: selectSavedOrders,
});

const mapDispatchToProps = (dispatch) => ({
  addSavedOrderToCart: (order) => dispatch(addSavedOrderToCart(order)),
  removeSavedOrder: (order) => dispatch(removeSavedOrder(order)),
  removeAllCartItems: () => dispatch(removeAllCartItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedOrders);
