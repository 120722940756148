import UserActionTypes from './user.types';
import _ from 'lodash';

export const DEFAULT_USER_CREDENTIALS_INITIAL_STATE = {
  tableNumber: '',
  userName: '',
  email: '',
  address: '',
  phone: '',
  whenOrderWanted: '',
  whenOrderWantedDate: '',
  notes: '',
};

// when dont have the 'notes' fields here because it's optional
// so no need to track error state or whether it has been active
export const ERROR_OR_TOUCHED_INITIAL_STATE = {
  tableNumber: false,
  userName: false,
  email: false,
  address: false,
  phone: false,
  whenOrderWanted: false,
  whenOrderWantedDate: false,
  notes: false,
};

export const INITIAL_USER_STATE = {
  userDetails: {},
  wholesaleUserDetails: null,
  isLoading: false,
  isWholesaleUserProfileHidden: true,
  errorMessage: '',
  paymentProviderDetails: {
    customerId: null,
    paymentMethods: [],
  },
  unsubscribedMsg: '',
  userCredentials: DEFAULT_USER_CREDENTIALS_INITIAL_STATE,
  keepUserCredentialsForCheckout: false,
};

export const userReducer = (state = INITIAL_USER_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.ADD_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.userDetails,
      };
    case UserActionTypes.LOGIN_WHOLESALE_USER_START:
    case UserActionTypes.LOGOUT_WHOLESALE_USER_START:
    case UserActionTypes.FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_START:
    case UserActionTypes.SEND_PASSWORD_RESET_EMAIL_START:
    case UserActionTypes.DETACH_PAYMENT_METHOD_START:
    case UserActionTypes.FETCH_WHOLESALE_USER_DOC_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case UserActionTypes.LOGIN_WHOLESALE_USER_FAILURE:
    case UserActionTypes.LOGOUT_WHOLESALE_USER_FAILURE:
    case UserActionTypes.FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_FAILURE:
    case UserActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE:
    case UserActionTypes.DETACH_PAYMENT_METHOD_FAILURE:
    case UserActionTypes.FETCH_WHOLESALE_USER_DOC_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    case UserActionTypes.LOGIN_WHOLESALE_USER_SUCCESS:
      return {
        ...state,
        wholesaleUserDetails: action.payload.wholesaleUserDetails,
        isLoading: false,
        errorMessage: '',
      };
    case UserActionTypes.SET_WHOLESALE_DROPDOWN_IS_HIDDEN:
      return {
        ...state,
        isWholesaleUserProfileHidden: action.payload.isHidden,
      };
    case UserActionTypes.LOGOUT_WHOLESALE_USER_SUCCESS:
    case UserActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        wholesaleUserDetails: null,
        userCredentials: DEFAULT_USER_CREDENTIALS_INITIAL_STATE,
        isWholesaleUserProfileHidden: true,
        isLoading: false,
        errorMessage: '',
      };
    case UserActionTypes.UPDATE_WHOLESALE_USER_DETAILS:
      return {
        ...state,
        wholesaleUserDetails: {
          ...state.wholesaleUserDetails,
          ...action.payload.someDetails,
        },
      };
    case UserActionTypes.FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        paymentProviderDetails: {
          ...state.paymentProviderDetails,
          paymentMethods: action.payload.paymentMethods,
        },
      };
    case UserActionTypes.SET_STRIPE_CUSTOMER_ID:
      return {
        ...state,
        paymentProviderDetails: {
          ...state.paymentProviderDetails,
          customerId: action.payload.customerId,
        },
      };
    case UserActionTypes.RESET_ERROR_AND_LOADING_STATE:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
      };
    case UserActionTypes.DETACH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        paymentProviderDetails: {
          ...state.paymentProviderDetails,
          paymentMethods: _.filter(
            state.paymentProviderDetails.paymentMethods,
            (pm) => pm.id !== action.payload.paymentMethodId
          ),
        },
      };
    case UserActionTypes.UNSUBSCRIBE_CUSTOMER_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        unsubscribedMsg: '',
      };
    case UserActionTypes.UNSUBSCRIBE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        unsubscribedMsg: action.payload.msg,
      };
    case UserActionTypes.UNSUBSCRIBE_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        unsubscribedMsg: '',
      };
    case UserActionTypes.RESET_UNSUBSCRIBE_MSG:
      return {
        ...state,
        unsubscribedMsg: '',
      };
    case UserActionTypes.FETCH_WHOLESALE_USER_DOC_SUCCESS:
      return {
        ...state,
        wholesaleUserDetails: action.payload.doc,
      };
    case UserActionTypes.SET_USER_CREDENTIALS:
      return {
        ...state,
        userCredentials: {
          ...state.userCredentials,
          ...action.payload.userCredentials,
        },
      };
    case UserActionTypes.RESET_USER_CREDENTIALS: {
      const { onlineOrderingEnableToggleToKeepUserCredentialsForCheckout } =
        action.payload.config;
      if (
        onlineOrderingEnableToggleToKeepUserCredentialsForCheckout &&
        state.keepUserCredentialsForCheckout
      ) {
        // keep everything except whenOrderWanted and whenOrderWantedDate
        // which will be different, by definition, next time they order
        return {
          ...state,
          userCredentials: {
            ...state.userCredentials,
            whenOrderWanted: '',
            whenOrderWantedDate: '',
          },
        };
      } else {
        return {
          ...state,
          userCredentials: {
            ...DEFAULT_USER_CREDENTIALS_INITIAL_STATE,
          },
        };
      }
    }
    case UserActionTypes.SET_KEEP_USER_CREDENTIALS_FOR_CHECKOUT:
      return {
        ...state,
        keepUserCredentialsForCheckout: action.payload.shouldKeep,
      };
    default:
      return state;
  }
};

export default userReducer;
