import React from 'react';

import { AddOptionButton, ButtonText } from './customization-button.styles';

export const CustomizationButton = ({ option, handleClick, isSelected }) => {
  let buttonText;
  if (!option.price) {
    buttonText = option.title;
  } else {
    buttonText = `${option.title} $${option.price}`;
  }
  return (
    <AddOptionButton
      isSelected={isSelected}
      key={option.key}
      onClick={() => handleClick(option)}
    >
      <ButtonText isSelected={isSelected}>{buttonText}</ButtonText>
    </AddOptionButton>
  );
};

export default CustomizationButton;
