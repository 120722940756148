import styled from 'styled-components';

import { white, mobileSplitPoint } from '../../style-constants';

export const Container = styled.div`
  background-color: ${white};
  flex-grow: 10;
  margin-bottom: 50px;
  /* width: 70%; */
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    flex-grow: 2;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
  }
`;
Container.displayName = 'Container';
