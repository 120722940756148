import React from 'react';
import _ from 'lodash';

import Customization from '../customization/customization.container';
import { Container } from './customizations.styles';

export const Customizations = ({
  customizations,
  customizationsOrder,
  shopData,
}) => {
  // cart items are persisted. it may happen that
  // between website refreshes that the customization has
  // been deleted by shop. if so, then it wont be present
  // in shopData.customizations. check for this here
  return (
    <Container>
      {customizationsOrder
        ? _.map(customizationsOrder, (custKey) => {
            // new version: display of customizations are now ordered
            if (shopData.customizations && !shopData.customizations[custKey]) {
              return;
            }
            return <Customization key={custKey} customizationKey={custKey} />;
          })
        : _.map(customizations, (val, key) => {
            // fallback to older un-ordered version of displaying customizations
            if (shopData.customizations && !shopData.customizations[key]) {
              return;
            }

            return <Customization key={key} customizationKey={key} />;
          })}
    </Container>
  );
};

export default Customizations;
