import ShopActionTypes from './shop.types';

export const INITIAL_SHOP_STATE = {
  shopData: {
    collections: {},
    items: {},
    customizations: {},
    options: {},
  },
  collectionsOrder: {},
  itemsOrder: {},
  // mainly used for shop data fetch action...
  // other actions set this to true when starting, but
  // dont set to false upon their success.
  // the only success action is fetch shop data success that
  // sets it to true.
  // -> it usually takes the longest to finish and so will ensure
  // display loading component.
  // if we were to set to false for a v. fast fetching collection
  // then the loading component will display then hide too quickly
  // for the eye to see, even though there is still slower fetching
  // actions yet to complete.
  isFetching: false,
  operatingHours: {},
  shopDetails: {},
  errorMessage: undefined,
  itemModal: {
    item: {
      quantity: 1,
    },
    userCustomizations: [],
    selectedPrice: {},
    userInstructions: '',
    isUpdating: false,
    collection: {},
  },
  showItemModal: false,
  searchValue: '',
  overrideDays: {},
  deliveryZones: {},
  showDeliveryZonesModal: false,
  ordersThrottling: {},
  isFetchingThrottlingData: false,
  showDynamicDeliveryFeeInfoModal: false,
  showShopInfoModal: false,
};

export const shopReducer = (state = INITIAL_SHOP_STATE, action) => {
  switch (action.type) {
    case ShopActionTypes.FETCH_SHOP_DATA_START:
      return {
        ...state,
        // if we are refetching shop data in background then dont
        // show fetching ui changes. janky.
        // background refetching occurs when app becomes focused or visible
        // again after user navigates away from page
        isFetching: action.payload.showLoadingState ? true : false,
      };
    case ShopActionTypes.FETCH_SHOP_DATA_SUCCESS:
      return {
        ...state,
        shopData: action.payload.shopData,
        collectionsOrder: action.payload.collectionsOrder,
        itemsOrder: action.payload.itemsOrder,
        isFetching: false,
      };
    case ShopActionTypes.FETCH_OPERATING_HOURS_SUCCESS:
      return {
        ...state,
        operatingHours: action.payload.operatingHours,
        // isFetching: false,
      };
    case ShopActionTypes.FETCH_SHOP_DETAILS_SUCCESS:
      return {
        ...state,
        shopDetails: action.payload.shopDetails,
        // isFetching: false,
      };
    case ShopActionTypes.ADD_ITEM_TO_MODAL:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          item: {
            ...state.itemModal.item,
            ...action.payload.item,
          },
          collection: action.payload.collection,
        },
      };
    case ShopActionTypes.SET_SHOW_ITEM_MODAL:
      return {
        ...state,
        showItemModal: action.payload,
      };
    case ShopActionTypes.INCREMENT_QUANTITY_BY:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          item: {
            ...state.itemModal.item,
            quantity: state.itemModal.item.quantity + action.payload.quantity,
          },
        },
      };
    case ShopActionTypes.SET_SELECTED_PRICE:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          selectedPrice: action.payload.selectedPrice,
        },
      };
    case ShopActionTypes.SET_USER_INSTRUCTIONS:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          userInstructions: action.payload.userInstructions,
        },
      };
    case ShopActionTypes.RESET_ITEM_MODAL:
      return {
        ...state,
        itemModal: {
          item: {
            quantity: 1,
          },
          userCustomizations: [],
          selectedPrice: {},
          userInstructions: '',
          isUpdating: false,
          collection: {},
        },
      };
    case ShopActionTypes.ADD_CUSTOMIZATION_OPTION:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          userCustomizations: [
            ...state.itemModal.userCustomizations,
            action.payload,
          ],
        },
      };
    case ShopActionTypes.REMOVE_CUSTOMIZATION_OPTION:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          userCustomizations: state.itemModal.userCustomizations.filter(
            (customization) =>
              customization.customizationKey !==
                action.payload.customizationKey ||
              customization.title !== action.payload.title
          ),
        },
      };
    case ShopActionTypes.ADD_BACK_ALL_THE_USER_CUSTOMIZATIONS_FOR_CHECKOUT_ITEM:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          userCustomizations: action.payload,
        },
      };
    case ShopActionTypes.SET_IS_UPDATING_ITEM:
      return {
        ...state,
        itemModal: {
          ...state.itemModal,
          isUpdating: action.payload,
        },
      };
    case ShopActionTypes.RESET_SHOP_DATA_AND_COLLECTIONS_ORDERING_AND_ITEMS_ORDERING:
      return {
        ...state,
        shopData: {
          collections: {},
          items: {},
          customizations: {},
          options: {},
        },
        collectionsOrder: {},
        itemsOrder: {},
      };
    case ShopActionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload.searchValue,
      };
    case ShopActionTypes.FETCH_OVERRIDE_DAYS_SUCCESS:
      return {
        ...state,
        overrideDays: action.payload.overrideDays,
        // isFetching: false,
      };
    case ShopActionTypes.FETCH_SHOP_DETAILS_FAILURE:
    case ShopActionTypes.FETCH_OVERRIDE_DAYS_FAILURE:
    case ShopActionTypes.FETCH_OPERATING_HOURS_FAILURE:
    case ShopActionTypes.FETCH_SHOP_DATA_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isFetching: false,
      };
    case ShopActionTypes.FETCH_DELIVERY_ZONES_SUCCESS:
      return {
        ...state,
        deliveryZones: action.payload.deliveryZones,
        // isFetching: false,
      };
    case ShopActionTypes.SET_SHOW_DELIVERY_ZONES_MODAL:
      return {
        ...state,
        showDeliveryZonesModal: action.payload.show,
      };
    case ShopActionTypes.FETCH_ORDERS_THROTTLING_FOR_DAY_START:
      return {
        ...state,
        isFetchingThrottlingData: true,
      };
    case ShopActionTypes.FETCH_ORDERS_THROTTLING_FOR_DAY_SUCCESS:
      return {
        ...state,
        ordersThrottling: {
          ...state.ordersThrottling,
          [action.payload.throttleDayData.key]: action.payload.throttleDayData,
        },
        isFetchingThrottlingData: false,
      };
    case ShopActionTypes.FETCH_ORDERS_THROTTLING_FOR_DAY_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isFetchingThrottlingData: false,
      };
    case ShopActionTypes.SET_SHOW_DYNAMIC_DELIVERY_FEE_INFO_MODAL:
      return {
        ...state,
        showDynamicDeliveryFeeInfoModal: action.payload.show,
      };
    case ShopActionTypes.SET_SHOW_SHOP_INFO_MODAL:
      return {
        ...state,
        showShopInfoModal: action.payload.show,
      };
    default:
      return state;
  }
};

export default shopReducer;
