import _ from 'lodash';

import {
  DELIVERY_SURCHARGE_TITLE,
  PROMOTION,
  DELIVERY,
  GST_NONE,
  GST_INCLUDED,
  GST_EXCLUDED,
} from '../../global.constants';

//----------------------------------------------------------------------
//       *** CREATES A UNIQUE ID FOR ITEM ***
//----------------------------------------------------------------------
export const createUniqueIdentifier = (item) => {
  // uses the following to create a unique id for item:
  // 1. item id
  // 2. item price size eg. 'large',
  // 3. all item customizations (their associated names)
  // 4. user instructions (if any).
  // basically just concats them together to generate the unique identifier for a cart item.
  const customizationsAsString = item.userCustomizations.reduce(
    (accumulator, customization) => {
      return `${accumulator}_${customization.key}`;
    },
    ''
  );
  return `${item.key}.${item.priceDetails.size}.${customizationsAsString}.${item.userInstructions}`;
};

//----------------------------------------------------------------------
//       *** CART HANDLING FUNCS ***
//----------------------------------------------------------------------
export const addItemToCart = (cartItems, itemToAdd) => {
  // a unique identifier for a cart item is composed of 2 fields:
  // 1. item id
  // 2. item price size eg. 'large',
  // 3. all item customizations (their associated names)
  // 4. user instructions (if any).
  const alreadyInCart = cartItems.find(
    (cartItem) =>
      createUniqueIdentifier(cartItem) === createUniqueIdentifier(itemToAdd)
  );
  if (alreadyInCart) {
    const updatedCart = cartItems.map((cartItem) => {
      if (
        createUniqueIdentifier(cartItem) === createUniqueIdentifier(itemToAdd)
      ) {
        return {
          ...cartItem,
          quantity: cartItem.quantity + itemToAdd.quantity,
        };
      } else {
        return cartItem;
      }
    });
    return updatedCart;
  } else {
    return [...cartItems, itemToAdd];
  }
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const removeId = createUniqueIdentifier(cartItemToRemove);
  const existingCartItem = cartItems.find(
    (cartItem) => createUniqueIdentifier(cartItem) === removeId
  );
  if (!existingCartItem) {
    // item is not in the cart. return the cart 'as-is'
    return cartItems;
  }
  if (existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem) => createUniqueIdentifier(cartItem) !== removeId
    );
  }
  return cartItems.map((cartItem) =>
    createUniqueIdentifier(cartItem) === removeId
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

export const clearItemFromCart = (cartItems, cartItemToRemove) => {
  const removeId = createUniqueIdentifier(cartItemToRemove);
  return cartItems.filter(
    (cartItem) => createUniqueIdentifier(cartItem) !== removeId
  );
};

export const updateCartItem = (cartItems, prevCartItem, updatedCartItem) => {
  // find the location of the prevCartItem in cartItems, then replace
  // it with the updateCartItem. return a new cartItems array
  const prevCartItemIdx = _.findIndex(
    cartItems,
    (cartItem) =>
      createUniqueIdentifier(cartItem) === createUniqueIdentifier(prevCartItem)
  );
  return [
    ...cartItems.slice(0, prevCartItemIdx),
    updatedCartItem,
    ...cartItems.slice(prevCartItemIdx + 1, cartItems.length),
  ];
};

//----------------------------------------------------------------------
//       *** CART/ ORDER MATH FUNCS ***
//----------------------------------------------------------------------

const toCents = (dollars) => {
  // use lodash _.round func to do rounding up to nearest cent
  return parseInt(_.round(dollars * 100));
};

const toDollars = (cents) => {
  return _.round(cents / 100.0, 2);
};

// this version only considers a single 'quantity' in calculation of *single* item total
// ********************************
// * GST considerations *
// ********************************
// if GST is needed to be added, then we do so here
// this occurs when gstSetting === GST_EXCLUDED.
// for all other gstSetting vals, the price will either have
// no GST, or already include it (when gstSetting === GST_INCLUDED)
export const calculateItemTotalSingleQuantity = (
  priceDetails,
  userCustomizations,
  item
) => {
  const { gstSetting } = item;
  // some items may not have the gstSetting present. check
  const _gstSetting = gstSetting ? gstSetting : GST_NONE;

  const { price } = priceDetails;
  // priceDetails may be empty (for multisize items on loading item mobile/modal, say)
  const _price = price === undefined ? 0.0 : price;
  const initAccum = toCents(_price);
  let totalCents = userCustomizations.reduce((accum, changes) => {
    const { price } = changes;
    return accum + toCents(price);
  }, initAccum);

  // a cart item may have been assigned options with a negative price
  // (for removing options from item)
  // check for chances of negative item total
  if (totalCents <= 0) {
    return 0.0;
  }

  // assumption: if an item has GST then so do any of the customization
  // options added to it.
  if (_gstSetting === GST_NONE || _gstSetting === GST_INCLUDED) {
    // no need to add GST, or already included, respectively
  } else if (_gstSetting === GST_EXCLUDED) {
    // add GST
    const WITH_GST_FRAC = 11 / 10;
    totalCents = parseInt(totalCents * WITH_GST_FRAC);
  }

  return toDollars(totalCents);
};

// this version includes the 'quantity' in the calculation of item total
export const calculateItemTotal = (
  priceDetails,
  quantity,
  userCustomizations,
  item
) => {
  // this will contain GST in it if *either* item.gstSettings is
  // GST_INCLUDED or GST_EXCLUDED
  const itemTotal = calculateItemTotalSingleQuantity(
    priceDetails,
    userCustomizations,
    item
  );

  return toDollars(quantity * toCents(itemTotal));
};

export const calcCartGstTotal = (cartItems) => {
  const gstTotalCents = cartItems.reduce((accum, item) => {
    const { gstSetting, priceDetails, quantity, userCustomizations } = item;
    let itemTotalCents = toCents(
      calculateItemTotal(priceDetails, quantity, userCustomizations, item)
    );

    if (!gstSetting || gstSetting === GST_NONE) {
      return accum;
    }

    if (gstSetting === GST_INCLUDED || gstSetting === GST_EXCLUDED) {
      // by design, itemTotalCents will contain GST in it if either
      // we have GST_INCLUDED or GST_EXCLUDED.
      // basically comes down to how the calculateSingleItemTotal func
      // is written; it will always contain GST in it for either value
      // of gstSetting here.
      // so, we must calc how much GST occurs for this item,
      // then add it to accumulator
      const GST_FRAC = 1 / 11;
      const itemGstCents = itemTotalCents * GST_FRAC;

      accum += itemGstCents;
    }

    return accum;
  }, 0);

  return toDollars(gstTotalCents);
};

export const calcCartSubtotalAndCartGst = (cartItems) => {
  if (!cartItems || _.isEmpty(cartItems)) {
    return { cartSubtotal: toDollars(0), cartGst: toDollars(0) };
  }

  const cartTotal = calcCartTotal(cartItems);
  const cartGst = calcCartGstTotal(cartItems);
  const cartSubtotalCents = toCents(cartTotal) - toCents(cartGst);

  return { cartSubtotal: toDollars(cartSubtotalCents), cartGst: cartGst };
};

export const calcCartTotal = (cartItems) => {
  // equation is:
  // cartTotal = cartSubtotal + cartGST
  let totalCents = cartItems.reduce((accum, item) => {
    const { priceDetails, quantity, userCustomizations } = item;

    let itemTotalCents = toCents(
      calculateItemTotal(priceDetails, quantity, userCustomizations, item)
    );

    return accum + itemTotalCents;
  }, 0);

  return toDollars(totalCents);
};

export const calcHasFreeDelivery = (cartTotal, fee, deliveryFreeThreshold) => {
  let hasFreeDelivery;

  // if deliveryFreeThreshold === 0.0, or is undefined, then this signifies there
  // is NO free delivery threshold set
  if (deliveryFreeThreshold) {
    if (cartTotal >= deliveryFreeThreshold) {
      hasFreeDelivery = true;
    } else {
      hasFreeDelivery = false;
    }
  } else {
    // don't have free delivery threshold set
    // ie. deliveryFreeThreshold === 0.0, or it's undefined
    // just look at deliverySurcharge val
    if (!fee) {
      hasFreeDelivery = true;
    } else {
      hasFreeDelivery = false;
    }
  }

  return hasFreeDelivery;
};

export const calcDeliveryFee = (cartTotal, fee, deliveryFreeThreshold) => {
  return calcHasFreeDelivery(cartTotal, fee, deliveryFreeThreshold) ? 0.0 : fee;
};

export const calcBookingFee = (
  cartTotal,
  deliveryFee,
  promotionFee,
  orderType,
  bookingFeePercentage
) => {
  const _deliveryFee =
    orderType === DELIVERY && deliveryFee !== undefined ? deliveryFee : 0.0;
  const _promotionFee = promotionFee === undefined ? 0.0 : promotionFee;
  const _BOOKING_FEE_FRACTION = bookingFeePercentage / 100;

  // drop any fractional cents, then convert back to dollars
  const _totalCents =
    toCents(cartTotal) + toCents(_deliveryFee) + toCents(_promotionFee);
  const _bookingFeeCents = parseInt(_totalCents * _BOOKING_FEE_FRACTION);

  const _bookingFee = _bookingFeeCents / 100;

  return _bookingFee;
};

export const calcPromotionPercentType = (cartTotal, discountPercent) => {
  // convert to cents first before multiplying discount fract
  const discountCents = toCents(cartTotal) * (discountPercent / 100.0);
  // drop fractional cents, convert back to dollars
  const discount = (-1.0 * parseInt(discountCents)) / 100;

  return discount;
};

export const calcFinalTotal = (
  cartTotal,
  deliveryFee,
  promotionFee,
  bookingFee,
  orderType,
  addBookingFee
) => {
  const _deliveryFee =
    orderType === DELIVERY && deliveryFee !== undefined ? deliveryFee : 0.0;
  const _promotionFee = promotionFee === undefined ? 0.0 : promotionFee;
  const _bookingFee =
    !addBookingFee || bookingFee === undefined ? 0.0 : bookingFee;

  const _totalCents =
    toCents(cartTotal) +
    toCents(_deliveryFee) +
    toCents(_promotionFee) +
    toCents(_bookingFee);

  const _finalTotal = _totalCents / 100;

  return _finalTotal;
};

export const findCollectionForItem = (shopData, itemKey) => {
  // add collection key & title to proposed item.

  if (_.isEmpty(shopData) || _.isEmpty(shopData.collections) || !itemKey)
    return undefined;

  return _.find(shopData.collections, (coll, collKey) =>
    _.find(coll.items, (_, _itemKey) => _itemKey === itemKey)
  );
};

export const calcGstContainedInPrice = (priceWithGstIncluded) => {
  const priceWithGstCentsIncluded = toCents(priceWithGstIncluded);
  const GST_FRAC = 1 / 11;

  return toDollars(priceWithGstCentsIncluded * GST_FRAC);
};

export const calcGstBlurb = (
  item,
  selectedPrice,
  itemModalUserCustomizations
) => {
  const { gstSetting, quantity } = item;
  let blurb = '';
  const itemTotal = calculateItemTotal(
    selectedPrice,
    quantity,
    itemModalUserCustomizations,
    item
  );
  if (gstSetting === GST_EXCLUDED) {
    blurb = `*GST $${calcGstContainedInPrice(itemTotal)}`;
  }
  return blurb;
};

export const computeModalButtonText = (
  item,
  selectedPrice,
  itemModalUserCustomizations,
  isUpdatingItem
) => {
  const { gstSetting, quantity } = item;
  const itemTotal = calculateItemTotal(
    selectedPrice,
    quantity,
    itemModalUserCustomizations,
    item
  );
  const _text = isUpdatingItem ? 'Update' : 'Add';
  const _price = !itemTotal ? '' : `$${itemTotal}`;
  let buttonText = `${_text} ${_price}`;

  if (itemTotal && gstSetting && gstSetting === GST_EXCLUDED) {
    buttonText = `*${buttonText}`;
  }
  return buttonText;
};

export const sortCustomizationsUsingOrder = (
  customizations,
  customizationsOrder
) => {
  // sanity guards
  if (_.isEmpty(customizations) || _.isEmpty(customizationsOrder))
    return customizations;

  let tempArray = [];

  _.forEach(customizationsOrder, (custKey, idx) => {
    const relevantCusts = _.reduce(
      customizations,
      (accum, aCust) => {
        if (aCust.customizationKey === custKey) {
          accum = [...accum, aCust];
        }

        return accum;
      },
      []
    );

    if (_.isEmpty(relevantCusts)) return;

    tempArray = [...tempArray, ...relevantCusts];
  });

  return tempArray;
};

// a simple hash function
// copied from (with alterations)
// https://stackoverflow.com/a/7616484
export const stringToHashCodeString = (inputString) => {
  try {
    if (typeof inputString !== 'string')
      throw new Error('inputString must be a string');
    let hash = 0;
    let chr;
    if (!inputString.length) return hash;

    for (let i = 0; i < inputString.length; i++) {
      chr = inputString.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return `${hash}`;
  } catch (error) {
    return '0';
  }
};

export const findSavedOrder = (savedOrders, items) => {
  try {
    // we hash the stringified order so we can check if it already exists
    // in savedOrders. if it does, we dont add it again
    const hashedIncomingOrderItems = stringToHashCodeString(
      JSON.stringify(items)
    );
    const foundOrder = _.find(savedOrders, (aSavedOrder) => {
      // need to drop the savedOrderKey as it doesn't exist yet in clonedActinPayload
      const { savedOrderKey, items: savedItems } = aSavedOrder;
      const hashedSavedOrderItems = stringToHashCodeString(
        JSON.stringify(savedItems)
      );
      return hashedSavedOrderItems === hashedIncomingOrderItems;
    });
    return foundOrder;
  } catch (error) {
    return null;
  }
};
