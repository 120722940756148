import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';

import FormInput from '../form-input/form-input.component';

import {
  Container,
  ContentContainer,
  NavContainer,
  NavButtonContainer,
  BackIcon,
  NavButtonText,
  LoginContainer,
  LoginTitleContainer,
  LoginTitle,
  LoginText,
  InputContainer,
  ActionContainer,
  ForgotPasswordContainer,
  ForgotPasswordText,
  LoginButtonContainer,
  LoginButtonText,
  LoginFailure,
  BackButtonContainer,
  BackButtonText,
  SpinnerContainer,
  SendResetPasswordButtonContainer,
} from './login-wholesale-customer.styles';

import { darkerGray } from '../../style-constants';

export const LoginWholesaleCustomer = ({
  loginDetails,
  handleChange,
  handleBackClick,
  handleLoginClick,
  errorMessage,
  isLoading,
  handleForgotPassword,
  toggleForgotPassword,
  hasForgotPassword,
}) => {
  const { email, password } = loginDetails;
  return hasForgotPassword ? (
    <Container>
      <ContentContainer>
        <NavContainer>
          <NavButtonContainer onClick={handleBackClick}>
            <BackIcon transform={'scale(1.0)'} />
            <NavButtonText>Home</NavButtonText>
          </NavButtonContainer>
        </NavContainer>
        <LoginContainer>
          <LoginTitleContainer>
            <LoginTitle>Forgot Password</LoginTitle>
            <LoginText>
              Enter your email address to send password reset details.
            </LoginText>
          </LoginTitleContainer>
          <InputContainer>
            <FormInput
              name="email"
              type="text"
              value={email}
              label="Email"
              handleChange={handleChange}
              /* 
              error={userNameError}
              onBlur={() => handleBlur('userName')}
              */
            />
          </InputContainer>
        </LoginContainer>
        <ActionContainer>
          <BackButtonContainer onClick={toggleForgotPassword}>
            <BackButtonText>Back</BackButtonText>
          </BackButtonContainer>
          <SpinnerContainer>
            <BounceLoader color={darkerGray} size={30} loading={isLoading} />
          </SpinnerContainer>
          <SendResetPasswordButtonContainer
            onClick={handleForgotPassword}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <LoginButtonText>Reset password</LoginButtonText>
          </SendResetPasswordButtonContainer>
        </ActionContainer>
        {!!errorMessage && <LoginFailure>{errorMessage}</LoginFailure>}
      </ContentContainer>
    </Container>
  ) : (
    <Container>
      <ContentContainer>
        <NavContainer>
          <NavButtonContainer onClick={handleBackClick}>
            <BackIcon transform={'scale(1.0)'} />
            <NavButtonText>Home</NavButtonText>
          </NavButtonContainer>
        </NavContainer>
        <LoginContainer>
          <LoginTitleContainer>
            <LoginTitle>Login to Wholesale Account</LoginTitle>
            <LoginText>
              Retail customers: you don't need to log in to be able to make
              purchases
            </LoginText>
          </LoginTitleContainer>
          <InputContainer>
            <FormInput
              name="email"
              type="text"
              value={email}
              label="Email"
              handleChange={handleChange}
              /* 
              error={userNameError}
              onBlur={() => handleBlur('userName')}
              */
            />
          </InputContainer>
          <InputContainer>
            <FormInput
              name="password"
              type="password"
              value={password}
              label="Password"
              handleChange={handleChange}
              /* 
              error={userNameError}
              onBlur={() => handleBlur('userName')}
              */
            />
          </InputContainer>
        </LoginContainer>
        <ActionContainer>
          <ForgotPasswordContainer onClick={toggleForgotPassword}>
            <ForgotPasswordText>Forgot password</ForgotPasswordText>
          </ForgotPasswordContainer>
          <SpinnerContainer>
            <BounceLoader color={darkerGray} size={30} loading={isLoading} />
          </SpinnerContainer>
          <LoginButtonContainer
            onClick={handleLoginClick}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <LoginButtonText>Login</LoginButtonText>
          </LoginButtonContainer>
        </ActionContainer>
        {!!errorMessage && <LoginFailure>{errorMessage}</LoginFailure>}
      </ContentContainer>
    </Container>
  );
};

export default LoginWholesaleCustomer;
