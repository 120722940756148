import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import { Row, Day, Times } from './operating-hours-day.styles';

import {
  formatDatetime,
  checkMomentsAreSameDateExactly,
} from '../../utils/shop-utils';

export const OperatingHoursDay = ({ idx, day, hours, overrideDays }) => {
  const now = moment();
  const mOperatingDay = moment().startOf('isoWeek').add(idx, 'days');

  const {
    open: operatingOpen,
    close: operatingClose,
    isClosed: operatingIsClosed,
  } = hours;

  let _open = operatingOpen;
  let _close = operatingClose;
  let _isClosed = operatingIsClosed;

  const isHighlighted = mOperatingDay.day() === now.day();

  _.forEach(overrideDays, (details) => {
    const {
      type,
      date: overrideDate,
      openTypeOpenTime,
      openTypeCloseTime,
    } = details;

    const mOverrideDay = moment(overrideDate, 'ddd MMM DD YYYY HH:mm:ss ZZ');

    if (checkMomentsAreSameDateExactly(mOperatingDay, mOverrideDay)) {
      if (type === 'closed') {
        _isClosed = true;
      }

      if (type === 'open') {
        _isClosed = false;
        _open = formatDatetime(openTypeOpenTime);
        _close = formatDatetime(openTypeCloseTime);
      }

      return;
    }
  });

  return (
    <Row isHighlighted={isHighlighted}>
      <Day>{day}: </Day>
      <Times>{_isClosed ? 'CLOSED' : `${_open} - ${_close}`}</Times>
    </Row>
  );
};

export default OperatingHoursDay;
