import HeartedItemsTypes from './hearted-items.types';

import { addOrRemoveHeartedItem } from './heart-items.utils';

export const INITIAL_HEARTED_ITEMS_STATE = {
  heartedItems: [],
  errorMessage: '',
};

export const heartedItemsReducer = (
  state = INITIAL_HEARTED_ITEMS_STATE,
  action
) => {
  switch (action.type) {
    case HeartedItemsTypes.TOGGLE_ITEM_IS_HEARTED:
      return {
        ...state,
        heartedItems: addOrRemoveHeartedItem(
          action.payload.item,
          state.heartedItems
        ),
      };
    case HeartedItemsTypes.SET_ALL_HEARTED_ITEMS:
      return {
        ...state,
        heartedItems: action.payload.heartedItems,
      };
    default:
      return state;
  }
};

export default heartedItemsReducer;
