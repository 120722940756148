import { takeLatest, call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import QrCodesActionTypes from './qr-codes.types';
import { qrCodesApi as api } from '../../services/';

// TODO:
// add any sagas needed for qr code handling

// copy - pasta sagas from another saga redux slice
// ------------------------------------------------------
//  WORkER SAGAS
// ------------------------------------------------------
// export function* fetchWholesaleUserDoc({ payload: { wholesaleUserDetails } }) {
//   try {
//     const { key } = wholesaleUserDetails;
//     const doc = yield call(api.fetchWholesaleUserDoc, key);
//     yield put(fetchWholesaleUserDocSuccess(doc));
//   } catch (error) {
//     console.log(error);
//     yield put(
//       fetchWholesaleUserDocFailure(
//         'Failed to fetch wholesale user doc for docKey'
//       )
//     );
//   }
// }

// ------------------------------------------------------
//  LISTENER SAGAS
// ------------------------------------------------------
// export function* fetchWholesaleUserDocStart() {
//   yield takeLatest(
//     UserActionTypes.FETCH_WHOLESALE_USER_DOC_START,
//     fetchWholesaleUserDoc
//   );
// }

export function* qrCodesSagas() {
  yield all([]);
}
