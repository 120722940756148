import { firestore, auth } from '../firebase/firebase.utils';
import axios from 'axios';

import { API_VERSION_V1 } from '../global.constants';

export const wholesaleUserDetails = async (userAuth) => {
  if (!userAuth) return;

  const userRef = firestore.collection('wholesaleCustomers').doc(userAuth.uid);
  const snapshot = await userRef.get();

  return {
    key: snapshot.id,
    ...snapshot.data(),
  };
};

export const fetchPaymentMethodsForCustomerId = async (
  customerId,
  shopDetails
) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/paymentMethodsForCustomer/${customerId}`;
  const {
    data: { paymentMethods, error },
  } = await axios({
    url,
    method: 'get',
  });

  return paymentMethods;
};

export const detachPaymentMethod = async (paymentMethodId, shopDetails) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/detachPaymentMethod`;
  const {
    data: { error },
  } = await axios({
    url,
    method: 'post',
    data: {
      paymentMethodId,
    },
  });

  if (error) throw new Error('API: failed to detach payment method');
};

export const retrieveIdToken = async () => {
  try {
    const idToken = await auth.currentUser.getIdToken(/* force refresh */ true);
    return { data: { idToken } };
  } catch (error) {
    console.log(error);
    return { error: 'ERROR: auth problems' };
  }
};

export const unsubscribeCustomer = async (customerDetails, shopDetails) => {
  const { backendServerUrl } = shopDetails;
  const url = `${backendServerUrl}/api/${API_VERSION_V1}/unsubscribeCustomer`;
  const {
    data: { error },
  } = await axios({
    url,
    method: 'post',
    data: {
      customerDetails,
    },
  });

  if (error) throw new Error('API: unsubscribeCustomer');
};

export const fetchWholesaleUserDoc = async (key) => {
  const ref = firestore.collection('wholesaleCustomers').doc(key);
  const doc = await ref.get();

  if (!doc.exists) throw new Error('Wholesale user does not exist');

  return {
    key, // could also use doc.id
    ...doc.data(),
  };
};
