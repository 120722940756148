import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectConfig } from '../../redux/config/config.selectors';
import { selectShopDetails } from '../../redux/shop/shop.selectors';

import {
  Container,
  InnerContainer,
  Title,
  TitleContainer,
  RowTitle,
  Row,
  Val,
  RowInfo,
  ValInfo,
  DeliveryFeeTitle,
  RightContainer,
} from './delivery-details.styles';

import {
  DELIVERY_CHECK_TYPE_RADIUS,
  DELIVERY_FEE_MODE_DYNAMIC,
  DELIVERY_CHECK_TYPE_POSTCODES,
} from '../../global.constants';

export const DeliveryDetails = ({ shopDetails, config }) => {
  const {
    deliveryFreeThreshold,
    deliveryMinimum,
    deliverySurcharge,
    deliveryFeeDynamicTypeMinimum,
    deliveryFeeDynamicTypeMaximum,
    shopAddressSearchResultsRadius,
  } = shopDetails;
  const {
    deliveryToAddressCheckType,
    displayMaxDeliveryDistance,
    deliveryFeeCalculationType,
  } = config;

  const calcDeliveryFeeUI = () => {
    let _inner = '';

    if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
      _inner = (
        <Row>
          <RowTitle>Delivery fee</RowTitle>
          <Val>Postcode *</Val>
        </Row>
      );
    } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS) {
      if (deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC) {
        _inner = (
          <Row>
            <RowTitle>Delivery fee</RowTitle>
            <Val>Dynamic *</Val>
          </Row>
        );
      } else {
        _inner = (
          <Row>
            <RowTitle>Delivery fee</RowTitle>
            <Val>{`$${deliverySurcharge}`}</Val>
          </Row>
        );
      }
    } else {
      // default to radius static mode for delivery pricing
      _inner = (
        <Row>
          <RowTitle>Delivery fee</RowTitle>
          <Val>{`$${deliverySurcharge}`}</Val>
        </Row>
      );
    }

    return _inner;
  };

  return (
    <Container>
      <InnerContainer>
        <TitleContainer>
          <Title>Delivery Details</Title>
        </TitleContainer>
        <Row>
          <RowTitle>Minimum:</RowTitle>
          <Val>${deliveryMinimum}</Val>
        </Row>
        {!!deliveryFreeThreshold && (
          <Row>
            <RowTitle>Free Delivery Above</RowTitle>
            <Val>${deliveryFreeThreshold}</Val>
          </Row>
        )}
        {calcDeliveryFeeUI()}
        {deliveryToAddressCheckType !== DELIVERY_CHECK_TYPE_POSTCODES &&
          displayMaxDeliveryDistance && (
            <Row>
              <RowTitle>Max distance:</RowTitle>
              <Val>
                {shopAddressSearchResultsRadius &&
                  `${parseInt(shopAddressSearchResultsRadius / 1000)} km`}
              </Val>
            </Row>
          )}
        {deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS &&
          deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC && (
            <RowInfo>
              <ValInfo>
                {`* We calculate your delivery fee based on the distance you are from our location.`}
              </ValInfo>
              <ValInfo>
                {`The delivery fee range between $${deliveryFeeDynamicTypeMinimum} and $${deliveryFeeDynamicTypeMaximum}`}
              </ValInfo>
            </RowInfo>
          )}
        {deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES && (
          <RowInfo>
            <ValInfo>
              * We calculate your delivery fee based on your postcode
            </ValInfo>
            <ValInfo>
              Please see the Delivery Zones info for more details.
            </ValInfo>
          </RowInfo>
        )}
      </InnerContainer>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  shopDetails: selectShopDetails,
  config: selectConfig,
});

export default connect(mapStateToProps, null)(DeliveryDetails);
