import { takeLatest, call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import {
  fetchShopDataSuccess,
  fetchShopDataFailure,
  fetchOperatingHoursSuccess,
  fetchOperatingHoursFailure,
  fetchShopDetailsSuccess,
  fetchShopDetailsFailure,
  fetchOverrideDaysSuccess,
  fetchOverrideDaysFailure,
  fetchDeliveryZonesSuccess,
  fetchDeliveryZonesFailure,
  fetchOrdersThrottlingForDaySuccess,
  fetchOrdersThrottlingForDayFailure,
} from './shop.actions';

import ShopActionTypes from './shop.types';
import { shopApi as api } from '../../services/';

import { adjustShopDataToWholesalePrices } from '../user/user.utils';

// ---------------------------------------------------
// ACTUAL SAGA BUSINESS LOGIC
// ---------------------------------------------------
export function* fetchShopData({ payload: { wholesaleUserDetails } }) {
  // get all shop data AND collectionsOrder/itemsOrder collections
  let shopData = {};

  try {
    // need to get all the data from these collections
    // 1. collections
    // 2. items
    // 3. customizations
    // 4. options
    // 5. collectionsOrder (for ordering the display of collections)
    // 6. itemsOrder (for ordering the display of items in a given collection)
    shopData['collections'] = yield call(api.fetchCollection, 'collections');
    shopData['items'] = yield call(api.fetchCollection, 'items');
    shopData['customizations'] = yield call(
      api.fetchCollection,
      'customizations'
    );
    shopData['options'] = yield call(api.fetchCollection, 'options');

    const collectionsOrder = yield call(
      api.fetchCollection,
      'collectionsOrder'
    );
    const itemsOrder = yield call(api.fetchCollection, 'itemsOrder');

    if (wholesaleUserDetails) {
      // wholesale user is logged in and we should adjust pricing for wholesale
      const updatedShopData = adjustShopDataToWholesalePrices(
        shopData,
        wholesaleUserDetails
      );
      yield put(
        fetchShopDataSuccess(updatedShopData, collectionsOrder, itemsOrder)
      );
    } else {
      yield put(fetchShopDataSuccess(shopData, collectionsOrder, itemsOrder));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchShopDataFailure('ERROR: fetch shop data failure'));
  }
}

export function* fetchOperatingHours() {
  try {
    const operatingHours = yield call(api.fetchCollection, 'operatingHours');
    yield put(fetchOperatingHoursSuccess(operatingHours));
  } catch (error) {
    console.log(error);
    yield put(
      fetchOperatingHoursFailure('ERROR: fetch operating hours failure')
    );
  }
}

export function* fetchShopDetails() {
  try {
    const shopDetails = yield call(api.fetchCollection, 'shopDetails');
    let actualShopDetails;
    if (_.size(shopDetails) === 0) {
      actualShopDetails = {};
    } else {
      actualShopDetails = _.values(shopDetails)[0];
    }
    yield put(fetchShopDetailsSuccess(actualShopDetails));
  } catch (error) {
    console.log(error);
    yield put(fetchShopDetailsFailure('ERROR: fetch shop details failure'));
  }
}

export function* fetchOverrideDays() {
  try {
    const overrideDays = yield call(api.fetchCollection, 'overrideDays');
    yield put(fetchOverrideDaysSuccess(overrideDays));
  } catch (error) {
    console.log(error);
    yield put(fetchOverrideDaysFailure('ERROR: fetch override days failure'));
  }
}

export function* fetchDeliveryZones() {
  try {
    const deliveryZones = yield call(api.fetchCollection, 'deliveryZones');
    yield put(fetchDeliveryZonesSuccess(deliveryZones));
  } catch (error) {
    console.log(error);
    yield put(fetchDeliveryZonesFailure('ERROR: fetch delivery zones failure'));
  }
}

export function* fetchOrdersThrottlingForDay({ payload: { day } }) {
  try {
    const ordersThrottlingDayData = yield call(
      api.fetchOrdersThrottlingDataForDay,
      day
    );
    if (_.size(ordersThrottlingDayData) !== 1)
      throw new Error('should have exactly 1 doc for throttle day');
    yield put(
      fetchOrdersThrottlingForDaySuccess(_.values(ordersThrottlingDayData)[0])
    );
  } catch (error) {
    console.log(error);
    yield put(
      fetchOrdersThrottlingForDayFailure(
        'Failure to fetch orders throttling data for day'
      )
    );
  }
}

// ---------------------------------------------------
// SETUP SAGAS WHICH LISTEN FOR SPECIFIC REDUX EVENTS
// ---------------------------------------------------
export function* fetchShopDataStart() {
  yield takeLatest(ShopActionTypes.FETCH_SHOP_DATA_START, fetchShopData);
}

export function* fetchOperatingHoursStart() {
  yield takeLatest(
    ShopActionTypes.FETCH_OPERATING_HOURS_START,
    fetchOperatingHours
  );
}

export function* fetchShopDetailsStart() {
  yield takeLatest(ShopActionTypes.FETCH_SHOP_DETAILS_START, fetchShopDetails);
}

export function* fetchOverrideDaysStart() {
  yield takeLatest(
    ShopActionTypes.FETCH_OVERRIDE_DAYS_START,
    fetchOverrideDays
  );
}

export function* fetchDeliveryZonesStart() {
  yield takeLatest(
    ShopActionTypes.FETCH_DELIVERY_ZONES_START,
    fetchDeliveryZones
  );
}

export function* fetchOrdersThrottlingForDayStart() {
  yield takeLatest(
    ShopActionTypes.FETCH_ORDERS_THROTTLING_FOR_DAY_START,
    fetchOrdersThrottlingForDay
  );
}

// -------------------------------------------
// MAKE SAGAS LISTEN
// -------------------------------------------
export function* shopSagas() {
  yield all([
    call(fetchShopDataStart),
    call(fetchOperatingHoursStart),
    call(fetchShopDetailsStart),
    call(fetchOverrideDaysStart),
    call(fetchDeliveryZonesStart),
    call(fetchOrdersThrottlingForDayStart),
  ]);
}
