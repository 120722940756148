import React from 'react'

import {
  TemplateButtonContainer,
} from './template-button.styles'

export const TemplateButton = ({ children, ...props}) => (
  <TemplateButtonContainer {...props}>
    {children}
  </TemplateButtonContainer>
)

export default TemplateButton