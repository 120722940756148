import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import {
  white,
  darkerGray,
  darkGray,
  lightGray,
  black,
} from '../../style-constants';

export const OuterContainer = styled.div`
  position: fixed;
  top: 140;
  width: 100%;
  z-index: 11;
`;
OuterContainer.displayName = 'OuterContainer';

export const CurrentCollectionContainer = styled.div`
  align-items: center;
  background-color: ${black};
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 70px;
`;
CurrentCollectionContainer.displayName = 'CurrentCollectionContainer';

export const TitleContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 70%;
  justify-content: center;
  width: 90%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
TitleContainer.displayName = 'TitleContainer';

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
InnerContainer.displayName = 'InnerContainer';

export const LoaderInnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5px;
`;
LoaderInnerContainer.displayName = 'LoaderInnerContainer';

export const EmptyContainer = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 74vh;
  width: 100%;
`;
EmptyContainer.displayName = 'EmptyContainer';

export const EmptyText = styled.div`
  font-size: 20px;
`;
EmptyText.displayName = 'EmptyText';

export const Title = styled.div`
  background-color: ${white};
  font-size: 18px;
  padding: 0px 15px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
Title.displayName = 'Title';

export const DownIcon = styled(IoIosArrowDown).attrs((props) => ({
  size: 25,
}))`
  background-color: ${white};
`;
DownIcon.displayName = 'DownIcon';

export const UpIcon = styled(IoIosArrowUp).attrs((props) => ({
  size: 25,
}))`
  background-color: ${white};
`;
UpIcon.displayName = 'UpIcon';

export const Container = styled.div`
  align-items: center;
  background-color: ${black};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
  height: 74vh;
  /* max-height: 70vh; */
  padding: 0px 20px 80px 20px;
  width: 100%;
`;
Container.displayName = 'Container';

export const ButtonContainer = styled.button`
  align-items: center;
  background-color: ${white};
  border: none;
  border-bottom-width: 0.25px;
  border-bottom-style: solid;
  border-bottom-color: ${lightGray};
  cursor: pointer;
  color: ${black};
  font-size: 18px;
  font-family: 'Cabin';
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60px;
  padding: 10px;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:active {
    background-color: ${darkGray};
  }
`;
ButtonContainer.displayName = 'ButtonContainer';

export const ButtonText = styled.div`
  height: 100%;
  width: 100%;
`;
ButtonText.displayName = 'ButtonText';
