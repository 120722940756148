import React from 'react';
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';

import TemplateButton from '../template-button/template-button.component';

import {
  lightGray,
  darkerGray,
  darkGray,
  white,
  black,
  whiteTransparent,
  mobileSplitPoint,
  red,
  veryLightGray,
} from '../../style-constants';

import { AVAILABILITY_STATUS__SOLD_OUT } from '../../global.constants';

const MODAL_WIDTH_DESKTOP = 65; // %
const MODAL_WIDTH_MOBILE = 95; // %

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

// solution from https://github.com/reactjs/react-modal/issues/603
const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  // re-assigning bodyOpenClassName generates a warning...
  // const bodyOpenClassName = `${className}__Body--open`;

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );

  // return (
  //   <Modal
  //     portalClassName={className}
  //     className={contentClassName}
  //     overlayClassName={overlayClassName}
  //     bodyOpenClassName={bodyOpenClassName}
  //     {...props}
  //   />
  // );
};

export const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.1s ease-in;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.63);
    bottom: 0px;
    display: flex;
    justify-content: center;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 3;
  }

  &__content {
    background: ${white};
    height: 87%;
    outline: none;
    overflow: scroll;
    margin-top: 90px;
    padding-bottom: 28px;
    width: ${MODAL_WIDTH_DESKTOP}%;
    -webkit-overflow-scrolling: touch;

    /*
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
    */

    @media screen and (max-width: ${mobileSplitPoint}) {
      height: 87%;
      left: 17px;
      top: 75px;
      width: ${MODAL_WIDTH_MOBILE}%;
    }
  }

  &__Body--open {
    overflow: hidden;
  }
`;
StyledModal.displayName = 'StyledModal';

export const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 10px 20px 15px;
  position: fixed;
  width: ${MODAL_WIDTH_DESKTOP - 1}%;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const CloseButtonAction = styled.div`
  align-items: center;
  background-color: ${lightGray};
  border: none;
  border-radius: 30px;
  color: ${black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  justify-content: center;
  padding: 10px;

  &:focus {
    outline: none;
  }
`;
CloseButtonAction.displayName = 'CloseButtonAction';

export const ButtonImg = styled.img``;
ButtonImg.displayName = 'ButtonImg';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
`;
TitleContainer.displayName = 'TitleContainer';

export const ItemTitle = styled.div`
  font-size: 20px;
  margin: ${({ haveImage }) => (haveImage ? '15px' : '100px 15px 15px 15px')};
`;
ItemTitle.displayName = 'ItemTitle';

export const Price = styled.div`
  font-size: 20px;
`;
Price.displayName = 'Price';

export const ItemImageContainer = styled.div`
  margin-top: 50px;
  min-height: 400px;
  width: 100%;
`;
ItemImageContainer.displayName = 'ItemImageContainer';

export const ImageContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const ItemImage = styled.img`
  /* height: 400px; */
  border-radius: 5px;
  width: 400px;
`;
ItemImage.displayName = 'ItemImage';

export const Description = styled.pre`
  font-size: 18px;
  margin: 15px;
  font-family: 'Cabin', sans-serif;
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
`;
Description.displayName = 'Description';

export const CustomizationsContainer = styled.div``;
CustomizationsContainer.displayName = 'CustomizationsContainer';

export const UserInstructionsContainer = styled.div`
  margin-bottom: 150px;
  padding: 0px 15px;
  width: 100%;
`;
UserInstructionsContainer.displayName = 'UserInstructionsContainer';

export const UserInstructions = styled.textarea.attrs((props) => ({}))`
  border: 2px solid ${lightGray};
  color: ${darkerGray};
  font-size: 15px;
  height: 100px;
  padding-left: 5px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${darkerGray};
    padding-left: 3px;
  }
`;
UserInstructions.displayName = 'UserInstructions';

export const ButtonContainer = styled.div`
  align-items: center;
  background-color: ${({ availabilityStatus }) => {
    if (availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT)
      return veryLightGray;

    return whiteTransparent;
  }};
  border-top: ${`1px solid ${lightGray}`};
  bottom: 0%;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  left: ${(100 - MODAL_WIDTH_DESKTOP) / 2}%;
  padding: 15px 30px 20px 15px;
  position: fixed;
  width: ${MODAL_WIDTH_DESKTOP}%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: ${MODAL_WIDTH_DESKTOP}%;
    /* left: 2.5%; */
  }
`;
ButtonContainer.displayName = 'ButtonContainer';

export const OuterSoldOutContainer = styled.div`
  align-items: center;
  /* background-color: ${white}; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
OuterSoldOutContainer.displayName = 'OuterSoldOutContainer';

export const SoldOutContainer = styled.div`
  align-items: center;
  background-color: ${white};
  /* border: 2px solid ${red}; */
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 15px;
`;
SoldOutContainer.displayName = 'SoldOutContainer';

export const SoldOutText = styled.div`
  color: ${red};
  font-size: 18px;
  font-weight: 700;
`;
SoldOutText.displayName = 'SoldOutText';

export const ChangeItemCountContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  margin-right: 15px;
  width: 40%;
`;
ChangeItemCountContainer.displayName = 'ChangeItemCountContainer';

export const CurvedLeftBorder = styled.div`
  background-color: ${whiteTransparent};
  border-radius: 30px 0px 0px 30px;
`;
CurvedLeftBorder.displayName = 'CurvedLeftBorder';

export const CurvedRightBorder = styled.div`
  background-color: ${whiteTransparent};
  border-radius: 0px 30px 30px 0px;
`;
CurvedRightBorder.displayName = 'CurvedRightBorder';

export const SubtractButtonAction = styled.div`
  align-items: center;
  background-color: ${darkGray};
  border: none;
  border-radius: 30px;
  color: ${black};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  height: 40px;
  width: 40px;

  &:focus {
    outline: 0;
  }
`;
SubtractButtonAction.displayName = 'SubtractButtonAction';

export const QuantityContainer = styled.div`
  align-items: center;
  background-color: ${whiteTransparent};
  display: flex;
  flex-direction: row;
  height: 40px;
  min-width: 60px;
  justify-content: center;

  @media screen and (max-width: ${mobileSplitPoint}) {
    min-width: 35px;
  }
`;
QuantityContainer.displayName = 'QuantityContainer';

export const Quantity = styled.div`
  color: ${black};
  font-size: 20px;
`;
Quantity.displayName = 'Quantity';

export const AddButtonAction = styled.div`
  align-items: center;
  background-color: ${darkGray};
  border: none;
  border-radius: 30px;
  color: ${black};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  height: 40px;
  width: 40px;

  &:focus {
    outline: none;
  }
`;
AddButtonAction.displayName = 'AddButtonAction';

export const SubmitButton = styled(TemplateButton)`
  font-size: 18px;
  height: 45px;
  width: 35%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 18px;
  }
`;
SubmitButton.displayName = 'SubmitButton';

export const GstContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border: 1px solid ${black};
  border-radius: 15px;
  bottom: 115px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 110px;
  padding: 5px 10px;
  position: fixed;
  right: 20.2%;
`;
GstContainer.displayName = 'GstContainer';

export const GstText = styled.div`
  font-size: 16px;
`;
GstText.displayName = 'GstText';
