import styled from 'styled-components';

import { mobileSplitPoint } from '../../style-constants';

export const Container = styled.div`
  cursor: pointer;
  margin-right: 20px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin-right: unset;
    margin-left: 20px;
  }
`;
Container.displayName = 'Container';
