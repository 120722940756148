import styled, { keyframes } from 'styled-components';

import TemplateButton from '../template-button/template-button.component';

import {
  red,
  white,
  whiteTransparent,
  whiteTransparentComplete,
  boxShadow,
  mobileSplitPoint,
  blackTransparent,
  veryLightGray,
} from '../../style-constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${whiteTransparentComplete};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 49;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${blackTransparent};
  }
`;

export const CartDropdownContainer = styled.div`
  animation: ${({ isCartHidden }) => (isCartHidden ? fadeOut : fadeIn)} 0.1s
    ease-in;
  background-color: ${white};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  /* min-height: 40vh; */
  max-height: 75vh;
  position: absolute;
  padding: 10px;
  right: 10px;
  top: 80px;

  width: 95%;
  z-index: 50;

  @media screen and (max-width: ${mobileSplitPoint}) {
    right: 10px;
    box-shadow: 8px 33px 101px -14px ${boxShadow};
  }
`;
CartDropdownContainer.displayName = 'CartDropdownContainer';

export const TheNotch = styled.div`
  background-color: ${white};
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  height: 35px;
  right: ${({ wholesaleUserDetails }) =>
    wholesaleUserDetails ? '75px' : '20px'};
  position: fixed;
  top: 66px;
  transform: rotate(-45deg);
  width: 35px;
`;
TheNotch.displayName = 'TheNotch';

export const CartHeaderContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
`;
CartHeaderContainer.displayName = 'CartHeaderContainer';

export const SummaryContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px;
  width: 100%;
`;
SummaryContainer.displayName = 'SummaryContainer';

export const Summary = styled.h3`
  margin: 5px 0px;
`;
Summary.displayName = 'Summary';

export const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  overflow: ${({ isEmpty }) => (isEmpty ? 'unset' : 'scroll')};
  padding-bottom: ${({ isEmpty }) => (isEmpty ? '0px' : '66px')};
`;
CartItemsContainer.displayName = 'CartItemsContainer';

export const EmptyMessageContainer = styled.div`
  font-size: 18px;
  margin: 50px auto;
`;
EmptyMessageContainer.displayName = 'EmptyMessageContainer';

export const ButtonContainer = styled.div`
  align-items: center;
  background-color: ${whiteTransparent};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 10px;
  width: 100%;

  position: absolute;
  bottom: 0px;
  left: 0px;
`;
ButtonContainer.displayName = 'ButtonContainer';

export const CartDropdownButton = styled(TemplateButton)`
  background-color: ${red};
  border: 1px solid red;
  width: 80%;
`;
CartDropdownButton.displayName = 'CartDropdownButton';

export const TotalsContainer = styled.div`
  align-items: stretch;
  background-color: ${veryLightGray};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 50px;
  margin-top: 10px;
  /* needed to stop ios safari from squashing: */
  min-height: 100px;
  padding: 15px 10px;
`;
TotalsContainer.displayName = 'TotalsContainer';

export const ValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
ValueRowContainer.displayName = 'ValueRowContainer';

export const SubvalueTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
`;
SubvalueTitle.displayName = 'SubvalueTitle';

export const SubvalueAmount = styled.div`
  font-weight: 500;
  font-size: 14px;
`;
SubvalueAmount.displayName = 'SubvalueAmount';

export const TotalValueRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;
`;
TotalValueRowContainer.displayName = 'TotalValueRowContainer';

export const TotalTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
`;
TotalTitle.displayName = 'TotalTitle';

export const TotalAmount = styled.div`
  font-weight: 700;
  font-size: 20px;
`;
TotalAmount.displayName = 'TotalAmount';
