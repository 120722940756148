import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ShopDetails from './shop-details.component';

import { selectConfig } from '../../redux/config/config.selectors';
import { selectWholesaleUserDetails } from '../../redux/user/user.selectors';

export const ShopDetailsContainer = ({ config, wholesaleUserDetails }) => {
  return (
    <ShopDetails config={config} wholesaleUserDetails={wholesaleUserDetails} />
  );
};

const mapStateToProps = createStructuredSelector({
  config: selectConfig,
  wholesaleUserDetails: selectWholesaleUserDetails,
});

export default connect(mapStateToProps, null)(ShopDetailsContainer);
