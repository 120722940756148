import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import CartItem from './cart-item.component';

import { calculateItemTotal } from '../../redux/cart/cart.utils';

import {
  clearItemFromCart,
  setCartHidden,
} from '../../redux/cart/cart.actions';

import { selectOrderIsPaid } from '../../redux/cart/cart.selectors';

import {
  addItemToModal,
  addBackAllTheUserCustomizationsForCheckoutItem,
  setIsUpdatingItem,
  setShowItemModal,
} from '../../redux/shop/shop.actions';

import { selectConfig } from '../../redux/config/config.selectors';

export const CartItemContainer = ({
  item,
  clearItemFromCart,
  addItemToModal,
  addBackAllTheUserCustomizationsForCheckoutItem,
  setIsUpdatingItem,
  setShowItemModal,
  orderIsPaid,
  setCartHidden,
  config,
}) => {
  const navigate = useNavigate();

  const { userCustomizations } = item;

  const groupedCustomizations = _.groupBy(
    userCustomizations,
    (customization) => customization.customizationType
  );

  const handleEditClick = (event) => {
    // dont bubble click event up to cart dropdown overlay element.
    event.stopPropagation();

    if (orderIsPaid) return;

    addItemToModal(item);
    addBackAllTheUserCustomizationsForCheckoutItem(userCustomizations);
    setIsUpdatingItem(true);

    if (window.innerWidth > 800) {
      setShowItemModal(true);
    } else {
      setCartHidden(true);

      // note: before pushing the selected item to modal,
      // first replace the last route to be '/'.
      //
      // this will handle case where user opens an item,
      // but then opens cart, clicks edit a cart item, then clicks update.
      // so one modal was open, then another opens to show update item.
      //
      // if you don't call history.replace('/'), then the call of history.goBack()
      // in item-mobile, upon update, will go back to previous item modal.
      // and since we clear out the data for the item, there's no data for the modal.
      // => show a blank modal (expect for user instructions textarea)
      //
      // there has to be a better solution to this...but for now it will do.
      navigate(`/item/${item.key}`, { replace: true });
    }
  };

  const handleClearItemFromCart = (event) => {
    // dont bubble click event up to cart dropdown overlay element.
    // ...otherwise we will accidentally close the dropdown
    event.stopPropagation();
    clearItemFromCart(item);
  };

  return (
    <CartItem
      item={item}
      itemTotal={calculateItemTotal(
        item.priceDetails,
        item.quantity,
        item.userCustomizations,
        item
      )}
      groupedCustomizations={groupedCustomizations}
      orderIsPaid={orderIsPaid}
      handleEditClick={handleEditClick}
      handleClearItemFromCart={handleClearItemFromCart}
      config={config}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  orderIsPaid: selectOrderIsPaid,
  config: selectConfig,
});

const mapDispatchToProps = (dispatch) => ({
  clearItemFromCart: (item) => dispatch(clearItemFromCart(item)),
  addItemToModal: (item) => dispatch(addItemToModal(item)),
  addBackAllTheUserCustomizationsForCheckoutItem: (userCustomizations) =>
    dispatch(
      addBackAllTheUserCustomizationsForCheckoutItem(userCustomizations)
    ),
  setIsUpdatingItem: (isUpdating) => dispatch(setIsUpdatingItem(isUpdating)),
  setShowItemModal: (show) => dispatch(setShowItemModal(show)),
  setCartHidden: (isHidden) => dispatch(setCartHidden(isHidden)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
