import styled from 'styled-components';

import {
  white,
  black,
  mobileSplitPoint,
  darkGray,
  green,
  veryLightGray,
  redAlarmBackground,
  redAlarmText,
  red,
} from '../../style-constants';

export const DesktopHoursInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
DesktopHoursInfoContainer.displayName = 'DesktopHoursInfoContainer';

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
InnerContainer.displayName = 'InnerContainer';

export const HoursInfoContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border-top: 1px solid ${darkGray};
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  padding-left: 10px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 11;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-top: unset;
    bottom: unset;
    height: unset;
    padding: 5px;
    position: unset;
    z-index: unset;
  }
`;
HoursInfoContainer.displayName = 'HoursInfoContainer';

export const InfoContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 15px;
  padding: 5px 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    flex-direction: column;
    min-height: 40px;
    margin-right: 10px;
    padding: 5px;
  }
`;
InfoContainer.displayName = 'InfoContainer';

export const HoursInfo = styled.div`
  color: ${black};
  text-align: center;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 14px;
  }
`;
HoursInfo.displayName = 'HoursInfo';

export const OrderStatusContainer = styled.div`
  align-items;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 10px;
  position: relative;
  width: 100%;
`;
OrderStatusContainer.displayName = 'OrderStatusContainer';

export const FeeInfoContainer = styled.div`
  align-items: center;
  background-color: ${green};
  border: 2px solid ${black};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 10px;
  padding: 5px 10px;
  min-width: 130px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border: 2px solid ${black};
    border-radius: 30px;
    background-color: ${veryLightGray};
    justify-content: center;
    margin: 0px 5px 0px 0px;
    min-width: unset;

    position: absolute;
    left: 0px;
    top: -45px;
  }
`;
FeeInfoContainer.displayName = 'FeeInfoContainer';

export const FeeInfoPostCodesContainer = styled.div`
  align-items: center;
  background-color: ${green};
  border: 2px solid ${black};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 10px;
  padding: 5px 10px;
  min-width: 130px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border: 2px solid ${black};
    border-radius: 30px;
    background-color: ${veryLightGray};
    justify-content: center;
    margin: 0px 5px 0px 0px;
    min-width: unset;
    padding: 5px;

    position: absolute;
    left: 108px;
    top: -45px;
  }
`;
FeeInfoPostCodesContainer.displayName = 'FeeInfoPostCodesContainer';

export const FeeInfoText = styled.div`
  color: ${black};
  font-size: unset;
  text-align: center;
  padding-right: 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    color: ${black};
    font-size: 14px;
  }
`;
FeeInfoText.displayName = 'FeeInfoText';

export const WaitTimeContainer = styled.div`
  align-items: center;
  background-color: ${redAlarmBackground};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 15px;
  padding: 5px 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: unset;
    padding: 6px 10px;

    position: absolute;
    right: 0px;
    top: -45px;
  }
`;
WaitTimeContainer.displayName = 'WaitTimeContainer';

export const WaitTimeText = styled.div`
  color: ${redAlarmText};
  font-weight: 900;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 14px;
  }
`;
WaitTimeText.displayName = 'WaitText';
