import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Routes } from 'react-router-dom';
import _ from 'lodash';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutPage from './pages/checkout/checkout.component';
import ShopPage from './pages/shop/shop.component';
import Header from './components/header/header.component';
import PaymentSuccess from './components/payment-success/payment-success.component';
import ItemModalContainer from './components/item-modal/item-modal.container';
import ItemMobileContainer from './components/item-mobile/item-mobile.container';
import LoginWholesaleCustomer from './components/login-wholesale-customer/login-wholesale-customer.container';
import WholesaleCustomerProfileDropdown from './components/wholesale-customer-profile-dropdown/wholesale-customer-profile-dropdown.container';
import CartDropdown from './components/cart-dropdown/cart-dropdown.component';
import DeliveryZonesModal from './components/delivery-zones-modal/delivery-zones-modal.component';
import Unsubscribe from './components/unsubscribe/unsubscribe.container';
import DynamicDeliveryFeeInfoModal from './components/dynamic-delivery-fee-info-modal/dynamic-delivery-fee-info-modal.component';
import ShopInfoModal from './components/shop-info-modal/shop-info-modal.component';
import QrCodeWrapper from './components/qr-code-wrapper/qr-code-wrapper.component';
import NotFound from './components/not-found/not-found.component';

import { fetchConfigStart } from './redux/config/config.actions';
import { selectConfig } from './redux//config/config.selectors';
import {
  fetchShopDataStart,
  fetchOperatingHoursStart,
  fetchShopDetailsStart,
  fetchOverrideDaysStart,
  fetchDeliveryZonesStart,
} from './redux/shop/shop.actions';
import { setOrderType } from './redux/cart/cart.actions';
import { selectOrderType, selectCartHidden } from './redux/cart/cart.selectors';
import {
  selectIsWholesaleUserProfileHidden,
  selectWholesaleUserDetails,
} from './redux/user/user.selectors';

import { GlobalStyle } from './global.styles';
import { AppContainer, ContentContainer } from './App.styles';
import {
  STRIPE_PROVIDER_API_KEY,
  ORDERTYPES_TO_SINGULAR_TYPES,
} from './global.constants';

// make sure to call 'loadStripe' outside of a component's render to
// avoid recreating the 'Stripe' object on every render
const stripePromise = loadStripe(STRIPE_PROVIDER_API_KEY);

// has the app been initialized yet
let initialized = false;

export const App = ({
  fetchConfigStart,
  fetchShopDataStart,
  fetchOperatingHoursStart,
  fetchShopDetailsStart,
  fetchOverrideDaysStart,
  config,
  orderType,
  isWholesaleUserProfileHidden,
  setOrderType,
  isCartHidden,
  wholesaleUserDetails,
  fetchDeliveryZonesStart,
}) => {
  const handleVisibilityChange = () => {
    if (window.document.visibilityState === 'visible') {
      refetchData();
    } else {
      // TODO
      // do we need to stop/cleanup anything
      // on window hidden?
    }
  };

  const refetchData = async () => {
    fetchConfigStart();
    // pass in wholesaleUserDetails when fetching shop data.
    // => if wholesale user is logged in then we will need to update
    // the shop data to reflect wholesale pricing, and not retail default prices
    fetchShopDataStart(wholesaleUserDetails, false);
    fetchOverrideDaysStart();
    fetchOperatingHoursStart();
    fetchShopDetailsStart();
    fetchDeliveryZonesStart();
  };

  useEffect(() => {
    fetchConfigStart();
    // pass in wholesaleUserDetails when fetching shop data.
    // => if wholesale user is logged in then we will need to update
    // the shop data to reflect wholesale pricing, and not retail default prices
    fetchShopDataStart(wholesaleUserDetails, false);
    fetchOverrideDaysStart();
    fetchOperatingHoursStart();
    fetchShopDetailsStart();
    fetchDeliveryZonesStart();

    if (!initialized) {
      // if window is refocused after previously loading
      // then we should refetch to avoid having stale
      // shop data
      if (typeof window !== undefined && window.addEventListener) {
        window.addEventListener(
          'visibilitychange',
          handleVisibilityChange,
          false
        );
        window.addEventListener('focus', refetchData, false);
      }

      initialized = true;
    }
    const unsubscribe = () => {
      window.removeEventListener('focus', refetchData);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      initialized = false;
    };

    return unsubscribe;
    // need to recalc event listeners when wholesaleUserDetails changes. otherwise,
    // the listeners will always hold onto the old state of wholesaleUserDetails
    // this leads to incorrect shop data pricing for logged in/out state.
  }, [wholesaleUserDetails]);

  useEffect(() => {
    if (_.isEmpty(config)) return;

    const { orderTypesRetail, orderTypesWholesale } = config;

    let _orderTypes = wholesaleUserDetails
      ? orderTypesWholesale
      : orderTypesRetail;

    if (_.size(ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes]) > 0) {
      if (
        !orderType ||
        !ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes].includes(orderType)
      ) {
        // set if
        // 1. orderType is undefined (default reducer state)
        // 2. admin has changed store orderTypes, and current orderType in cart reducer
        //    is no longer supported
        setOrderType(ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes][0]);
        return;
      }
    }
  }, [config]);

  // dont even attempt to show the app if config is {}
  // => stop from proceeding to use an unconfigured store.
  // blank screen should prompt user to refresh
  return (
    <AppContainer>
      {isWholesaleUserProfileHidden ? null : (
        <WholesaleCustomerProfileDropdown />
      )}
      {isCartHidden ? null : <CartDropdown />}
      <ContentContainer>
        <GlobalStyle />
        <ItemModalContainer />
        <DeliveryZonesModal />
        <DynamicDeliveryFeeInfoModal />
        <ShopInfoModal />
        <Header />
        {_.isEmpty(config) ? null : (
          <Elements stripe={stripePromise}>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<ShopPage />} />
              <Route
                path="/qr-code/*"
                element={
                  <QrCodeWrapper
                    config={config}
                    wholesaleUserDetails={wholesaleUserDetails}
                  />
                }
              />
              <Route
                path="/checkout/payment-success"
                element={<PaymentSuccess />}
              />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/item/:itemId" element={<ItemMobileContainer />} />
              <Route
                path="/wholesaleLogin"
                element={<LoginWholesaleCustomer />}
              />
              <Route
                path="/unsubscribe/:method/:val"
                element={<Unsubscribe />}
              />
            </Routes>
          </Elements>
        )}
      </ContentContainer>
    </AppContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  config: selectConfig,
  orderType: selectOrderType,
  isWholesaleUserProfileHidden: selectIsWholesaleUserProfileHidden,
  isCartHidden: selectCartHidden,
  wholesaleUserDetails: selectWholesaleUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchConfigStart: () => dispatch(fetchConfigStart()),
  fetchShopDataStart: (wholesaleUserDetails, showLoadingState) =>
    dispatch(fetchShopDataStart(wholesaleUserDetails, showLoadingState)),
  fetchOperatingHoursStart: () => dispatch(fetchOperatingHoursStart()),
  fetchShopDetailsStart: () => dispatch(fetchShopDetailsStart()),
  fetchOverrideDaysStart: () => dispatch(fetchOverrideDaysStart()),
  setOrderType: (orderType) => dispatch(setOrderType(orderType)),
  fetchDeliveryZonesStart: () => dispatch(fetchDeliveryZonesStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
