import styled from 'styled-components';
import { lightGray, darkBlue, black } from '../../style-constants';

export const AddOptionButton = styled.button`
  background-color: ${({ isSelected }) => (isSelected ? black : 'white')};
  border: 1px solid ${({ isSelected }) => (isSelected ? black : lightGray)};
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  font-family: 'Cabin';
  margin: 2px;
  padding: 10px;

  -webkit-tap-highlight-color: transparent;

  &:hover {
    /* background-color: ${({ isSelected }) =>
      isSelected ? darkBlue : lightGray};
    border: 1px solid ${lightGray};
    transition: background-color 400ms cubic-bezier(0.4, 0, 0.4, 1);
    transition: border 400ms cubic-bezier(0.4, 0, 0.4, 1); */
  }

  &:active {
    /* background-color: ${({ isSelected }) =>
      isSelected ? lightGray : darkBlue};
    transition: background-color 100ms cubic-bezier(0.4, 0, 0.4, 1); */
  }

  &:focus {
    outline: 0;
  }
`;
AddOptionButton.displayName = 'AddOptionButton';

export const ButtonText = styled.div`
  color: ${({ isSelected }) => (isSelected ? 'white' : black)};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.075em;
  text-align: center;
`;
ButtonText.displayName = 'ButtonText';
