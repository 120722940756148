import _ from 'lodash';

export const addOrRemoveHeartedItem = (item, heartedItems) => {
  const isAlreadyHearted = _.find(heartedItems, (heartedItem) => {
    return heartedItem.key === item.key;
  });

  if (isAlreadyHearted) {
    // remove item from hearted items
    return _.filter(heartedItems, (heartedItem) => {
      return heartedItem.key !== item.key;
    });
  } else {
    // add the item to hearted items
    return [
      ...heartedItems,
      {
        ...item,
        isHearted: true,
      },
    ];
  }
};
