import QrCodesActionTypes from './qr-codes.types';

export const INITIAL_STATE = {
  tableNumber: null,
  voucherId: null,
};

export const qrCodesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QrCodesActionTypes.SET_TABLE_NUMBER:
      return {
        ...state,
        tableNumber: action.payload.tableNumber,
      };
    case QrCodesActionTypes.SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload.orderType,
      };
    case QrCodesActionTypes.SET_VOUCHER_ID:
      return {
        ...state,
        voucherId: action.payload.voucherId,
      };
    default:
      return state;
  }
};

export default qrCodesReducer;
