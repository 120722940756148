import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { createStructuredSelector } from 'reselect';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import {
  setTableNumber,
  setVoucherId,
} from '../../redux/qr-codes/qr-codes.actions';

import { setUserCredentials as setReduxUserCredentials } from '../../redux/user/user.actions';
import { setOrderType } from '../../redux/cart/cart.actions';

import { ORDERTYPES_TO_SINGULAR_TYPES } from '../../global.constants';

export const QrCodeWrapper = ({
  config,
  wholesaleUserDetails,
  setTableNumber,
  setOrderType,
  setVoucherId,
  setReduxUserCredentials,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { orderTypesRetail, orderTypesWholesale } = config;

  console.log(JSON.stringify(config, null, 2));

  useEffect(() => {
    const tableNumber = searchParams.get('table-number'); // string
    const orderType = searchParams.get('order-type'); // string
    const voucherId = searchParams.get('voucher-id'); // string
    const ORDER_TYPES_ARRAY = wholesaleUserDetails
      ? ORDERTYPES_TO_SINGULAR_TYPES[orderTypesWholesale]
      : ORDERTYPES_TO_SINGULAR_TYPES[orderTypesRetail];

    const userCredentials = {};

    if (tableNumber !== undefined || tableNumber !== null) {
      setTableNumber(tableNumber);
      userCredentials.tableNumber = tableNumber;
    }

    if (
      (orderType !== undefined || orderType !== null) &&
      ORDER_TYPES_ARRAY.includes(orderType)
    ) {
      setOrderType(orderType);
      userCredentials.orderType = orderType;
    }

    if (!_.isEmpty(userCredentials)) setReduxUserCredentials(userCredentials);

    if (voucherId !== undefined || voucherId !== null) {
      setVoucherId(voucherId);
    }

    navigate('/', { reset: true });
  }, []);

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  setTableNumber: (tableNumber) => dispatch(setTableNumber(tableNumber)),
  setOrderType: (orderType) => dispatch(setOrderType(orderType)),
  setVoucherId: (voucherId) => dispatch(setVoucherId(voucherId)),
  setReduxUserCredentials: (userCredentials) =>
    dispatch(setReduxUserCredentials(userCredentials)),
  setOrderType: (orderType) => dispatch(setOrderType(orderType)),
});

export default connect(null, mapDispatchToProps)(QrCodeWrapper);
