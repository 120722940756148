import styled from 'styled-components';

import {
  white,
  veryLightGray,
  darkerGray,
  green,
  mobileSplitPoint,
  black,
  lightGray,
  redBackgroundLight,
  redBackgroundVeryLightTransparent,
  red,
} from '../../style-constants';

export const Container = styled.div`
  align-items: stretch;
  margin-bottom: 10px;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    flex-grow: 2;
    margin-top: 10px;
    margin-bottom: unset;
    padding: 20px 10px 0px 10px;
    width: 100%;
  }
`;
Container.displayName = 'Container';

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 10px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    justify-content: center;
  }
`;
HeaderContainer.displayName = 'HeaderContainer';

export const BodyContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
BodyContainer.displayName = 'BodyContainer';

export const Title = styled.div`
  /* border-bottom: 1px solid ${darkerGray}; */
  font-size: 26px;
  margin-bottom: 5px;
  padding-right: 2px;
  padding-left: 1px;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 22px;
    padding-left: 5px;
    text-align: center;
  }
`;
Title.displayName = 'Title';

export const Row = styled.div`
  align-items: center;
  /* background-color: ${redBackgroundLight}; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 10px;
  width: 100%;
`;
Row.displayName = 'Row';

export const OrderButton = styled.button`
  align-items: stretch;
  background-color: ${veryLightGray};
  border: unset;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: none; */
  color: ${black};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: 'Cabin';
  font-size: 100%;
  font-weight: 700;
  justify-content: flex-start;
  margin: 5px 0px;
  padding: 0.75em;
  max-width: 49%;
  width: 49%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin: 5px 0px;
    max-width: unset;
    width: 100%;
  }
`;
OrderButton.displayName = 'OrderButton';

export const TopContainer = styled.div`
  align-items: center;
  padding: 0px 5px 15px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
TopContainer.displayName = 'TopContainer';

export const FooterContainer = styled.div`
  align-items: center;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
FooterContainer.displayName = 'FooterContainer';

export const RemoveButton = styled.button`
  align-items: center;
  background-color: ${white};
  /* border: 2px solid ${black}; */
  border: unset;
  border-radius: 30px;
  box-shadow: none;
  color: ${black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'Cabin';
  font-size: 100%;
  font-weight: 700;
  justify-content: center;
  padding: 0.75em;
  /* width: 100px; */

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
RemoveButton.displayName = 'RemoveButton';

export const ButtonText = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 700;
  padding-left: 10px;
`;
ButtonText.displayName = 'ButtonText';

export const AddToCartContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border: 2px solid ${black};
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 15px;
`;
AddToCartContainer.displayName = 'AddToCartContainer';

export const AddToCartText = styled.div`
  color: ${black};
  font-size: 16px;
  font-weight: 700;
`;
AddToCartText.displayName = 'AddToCartText';
