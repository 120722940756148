import * as _shopApi from './shop-api';
import * as _cartApi from './cart-api';
import * as _configApi from './config-api';
import * as _userApi from './user-api';
import * as _qrCodesApi from './qr-codes-api';

export const shopApi = _shopApi;
export const cartApi = _cartApi;
export const configApi = _configApi;
export const userApi = _userApi;
export const qrCodesApi = _qrCodesApi;
