import React from 'react';

import {
  Container,
  TitleContainer,
  Title,
  ButtonContainer,
  OrderButton,
} from './wholesale-ordering-methods.styles';

import { ADD_TO_ACCOUNT, PAY_NOW } from '../../global.constants';

export const WholesaleOrderingMethods = ({
  wholesalePaymentMethod,
  setWholesalePaymentMethod,
}) => {
  return (
    <Container>
      <TitleContainer>
        <Title>Select payment method</Title>
      </TitleContainer>
      <ButtonContainer>
        <OrderButton
          isSelected={wholesalePaymentMethod === ADD_TO_ACCOUNT}
          onClick={(e) => {
            setWholesalePaymentMethod(ADD_TO_ACCOUNT);
          }}
          type="button"
          disabled={false}
        >
          Add to Account
        </OrderButton>
        <OrderButton
          isSelected={wholesalePaymentMethod === PAY_NOW}
          onClick={(e) => {
            setWholesalePaymentMethod(PAY_NOW);
          }}
          type="button"
          disabled={false}
        >
          Use Credit Card
        </OrderButton>
      </ButtonContainer>
    </Container>
  );
};

export default WholesaleOrderingMethods;
