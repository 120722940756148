import styled from 'styled-components';

import { darkGreen, lightGray, red } from '../../style-constants';

export const TemplateButtonContainer = styled.button`
  background-color: ${({ disabled }) => (disabled ? lightGray : red)};
  border: 1px solid ${({ disabled }) => (disabled ? lightGray : red)};
  border-radius: 30px;
  box-shadow: none;
  color: #fff;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  font-family: 'Cabin';
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0.075em;
  padding: 0.75rem;
  text-align: center;
  width: inherit;

  -webkit-tap-highlight-color: transparent;

  &:hover {
    /* background-color: ${({ disabled }) =>
      disabled ? lightGray : darkGreen};
    border: 1px solid ${({ disabled }) => (disabled ? lightGray : darkGreen)};
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.4, 1);
    transition: border 200ms cubic-bezier(0.4, 0, 0.4, 1); */
  }

  &:focus {
    outline: 0;
  }
`;
TemplateButtonContainer.displayName = 'TemplateButtonContainer';
