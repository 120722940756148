import { useLayoutEffect } from 'react';

export const useScrollLock = (isLocked) => {
  const originalStyle = window.getComputedStyle(document.body).overflow;

  useLayoutEffect(() => {
    if (isLocked) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isLocked]);
};
