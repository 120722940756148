import styled from 'styled-components';

import {
  white,
  darkerGray,
  collectionSplitPoint,
  mobileSplitPoint,
} from '../../style-constants';

export const CollectionContainer = styled.div`
  background-color: ${white};
  margin: 40px 0px;

  @media screen and (max-width: ${mobileSplitPoint}) {
  }
`;
CollectionContainer.displayName = 'CollectionContainer';

export const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    margin-bottom: 20px;
  }
`;
TitleContainer.displayName = 'TitleContainer';

export const Title = styled.div`
  /* border-bottom: 1px solid ${darkerGray}; */
  font-size: 26px;
  margin-bottom: 5px;
  padding-right: 2px;
  padding-left: 1px;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 22px;
    padding-left: 5px;
    text-align: center;
  }
`;
Title.displayName = 'Title';

export const SubTitle = styled.pre`
  padding-bottom: 10px;
  padding-left: 2px;

  font-family: 'Cabin', sans-serif;
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
  padding: 10px 20px;

  font-family: 'Cabin', sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding: 0px 20px;
  }
`;
SubTitle.displayName = 'SubTitle';

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: ${collectionSplitPoint}) {
    align-items: center;
    flex-direction: column;
    flex-wrap: unset;
    justify-content: flex-start;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    flex-direction: column;
    flex-wrap: unset;
    justify-content: flex-start;
  }
`;
ItemsContainer.displayName = 'ItemsContainer';
