import React, { createRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AnimateGroup } from 'react-animation';
import _ from 'lodash';
import { FaArrowRight } from 'react-icons/fa';

import OrderMethod from '../order-method/order-method.container';
import CartItem from '../cart-item/cart-item.container';

import {
  selectCartItems,
  selectOrderType,
} from '../../redux/cart/cart.selectors';

import { selectShopDetails } from '../../redux/shop/shop.selectors';

import {
  createUniqueIdentifier,
  calcCartTotal,
  calcCartSubtotalAndCartGst,
} from '../../redux/cart/cart.utils';
import { selectConfig } from '../../redux/config/config.selectors';

import {
  OuterContainer,
  CartDesktopContainer,
  InnerContainer,
  Container,
  CartItemsContainer,
  TotalsContainer,
  ValueRowContainer,
  SubvalueTitle,
  SubvalueAmount,
  TotalValueRowContainer,
  TotalTitle,
  TotalAmount,
  SubmitButton,
  SubmitButtonText,
  EmptyCartMsg,
} from './cart-desktop.styles';

import { PAYMENT_URL, DELIVERY } from '../../global.constants';
import { white } from '../../style-constants';

export const CartDesktop = ({ cartItems, orderType, shopDetails, config }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { displayPricingOnline } = config;
  const cartScrollRef = createRef();
  const { deliveryMinimum } = shopDetails;

  const [previousCartLength, setPreviousCartLength] = useState(
    cartItems.length
  );
  const [totals, setTotals] = useState({
    cartSubtotal: 0.0,
    cartGst: 0.0,
    cartTotal: 0.0,
  });

  useEffect(() => {
    // if user adds a new item then always scroll to bottom of cart, as that is
    // where the new item will be located.
    // if user removes an item from cart, don't scroll to bottom of cart. stay in place,
    // there's no reason to look at the end of the cart items when removing an item.
    if (cartItems.length > previousCartLength) {
      cartScrollRef.current.scrollTop = cartScrollRef.current.scrollHeight;
    }

    setPreviousCartLength(cartItems.length);
  }, [cartItems.length]);

  useEffect(() => {
    const cartTotal = calcCartTotal(cartItems);
    const { cartSubtotal, cartGst } = calcCartSubtotalAndCartGst(cartItems);

    setTotals({
      cartSubtotal,
      cartGst,
      cartTotal,
    });
  }, [cartItems]);

  const shouldPaymentButtonDisplay = () => {
    if (location.pathname === PAYMENT_URL) return false;

    return true;
  };

  const handleClick = () => {
    if (orderType === DELIVERY && totals.cartTotal < deliveryMinimum) {
      Swal.fire(
        `Delivery mininum amount is $${deliveryMinimum}. Please add more items.`
      );
      return;
    }

    navigate(PAYMENT_URL);
  };

  return (
    <OuterContainer>
      <CartDesktopContainer>
        {shouldPaymentButtonDisplay() ? (
          <SubmitButton onClick={handleClick}>
            <SubmitButtonText>Go to Checkout</SubmitButtonText>
            <FaArrowRight style={{ color: white }} />
          </SubmitButton>
        ) : null}
        <OrderMethod />
        <InnerContainer ref={cartScrollRef}>
          <Container>
            <CartItemsContainer>
              {cartItems.length ? (
                <AnimateGroup animate="group">
                  {cartItems.map((cartItem) => {
                    return (
                      <CartItem
                        key={createUniqueIdentifier(cartItem)}
                        item={cartItem}
                      />
                    );
                  })}
                </AnimateGroup>
              ) : (
                <EmptyCartMsg>Your cart is empty</EmptyCartMsg>
              )}
            </CartItemsContainer>
          </Container>
        </InnerContainer>
        {displayPricingOnline && (
          <TotalsContainer>
            <ValueRowContainer>
              <SubvalueTitle>Subtotal</SubvalueTitle>
              {_.isNumber(totals.cartSubtotal) && (
                <SubvalueAmount>
                  {totals.cartSubtotal.toFixed(2)}
                </SubvalueAmount>
              )}
            </ValueRowContainer>
            <ValueRowContainer>
              <SubvalueTitle>* GST</SubvalueTitle>
              {_.isNumber(totals.cartGst) && (
                <SubvalueAmount>{totals.cartGst.toFixed(2)}</SubvalueAmount>
              )}
            </ValueRowContainer>
            <TotalValueRowContainer>
              <TotalTitle>Total</TotalTitle>
              {_.isNumber(totals.cartTotal) && (
                <TotalAmount>${totals.cartTotal.toFixed(2)}</TotalAmount>
              )}
            </TotalValueRowContainer>
          </TotalsContainer>
        )}
      </CartDesktopContainer>
    </OuterContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  orderType: selectOrderType,
  shopDetails: selectShopDetails,
  config: selectConfig,
});

export default connect(mapStateToProps, null)(CartDesktop);
