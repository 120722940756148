import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectShouldSaveOrder } from '../../redux/cart/cart.selectors';

import { setShouldSaveOrder } from '../../redux/cart/cart.actions';

import {
  SaveOrderContainer,
  SaveOrderText,
  SwitchReact,
} from './save-order-switch.styles';

export const SaveOrderSwitch = ({ shouldSaveOrder, setShouldSaveOrder }) => {
  const handledSaveOrderForNextTime = (checked) => {
    setShouldSaveOrder(checked);
  };

  return (
    <SaveOrderContainer>
      <SaveOrderText>Save order for next time</SaveOrderText>
      <SwitchReact
        checked={shouldSaveOrder}
        onChange={(checked) => handledSaveOrderForNextTime(checked)}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={10}
        width={35}
        className="react-switch"
        id="material-switch-save-order-for-next-time"
      />
    </SaveOrderContainer>
  );
};
const mapStateToProps = createStructuredSelector({
  shouldSaveOrder: selectShouldSaveOrder,
});

const mapDispatchToProps = (dispatch) => ({
  setShouldSaveOrder: (shouldSaveOrder) =>
    dispatch(setShouldSaveOrder(shouldSaveOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveOrderSwitch);
