import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import BounceLoader from 'react-spinners/BounceLoader';

import logoVisa from '../../assets/visa.png';
import logoMastercard from '../../assets/mastercard.png';
import logoAmericanExpress from '../../assets/american-express.png';
import logoDinersClub from '../../assets/diners-club.png';
import logoDefault from '../../assets/default-credit-card.png';

import { selectIsLoading } from '../../redux/user/user.selectors';

import {
  Container,
  RowContainer,
  SelectContainer,
  CheckMarkContainer,
  CheckedIcon,
  UncheckedIcon,
  BrandContainer,
  ImageContainer,
  BrandImage,
  BrandImageMastercard,
  BrandImageAmex,
  BrandText,
  CardDetailsContainer,
  CardDetail,
  CardDetailLast4,
  DeleteContainer,
  DeleteIcon,
} from './payment-method.styles';

import { darkerGray } from '../../style-constants';

export const PaymentMethod = ({
  paymentMethod,
  paymentMethodsState,
  handleSelectCardForPaymentMethodClick,
  isLoading,
  handleDetachPaymentMethod,
}) => {
  const { id, brand, expMonth, expYear, last4 } = paymentMethod;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (showLoader && !isLoading) setShowLoader(false);
  }, [isLoading]);

  const detachPaymentMethod = () => {
    if (!showLoader) setShowLoader(true);

    handleDetachPaymentMethod(id);
  };

  const calcCardImage = () => {
    const type = brand.toUpperCase();
    if (type === 'VISA') return <BrandImage src={logoVisa} />;
    if (type === 'MASTERCARD')
      return <BrandImageMastercard src={logoMastercard} />;
    if (type === 'AMEX') return <BrandImageAmex src={logoAmericanExpress} />;
    if (type === 'DINERS CLUB') return <BrandImage src={logoDinersClub} />;

    return <BrandImage src={logoDefault} />;
  };

  return (
    <Container isSelected={id === paymentMethodsState.selectedPaymentMethodId}>
      <RowContainer>
        <SelectContainer
          onClick={() => handleSelectCardForPaymentMethodClick(id)}
        >
          <CheckMarkContainer>
            {id === paymentMethodsState.selectedPaymentMethodId ? (
              <CheckedIcon />
            ) : (
              <UncheckedIcon />
            )}
          </CheckMarkContainer>
          <BrandContainer>
            <ImageContainer>{calcCardImage()}</ImageContainer>
            <BrandText>{brand.toUpperCase()}</BrandText>
          </BrandContainer>
          <CardDetailsContainer>
            <CardDetail>{`${expMonth}/${expYear}`}</CardDetail>
            <CardDetailLast4>{`****${last4}`}</CardDetailLast4>
          </CardDetailsContainer>
        </SelectContainer>
        <DeleteContainer onClick={detachPaymentMethod}>
          {showLoader ? (
            <BounceLoader color={darkerGray} size={20} />
          ) : (
            <DeleteIcon size={25} />
          )}
        </DeleteContainer>
      </RowContainer>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
});

export default connect(mapStateToProps, null)(PaymentMethod);
