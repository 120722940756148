import styled from 'styled-components';

import TemplateButton from '../template-button/template-button.component';

import { red, mobileSplitPoint, white } from '../../style-constants';

export const SubmitButtonContainer = styled.div`
  background-color: ${white};
  padding: 15px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${white};
    margin: unset;
    padding: 15px 15px 50px 15px;
  }
`;
SubmitButtonContainer.displayName = 'SubmiteButtonContainer';

export const SubmitButton = styled(TemplateButton)`
  align-items: center;
  background-color: ${red};
  border: 1px solid red;
  border-radius-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  padding: 15px;
`;
SubmitButton.displayName = 'SubmitButton';

export const SubmitButtonText = styled.div`
  color: ${white};
  font-size: 18px;
  font-weight: 600;
  padding-right: 10px;
`;
SubmitButtonText.displayName = 'SubmitButtonText';
