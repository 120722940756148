import styled, { keyframes } from 'styled-components';

import {
  white,
  whiteTransparentComplete,
  boxShadow,
  mobileSplitPoint,
  darkGray,
  lightGray,
  whiteOff,
  blackTransparent,
  green,
  red,
  lightBlue,
} from '../../style-constants';

export const Container = styled.div`
  align-items: center;
  background-color: ${lightGray};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 10%;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${darkGray};
    /* margin-top: 50px; */
    /* padding: 15%; */
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: space-between;
  padding: 50px;
  width: 55%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: 63vh;
    /* justify-content: flex-start; */
    justify-content: space-between;
    padding: 20px;
    width: 100%;
  }
`;
ContentContainer.displayName = 'ContentContainer';

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
InnerContainer.displayName = 'InnerContainer';

export const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
`;
Title.displayName = 'Title';

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
  width: 100%;
`;
ImageContainer.displayName = 'ImageContainer';

export const UnsubscribeImage = styled.img`
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    height: 100%;
    width: 100%;
  }
`;
UnsubscribeImage.displayName = 'UnsubscribeImage';

export const BlurbContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0px;
  width: 100%;
`;
BlurbContainer.displayName = 'BlurbContainer';

export const BlurbText = styled.div``;
BlurbText.displayName = 'BlurbText';

export const ButtonOuterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  /* height: 80px; */
  justify-content: flex-end;
  width: 100%;
`;
ButtonOuterContainer.displayName = 'ButtonOuterContainer';

export const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 15px;
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export const ButtonContainer = styled.div`
  align-items: center;
  background-color: ${({ isLoading, unsubscribedMsg }) =>
    isLoading || !!unsubscribedMsg ? darkGray : red};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  pointer-events: ${({ isLoading, unsubscribedMsg }) =>
    isLoading || !!unsubscribedMsg ? 'none' : 'unset'};
  width: 110px;
`;
ButtonContainer.displayName = 'ButtonContainer';

export const ButtonText = styled.div`
  color: ${whiteOff};
  font-size: 18px;
`;
ButtonText.displayName = 'ButtonText';

export const MsgContainer = styled.div`
  align-items: center;
  background-color: ${lightBlue};
  border-radius: 30px
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0px;
  width: 60%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
  }
`;
MsgContainer.displayName = 'MsgContainer';

export const Msg = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding: 10px 20px;
`;
Msg.displayName = 'Msg';
