/*global google*/
// the above line tells eslint that google is a global name
// and is available at runtime
// https://github.com/hibiken/react-places-autocomplete/issues/150
// https://github.com/hibiken/react-places-autocomplete/issues/57#issuecomment-335043874

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FormInput from '../form-input/form-input.component';

import { selectConfig } from '../../redux/config/config.selectors';

import {
  selectShopDetails,
  selectDeliveryZones,
} from '../../redux/shop/shop.selectors';

import {
  setDeliveryFeePostcodes,
  setDeliveryFeeDynamic,
} from '../../redux/cart/cart.actions';
import { calcDynamicFee } from '../../utils/cart-utils';
import { selectCartTotal } from '../../redux/cart/cart.selectors';
import { calcHasFreeDelivery } from '../../redux/cart/cart.utils';

import { geocodeAddress } from '../../pages/checkout/checkout.component';

import {
  RowsContainer,
  Row,
  RowActive,
  AddressContainer,
} from './payment-delivery-address.styles';

import {
  DELIVERY_FEE_MODE_DYNAMIC,
  DELIVERY_CHECK_TYPE_RADIUS,
  DELIVERY_CHECK_TYPE_POSTCODES,
} from '../../global.constants';

export const PaymentDeliveryAddress = ({
  address,
  addressError,
  handleBlur,
  handleAddressChange,
  shopDetails,
  deliveryZones,
  config,
  setDeliveryFeePostcodes,
  setDeliveryFeeDynamic,
  cartTotal,
}) => {
  const {
    deliveryFeeDynamicTypeMinimum,
    deliveryFeeDynamicTypeMaximum,
    deliveryFreeThreshold,
    shopLatitude,
    shopLongitude,
    shopAddressSearchResultsRadius, // in metres
  } = shopDetails;
  const {
    deliveryFeeCalculationType,
    deliveryFeeDynamicModeCostFunction,
    deliveryToAddressCheckType,
  } = config;

  const MAX_BOUND = shopAddressSearchResultsRadius / 1000.0;

  // lat lng of warralily cockatoo cafe. numbers are found by
  // searching address in google maps webpage
  const SHOP_LAT_LNG = new google.maps.LatLng(shopLatitude, shopLongitude);

  const addressSearchOptions = {
    location: SHOP_LAT_LNG,
    radius: shopAddressSearchResultsRadius,
    type: ['Address'],
  };

  const handleBlurInternal = async () => {
    /*
        NOTES ABOUT react-toast
          The <ToastContainer /> is not located in this component, but is in payment-details.component.
          ...further up the component tree because payment details container also uses react-toast for
          wholesale address checking upon component load
    */
    // have this first
    // call the chore handleBlur function in payment details container
    handleBlur('address');

    if (!address) {
      // reset delivery distance/postcode related fees
      setDeliveryFeeDynamic(0.0);
      setDeliveryFeePostcodes(0.0);
      return;
    }

    const { distance } = await geocodeAddress(address, shopDetails);
    if (_.isNumber(distance) && _.isNumber(MAX_BOUND) && distance > MAX_BOUND) {
      toast.error(
        `Sorry your address is ${distance.toFixed(
          2
        )} kms away and outside our delivery radius of ${MAX_BOUND.toFixed(
          2
        )} kms`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    if (
      deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS &&
      deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC
    ) {
      let price = calcDynamicFee(
        distance,
        deliveryFeeDynamicModeCostFunction,
        deliveryFeeDynamicTypeMinimum,
        deliveryFeeDynamicTypeMaximum,
        shopAddressSearchResultsRadius
      );

      // first check if qualify for free delivery
      price = calcHasFreeDelivery(cartTotal, price, deliveryFreeThreshold)
        ? 0.0
        : price;

      const deliveryPriceBlurb = !price
        ? 'Free Delivery 😃'
        : `Delivery fee for ${
            _.isNumber(distance) ? distance.toFixed(2) : 'UNAVAILABLE'
          } kms is $ ${_.isNumber(price) ? price.toFixed(2) : 'UNAVAILABLE'}`;

      toast.info(deliveryPriceBlurb, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // set the dynamic delivery fee
      setDeliveryFeeDynamic(price);
    } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
      const { distance, postCodeComponent } = await geocodeAddress(
        address,
        MAX_BOUND
      );

      if (!postCodeComponent) {
        // geocoder returned results which did not contain post code component
        if (address) {
          toast.error(
            `Sorry we failed to find postal code for your address. Please use a more detailed address.`,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        return;
      }
      const { long_name: postCodeForOrder } = postCodeComponent;

      const matchedZoneMaybe = _.find(deliveryZones, (zone) => {
        const { postCodes } = zone;
        const matchedPostCodeObjMaybe = _.find(postCodes, (postCodeObj) => {
          const { postCode } = postCodeObj;
          return postCode === postCodeForOrder;
        });
        return matchedPostCodeObjMaybe;
      });

      if (matchedZoneMaybe) {
        const { title, price } = matchedZoneMaybe;
        toast.info(
          `Our delivery fee to postcode ${postCodeForOrder} is $${price}`,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        // set the delivery fee in state
        setDeliveryFeePostcodes(price);
      } else {
        if (address) {
          toast.error(
            `Sorry we dont deliver to the postcode ${postCodeForOrder}`,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    } else {
      // no-op
    }
  };

  return (
    <AddressContainer onBlur={handleBlurInternal}>
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressChange}
        searchOptions={addressSearchOptions}
        shouldFetchSuggestions={address.length > 2}
        onError={(status, clearSuggestions) => {
          clearSuggestions();
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <FormInput
              {...getInputProps({
                label: '* Delivery Address',
                // required: true,
                className: 'addressInput',
                error: addressError,
              })}
              onFocus={() => {
                const SPACE = 200;
                // make some room for the dropdown address suggestions
                // on mobile devices by automatically scrolling down a little
                if (window.innerWidth <= 800) {
                  const elements =
                    document.getElementsByClassName('addressInput');
                  if (elements.length === 1) {
                    // should only every have 1 element on page with class 'addressInput'
                    // => if so, then find the 'top' position of the element, and use
                    //    it scroll it to top of viewport when focused. we do this because
                    //    the address suggestions dropdown should be seen by user when
                    //    they type into address field.

                    const rect = elements[0].getBoundingClientRect();
                    const rectTop = rect.top;
                    // console.log('recTop: ', rectTop)

                    if (rectTop <= SPACE) {
                      // if input is already above where we want to scroll to
                      // then we don't need to scroll to make room
                      return;
                    }

                    // how much screen is above top line of viewport
                    // ie. the amount scrolled
                    const scrollTop = document.documentElement.scrollTop;

                    // scroll to location, but not completely - make some room for the
                    // header - hence the SPACE value
                    window.scrollTo(0, rectTop + scrollTop - SPACE);
                  }
                }
              }}
            />
            <RowsContainer>
              {suggestions.map((suggestion, idx) =>
                suggestion.active ? (
                  <RowActive
                    {...getSuggestionItemProps(suggestion, {
                      // className,
                      // style,
                    })}
                    key={idx}
                  >
                    <span>{suggestion.description}</span>
                  </RowActive>
                ) : (
                  <Row
                    {...getSuggestionItemProps(suggestion, {
                      // className,
                      // style,
                    })}
                    key={idx}
                  >
                    <span>{suggestion.description}</span>
                  </Row>
                )
              )}
            </RowsContainer>
          </div>
        )}
      </PlacesAutocomplete>
    </AddressContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  shopDetails: selectShopDetails,
  deliveryZones: selectDeliveryZones,
  config: selectConfig,
  cartTotal: selectCartTotal,
});

const mapDispatchToProps = (dispatch) => ({
  setDeliveryFeePostcodes: (deliveryFeePostcodes) =>
    dispatch(setDeliveryFeePostcodes(deliveryFeePostcodes)),
  setDeliveryFeeDynamic: (deliveryFeeDynamic) =>
    dispatch(setDeliveryFeeDynamic(deliveryFeeDynamic)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDeliveryAddress);
