import React from 'react';
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';

import {
  lightGray,
  darkerGray,
  white,
  black,
  mobileSplitPoint,
} from '../../style-constants';

const MODAL_WIDTH_DESKTOP = 90; // %
const MODAL_WIDTH_MOBILE = 95; // %

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

// solution from https://github.com/reactjs/react-modal/issues/603
const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  // re-assigning bodyOpenClassName generates a warning...
  // const bodyOpenClassName = `${className}__Body--open`;

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );

  // return (
  //   <Modal
  //     portalClassName={className}
  //     className={contentClassName}
  //     overlayClassName={overlayClassName}
  //     bodyOpenClassName={bodyOpenClassName}
  //     {...props}
  //   />
  // );
};

export const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.1s ease-in;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.63);
    bottom: 0px;
    display: flex;
    justify-content: center;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 3;
  }

  &__content {
    background: ${white};
    border-radius: 30px;
    height: 87%;
    outline: none;
    overflow: scroll;
    margin-top: 68px;
    padding-bottom: 28px;
    width: ${MODAL_WIDTH_DESKTOP}%;
    -webkit-overflow-scrolling: touch;

    /*
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
    */

    @media screen and (max-width: ${mobileSplitPoint}) {
      height: 87%;
      left: 17px;
      top: 75px;
      width: ${MODAL_WIDTH_MOBILE}%;
    }
  }

  &__Body--open {
    overflow: hidden;
  }
`;
StyledModal.displayName = 'StyledModal';

export const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${white};
  /* border-bottom: 1px solid ${darkerGray}; */
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  width: ${MODAL_WIDTH_DESKTOP - 1}%;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const CloseButtonAction = styled.div`
  align-items: center;
  background-color: ${lightGray};
  border: none;
  border-radius: 30px;
  color: ${black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  justify-content: center;
  padding: 10px;

  &:focus {
    outline: none;
  }
`;
CloseButtonAction.displayName = 'CloseButtonAction';

export const ButtonImg = styled.img``;
ButtonImg.displayName = 'ButtonImg';

export const Spacer = styled.div``;
Spacer.displayName = 'Spacer';

export const MainTitle = styled.div`
  font-size: 24px;
  font-weight: 900;
`;
MainTitle.displayName = 'MainTitle';

export const BodyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: 100%;
`;
BodyContainer.displayName = 'BodyContainer';
