import styled from 'styled-components';
import Switch from 'react-switch';

import {
  lightGray,
  collectionSplitPoint,
  mobileSplitPoint,
  white,
  lightBlue,
  pink,
  orange,
  darkerGray,
  black,
  veryLightGray,
  lightTextGray,
} from '../../style-constants';

export const SaveOrderContainer = styled.div`
  align-items: center;
  background-color: ${veryLightGray};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 15px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${white};
    display: flex;
    flex-direction: row;
    padding: 25px;
  }
`;
SaveOrderContainer.displayName = 'SaveOrderContainer';

export const SaveOrderText = styled.div`
  color: ${black};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  padding-right: 15px;
`;
SaveOrderText.displayName = 'SaveOrderText';

export const SwitchReact = styled(Switch).attrs((props) => ({}))``;
SwitchReact.displayName = 'SwitchReact';
