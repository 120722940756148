import styled, { keyframes } from 'styled-components';

import {
  white,
  whiteTransparentComplete,
  boxShadow,
  mobileSplitPoint,
  darkGray,
  whiteOff,
  blackTransparent,
  red,
} from '../../style-constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${whiteTransparentComplete};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${blackTransparent};
  }
`;

export const DropdownContainer = styled.div`
  animation: ${({ isHidden }) => (isHidden ? fadeOut : fadeIn)} 0.08s ease-in;
  background-color: ${white};
  border: 1px solid ${darkGray};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  position: fixed;
  padding: 10px;
  right: 10px;
  top: 80px;
  width: 20%;
  z-index: 50;

  -webkit-box-shadow: 1px 10px 27px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 10px 27px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 10px 27px -8px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: ${mobileSplitPoint}) {
    right: 10px;
    box-shadow: 8px 33px 101px -14px ${boxShadow};
    width: 95%;
  }
`;
DropdownContainer.displayName = 'DropdownContainer';

export const TheNotch = styled.div`
  background-color: ${white};
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top: 1px solid ${darkGray};
  border-right: 1px solid ${darkGray};
  height: 35px;
  right: 40px;
  position: fixed;
  top: 63px;
  transform: rotate(-45deg);
  width: 35px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    right: 18px;
    position: fixed;
    top: 63px;
  }
`;
TheNotch.displayName = 'TheNotch';

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  padding-top: 20px;
  width: 100%;
`;
ContentContainer.displayName = 'ContentContainer';

export const DetailsContainer = styled.div`
  flex-grow: 1;
`;
DetailsContainer.displayName = 'DetailsContainer';

export const ProfileTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
`;
ProfileTitle.displayName = 'ProfileTitle';

export const LogoutButtonContainer = styled.div`
  align-items: center;
  background-color: ${red};
  border: 1px solid ${red};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  width: 100%;
`;
LogoutButtonContainer.displayName = 'LogoutButtonContainer';

export const LogoutButtonText = styled.div`
  color: ${whiteOff};
  font-size: 18px;
`;
LogoutButtonText.displayName = 'LogoutButtonText';
