import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import close from '../../assets/close.svg';

import ShopDetails from '../shop-details/shop-details.container';

import { selectShowShopInfoModal } from '../../redux/shop/shop.selectors';

import { setShowShopInfoModal } from '../../redux/shop/shop.actions';

import { useScrollLock } from '../../hooks/use-scroll-lock';

import {
  StyledModal,
  HeaderContainer,
  CloseButtonAction,
  ButtonImg,
  Spacer,
  MainTitle,
  BodyContainer,
} from './shop-info-modal.styles';

export const ShopInfoModal = ({ showShopInfoModal, setShowShopInfoModal }) => {
  useScrollLock(showShopInfoModal);

  const handleCloseOfModal = () => {
    setShowShopInfoModal(false);
  };

  const clientWidthSafe = () => {
    let clientWidth = 700;
    if (
      !document ||
      !document.documentElement ||
      !document.documentElement.clientWidth
    )
      return clientWidth;

    return document.documentElement.clientWidth;
  };

  return (
    <StyledModal
      isOpen={showShopInfoModal}
      onRequestClose={handleCloseOfModal}
      contentLabel="Modal"
      clientWidth={clientWidthSafe()}
    >
      <HeaderContainer>
        <CloseButtonAction onClick={handleCloseOfModal}>
          <ButtonImg src={close} />
        </CloseButtonAction>
        <MainTitle>Shop Info</MainTitle>
        <Spacer />
      </HeaderContainer>
      <BodyContainer>
        <ShopDetails />
      </BodyContainer>
    </StyledModal>
  );
};

const mapStateToProps = createStructuredSelector({
  showShopInfoModal: selectShowShopInfoModal,
});

const mapDispatchToProps = (dispatch) => ({
  setShowShopInfoModal: (show) => dispatch(setShowShopInfoModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopInfoModal);
