import styled from 'styled-components';
import { MdArrowBack } from 'react-icons/md';

import {
  white,
  lightGray,
  darkerGray,
  green,
  mobileSplitPoint,
  red,
  darkBlue,
  black,
} from '../../style-constants';

export const Container = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  margin-top: 70px;
  width: 100%;
`;
Container.displayName = 'Container';

export const ContentContainer = styled.div`
  align-items: center;
  background-color: ${white};
  border: 1px solid ${black};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  width: 40%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 90%;
  }
`;
ContentContainer.displayName = 'ContentContainer';

export const NavContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px 0px;
  width: 100%;
`;
NavContainer.displayName = 'NavContainer';

export const NavButtonContainer = styled.div`
  align-items: center;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 10px;
`;
NavButtonContainer.displayName = 'NavButtonContainer';

export const BackIcon = styled(MdArrowBack)`
  width: 25px;
  height: 25px;
`;
BackIcon.displayName = 'BackIcon';

export const NavButtonText = styled.div`
  border-bottom: 2px solid ${darkerGray};
`;
NavButtonText.displayName = 'NavButtonText';

export const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
`;
LoginContainer.displayName = 'LoginContainer';

export const LoginTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
LoginTitleContainer.displayName = 'LoginTitleContainer';

export const LoginTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
LoginTitle.displayName = 'LoginTitle';

export const LoginText = styled.div`
  font-size: 16px;
  padding-top: 15px;
`;
LoginText.displayName = 'LoginText';

export const InputContainer = styled.div`
  width: 100%;
`;
InputContainer.displayName = 'InputContainer';

export const ActionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px 0px 20px 0px;
  width: 80%;
`;
ActionContainer.displayName = 'ActionContainer';

export const ForgotPasswordContainer = styled.div``;
ForgotPasswordContainer.displayName = 'ForgotPasswordContainer';

export const ForgotPasswordText = styled.div`
  border-bottom: 2px solid ${darkBlue};
  color: ${darkBlue};
  cursor: pointer;
  font-weight: 700;
`;
ForgotPasswordText.displayName = 'ForgotPasswordText';

export const LoginButtonContainer = styled.div`
  background-color: ${({ isLoading }) => (isLoading ? lightGray : red)};
  border-radius: 30px;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 40px;
`;
LoginButtonContainer.displayName = 'LoginButtonContainer';

export const LoginButtonText = styled.div`
  color: ${white};
  font-size: 20px;
  font-weight: 700;
`;
LoginButtonText.displayName = 'LoginButtonText';

export const LoginFailure = styled.div`
  color: ${red};
  padding-right: 15px;
  padding-bottom: 15px;
`;
LoginFailure.displayName = 'LoginFailure';

export const BackButtonContainer = styled.div`
  background-color: ${lightGray};
  border: 1px solid ${black};
  border-radius: 30px;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 20px;
`;
BackButtonContainer.displayName = 'BackButtonContainer';

export const BackButtonText = styled.div`
  color: ${darkerGray};
  font-size: 20px;
  font-weight: 700;
`;
BackButtonText.displayName = 'BackButtonText';

export const SpinnerContainer = styled.div`
  padding-right: 15px;
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export const SendResetPasswordButtonContainer = styled.div`
  background-color: ${({ isLoading }) => (isLoading ? lightGray : red)};
  border-radius: 30px;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 40px;
`;
SendResetPasswordButtonContainer.displayName =
  'SendResetPasswordButtonContainer';
