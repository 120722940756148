import React, { useState, useRef, useLayoutEffect } from 'react';
import _ from 'lodash';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { IconContext } from 'react-icons';

import { HEARTED_ITEMS_COLLECTION_REF } from '../../global.constants';

import {
  OuterContainer,
  InnerContainer,
  Container,
  ButtonContainer,
  ButtonText,
  ScrollLeftIconContainer,
  ScrollRightIconContainer,
  IconInnerContainer,
} from './nav-bar-collections.styles';

export const NavBarCollections = ({
  collections,
  handleClick,
  collectionsOrder,
  config,
  selectedCollection,
}) => {
  const { enableHeartedItems } = config;
  const navBarRef = useRef(null);
  const [showScrollButtons, setShowScrollButtons] = useState({
    showScrollLeftButton: false,
    showScrollRightButton: false,
  });

  useLayoutEffect(() => {
    if (!navBarRef || !navBarRef.current) return;

    // add a listener for scrolling
    navBarRef.current.addEventListener('scroll', handleNavBarScroll);

    if (navBarRef.current.scrollWidth > navBarRef.current.offsetWidth) {
      // show scroll right button
      setShowScrollButtons({
        ...showScrollButtons,
        showScrollRightButton: true,
      });
    }

    return () => {
      if (navBarRef && navBarRef.current) {
        navBarRef.current.removeEventListener('scroll', handleNavBarScroll);
      }
    };
  }, [navBarRef]);

  const handleNavBarScroll = (event) => {
    const {
      target: { scrollLeft, scrollWidth, offsetWidth },
    } = event;

    let updates = {};
    if (scrollLeft > 0) {
      updates.showScrollLeftButton = true;
    } else {
      updates.showScrollLeftButton = false;
    }

    if (scrollLeft + offsetWidth >= scrollWidth) {
      updates.showScrollRightButton = false;
    } else {
      updates.showScrollRightButton = true;
    }

    setShowScrollButtons(updates);
  };

  const handleHorizontalScroll = (direction) => {
    if (navBarRef && navBarRef.current && direction === 'left') {
      navBarRef.current.scrollLeft -= 100;
    }

    if (navBarRef && navBarRef.current && direction === 'right') {
      navBarRef.current.scrollLeft += 100;
    }
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <Container ref={navBarRef}>
          {enableHeartedItems && (
            <ButtonContainer
              key={HEARTED_ITEMS_COLLECTION_REF}
              type="button"
              onClick={() =>
                handleClick(HEARTED_ITEMS_COLLECTION_REF, {
                  isSavedCollectionClicked: true,
                })
              }
              isSelected={selectedCollection === HEARTED_ITEMS_COLLECTION_REF}
            >
              <ButtonText
                isSelected={selectedCollection === HEARTED_ITEMS_COLLECTION_REF}
              >
                Saved
              </ButtonText>
            </ButtonContainer>
          )}
          {!_.isEmpty(collectionsOrder) &&
            _.map(collectionsOrder, (cKey, idx) => {
              const c = collections[cKey];

              return (
                <ButtonContainer
                  key={cKey}
                  onClick={() =>
                    handleClick(cKey, { isSavedCollectionClicked: false })
                  }
                  isSelected={selectedCollection === cKey}
                >
                  <ButtonText isSelected={selectedCollection === cKey}>
                    {c.title}
                  </ButtonText>
                </ButtonContainer>
              );
            })}
        </Container>
        {showScrollButtons.showScrollLeftButton && (
          <ScrollLeftIconContainer
            onClick={(e) => handleHorizontalScroll('left')}
          >
            <IconInnerContainer>
              <IconContext.Provider
                value={{ style: { height: '40px', width: '40px' } }}
              >
                <FaAngleLeft />
              </IconContext.Provider>
            </IconInnerContainer>
          </ScrollLeftIconContainer>
        )}
        {showScrollButtons.showScrollRightButton && (
          <ScrollRightIconContainer
            onClick={(e) => handleHorizontalScroll('right')}
          >
            <IconInnerContainer>
              <IconContext.Provider
                value={{ style: { height: '40px', width: '40px' } }}
              >
                <FaAngleRight />
              </IconContext.Provider>
            </IconInnerContainer>
          </ScrollRightIconContainer>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};

export default NavBarCollections;
