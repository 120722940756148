import styled from 'styled-components';

import { lightGray, red } from '../../style-constants';

export const Container = styled.div`
  background-color: ${lightGray};
  margin: 1rem;
  padding: 0.5rem;
`;
Container.displayName = 'Container';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flext-start;
`;
TitleContainer.displayName = 'TitleContainer';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: flex-start;
  margin: 5px;
`;
InfoContainer.displayName = 'InfoContainer';

export const Title = styled.h3`
  margin: 5px;
`;
Title.displayName = 'Title';

export const IsRequired = styled.div``;
IsRequired.displayName = 'IsRequired';

export const MaxSelect = styled.div`
  margin-right: 5px;
`;
MaxSelect.displayName = 'MaxSelect';

export const RequiredText = styled.div`
  color: ${red};
`;
RequiredText.displayName = 'RequiredText';

export const PickOneText = styled.div`
  margin-right: 5px;
`;
PickOneText.displayName = 'PickOneText';

export const ButtonsContainer = styled.div``;
ButtonsContainer.displayName = 'ButtonsContainer';
