import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Swal from 'sweetalert2';

import Promotion from './promotion.component';

import {
  verifyPromoCodeStart,
  setPromotion,
  removePromotionFromPreviouslyUsedPromotions,
} from '../../redux/cart/cart.actions';

import {
  selectCartTotal,
  selectIsLoadingPromo,
  selectPromotion,
  selectPreviouslyUsedPromotions,
} from '../../redux/cart/cart.selectors';

import { selectShopDetails } from '../../redux/shop/shop.selectors';

export const PromotionContainer = ({
  isLoadingPromo,
  promotion,
  cartTotal,
  verifyPromoCodeStart,
  setPromotion,
  shopDetails,
  previouslyUsedPromotions,
  removePromotionFromPreviouslyUsedPromotions,
}) => {
  const [promoCode, setPromoCode] = useState('');

  const handleClosePromoResult = () => {
    //resetting the promotion will close the result UI
    setPromotion({});
    setPromoCode('');
  };

  const handlePromoCodeChange = (event) => {
    const { value } = event.target;
    setPromoCode(value);
  };

  const handlePromoCodeSubmit = () => {
    if (!promoCode) {
      Swal.fire('Please enter a promo code');
      return;
    }

    verifyPromoCodeStart(promoCode, cartTotal, shopDetails);
  };

  const handlePressPreviouslyUsedPromotion = (promotion) => {
    console.log('handlePressPreviouslyUsedPromotion');
    if (!promotion.promoCode) return;
    if (promotion.promoCode === promoCode) return;
    setPromoCode(promotion.promoCode);
    verifyPromoCodeStart(promotion.promoCode, cartTotal, shopDetails);
  };

  const handleRemovePromotionClick = (promotion) => {
    console.log('handleRemovePromotionClick');
    // TODO: (this is async, remember)
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: `You are about to remove the promotion ${promotion.promoCode} from your cart.`,
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: black,
    // })
    removePromotionFromPreviouslyUsedPromotions(promotion);
  };

  return (
    <Promotion
      promoCode={promoCode}
      handleClosePromoResult={handleClosePromoResult}
      handlePromoCodeChange={handlePromoCodeChange}
      handlePromoCodeSubmit={handlePromoCodeSubmit}
      promotion={promotion}
      isLoadingPromo={isLoadingPromo}
      previouslyUsedPromotions={previouslyUsedPromotions}
      handlePressPreviouslyUsedPromotion={handlePressPreviouslyUsedPromotion}
      handleRemovePromotionClick={handleRemovePromotionClick}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  isLoadingPromo: selectIsLoadingPromo,
  promotion: selectPromotion,
  cartTotal: selectCartTotal,
  shopDetails: selectShopDetails,
  previouslyUsedPromotions: selectPreviouslyUsedPromotions,
});

const mapDispatchToProps = (dispatch) => ({
  verifyPromoCodeStart: (promoCode, cartTotal, shopDetails) =>
    dispatch(verifyPromoCodeStart(promoCode, cartTotal, shopDetails)),
  setPromotion: (promotion) => dispatch(setPromotion(promotion)),
  removePromotionFromPreviouslyUsedPromotions: (promotion) =>
    dispatch(removePromotionFromPreviouslyUsedPromotions(promotion)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionContainer);
