import CartActionTypes from './cart.types';
import _ from 'lodash';

import {
  addItemToCart,
  removeItemFromCart,
  clearItemFromCart,
  updateCartItem,
} from './cart.utils';

import { DELIVERY } from '../../global.constants';

export const INITIAL_CART_STATE = {
  hidden: true,
  cartItems: [],
  purchasedCart: {},
  orderIsPaid: false, // not used anymore. can safely delete given it is persisted in localStorage for older clients versions?
  isLoading: false,
  isLoadingPromo: false,
  paymentErrorMessage: undefined,
  orderType: undefined,
  errorMessage: '',
  fastDisable: false,
  promotion: {},
  deliveryFee: undefined,
  deliveryFeePostCodes: undefined,
  deliveryFeeDynamic: undefined,
  promotionFee: undefined,
  bookingFee: undefined,
  savedOrders: [],
  shouldSaveOrder: false,
  previouslyUsedPromotions: [],
};

export const cartReducer = (state = INITIAL_CART_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.TOGGLE_CART_HIDDEN:
      return {
        ...state,
        hidden: !state.hidden,
      };
    case CartActionTypes.SET_CART_HIDDEN:
      return {
        ...state,
        hidden: action.payload,
      };
    case CartActionTypes.ADD_TO_CART:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload.itemToAdd),
      };
    case CartActionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload),
      };
    case CartActionTypes.CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: clearItemFromCart(state.cartItems, action.payload),
      };
    case CartActionTypes.UPDATE_CART_ITEM:
      return {
        ...state,
        cartItems: updateCartItem(
          action.payload.cartItems,
          action.payload.prevItem,
          action.payload.updatedItem
        ),
      };
    case CartActionTypes.SET_NEW_ORDER:
      return INITIAL_CART_STATE;
    case CartActionTypes.ORDER_PAYMENT_START:
    case CartActionTypes.WHOLESALE_ADD_TO_ACCOUNT_START:
    case CartActionTypes.PAYMENT_NEW_CUSTOMER_START:
    case CartActionTypes.PAYMENT_CURRENT_CUSTOMER_START:
      return {
        ...state,
        isLoading: true,
        paymentErrorMessage: undefined,
      };
    case CartActionTypes.ORDER_PAYMENT_SUCCESS:
    case CartActionTypes.WHOLESALE_ADD_TO_ACCOUNT_SUCCESS:
    case CartActionTypes.PAYMENT_NEW_CUSTOMER_SUCCESS:
    case CartActionTypes.PAYMENT_CURRENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        cartItems: [],
        errorMessage: '',
        isLoading: false,
        fastDisable: false,
        paymentErrorMessage: undefined,
        purchasedCart: {
          cartItems: action.payload.cartItems,
          actionPayload: action.payload.actionPayload,
        },
        // deliveryFee: undefined,
        // deliveryFeePostcodes: undefined,
        // deliveryFeeDynamic: undefined,
        // promotionFee: undefined,
        // bookingFee: undefined,
        // promotion: {},
      };
    case CartActionTypes.ORDER_PAYMENT_FAILURE:
    case CartActionTypes.WHOLESALE_ADD_TO_ACCOUNT_FAILURE:
    case CartActionTypes.PAYMENT_NEW_CUSTOMER_FAILURE:
    case CartActionTypes.PAYMENT_CURRENT_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        fastDisable: false,
        paymentErrorMessage: action.payload.response,
      };
    case CartActionTypes.SET_PURCHASED_CART:
      return {
        ...state,
        purchasedCart: {
          cartItems: action.payload.purchasedItems,
          actionPayload: action.payload.actionPayload,
        },
      };
    case CartActionTypes.SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload.orderType,
        deliveryFee:
          // make sure to reset deliveryFee when changing to an orderType
          // that is not DELIVERY
          action.payload.orderType !== DELIVERY ? 0.0 : state.deliveryFee,
      };
    case CartActionTypes.VERIFY_PROMO_CODE_START:
      return {
        ...state,
        isLoadingPromo: true,
        promotion: {}, // reset the promotion also
      };
    case CartActionTypes.VERIFY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isLoadingPromo: false,
        promotion: action.payload.promotion,
      };
    case CartActionTypes.VERIFY_PROMO_CODE_FAILURE:
      return {
        ...state,
        isLoadingPromo: false,
        errorMessage: action.payload.errorMessage,
      };
    case CartActionTypes.SET_FAST_DISABLE:
      return {
        ...state,
        fastDisable: action.payload.isDisabled,
      };
    case CartActionTypes.RESET_PAYMENT_ERROR_MESSAGE:
      return {
        ...state,
        paymentErrorMessage: undefined,
      };
    case CartActionTypes.SET_DELIVERY_FEE:
      return {
        ...state,
        deliveryFee: action.payload.deliveryFee,
      };
    case CartActionTypes.SET_DELIVERY_FEE_POSTCODES:
      return {
        ...state,
        deliveryFeePostcodes: action.payload.deliveryFeePostcodes,
      };
    case CartActionTypes.SET_DELIVERY_FEE_DYNAMIC:
      return {
        ...state,
        deliveryFeeDynamic: action.payload.deliveryFeeDynamic,
      };
    case CartActionTypes.SET_PROMOTION_FEE:
      return {
        ...state,
        promotionFee: action.payload.promotionFee,
      };
    case CartActionTypes.SET_BOOKING_FEE:
      return {
        ...state,
        bookingFee: action.payload.bookingFee,
      };
    case CartActionTypes.SET_PROMOTION:
      return {
        ...state,
        promotion: action.payload.promotion,
      };
    case CartActionTypes.RESET_CART_STATE_AFTER_LOGOUT_OR_LOGIN:
      return {
        ...INITIAL_CART_STATE,
        // need to set orderType to a string, not undefined,
        // b/c order method component is not reloaded (which sets the orderType val)
        orderType: action.payload.defaultOrderType,
      };
    case CartActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case CartActionTypes.RESET_ALL_CART_FEES:
      return {
        ...state,
        bookingFee: undefined,
        deliveryFee: undefined,
        deliveryFeePostCodes: undefined,
        deliveryFeeDynamic: undefined,
        promotion: {},
        promotionFee: undefined,
      };
    case CartActionTypes.ADD_ORDER_TO_SAVED_ORDERS: {
      const savedOrdersSafe = state.savedOrders || [];
      return {
        ...state,
        savedOrders: [action.payload.order, ...savedOrdersSafe],
      };
    }
    case CartActionTypes.ADD_SAVED_ORDER_TO_CART:
      return {
        ...state,
        cartItems: action.payload.order.items,
      };
    case CartActionTypes.REMOVE_SAVED_ORDER:
      return {
        ...state,
        savedOrders: _.reduce(
          state.savedOrders,
          (accum, order) => {
            if (order.savedOrderKey === action.payload.order.savedOrderKey) {
              return accum;
            }
            accum = [...accum, order];
            return accum;
          },
          []
        ),
      };
    case CartActionTypes.SET_SHOULD_SAVE_ORDER:
      return {
        ...state,
        shouldSaveOrder: action.payload.shouldSaveOrder,
      };
    case CartActionTypes.REMOVE_ALL_CART_ITEMS:
      return {
        ...state,
        cartItems: [],
      };
    case CartActionTypes.ADD_PROMOTION_TO_PREVIOUSLY_USED_PROMOTIONS: {
      const previouslyUsedPromotionsSafe = state.previouslyUsedPromotions ?? [];
      const foundPromotion = _.find(
        previouslyUsedPromotionsSafe,
        (promotion) =>
          promotion.promoCode === action.payload.promotion.promoCode
      );
      const updated = foundPromotion
        ? previouslyUsedPromotionsSafe
        : [action.payload.promotion, ...previouslyUsedPromotionsSafe];
      return {
        ...state,
        previouslyUsedPromotions: updated,
      };
    }
    case CartActionTypes.REMOVE_PROMOTION_FROM_PREVIOUSLY_USED_PROMOTIONS: {
      return {
        ...state,
        previouslyUsedPromotions: _.filter(
          state.previouslyUsedPromotions,
          (promotion) => {
            return promotion.promoCode !== action.payload.promotion.promoCode;
          }
        ),
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
