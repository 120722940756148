import { all, call, takeLatest, put, debounce } from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import { setStripeCustomerId } from '../user/user.actions';

import {
  verifyPromoCodeSuccess,
  verifyPromoCodeFailure,
  wholesaleAddToAccountSuccess,
  wholesaleAddToAccountFailure,
  paymentNewCustomerSuccess,
  paymentNewCustomerFailure,
  paymentCurrentCustomerSuccess,
  paymentCurrentCustomerFailure,
  addOrderToSavedOrders,
  addPromotionToPreviouslyUsedPromotions,
} from './cart.actions';
import { findSavedOrder } from './cart.utils';

import CartActionTypes from './cart.types';
import { cartApi as api } from '../../services';
import { userApi } from '../../services';
import { store } from '../store';

import { PAYMENT_SUCCESS_URL } from '../../global.constants';

const MULTIPLE_IDENTICAL_ORDERS_SENT = 'multiple_identical_orders_sent';
const NETWORK_ERROR_MSG =
  '🚫 Your network dropped out whilst making the payment 🚫\n\n ' +
  'This is rare but can happen with bad internet. Contact the business to find out if your order was successfully processed. \n\n' +
  'Please DO NOT try to resubmit the order until finding out if it was processed - otherwise you may double charge your card 💳';

const _isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response;
};

export function* verifyPromoCode({
  payload: { promoCode, cartTotal, shopDetails },
}) {
  try {
    // if isPromoValid === true then promo === object contain promo details,
    // otherwise promo === undefined
    const { isPromoValid, promo } = yield call(
      api.verifyPromoCode,
      promoCode,
      cartTotal,
      shopDetails
    );
    yield put(verifyPromoCodeSuccess({ isPromoValid, promo }));
    if (isPromoValid) {
      yield put(
        addPromotionToPreviouslyUsedPromotions({ ...promo, promoCode })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(verifyPromoCodeFailure('ERROR: verify promo code failure'));
  }
}

export function* paymentNewCustomer({
  payload: {
    actionPayload,
    cartItems,
    userCredentials,
    cardNumberElement,
    stripe,
    shouldSaveCardDetails,
    config,
    navigate,
    shopDetails,
  },
}) {
  try {
    // clone the cartItems incase clear cart items is called before
    // adding them to purchased items
    const clonedCartItems = _.cloneDeep(cartItems);
    const clonedActionPayload = _.cloneDeep(actionPayload);

    const { isOrdersThrottlingEnabled, wantedAtTypes, saveOrderToSavedOrders } =
      config;
    const state = store.getState();
    const { savedOrders, shouldSaveOrder } = state.cart;
    if (saveOrderToSavedOrders && shouldSaveOrder) {
      // must use items from clonedActionPayload because we store
      // saved order using it, not cartItems (which a different to clonedActionPayload.items)
      const foundOrder = findSavedOrder(savedOrders, clonedCartItems);
      if (!foundOrder) {
        const savedOrderKey = uuidv4();
        yield put(
          addOrderToSavedOrders({
            savedOrderKey,
            items: clonedCartItems,
          })
        );
      }
    }

    // *** NOTE ***
    // customerId returned from API will be null if customer does NOT want
    // cc saved for later.
    // if they do, then customerId will be a string
    const { clientSecret, customerId, paymentIntentId, error } = yield call(
      api.paymentNewCustomer,
      actionPayload,
      shouldSaveCardDetails,
      shopDetails
    );

    if (error) {
      yield put(
        paymentNewCustomerFailure(`Error with payment: ${error.message}`)
      );
      return;
    }

    // do this so we dont pass in null to firestore. it
    // doesnt like null for value of a field in a doc
    const _paymentIntentId = paymentIntentId ? paymentIntentId : '';

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const options = {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: userCredentials.userName,
        },
      },
      setup_future_usage: 'off_session',
    };

    const result = yield call(stripe.confirmCardPayment, clientSecret, options);

    if (result.error) {
      // If card CVC is wrong sometimes the bank with show a pending transaction
      // but it will be cancelled. Warn users that they may see a pending transaction
      // although no money will be taken
      yield put(
        paymentNewCustomerFailure(
          `Payment Error: ${result.error.message}. For incorrect CVC sometimes this charge may temporarily appear as pending in your bank account before being removed. No money will be deducted.`
        )
      );
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        yield put(setStripeCustomerId(customerId));

        const { error: backOfHouseError } = yield call(
          api.paymentBackOfHouse,
          clonedActionPayload,
          customerId,
          _paymentIntentId,
          isOrdersThrottlingEnabled,
          wantedAtTypes,
          shopDetails
        );
        if (backOfHouseError) {
          yield put(
            paymentNewCustomerFailure(
              `Error: back of house error. Payment made but not received by business. Please call business.`
            )
          );
        } else {
          yield put(
            paymentNewCustomerSuccess(clonedActionPayload, clonedCartItems)
          );
          yield call(navigate, PAYMENT_SUCCESS_URL);
        }
      } else {
        // stop payment if no error, but not succeeded.
        // more info/input needed
        throw new Error(`Payment status: ${result.paymentIntent.status}`);
      }
    }
  } catch (error) {
    console.log(error);
    let errorMsg = error.message ? error.message : '';

    if (_isNetworkError(error)) {
      //SEE GITHUB THREAD: https://github.com/axios/axios/issues/383
      console.log('ERROR: _isNetworkError(error) === true');
      // client network connection dropped out during payment.
      // server is probably still processing the order thinking everything is fine.
      // it. is. not.
      yield put(paymentNewCustomerFailure(NETWORK_ERROR_MSG));
    } else {
      if (errorMsg !== MULTIPLE_IDENTICAL_ORDERS_SENT) {
        const msg = `There was an error with payment.\n\n${errorMsg}`;
        yield put(paymentNewCustomerFailure(msg));
      } else {
        // a payment failure due multiple submitting the identical order
        // is a no-op. dont display anything to the user.
        console.log(
          'prevented order being sent multiple times. ' +
            'no need to escalate from here ' +
            'no-op, just log it'
        );
      }
    }
  }
}

export function* paymentCurrentCustomer({
  payload: {
    actionPayload,
    cartItems,
    userCredentials,
    cardNumberElement,
    stripe,
    customerId,
    paymentMethodState,
    config,
    navigate,
    shopDetails,
  },
}) {
  const { selectedPaymentMethodId: paymentMethodId, shouldSaveCardDetails } =
    paymentMethodState;

  const { isOrdersThrottlingEnabled, wantedAtTypes, saveOrderToSavedOrders } =
    config;

  try {
    // clone the cartItems incase clear cart items is called before
    // adding them to purchased items
    const clonedCartItems = _.cloneDeep(cartItems);
    const clonedActionPayload = _.cloneDeep(actionPayload);

    const state = store.getState();
    const { savedOrders, shouldSaveOrder } = state.cart;
    if (saveOrderToSavedOrders && shouldSaveOrder) {
      // must use items from clonedActionPayload because we store
      // saved order using it, not cartItems (which a different to clonedActionPayload.items)
      const foundOrder = findSavedOrder(savedOrders, clonedCartItems);
      if (!foundOrder) {
        const savedOrderKey = uuidv4();
        yield put(
          addOrderToSavedOrders({
            savedOrderKey,
            items: clonedCartItems,
          })
        );
      }
    }

    if (paymentMethodId) {
      const { clientSecret, paymentIntentId } = yield call(
        api.paymentCurrentCustomer,
        actionPayload,
        customerId,
        paymentMethodId,
        shopDetails
      );

      const result = yield call(stripe.confirmCardPayment, clientSecret, {
        payment_method: paymentMethodId,
      });

      if (result.error) {
        yield put(
          paymentCurrentCustomerFailure(
            `Payment Error. ${result.error.message}`
          )
        );
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === 'requires_payment_method'
      ) {
        yield put(
          paymentCurrentCustomerFailure(
            `We are sorry, there was an error processing your payment. Please try again.`
          )
        );
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === 'succeeded'
      ) {
        const { error: backOfHouseError } = yield call(
          api.paymentBackOfHouse,
          clonedActionPayload,
          customerId,
          paymentIntentId,
          isOrdersThrottlingEnabled,
          wantedAtTypes,
          shopDetails
        );

        if (backOfHouseError) {
          yield put(paymentCurrentCustomerFailure(`Payment failure (0)`));
        } else {
          yield put(
            paymentCurrentCustomerSuccess(clonedActionPayload, clonedCartItems)
          );
          yield call(navigate, PAYMENT_SUCCESS_URL);
        }
      } else {
        // status is either
        // - processing
        // - requires_confirmation
        // - requires_action (eg. 3DS)
        // => either not currently supported or not needed for UI

        // stop payment if no error, but not succeeded.
        // more info/input needed
        throw new Error(`Payment status: ${result.paymentIntent.status}`);
      }
    } else {
      // user wants to add another card and use it for payment.
      // need to also consider whether they want to save it

      const { clientSecret, paymentIntentId } = yield call(
        api.paymentCurrentCustomerNewCard,
        actionPayload,
        customerId,
        shouldSaveCardDetails,
        shopDetails
      );

      const options = {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: userCredentials.userName,
          },
        },
        setup_future_usage: 'off_session',
      };

      const result = yield call(
        stripe.confirmCardPayment,
        clientSecret,
        options
      );

      if (result.error) {
        yield put(
          paymentCurrentCustomerFailure(
            `Payment Error. ${result.error.message}`
          )
        );
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === 'requires_payment_method'
      ) {
        yield put(
          paymentCurrentCustomerFailure(
            `We are sorry, there was an error processing your payment. Please try agai with a different payment method.`
          )
        );
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === 'succeeded'
      ) {
        const { error: backOfHouseError } = yield call(
          api.paymentBackOfHouse,
          clonedActionPayload,
          customerId,
          paymentIntentId,
          isOrdersThrottlingEnabled,
          wantedAtTypes,
          shopDetails
        );

        if (backOfHouseError) {
          yield put(
            paymentCurrentCustomerFailure(`Payment failure back of house (0)`)
          );
        } else {
          yield put(
            paymentCurrentCustomerSuccess(clonedActionPayload, clonedCartItems)
          );
          yield call(navigate, PAYMENT_SUCCESS_URL);
        }
      } else {
        // status is either
        // - processing
        // - requires_confirmation
        // - requires_action (eg. 3DS)
        // => either not currently supported or not needed for UI

        // stop payment if no error, but not succeeded.
        // more info/input needed
        throw new Error(`Payment status: ${result.paymentIntent.status}`);
      }
    }
  } catch (error) {
    console.log(error);
    let errorMsg = error.message ? error.message : '';

    if (_isNetworkError(error)) {
      //SEE GITHUB THREAD: https://github.com/axios/axios/issues/383
      console.log('ERROR: _isNetworkError(error) === true');
      // client network connection dropped out during payment.
      // server is probably still processing the order thinking everything is fine.
      // it. is. not.
      yield put(paymentNewCustomerFailure(NETWORK_ERROR_MSG));
    } else {
      if (errorMsg !== MULTIPLE_IDENTICAL_ORDERS_SENT) {
        const msg = `There was an error with payment.\n\n${errorMsg}`;
        yield put(paymentNewCustomerFailure(msg));
      } else {
        // a payment failure due multiple submitting the identical order
        // is a no-op. dont display anything to the user.
        console.log(
          'prevented order being sent multiple times. ' +
            'no need to escalate from here ' +
            'no-op, just log it'
        );
      }
    }
  }
}

// TODO: handle network errors like above
export function* wholesaleAddToAccount({
  payload: { actionPayload, cartItems, navigate, shopDetails },
}) {
  try {
    // need idToken from auth service for authenicating req
    const {
      data: { idToken },
      error,
    } = yield call(userApi.retrieveIdToken);
    if (error) throw new Error('No id token found');

    // clone the cartItems incase clear cart items is called before
    // adding them to purchased items
    const clonedCartItems = _.cloneDeep(cartItems);
    const clonedActionPayload = _.cloneDeep(actionPayload);

    const { error: wholesaleBackOfHouseError } = yield call(
      api.wholesaleAddToAccount,
      clonedActionPayload,
      idToken,
      shopDetails
    );

    if (wholesaleBackOfHouseError) {
      yield put(
        wholesaleAddToAccountFailure('FAILURE: wholesale add order to account')
      );
    } else {
      yield put(
        wholesaleAddToAccountSuccess(clonedActionPayload, clonedCartItems)
      );
      yield call(navigate, PAYMENT_SUCCESS_URL);
    }
  } catch (error) {
    console.log(error);
    yield put(
      wholesaleAddToAccountFailure(
        'FAILURE: could not add wholesale order to account'
      )
    );
  }
}

export function* verifyPromoCodeStart() {
  yield takeLatest(CartActionTypes.VERIFY_PROMO_CODE_START, verifyPromoCode);
}

export function* paymentNewCustomerStart() {
  yield debounce(
    1000,
    CartActionTypes.PAYMENT_NEW_CUSTOMER_START,
    paymentNewCustomer
  );
}

export function* paymentCurrentCustomerStart() {
  yield debounce(
    1000,
    CartActionTypes.PAYMENT_CURRENT_CUSTOMER_START,
    paymentCurrentCustomer
  );
}

export function* wholesaleAddToAccountStart() {
  yield debounce(
    1000,
    CartActionTypes.WHOLESALE_ADD_TO_ACCOUNT_START,
    wholesaleAddToAccount
  );
}

export function* cartSagas() {
  yield all([
    call(verifyPromoCodeStart),
    call(paymentNewCustomerStart),
    call(paymentCurrentCustomerStart),
    call(wholesaleAddToAccountStart),
  ]);
}
