import React from 'react';

import OperatingHoursOverview from '../operating-hours-overview/operating-hours-overview.component';
import ContactDetails from '../contact-details/contact-details.component';
import DeliveryDetails from '../delivery-details/delivery-details.component';

import { Container } from './shop-details.styles';

import { ORDERTYPES_TO_SINGULAR_TYPES, DELIVERY } from '../../global.constants';

export const ShopDetails = ({ config, wholesaleUserDetails }) => {
  const { orderTypesRetail, orderTypesWholesale } = config;

  let _orderTypes = wholesaleUserDetails
    ? orderTypesWholesale
    : orderTypesRetail;

  return (
    <Container>
      <ContactDetails />
      {_orderTypes &&
        ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes] &&
        ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes].includes(DELIVERY) && (
          <DeliveryDetails />
        )}
      <OperatingHoursOverview />
    </Container>
  );
};

export default ShopDetails;
