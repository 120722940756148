import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import close from '../../assets/close.svg';

import {
  selectDeliveryZones,
  selectShowDeliveryZonesModal,
} from '../../redux/shop/shop.selectors';

import { setShowDeliveryZonesModal } from '../../redux/shop/shop.actions';

import { useScrollLock } from '../../hooks/use-scroll-lock';

import {
  StyledModal,
  HeaderContainer,
  CloseButtonAction,
  ButtonImg,
  TitleContainer,
  Spacer,
  MainTitle,
  BodyContainer,
  Info,
  DeliveryZoneContainer,
  ZoneTitle,
  PostCodesContainer,
  PostCodeHeaderContainer,
  PostCodeHeaderTitle,
  PostCodeTitle,
  PostCodeContainer,
  SuburbsContainer,
  Suburb,
} from './delivery-zones-modal.styles';

export const DeliveryZonesModal = ({
  deliveryZones,
  setShowDeliveryZonesModal,
  showDeliveryZonesModal,
}) => {
  useScrollLock(showDeliveryZonesModal);

  const handleCloseOfModal = () => {
    setShowDeliveryZonesModal(false);
  };

  const clientWidthSafe = () => {
    let clientWidth = 700;
    if (
      !document ||
      !document.documentElement ||
      !document.documentElement.clientWidth
    )
      return clientWidth;

    return document.documentElement.clientWidth;
  };

  return (
    <StyledModal
      isOpen={showDeliveryZonesModal}
      onRequestClose={handleCloseOfModal}
      contentLabel="Modal"
      clientWidth={clientWidthSafe()}
    >
      <HeaderContainer>
        <CloseButtonAction onClick={handleCloseOfModal}>
          <ButtonImg src={close} />
        </CloseButtonAction>
        <MainTitle>Delivery Zones</MainTitle>
        <Spacer />
      </HeaderContainer>
      <BodyContainer>
        <Info>We deliver to the following postcodes:</Info>
        {_.map(
          _.orderBy(deliveryZones, (zone) => zone.price),
          (zone, zoneIdx) => {
            const { price, title, postCodes } = zone;
            return (
              <DeliveryZoneContainer key={zoneIdx}>
                <TitleContainer>
                  <ZoneTitle>{`${title} =    $${price}`}</ZoneTitle>
                </TitleContainer>
                <PostCodesContainer>
                  {_.map(postCodes, (postCodeObj) => {
                    const { suburbs, postCode } = postCodeObj;
                    return (
                      <PostCodeContainer key={postCode}>
                        <PostCodeHeaderContainer>
                          <PostCodeHeaderTitle>Postcode</PostCodeHeaderTitle>
                        </PostCodeHeaderContainer>
                        <PostCodeTitle>{postCode}</PostCodeTitle>
                        <SuburbsContainer>
                          {_.map(suburbs, (suburb, idx) => {
                            return <Suburb key={idx}>{suburb}</Suburb>;
                          })}
                        </SuburbsContainer>
                      </PostCodeContainer>
                    );
                  })}
                </PostCodesContainer>
              </DeliveryZoneContainer>
            );
          }
        )}
      </BodyContainer>
    </StyledModal>
  );
};

const mapStateToProps = createStructuredSelector({
  deliveryZones: selectDeliveryZones,
  showDeliveryZonesModal: selectShowDeliveryZonesModal,
});

const mapDispatchToProps = (dispatch) => ({
  setShowDeliveryZonesModal: (show) =>
    dispatch(setShowDeliveryZonesModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryZonesModal);
