const UserActionTypes = {
  ADD_USER_DETAILS: 'ADD_USER_DETAILS',

  LOGIN_WHOLESALE_USER_START: 'LOGIN_WHOLESALE_USER_START',
  LOGIN_WHOLESALE_USER_SUCCESS: 'LOGIN_WHOLESALE_USER_SUCCESS',
  LOGIN_WHOLESALE_USER_FAILURE: 'LOGIN_WHOLESALE_USER_FAILURE',

  SET_WHOLESALE_DROPDOWN_IS_HIDDEN: 'SET_WHOLESALE_DROPDOWN_IS_HIDDEN',

  LOGOUT_WHOLESALE_USER_START: 'LOGOUT_WHOLESALE_USER_START',
  LOGOUT_WHOLESALE_USER_SUCCESS: 'LOGOUT_WHOLESALE_USER_SUCCESS',
  LOGOUT_WHOLESALE_USER_FAILURE: 'LOGOUT_WHOLESALE_USER_FAILURE',

  CHECK_WHOLESALE_USER_SESSION: 'CHECK_WHOLESALE_USER_SESSION',

  UPDATE_WHOLESALE_USER_DETAILS: 'UPDATE_WHOLESALE_USER_DETAILS',

  FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_START:
    'FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_START',
  FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_SUCCESS:
    'FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_SUCCESS',
  FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_FAILURE:
    'FETCH_PAYMENT_METHODS_FOR_CUSTOMER_ID_FAILURE',

  SET_STRIPE_CUSTOMER_ID: 'SET_STRIPE_CUSTOMER_ID',

  SEND_PASSWORD_RESET_EMAIL_START: 'SEND_PASSWORD_RESET_EMAIL_START',
  SEND_PASSWORD_RESET_EMAIL_SUCCESS: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
  SEND_PASSWORD_RESET_EMAIL_FAILURE: 'SEND_PASSWORD_RESET_EMAIL_FAILURE',

  RESET_ERROR_AND_LOADING_STATE: 'RESET_ERROR_AND_LOADING_STATE',

  DETACH_PAYMENT_METHOD_START: 'DETACH_PAYMENT_METHOD_START',
  DETACH_PAYMENT_METHOD_SUCCESS: 'DETACH_PAYMENT_METHOD_SUCCESS',
  DETACH_PAYMENT_METHOD_FAILURE: 'DETACH_PAYMENT_METHOD_FAILURE',

  UNSUBSCRIBE_CUSTOMER_START: 'UNSUBSCRIBE_CUSTOMER_START',
  UNSUBSCRIBE_CUSTOMER_SUCCESS: 'UNSUBSCRIBE_CUSTOMER_SUCCESS',
  UNSUBSCRIBE_CUSTOMER_FAILURE: 'UNSUBSCRIBE_CUSTOMER_FAILURE',

  RESET_UNSUBSCRIBE_MSG: 'RESET_UNSUBSCRIBE_MSG',

  FETCH_WHOLESALE_USER_DOC_START: 'FETCH_WHOLESALE_USER_DOC_START',
  FETCH_WHOLESALE_USER_DOC_SUCCESS: 'FETCH_WHOLESALE_USER_DOC_SUCCESS',
  FETCH_WHOLESALE_USER_DOC_FAILURE: 'FETCH_WHOLESALE_USER_DOC_FAILURE',

  SET_USER_CREDENTIALS: 'SET_USER_CREDENTIALS',
  RESET_USER_CREDENTIALS: 'RESET_USER_CREDENTIALS',
  SET_KEEP_USER_CREDENTIALS_FOR_CHECKOUT:
    'SET_KEEP_USER_CREDENTIALS_FOR_CHECKOUT',
};

export default UserActionTypes;
