export const greenVeryLight = '#ecfdea';
export const green = '#2ed288';
export const greenTransparent = '#2ed28863';
export const greenLight = '#8bdab6';
export const darkGreen = '#2EBE88';
export const greenSuccessLight = '#72ff4eab';
export const darkGreenText = '#144c25';
export const orange = '#fabf0c';
export const orangeOrderSuccess = '#ff981994';
export const lightGray = '#e7e7e7';
export const veryLightGray = '#f3f3f3';
export const superLightGray = '#f9f9f9';
export const darkGray = '#D7D7D7';
export const darkerGray = '#6e6e6e';
export const lightBlue = '#6bd6f9';
export const darkBlue = '#1594bf';
export const red = '#ff5252';
export const redTransparent = '#ff525252';
export const redLight = '#da8888';
export const redTextDark = '#8a1010';
export const redBackgroundLight = '#fbe9e7';
export const redBackgroundVeryLightTransparent = '#fbe9e73b';
export const pink = '#ffc0c0';
export const darkRed = '#e04848';
export const black = '#354b5d';
export const blackBackground = '#000000c2';
export const blackTransparent = '#00000082';
export const boxShadow = '#303030;';
export const white = '#fff';
export const whiteOff = '#f5f5f5';
export const whiteTransparent = '#ffffff8c';
export const whiteTransparentComplete = '#ffffff00';
export const purple = '#8052ff';
export const yellow = '#fefe8c';
export const brightYellow = '#fff937';
export const lightTextGray = '#707070';
export const greenSuccessNew = '#3cff1994';
export const redAlarmText = '#b32323';
export const redAlarmBackground = '#ffd1d1';
export const greenBorderDark = '#217a28';
export const greenSuccessBackground = '#e6ffe5';
export const blackTransparentDark = '#364a5da1';

// used in @media queries for changing UI to suit mobile devices
// in both portrait & landscape orientations
export const mobileSplitPoint = '850px';

// export const collectionSplitPoint = '1180px';
// export const collectionSplitPoint = '1120px';
export const collectionSplitPoint = '1251px';

export const windowInnerWidthSplit = 850;
