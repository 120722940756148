import styled from 'styled-components';

import {
  white,
  veryLightGray,
  darkerGray,
  green,
  mobileSplitPoint,
  black,
  lightGray,
} from '../../style-constants';

export const OrderMethodContainer = styled.div`
  background-color: ${lightGray};
  /*
  border-bottom-color: ${black};
  border-bottom-style: solid;
  */
  margin-bottom: 10px;
  padding: 20px 5px 0px 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${white};
    flex-grow: 2;
    margin-top: 10px;
    margin-bottom: unset;
    padding: 20px 10px 0px 10px;
    width: 100%;
  }
`;
OrderMethodContainer.displayName = 'OrderMethodContainer';

export const TopContainer = styled.div``;
TopContainer.displayName = 'TopContainer';

export const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    /* display: none; */
  }
`;
HeaderContainer.displayName = 'HeaderContainer';

export const Title = styled.div`
  background-color: ${({ orderIsPaid }) => (orderIsPaid ? green : white)};
  background-color: ${lightGray};
  font-weight: 700;
  font-size: 20px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${white};
    margin: unset;
  }
`;
Title.displayName = 'Title';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 100%;
  }
`;
ButtonContainer.displayName = 'ButtonContainer';

export const Button = styled.button`
  background-color: ${({ isSelected }) => (isSelected ? black : veryLightGray)};
  border: 2px solid ${({ isSelected }) => (isSelected ? black : black)};
  border-radius: 30px;
  box-shadow: none;
  color: ${({ isSelected }) => (isSelected ? white : black)};
  cursor: pointer;
  flex-grow: 1;
  font-family: 'Cabin';
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0.075em;
  margin: 0px 1px;
  padding: 0.75em;
  text-align: center;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin: 0px 5px;
  }
`;
Button.displayName = 'Button';

export const PickupButton = styled(Button)`
  -webkit-tap-highlight-color: transparent;
`;
PickupButton.displayName = 'PickupButton';

export const DeliveryButton = styled(Button)`
  -webkit-tap-highlight-color: transparent;
`;
DeliveryButton.displayName = 'DeliveryButton';

export const DineInButton = styled(Button)`
  -webkit-tap-highlight-color: transparent;
`;
DineInButton.displayName = 'DineInButton';

export const DeliveryDistanceContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding: unset;
  }
`;
DeliveryDistanceContainer.displayName = 'DeliveryDistanceContainer';

export const DeliveryDistance = styled.div`
  @media screen and (max-width: ${mobileSplitPoint}) {
    padding-bottom: 10px;
    padding-top: 15px;
  }
`;
DeliveryDistance.displayName = 'DeliveryDistance';
