import styled from 'styled-components';

import { lightGray, mobileSplitPoint, white } from './style-constants';

export const AppContainer = styled.div`
  align-items: flex-start;
  background-color: ${white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
AppContainer.displayName = 'AppContainer';

export const ContentContainer = styled.div`
  max-width: 1300px;
  /* this stops janky width adjustments when loading
  (despite the max-width condition)
  */
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    max-width: unset;
    width: 100%;
  }
`;

ContentContainer.displayName = 'ContentContainer';
