import React from 'react';

import plus from '../../assets/plus.svg';
import minus from '../../assets/minus.svg';
import close from '../../assets/close.svg';
import _ from 'lodash';

import Customizations from '../customizations/customizations.component';
import ItemPrices from '../item-prices/item-prices.container';

import { calcItemAvailabilityStatus } from '../../utils/shop-utils';

import {
  ADD,
  SUBTRACT,
  GST_EXCLUDED,
  AVAILABILITY_STATUS__SOLD_OUT,
} from '../../global.constants';

import {
  StyledModal,
  HeaderContainer,
  CloseButtonAction,
  ButtonImg,
  TitleContainer,
  ItemTitle,
  Price,
  ItemImageContainer,
  ImageContainer,
  ItemImage,
  Description,
  CustomizationsContainer,
  UserInstructionsContainer,
  UserInstructions,
  ButtonContainer,
  OuterSoldOutContainer,
  SoldOutContainer,
  SoldOutText,
  ChangeItemCountContainer,
  CurvedLeftBorder,
  CurvedRightBorder,
  SubtractButtonAction,
  QuantityContainer,
  Quantity,
  AddButtonAction,
  SubmitButton,
  GstContainer,
  GstText,
} from './item-modal.styles';

export const ItemModal = ({
  item,
  sortedPrices,
  selectedPrice,
  setSelectedPrice,
  showItemModal,
  handleCloseOfModal,
  handleItemChangeClick,
  handleSubmission,
  shopData,
  userInstructions,
  handleUserInstructionsChange,
  shopDetails,
  config,
  computeDisplayPrice,
  computeButtonText,
  calcGstBlurb,
  calculateItemPrice,
  isUpdatingItem,
  collection,
}) => {
  const availabilityStatus = calcItemAvailabilityStatus(item, collection);
  const { imageProviderApi } = shopDetails;
  const { disableItemUserInstructions } = config;
  const {
    customizations,
    customizationsOrder,
    description,
    gstSetting,
    imagePath,
    quantity,
    title,
  } = item;

  return (
    <StyledModal
      isOpen={showItemModal}
      onRequestClose={handleCloseOfModal}
      contentLabel="Modal"
    >
      <HeaderContainer>
        <CloseButtonAction onClick={handleCloseOfModal}>
          <ButtonImg src={close} />
        </CloseButtonAction>
        <TitleContainer>
          <Price>{computeDisplayPrice(sortedPrices)}</Price>
        </TitleContainer>
      </HeaderContainer>
      <ItemImageContainer>
        {!!imagePath && (
          <ImageContainer>
            <ItemImage
              src={`${imageProviderApi}/${imagePath}?auto=compress&fit=clamp&q=30&fm=png`}
            />
          </ImageContainer>
        )}
      </ItemImageContainer>
      <ItemTitle haveImage={!!imagePath}>{item && title}</ItemTitle>
      <Description>{item && description}</Description>
      {_.size(sortedPrices) === 1 ? null : (
        <ItemPrices
          sortedPrices={sortedPrices}
          selectedPrice={selectedPrice}
          setSelectedPrice={setSelectedPrice}
        />
      )}
      <CustomizationsContainer>
        <Customizations
          customizations={customizations}
          customizationsOrder={customizationsOrder}
          shopData={shopData}
        />
      </CustomizationsContainer>
      {!disableItemUserInstructions && (
        <UserInstructionsContainer>
          <UserInstructions
            placeholder="Comments/Notes"
            value={userInstructions}
            onChange={handleUserInstructionsChange}
          ></UserInstructions>
        </UserInstructionsContainer>
      )}
      {calculateItemPrice() && gstSetting && gstSetting === GST_EXCLUDED ? (
        <GstContainer>
          <GstText>{calcGstBlurb()}</GstText>
        </GstContainer>
      ) : null}
      {availabilityStatus === AVAILABILITY_STATUS__SOLD_OUT &&
      !isUpdatingItem ? (
        <ButtonContainer availabilityStatus={availabilityStatus}>
          <OuterSoldOutContainer>
            <SoldOutContainer>
              <SoldOutText>Sold Out</SoldOutText>
            </SoldOutContainer>
          </OuterSoldOutContainer>
        </ButtonContainer>
      ) : (
        <ButtonContainer availabilityStatus={availabilityStatus}>
          <ChangeItemCountContainer>
            <CurvedLeftBorder>
              <SubtractButtonAction
                onClick={() =>
                  quantity === 1 ? null : handleItemChangeClick(SUBTRACT)
                }
              >
                <ButtonImg src={minus} />
              </SubtractButtonAction>
            </CurvedLeftBorder>
            <QuantityContainer>
              <Quantity>{quantity}</Quantity>
            </QuantityContainer>
            <CurvedRightBorder>
              <AddButtonAction onClick={() => handleItemChangeClick(ADD)}>
                <ButtonImg src={plus} />
              </AddButtonAction>
            </CurvedRightBorder>
          </ChangeItemCountContainer>
          <SubmitButton onClick={handleSubmission}>
            {computeButtonText()}
          </SubmitButton>
        </ButtonContainer>
      )}
    </StyledModal>
  );
};

export default ItemModal;
