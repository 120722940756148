import React from 'react';

import {
  GroupContainer,
  FormInputContainer,
  FormInputLabel,
  LittleContainer,
  Required,
  Label,
} from './form-input.styles.jsx';

export const FormInput = ({ handleChange, label, error, ...otherProps }) => (
  <GroupContainer error={error}>
    <FormInputContainer onChange={handleChange} {...otherProps} />
    {label ? (
      <FormInputLabel className={otherProps.value.length ? 'shrink' : ''}>
        <LittleContainer>
          <Label>{label}</Label>
          {error && <Required>* required</Required>}
        </LittleContainer>
      </FormInputLabel>
    ) : null}
  </GroupContainer>
);

export default FormInput;
