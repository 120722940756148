import React from 'react';
import _ from 'lodash';
import { MdDone, MdClear } from 'react-icons/md';
import { IconContext } from 'react-icons';
import { FaInfoCircle } from 'react-icons/fa';

import {
  isTodayOpenForOrderType,
  arePreOrdersClosedTodayForNoTimesFeature,
  haveAnyApplicableOverrideDaysForPreOrder,
} from '../../utils/shop-utils';

import {
  OrderStatusContainer,
  InnerContainer,
  DesktopHoursInfoContainer,
  HoursInfoContainer,
  InfoContainer,
  HoursInfo,
  WaitTimeContainer,
  WaitTimeText,
  FeeInfoPostCodesContainer,
  FeeInfoContainer,
  FeeInfoText,
} from './shop-open-status-bar.styles';

import { red, green, black } from '../../style-constants';

import {
  PICKUP,
  DELIVERY,
  DINE_IN,
  ORDERTYPES_TO_SINGULAR_TYPES,
  WANTED_AT_NONE,
  DELIVERY_CHECK_TYPE_POSTCODES,
} from '../../global.constants';

export const ShopOpenStatusBar = ({
  operatingHours,
  displayType,
  config,
  overrideDays,
  wholesaleUserDetails,
  shopDetails,
  handleShopInfoClick,
  handleDeliveryPostcodesFeeInfoClick,
}) => {
  const {
    deliveryToAddressCheckType,
    cannotPlaceOrderForToday,
    orderTypesRetail,
    orderTypesWholesale,
    wantedAtTypes,
  } = config;

  const { waitTime } = shopDetails;

  let _orderTypes = wholesaleUserDetails
    ? orderTypesWholesale
    : orderTypesRetail;

  const _render = () => {
    const innerContent = _renderOrderStatus();
    if (displayType === 'desktop') {
      return (
        <DesktopHoursInfoContainer>{innerContent}</DesktopHoursInfoContainer>
      );
    }

    if (displayType === 'mobile') {
      return <HoursInfoContainer>{innerContent}</HoursInfoContainer>;
    }

    return null;
  };

  const calcIfPreOrderOpenForOtherWantedAtTypes = () => {
    // need to respect which orderTypes are currently supported by shop
    const actualOrderTypesArray = ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes];
    const orderTypesAtLeastOneOpen = _.reduce(
      actualOrderTypesArray,
      (accum, orderType) => {
        const matchedOperatingHrs = _.find(
          operatingHours,
          (operatingHour) => operatingHour.title === orderType
        );
        if (!matchedOperatingHrs) {
          accum[orderType] = false; //assume closed
        }

        // cycle through all days and look at isClosed
        const atLeastOneOpen = _.reduce(
          matchedOperatingHrs.hours,
          (accum, day) => {
            const { isClosed } = day;
            return !isClosed || accum;
          },
          false
        );
        accum[orderType] = atLeastOneOpen;
        return accum;
      },
      {}
    );

    const atLeastOneDayAcrossAllOrderTypesIsOpen = _.reduce(
      orderTypesAtLeastOneOpen,
      (accum, atLeastOneOpen) => {
        return atLeastOneOpen || accum;
      },
      false
    );

    // need to also consider override days
    // for present day or future
    const haveAtLeastOneOpenOverride =
      haveAnyApplicableOverrideDaysForPreOrder(overrideDays);

    let blah = '';

    if (haveAtLeastOneOpenOverride || atLeastOneDayAcrossAllOrderTypesIsOpen) {
      blah = (
        <InfoContainer>
          <HoursInfo isOpen={true}>Preorder OPEN</HoursInfo>
          <MdDone color={green} size="1.2em" />
        </InfoContainer>
      );
    } else {
      blah = (
        <InfoContainer>
          <HoursInfo isOpen={true}>Preorder CLOSED</HoursInfo>
          <MdClear color={red} size="1.2em" />
        </InfoContainer>
      );
    }

    return blah;
  };

  const _renderOrderStatus = () => {
    const isPickupOpen = isTodayOpenForOrderType(
      PICKUP,
      operatingHours,
      overrideDays
    );
    const isDeliveryOpen = isTodayOpenForOrderType(
      DELIVERY,
      operatingHours,
      overrideDays
    );
    const isDineInOpen = isTodayOpenForOrderType(
      DINE_IN,
      operatingHours,
      overrideDays
    );

    // dont show pickup/delivery/dine open-closed status if cannot place order
    // for today. it's confusing and sometimes doesn't make sense
    // (eg. shop says open for today, but cant order for today).
    // ...just show preorders status
    return (
      <OrderStatusContainer>
        <FeeInfoContainer onClick={handleShopInfoClick}>
          <FeeInfoText>Shop Info</FeeInfoText>
          <IconContext.Provider value={{ color: black }}>
            <FaInfoCircle />
          </IconContext.Provider>
        </FeeInfoContainer>

        {deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES && (
          <FeeInfoPostCodesContainer
            onClick={handleDeliveryPostcodesFeeInfoClick}
          >
            <FeeInfoText>Delivery Zones</FeeInfoText>
          </FeeInfoPostCodesContainer>
        )}
        {waitTime && (
          <WaitTimeContainer>
            <WaitTimeText>{`${waitTime} wait`}</WaitTimeText>
          </WaitTimeContainer>
        )}
        {wantedAtTypes === WANTED_AT_NONE ? (
          <InfoContainer>
            {arePreOrdersClosedTodayForNoTimesFeature(
              operatingHours,
              config,
              overrideDays,
              wholesaleUserDetails
            ) ? (
              <>
                <HoursInfo isOpen={true}>Preorder CLOSED</HoursInfo>
                <MdClear color={red} size="1.2em" />
              </>
            ) : (
              <>
                <HoursInfo isOpen={true}>Pre-orders OPEN</HoursInfo>
                <MdDone color={green} size="1.2em" />
              </>
            )}
          </InfoContainer>
        ) : (
          calcIfPreOrderOpenForOtherWantedAtTypes()
        )}
        {!cannotPlaceOrderForToday && (
          <InnerContainer>
            {ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes] &&
              ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes].includes(PICKUP) &&
              (isPickupOpen ? (
                <InfoContainer>
                  <HoursInfo>Pickup OPEN</HoursInfo>
                  <MdDone color={green} size="1.2rem" />
                </InfoContainer>
              ) : (
                <InfoContainer>
                  <HoursInfo>Pickup CLOSED</HoursInfo>
                  <MdClear color={red} size="1.2em" />
                </InfoContainer>
              ))}
            {ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes] &&
              ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes].includes(DELIVERY) &&
              (isDeliveryOpen ? (
                <InfoContainer>
                  <HoursInfo>Delivery OPEN</HoursInfo>
                  <MdDone color={green} size="1.2rem" />
                </InfoContainer>
              ) : (
                <InfoContainer>
                  <HoursInfo>Delivery CLOSED</HoursInfo>
                  <MdClear color={red} size="1.2em" />
                </InfoContainer>
              ))}
            {ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes] &&
              ORDERTYPES_TO_SINGULAR_TYPES[_orderTypes].includes(DINE_IN) &&
              (isDineInOpen ? (
                <InfoContainer>
                  <HoursInfo>Dine In OPEN</HoursInfo>
                  <MdDone color={green} size="1.2rem" />
                </InfoContainer>
              ) : (
                <InfoContainer>
                  <HoursInfo>Dine In CLOSED</HoursInfo>
                  <MdClear color={red} size="1.2em" />
                </InfoContainer>
              ))}
          </InnerContainer>
        )}
      </OrderStatusContainer>
    );
  };

  return _render();
};

export default ShopOpenStatusBar;
