import styled from 'styled-components';
import {
  CardElement,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Switch from 'react-switch';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import TemplateButton from '../template-button/template-button.component';

import {
  black,
  lightGray,
  darkerGray,
  white,
  green,
  red,
  redTransparent,
  mobileSplitPoint,
  lightBlue,
  veryLightGray,
} from '../../style-constants';

export const PaymentDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  width: 85%;
  margin-top: 10px;
  margin-bottom: 80px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin: 15px 0px;
    padding: unset;
    top: unset;
    width: 100%;
  }
`;
PaymentDetailsContainer.displayName = 'PaymentDetailsContainer';

export const PaymentInformationContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
PaymentInformationContainer.displayName = 'PaymentInformationContainer';

export const PaymentInformation = styled.div`
  background: ${veryLightGray};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: 30px;
    left: unset;
    margin: unset;
    padding: 25px;
    position: relative;
    top: unset;
    width: 100%;
  }
`;
PaymentInformation.displayName = 'PaymentInformation';

export const PaymentForm = styled.form``;
PaymentForm.displayName = 'PaymentForm';

export const SelectTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
SelectTitleContainer.displayName = 'SelectTitleContainer';

export const SelectTitle = styled.div`
  margin-bottom: 15px;
`;
SelectTitle.displayName = 'SelectTitle';

export const CardContainer = styled.div`
  border-radius: 8px;
  margin: 0px 0px 30px 0px;
  padding: 40px 0px 20px 0px;
`;
CardContainer.displayName = 'CardContainer';

export const CardInnerContainer = styled.div`
  align-items: center;
  border: 2px solid ${darkerGray};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 180px;
  margin-bottom: 20px;
  padding: 10px 20px 10px 15px;
  width: 100%;
`;
CardInnerContainer.displayName = 'CardInnerContainer';

export const CardRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
CardRowContainer.displayName = 'CardRowContainer';

export const CardNumberRowContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
CardNumberRowContainer.displayName = 'CardNumberRowContainer ';

export const CardExpiryRowContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
`;
CardExpiryRowContainer.displayName = 'CardExpiryRowContainer ';

export const CardCvcRowContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
`;
CardCvcRowContainer.displayName = 'CardCvcRowContainer ';

export const CardFieldTitle = styled.div`
  font-size: 14px;
  font-weight: 800;
  padding-bottom: 5px;
`;

export const CardFieldNumberTitle = styled.div`
  font-size: 14px;
  font-weight: 800;
  padding-bottom: 10px;
`;

export const CardElementContainer = styled(CardElement)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${darkerGray};
  padding-bottom: 5px;
  width: 100%;
`;
CardElementContainer.displayName = 'CardElementContainer';

export const CardNumberElementContainer = styled(CardNumberElement)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${darkerGray};
  margin-bottom: 30px;
  width: 100%;
`;
CardNumberElementContainer.displayName = 'CardNumberElementContainer';

export const CardExpiryElementContainer = styled(CardExpiryElement)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${darkerGray};
  width: 65%;
`;
CardExpiryElementContainer.displayName = 'CardExpiryElementContainer';

export const CardCvcElementContainer = styled(CardCvcElement)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${darkerGray};
  width: 50%;
`;
CardCvcElementContainer.displayName = 'CardCvcElementContainer ';

export const CardDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;
CardDetailsContainer.displayName = 'CardDetailsContainer';

export const CCNumberContainer = styled.div`
  align-items: flex-start;
  background-color: ${red};
  display: flex;
  flex-direction: column;
  /* height: 50px; */
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;
CCNumberContainer.displayName = 'CCNumberContainer';

export const PaymentMethodsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
PaymentDetailsContainer.displayName = 'PaymentMethodsContainer';

export const Title = styled.div`
  font-weight: 800;
  font-size: 20px;
  padding-bottom: 15px;
`;
Title.displayName = 'Title';

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
  margin-bottom: 20px;
`;
TitleContainer.displayName = 'TitleContainer';

export const RowsContainer = styled.div`
  background-color: ${white};
`;
RowsContainer.displayName = 'RowsContainer';

export const Required = styled.span`
  color: ${red};
  margin-left: 10px;
`;
Required.displayName = 'Required';

export const PlaceOrderButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
PlaceOrderButtonContainer.displayName = 'PlaceOrderButtonContainer';

export const PlaceOrderButton = styled(TemplateButton)`
  background-color: ${({ isLoading, disabled }) =>
    isLoading || disabled ? lightGray : red};
  border: 1px solid
    ${({ isLoading, disabled }) => (isLoading || disabled ? lightGray : red)};
  cursor: ${({ isLoading, disabled }) =>
    isLoading || disabled ? 'not-allowed' : 'pointer'};
  padding: 15px 30px;
  font-size: 18px;
`;
PlaceOrderButton.displayName = 'PlaceOrderButton';

export const PlaceOrderText = styled.div`
  color: ${white};
  font-weight: 700;
  font-size: 18px;
`;
PlaceOrderText.displayName = 'PlaceOrderText';

export const DateWantedContainer = styled.div`
  border: ${({ error }) => (error ? `1px solid ${red}` : 'none')};
  border-radius: 3px;
  margin: 50px 0px 30px 0px;
  width: 100%;
`;
DateWantedContainer.displayName = 'DateWantedContainer';

export const DateContainer = styled.div`
  width: 100%;
`;
DateContainer.displayName = 'DateContainer';

export const DateSelector = styled(DatePicker).attrs((props) => ({
  // height: 100,
}))`
  height: 35px;
  border: 1px solid ${darkerGray};
  border-radius: 3px;
  font-size: 16px;
  padding-left: 10px;
  width: 100%;
`;
DateSelector.displayName = 'DateSelector';

export const TimeWantedContainer = styled.div`
  border: ${({ error }) => (error ? `1px solid ${red}` : 'none')};
  border-radius: 3px;
  margin: 50px 0px 0px 0px;
`;
TimeWantedContainer.displayName = 'TimeWantedContainer';

export const TimeTitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
TimeTitleContainer.displayName = 'TimeTitleContainer';

export const TimesButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
TimesButtonsContainer.displayName = 'TimesButtonsContainer';

export const TimeActionContainer = styled.div`
  align-items: center;
  background-color: ${({ isSelected, isDisabled }) => {
    if (isDisabled) return darkerGray;
    return isSelected ? green : white;
  }};
  border: 1px solid
    ${({ isSelected, isDisabled }) => {
      if (isDisabled) return darkerGray;
      return isSelected ? green : lightGray;
    }};
  border-radius: 3px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  height: 33px;
  justify-content: center;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 21.5%;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
TimeActionContainer.displayName = 'TimeActionContainer';

export const TimeAction = styled.div`
  color: ${({ isSelected }) => (isSelected ? white : black)};
  padding: 10px 20px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
TimeAction.displayName = 'TimeAction';

export const ClosedMsg = styled.div`
  background-color: ${redTransparent};
  border-radius: 3px;
  margin: 10px 0px;
  padding: 5px;
`;
ClosedMsg.displayName = 'ClosedMsg';

export const SpinnerContainer = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export const SwitchContainerConsent = styled.div`
  border: 2px solid ${lightBlue};
  border-radius: 3px;
  margin-top: 30px;
  padding: 15px 0px 15px 10px;
  width: 100%;
`;
SwitchContainerConsent.displayName = 'SwitchContainerConsent';

export const SwitchContainer = styled.div`
  padding-top: 30px;
  width: 100%;
`;
SwitchContainer.displayName = 'SwitchContainer';

export const SwitchLabel = styled.label`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
SwitchLabel.displayName = 'SwitchLabel';

export const SwitchLabelInfo = styled.span`
  padding: 0px 10px;
`;
SwitchLabelInfo.displayName = 'SwitchLabelInfo';

export const SwitchReact = styled(Switch).attrs((props) => ({}))``;
SwitchReact.displayName = 'SwitchReact';

export const DeliveryAddressContainer = styled.div``;
DeliveryAddressContainer.displayName = 'DeliveryAddressContainer';

export const UseDifferentCardContainer = styled.div`
  align-items: center;
  background-color: ${lightGray};
  border: 2px solid ${darkerGray};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 18px;
  padding: 10px 15px;

  -webkit-tap-highlight-color: transparent;
`;
UseDifferentCardContainer.displayName = 'UseDifferentCardContainer';

export const UseDifferentCardText = styled.div`
  font-weight: 700;
`;
UseDifferentCardText.displayName = 'UseDifferentCardText';

export const SwitchContainerSaveCard = styled.div`
  padding-top: 15px
  width: 100%;
`;
SwitchContainerSaveCard.displayName = 'SwitchContainerSaveCard';
