import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import close from '../../assets/close.svg';

import {
  selectShopDetails,
  selectShowDynamicDeliveryFeeInfoModal,
} from '../../redux/shop/shop.selectors';

import { setShowDynamicDeliveryFeeInfoModal } from '../../redux/shop/shop.actions';

import { useScrollLock } from '../../hooks/use-scroll-lock';

import {
  StyledModal,
  HeaderContainer,
  CloseButtonAction,
  ButtonImg,
  Spacer,
  MainTitle,
  BodyContainer,
  Info,
} from './dynamic-delivery-fee-info-modal.styles';

export const DynamicDeliveryFeeInfoModal = ({
  shopDetails,
  showDynamicDeliveryFeeInfoModal,
  setShowDynamicDeliveryFeeInfoModal,
}) => {
  useScrollLock(showDynamicDeliveryFeeInfoModal);

  const { deliveryFeeDynamicTypeMinimum, deliveryFeeDynamicTypeMaximum } =
    shopDetails;

  const handleCloseOfModal = () => {
    setShowDynamicDeliveryFeeInfoModal(false);
  };

  const clientWidthSafe = () => {
    let clientWidth = 700;
    if (
      !document ||
      !document.documentElement ||
      !document.documentElement.clientWidth
    )
      return clientWidth;

    return document.documentElement.clientWidth;
  };

  return (
    <StyledModal
      isOpen={showDynamicDeliveryFeeInfoModal}
      onRequestClose={handleCloseOfModal}
      contentLabel="Modal"
      clientWidth={clientWidthSafe()}
    >
      <HeaderContainer>
        <CloseButtonAction onClick={handleCloseOfModal}>
          <ButtonImg src={close} />
        </CloseButtonAction>
        <MainTitle>Delivery Fee Info</MainTitle>
        <Spacer />
      </HeaderContainer>
      <BodyContainer>
        <Info>{`We calculate your delivery fee based on the distance you are from our location.`}</Info>
        <Info>{`The delivery fee range between $${deliveryFeeDynamicTypeMinimum} and $${deliveryFeeDynamicTypeMaximum}`}</Info>
      </BodyContainer>
    </StyledModal>
  );
};

const mapStateToProps = createStructuredSelector({
  shopDetails: selectShopDetails,
  showDynamicDeliveryFeeInfoModal: selectShowDynamicDeliveryFeeInfoModal,
});

const mapDispatchToProps = (dispatch) => ({
  setShowDynamicDeliveryFeeInfoModal: (show) =>
    dispatch(setShowDynamicDeliveryFeeInfoModal(show)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDeliveryFeeInfoModal);
