import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import Customization from './customization.component';

import {
  addCustomizationOption,
  removeCustomizationOption,
} from '../../redux/shop/shop.actions';

import {
  selectShopData,
  selectItemModalUserCustomizations,
} from '../../redux/shop/shop.selectors';

const CustomizationContainer = ({
  shopData,
  customizationKey,
  addCustomizationOption,
  removeCustomizationOption,
  userCustomizations,
}) => {
  const {
    displayTitle,
    isMultiSelect,
    isRemoveType,
    isRequired,
    maxSelect,
    options,
  } = shopData.customizations[customizationKey];

  const sortedOptions = _.sortBy(
    _.map(options, (val, optionKey) => ({
      optionKey,
      ...shopData.options[optionKey],
    })),
    (optionObj) => optionObj.title
  );

  const isAtMaxSelectsForCustomization = (userCustomizations, maxSelect) => {
    // we need to only consider the options relevant to the given customization,
    // which is identified by its customizationKey coming from props
    const relevantCusts = _.filter(
      userCustomizations,
      (aCust) => aCust.customizationKey === customizationKey
    );
    const relevantSize = _.size(relevantCusts);

    return relevantSize >= maxSelect;
  };

  const handleClick = (option) => {
    // IMPORTANT: need to add the type of customization for the option clicked.
    // ie. we need to "Add" or "Remove" or "Single choice" or ..., to our option.
    // this is because later on, we need to group all customizations for a given item
    // into their types ("Add" or "Remove" etc), to display to the customer before they finalise
    // their order, and, to correctly print out the docket telling the kitchen what options to add,
    // what options to remove, and what options to switch etc.
    //
    // we get the type of customization from the title of the current customization component, which
    // is here, in this component.
    //
    // NOTE: the payload.id comes from the option object.
    // see below how we use payload.id with the 'filter' function
    // as to whether the option has, say, been selected.
    const payload = {
      ...option,
      // we want to use displayTitle, not title, here
      // because user-facing title for customization should be
      // the displayTitle.
      // title is for identifying the customization in kitchen whoods app
      // ie. for back-of-house purposes only
      customizationType: displayTitle,
      customizationKey,
      isRemoveType,
    };

    const optionAlreadySelected = _.find(
      userCustomizations,
      (selectedOption) =>
        selectedOption.customizationKey === payload.customizationKey &&
        selectedOption.title === payload.title
    );

    if (isMultiSelect) {
      // customizations with multiple selections possible
      if (optionAlreadySelected) {
        // remove it
        removeCustomizationOption(payload);
      } else if (
        isAtMaxSelectsForCustomization(userCustomizations, maxSelect)
      ) {
        // at max number of options selecteed. do nothing
        return;
      } else {
        // add it
        addCustomizationOption(payload);
      }
    } else {
      // customizations with *single* selections only

      // see if there are any other single options in userCustomizations belonging
      // to *this* customization key
      // if nothing is found then undefined is returned
      const previousSingleCustomization = _.find(
        userCustomizations,
        (selectedOption) =>
          selectedOption.customizationKey === payload.customizationKey
      );

      if (previousSingleCustomization) {
        if (
          previousSingleCustomization.customizationKey ===
            payload.customizationKey &&
          previousSingleCustomization.title === payload.title
        ) {
          // user wants to toggle off the single select item
          removeCustomizationOption(previousSingleCustomization);
        } else {
          // user is switching between 2 different single select items
          removeCustomizationOption(previousSingleCustomization);
          addCustomizationOption(payload);
        }
      } else {
        // no previous single selected items
        addCustomizationOption(payload);
      }
    }
  };

  return (
    <Customization
      displayTitle={displayTitle}
      isRequired={isRequired}
      isMultiSelect={isMultiSelect}
      maxSelect={maxSelect}
      customizationKey={customizationKey}
      sortedOptions={sortedOptions}
      handleClick={handleClick}
      selectedOptions={userCustomizations}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  shopData: selectShopData,
  userCustomizations: selectItemModalUserCustomizations,
});

const mapDispatchToProps = (dispatch) => ({
  addCustomizationOption: (option) => dispatch(addCustomizationOption(option)),
  removeCustomizationOption: (option) =>
    dispatch(removeCustomizationOption(option)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizationContainer);
