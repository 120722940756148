import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import Swal from 'sweetalert2';

import LoginWholesaleCustomer from './login-wholesale-customer.component';

import {
  loginWholesaleUserStart,
  sendPasswordResetEmailStart,
  resetErrorAndLoadingState,
} from '../../redux/user/user.actions';
import {
  selectErrorMessage,
  selectWholesaleUserDetails,
  selectIsLoading,
} from '../../redux/user/user.selectors';

import { selectConfig } from '../../redux/config/config.selectors';

export const LoginWholesaleCustomerContainer = ({
  loginWholesaleUserStart,
  errorMessage,
  wholesaleUserDetails,
  isLoading,
  sendPasswordResetEmailStart,
  resetErrorAndLoadingState,
  config,
}) => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
  });
  const [hasForgotPassword, setHasForgotPassword] = useState(false);

  useEffect(() => {
    resetErrorAndLoadingState();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(wholesaleUserDetails)) {
      // redirect user to shop page after successful login
      navigate('/');
    }
  }, [wholesaleUserDetails]);

  const handleBackClick = () => {
    navigate('/');
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  const handleLoginClick = () => {
    const { email, password } = loginDetails;
    if (!email || !password) {
      Swal.fire('Please fill out email and password fields');
      return;
    }
    loginWholesaleUserStart(loginDetails, config);
  };

  const toggleForgotPassword = () => {
    setHasForgotPassword(!hasForgotPassword);
    resetErrorAndLoadingState();
    setLoginDetails({
      email: '',
      password: '',
    });
  };

  const handleForgotPassword = () => {
    const { email } = loginDetails;
    if (!email) {
      Swal.fire('Please fill out email');
      return;
    }

    sendPasswordResetEmailStart(email);
  };

  return (
    <LoginWholesaleCustomer
      loginDetails={loginDetails}
      handleBackClick={handleBackClick}
      handleChange={handleChange}
      handleLoginClick={handleLoginClick}
      errorMessage={errorMessage}
      isLoading={isLoading}
      handleForgotPassword={handleForgotPassword}
      toggleForgotPassword={toggleForgotPassword}
      hasForgotPassword={hasForgotPassword}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  errorMessage: selectErrorMessage,
  wholesaleUserDetails: selectWholesaleUserDetails,
  isLoading: selectIsLoading,
  config: selectConfig,
});

const mapDispatchToProps = (dispatch) => ({
  loginWholesaleUserStart: (loginDetails, config) =>
    dispatch(loginWholesaleUserStart(loginDetails, config)),
  sendPasswordResetEmailStart: (email) =>
    dispatch(sendPasswordResetEmailStart(email)),
  resetErrorAndLoadingState: () => dispatch(resetErrorAndLoadingState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginWholesaleCustomerContainer);
