import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MdPhone, MdEmail, MdHome } from 'react-icons/md';

import { selectShopDetails } from '../../redux/shop/shop.selectors';

import {
  ContactContainer,
  Title,
  TitleContainer,
  Row,
  Link,
  AddressContainer,
  IconContainer,
} from './contact-details.styles';

export const ContactDetails = ({ shopDetails }) => {
  const {
    shopPhoneNumber,
    shopAddressLine1,
    shopAddressLine2,
    shopAddressLine3,
    shopPostcode,
    shopEmail,
  } = shopDetails;

  return (
    <ContactContainer>
      <TitleContainer>
        <Title>Contact Details</Title>
      </TitleContainer>
      <Row>
        <MdPhone size="1.2em" />
        <Link href={`tel:${shopPhoneNumber}`}>{shopPhoneNumber}</Link>
      </Row>
      <Row>
        <IconContainer>
          <MdHome size="1.2em" />
        </IconContainer>
        <AddressContainer>
          {!!shopAddressLine1 && <div>{shopAddressLine1}</div>}
          {!!shopAddressLine2 && <div>{shopAddressLine2}</div>}
          {!!shopAddressLine3 && (
            <div>{`${shopAddressLine3}, ${shopPostcode}`}</div>
          )}
        </AddressContainer>
      </Row>
      <Row>
        <MdEmail size="1.2em" />
        <Link href={`mailto:${shopEmail}`}>{shopEmail}</Link>
      </Row>
    </ContactContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  shopDetails: selectShopDetails,
});

export default connect(mapStateToProps, null)(ContactDetails);
